import { AttachFile, Description } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, MenuItem, Select, Stack, Typography, CircularProgress } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React, { useContext, useMemo, useState } from "react";
import { CustomModalComponent } from "../../../CustomModalComponent";
import { formatFileSize } from "../../../../const/globalConst";
import { SearchbarSelector } from "./SearchbarSelector";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SnackBarContext } from "../../../../context/snackBarContext";
import { UseGetPresignedURLUpload, uploadFileToS3 } from "../../../../lib/s3Client";
import { UserContext } from "../../../../context/userContext";
import { AddFileModal } from "../../../MiLecosy/AddFileModal";
import { Form, Formik, FormikProps } from "formik";
import { createPowerGranted } from "../../../../lib/usersBEClient";
import { useGetMembersInfo } from "../../../../hooks/gob-corp/corporateData/useGetMembersInfo";
import { CorporateDataContext } from "../../../../context/governanceContext/corporateDataContext";
import { IFile } from "../../../../types/BaseTypes";

const PODERES = [
   "Poder general para pleitos y cobranzas",
   "Poder general para actos de administración",
   "Poder en materia laboral",
   "Poder general para actos de dominio",
   "Poder para suscribir títulos y operaciones de crédito",
   "Poder para efectos bancarios y cambiarios",
   "Poder para otorgar y delegar poderes generales o especiales",
   "Facultades para ejercitar actos de administración aduanal",
   "Poder general para llevar a cabo actos de rescisión",
   "Poder para efectos fiscales",
];

const AddPowerModal = (props: { open: boolean; setOpen: Function; onSuccess?: Function }) => {
   const { companySelected } = useContext(UserContext);
   const [open, setOpen] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [file, setFile] = useState<null | File>(null);
   const { showSnackBar } = useContext(SnackBarContext);
   const { formatedMembers, members } = useGetMembersInfo(false);
   const { governanceBody } = useContext(CorporateDataContext);

   const memberName = useMemo(() => {
      return members?.map((member) => ({
         _id: member.user,
         firstName: member.firstName,
         lastName: member.lastName,
      }));
   }, [formatedMembers, members]);

   const handleUploadFile = async (fileResponse: { filecreated: IFile; urlToUpload: string }, file: File) => {
      try {
         await uploadFileToS3(fileResponse.urlToUpload, file);
      } catch (error) {
         console.error("Error uploading file:", error);
         showSnackBar("Error al subir el archivo", true);
      }
   };

   const handleSave = async (values) => {
      try {
         setIsLoading(true);
         if (!values.powerGranted) {
            showSnackBar("Seleccione un poder", true);
            return;
         }

         if (!values.user && !values.representativeName) {
            showSnackBar("Seleccione un apoderado", true);
            return;
         }

         if (!file) {
            showSnackBar("Adjunte el documento del poder", true);
            return;
         }

         const data = {
            powerGranted: values.powerGranted,
            user: values.user || null,
            grantDate: values.grantDate,
            expirationDate: values.expirationDate,
            company: companySelected._id,
            representativeName: values.representativeName,
            file: { name: file.name, size: file.size, type: file.type.split("/")[1] },
            governanceBodyId: governanceBody._id,
         };

         const response = await createPowerGranted(data);

         await handleUploadFile(response.file, file);

         showSnackBar("Poder agregado correctamente", false);
         props.setOpen(false);

         if (props.onSuccess) {
            props.onSuccess();
         }
      } catch (error) {
         console.error(error);
         showSnackBar("Error al guardar el poder", true);
      } finally {
         setIsLoading(false);
      }
   };

   return (
      <>
         <CustomModalComponent title="Otorgar otro poder" setOpen={props.setOpen} open={props.open}>
            <Formik
               initialValues={{
                  powerGranted: "",
                  user: "",
                  grantDate: "",
                  expirationDate: "",
                  file: "",
                  representativeName: "",
               }}
               onSubmit={handleSave}
            >
               {(formProps: FormikProps<any>) => (
                  <Form>
                     <Box
                        sx={{
                           display: "flex",
                           flexDirection: "column",
                           maxHeight: 500,
                           width: 700,
                           px: 4,
                           py: 2,
                           rowGap: 2,
                           bgcolor: "white",
                           overflow: "auto",
                        }}
                     >
                        <Box sx={{ display: "flex", columnGap: 8, justifyContent: "space-between" }}>
                           <Stack sx={{ width: "100%", rowGap: 1 }}>
                              <>
                                 <Typography>Poder otorgado:</Typography>
                                 <Select
                                    value={formProps.values.powerGranted}
                                    onChange={(event) => {
                                       formProps.setFieldValue("powerGranted", event.target.value);
                                    }}
                                    size="small"
                                    fullWidth
                                    sx={{
                                       bgcolor: "#F5F5F5",
                                       "& .MuiOutlinedInput-notchedOutline": {
                                          borderColor: "#E0E0E0",
                                       },
                                    }}
                                 >
                                    {PODERES.map((poder) => (
                                       <MenuItem key={poder} value={poder}>
                                          {poder}
                                       </MenuItem>
                                    ))}
                                 </Select>

                                 <Box sx={{ display: "flex", flexDirection: "column", rowGap: 1, paddingTop: 1 }}>
                                    <Typography>Apoderado:</Typography>
                                    <SearchbarSelector
                                       users={memberName}
                                       isLoading={isLoading}
                                       placeholder="Selecciona o agrega apoderado"
                                       setSelectedUser={(user) => {
                                          formProps.setFieldValue("user", user?._id);

                                          formProps.setFieldValue("representativeName", user?.name);
                                       }}
                                    />
                                 </Box>

                                 <Box sx={{ display: "flex", gap: 5 }}>
                                    <Box sx={{ flex: 1 }}>
                                       <Typography sx={{ mb: 1 }}>Fecha de otorgamiento:</Typography>
                                       <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                          <DatePicker
                                             value={formProps.values.grantDate}
                                             onChange={(newValue) => formProps.setFieldValue("grantDate", newValue.$d)}
                                             format="DD / MMMM / YYYY"
                                             views={["year", "month", "day"]}
                                             slotProps={{
                                                textField: {
                                                   variant: "outlined",
                                                   size: "small",
                                                   fullWidth: true,
                                                   sx: {
                                                      bgcolor: "#F5F5F5",
                                                      borderColor: "#E0E0E0",
                                                   },
                                                },
                                             }}
                                          />
                                       </LocalizationProvider>
                                    </Box>

                                    <Box sx={{ flex: 1 }}>
                                       <Typography sx={{ mb: 1 }}>Fecha de vencimiento:</Typography>
                                       <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                          <DatePicker
                                             value={formProps.values.expirationDate}
                                             onChange={(newValue) =>
                                                formProps.setFieldValue("expirationDate", newValue.$d)
                                             }
                                             format="DD / MMMM / YYYY"
                                             views={["year", "month", "day"]}
                                             slotProps={{
                                                textField: {
                                                   variant: "outlined",
                                                   size: "small",
                                                   fullWidth: true,
                                                   sx: {
                                                      bgcolor: "#F5F5F5",
                                                      borderColor: "#E0E0E0",
                                                   },
                                                },
                                             }}
                                          />
                                       </LocalizationProvider>
                                    </Box>
                                 </Box>

                                 <Box sx={{ display: "flex", flexDirection: "column", rowGap: 1, paddingTop: 1 }}>
                                    <Typography variant="body2" fontWeight={600}>
                                       Documento del poder
                                    </Typography>
                                    <Grid
                                       container
                                       sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          flexDirection: "row",
                                          border: 1,
                                          borderColor: "#E0E0E0",
                                          width: "100%",
                                          mt: 1,
                                          px: 1,
                                       }}
                                    >
                                       {file ? (
                                          <>
                                             <Grid
                                                xs={9}
                                                sx={{
                                                   display: "flex",
                                                   flexDirection: "row",
                                                   alignItems: "center",
                                                   columnGap: 2,
                                                }}
                                             >
                                                <IconButton onClick={() => {}}>
                                                   <Description />
                                                </IconButton>
                                                <Typography fontSize={12}>{file.name.substring(0, 20)}</Typography>
                                             </Grid>
                                             <Grid
                                                xs={2}
                                                sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                                             >
                                                <Typography fontSize={12}>{formatFileSize(file.size)}</Typography>
                                             </Grid>
                                             <Grid
                                                xs={1}
                                                sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                                             >
                                                <IconButton onClick={() => setOpen(true)}>
                                                   <AttachFile />
                                                </IconButton>
                                             </Grid>
                                          </>
                                       ) : (
                                          <>
                                             <Grid xs={9}>
                                                <Typography fontSize={13}>Adjuntar archivo</Typography>
                                             </Grid>
                                             <Grid
                                                xs={3}
                                                sx={{
                                                   display: "flex",
                                                   flexDirection: "row",
                                                   alignItems: "center",
                                                   columnGap: 3,
                                                }}
                                             >
                                                <Typography fontSize={13}>Subir nuevo</Typography>
                                                <IconButton onClick={() => setOpen(true)}>
                                                   <AttachFile />
                                                </IconButton>
                                             </Grid>
                                          </>
                                       )}
                                    </Grid>
                                 </Box>
                              </>
                           </Stack>
                        </Box>
                     </Box>

                     <Box
                        sx={{
                           display: "flex",
                           justifyContent: "flex-end",
                           bgcolor: "#F3F3F3",
                           width: "100%",
                           p: 1,
                           height: 50,
                           borderBottomRightRadius: 5,
                           borderBottomLeftRadius: 5,
                        }}
                     >
                        <Button
                           onClick={() => props.setOpen(false)}
                           sx={{ color: "black", height: 35, ":hover": { bgcolor: "#E5E6EB" }, mr: 2 }}
                        >
                           Cancelar
                        </Button>
                        <Button
                           type="submit"
                           disabled={isLoading}
                           sx={{ height: 35, ":hover": { bgcolor: "success" } }}
                        >
                           {isLoading ? <CircularProgress size={24} /> : "Guardar"}
                        </Button>
                     </Box>
                  </Form>
               )}
            </Formik>
         </CustomModalComponent>

         <AddFileModal state={open} setState={setOpen} setFile={setFile} file={file} />
      </>
   );
};

export default AddPowerModal;
