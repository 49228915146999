import { Box, Typography, Card, IconButton } from "@mui/material";
import { useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GovernanceContext } from "../../context/governanceContext/governanceContext";
import ArrowBack from "@mui/icons-material/ArrowBack";

export const SecondTopMenu = () => {
   const { companySelected, selectedGovernance, setSelectedSection, selectedSection, documentSelected } =
      useContext(GovernanceContext);
   const navigate = useNavigate();
   const route = useLocation();

   const commonOptions = [
      { lable: "Miembros", path: "miembros" },
      { lable: "Mi perfil", path: "perfil" },
      { lable: "Chat", path: "chat" },
   ];

   let menuOptions;
   if (route.pathname.includes("mi-cuenta/miembros")) menuOptions = [commonOptions[1], commonOptions[2]];
   else if (route.pathname.includes("mi-cuenta/perfil")) menuOptions = [commonOptions[0], commonOptions[2]];
   else if (route.pathname.includes("mi-cuenta/chat")) menuOptions = [commonOptions[0], commonOptions[1]];
   else if (selectedGovernance?.title === "PANEL DE USUARIO") menuOptions = commonOptions;
   else if (selectedGovernance?.title !== "PANEL DE USUARIO") menuOptions = [commonOptions[0]];
   else menuOptions = commonOptions;

   const back: boolean =
      route.pathname.includes("archivos/folder") ||
      route.pathname.includes("documentos/folder") ||
      route.pathname.includes("reportes/evaluation");
   return (
      <Card
         sx={{
            borderRadius: 2,
            display: "flex",
            alignItems: "center",
            py: 2,
            px: 4,
         }}
      >
         <Box sx={{ flex: 1, display: "flex", alignItems: "center", columnGap: 1 }}>
            {back && (
               <IconButton onClick={() => navigate(-1)} sx={{ m: 0, p: 0 }}>
                  <ArrowBack />
               </IconButton>
            )}
            <Typography sx={{ flex: 1 }} variant="h6">
               {route.pathname.endsWith("documentosVerificados") || route.pathname.endsWith("archivosPorVerificar")
                  ? "Documentos de miembros"
                  : documentSelected && back
                  ? documentSelected.originalName ?? documentSelected.name
                  : selectedSection}
            </Typography>
         </Box>
         <Box sx={{ flex: 1, display: "flex", columnGap: 2, justifyContent: "flex-end" }}>
            {menuOptions.map((option, index) => (
               <Box key={index} onClick={() => setSelectedSection(option.lable)}>
                  <Link
                     to={`/gobierno-corporativo/${companySelected}/mi-cuenta/${option.path}`}
                     style={{ textDecoration: "none", color: "gray" }}
                  >
                     <Typography variant="subtitle2">{option.lable}</Typography>
                  </Link>
               </Box>
            ))}
         </Box>
      </Card>
   );
};
