import React from "react";
import { SystemThemeConfig } from "../../../config/systemTheme.config";
import ScreenHeaderExternal from "../../../components/Gobierno corporativo/ExternalBill/ScreenHeaderExternal";
import { Box } from "@mui/material";
import BeneficiaryQuestionaryModal from "../../../components/Beneficiary Controller/BeneficiaryDashboard/PossibleBeneficiaries/Questionary/BeneficiaryQuestionaryModal";

const ExternalQuestionaryScreen = () => {
   return (
      <ExternalBase>
         <BeneficiaryQuestionaryModal state={true} />
      </ExternalBase>
   );
};

const ExternalBase = ({ children }) => {
   return (
      <SystemThemeConfig>
         <ScreenHeaderExternal />
         <Box
            sx={{
               height: "100vh",
               overflowY: "auto",
               bgcolor: "#F5F5F5",
               p: { xs: 0, lg: 5 },
               pt: { xs: 8, lg: 12 },
            }}
         >
            {children}
         </Box>
      </SystemThemeConfig>
   );
};

export default ExternalQuestionaryScreen;
