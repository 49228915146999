import {
   Button,
   Grid,
   Typography,
   Box,
   Radio,
   RadioGroup,
   FormControlLabel,
   FormControl,
   Tooltip,
} from "@mui/material";
import { InputTextField } from "../../components/Inputs/InputTextField";
import MenuItem from "@mui/material/MenuItem";
import { Edit } from "@mui/icons-material";
import { CloudUpload } from "@mui/icons-material";
import { useState } from "react";
import Close from "@mui/icons-material/Close";
import { Select, FormHelperText, InputLabel } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
// Constantes
const LABELS = ["FIDEICOMISO", "ORGANIZACIÓN  DE ADMINISTRACIÓN", "FIDEICOMITENTE", "FIDEICOMISARIO", "FIDUCIARIA"];
const CIRCLE_STYLES = {
   width: "25px",
   height: "25px",
   borderRadius: "50%",
   bgcolor: "#9E9E9E",
   display: "flex",
   alignItems: "center",
   justifyContent: "center",
   color: "white",
   fontWeight: "bold",
};
const LINE_STYLES = {
   top: "55%",
   position: "absolute",
   display: "block",
   left: "50%",
   transform: "translateX(-50%)",
   width: "1px",
   height: "50%",
   bgcolor: "#9E9E9E",
};

const CircleWithLabel = ({ number, label, showLine, isActive }) => (
   <Box
      sx={{
         display: "flex",
         flexDirection: "column",
         alignItems: "center",
         gap: 1,
         position: "relative",
         height: "100%",
      }}
   >
      {showLine && <Box sx={LINE_STYLES} />}
      <Box
         sx={{
            width: 20,
            height: 20,
            borderRadius: "50%",
            bgcolor: isActive ? "#000000" : "#9E9E9E",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            fontWeight: "bold",
         }}
      >
         <Typography variant="body1">{number}</Typography>
      </Box>
      <Typography
         variant="body2"
         sx={{ color: isActive ? "#000000" : "#9E9E9E", textAlign: "center", fontSize: "12px" }}
      >
         {label}
      </Typography>
   </Box>
);

const StepContent = ({ currentStep, formik, openFileModal, setOpenFileModal }) => {
   const [tieneComiteTecnico, setTieneComiteTecnico] = useState("No");
   const [tieneProtectorFiducioso, setTieneProtectorFiducioso] = useState("No");
   const [grids, setGrids] = useState([]);

   const agregarGrid = () => {
      const nuevoMiembro = {
         id: Date.now(), // ID único
         cargo: "", // Valor inicial del cargo
         nombre: "", // Valor inicial del nombre
      };
      formik.setFieldValue("miembrosComite", [...formik.values.miembrosComite, nuevoMiembro]);
      setGrids([...grids, nuevoMiembro]);
   };

   const eliminarGrid = (id) => {
      const nuevosMiembros = formik.values.miembrosComite.filter((miembro) => miembro.id !== id);
      formik.setFieldValue("miembrosComite", nuevosMiembros);
   };

   switch (currentStep) {
      case 1:
         return (
            <Grid container spacing={4} sx={{ width: "90%", height: "100%", margin: "auto", marginLeft: "12px" }}>
               <Grid item sx={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "10px" }}>
                  <Grid item sx={{ width: "100%" }}>
                     <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                        Fecha de inicio
                     </Typography>
                     <InputTextField id="fechainicio" name="fechainicio" type="date" size="small" fullWidth />
                  </Grid>

                  <Grid item sx={{ width: "100%" }}>
                     <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                        Duración
                     </Typography>
                     <InputTextField
                        id="duracion"
                        endAdornment={
                           <Tooltip
                              title={
                                 <span
                                    dangerouslySetInnerHTML={{
                                       __html: "Usar el formato : a|años|m|meses|d|días.<br />Por ejemplo : 2a 10m 16d",
                                    }}
                                 />
                              }
                           >
                              <InfoIcon sx={{ fontSize: "18px" }} />
                           </Tooltip>
                        }
                        type="text"
                        label="Xa | Xm | Xs | Xd"
                        name="duracion"
                        size="small"
                        fullWidth
                        variant={"outlined"}
                     />
                  </Grid>

                  <Grid item sx={{ width: "100%" }}>
                     <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                        Número de Inscripción
                     </Typography>
                     <InputTextField
                        id="numinscripcion"
                        type="number"
                        label="Número de Inscripción"
                        size="small"
                        name="numinscripcion"
                        fullWidth
                     />
                  </Grid>

                  <Grid item sx={{ width: "100%" }}>
                     <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                        Número de Escritura
                     </Typography>
                     <InputTextField
                        id="numescritura"
                        type="number"
                        label="Número de Escritura"
                        size="small"
                        name="numescritura"
                        fullWidth
                     />
                  </Grid>

                  <Grid item sx={{ width: "100%" }}>
                     <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "1px" }}>
                        Nombre de notario corredor
                     </Typography>
                     <InputTextField
                        id="numnotariocorredor"
                        type="text"
                        label="Nombre de notario corredor"
                        size="small"
                        name="numnotariocorredor"
                        fullWidth
                     />
                  </Grid>

                  <Grid item sx={{ width: "100%" }}>
                     <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "1px" }}>
                        Nombre de notario corredoría
                     </Typography>
                     <InputTextField
                        id="numnotariocorreduria"
                        type="text"
                        label="Nombre de notario corredoría"
                        size="small"
                        name="numnotariocorredoria"
                        fullWidth
                     />
                  </Grid>
               </Grid>

               <Grid item sx={{ display: "flex", flexDirection: "column", gap: "10px", width: "100%" }}>
                  <Grid item sx={{ width: "100%" }}>
                     <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "3px" }}>
                        Patrimonio del fideicomiso
                     </Typography>
                     <Button
                        variant="outlined"
                        component="span"
                        startIcon={<CloudUpload />}
                        fullWidth
                        onClick={() => setOpenFileModal(true)}
                        sx={{
                           backgroundColor: "white",
                           border: "1px solid #ccc",
                           borderRadius: "4px",
                           padding: "10px",
                           textAlign: "center",
                           cursor: "pointer",
                           "&:hover": {
                              backgroundColor: "#f5f5f5",
                           },
                        }}
                     >
                        Subir archivos
                     </Button>
                     {formik.values.files && formik.values.files.length > 0 && (
                        <div>
                           <p>Archivos seleccionados:</p>
                           <ul>
                              {formik.values.files.map((file, index) => (
                                 <li key={index}>{file.name}</li>
                              ))}
                           </ul>
                        </div>
                     )}
                  </Grid>

                  <Grid item sx={{ width: "100%" }}>
                     <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "3px" }}>
                        Fines del fideicomiso
                     </Typography>
                     <InputTextField
                        id="finesdelfideicomiso"
                        type="number"
                        label="Fines del fideicomiso"
                        name="finesdelfideicomiso"
                        fullWidth
                        multiline
                        rows={2}
                     />
                  </Grid>

                  <Grid item sx={{ width: "100%" }}>
                     <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "3px" }}>
                        Distribución de los ingresos
                     </Typography>
                     <InputTextField
                        id="disdelosingresos"
                        type="text"
                        label="Distribución de los ingresos"
                        name="disdelosingresos"
                        fullWidth
                        multiline
                        rows={2}
                     />
                  </Grid>
               </Grid>
            </Grid>
         );
      case 2:
         return (
            <Grid
               container
               spacing={4}
               sx={{
                  width: "90%",
                  height: "100%",
                  margin: "auto",
                  marginLeft: "12px",
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px",
                  gap: "0px",
               }}
            >
               {/* Sección del comité técnico */}
               <Grid
                  item
                  sx={{
                     width: "100%",
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "flex-start",
                     gap: "10px",
                  }}
               >
                  <Typography sx={{ color: "#000", fontSize: "14px", fontWeight: "bold" }}>
                     ¿Cuenta con un comité técnico?
                  </Typography>
                  <Grid>
                     <FormControl component="fieldset">
                        <RadioGroup
                           row
                           aria-label="comitetecnico"
                           name="comitetecnico"
                           id="comitetecnico"
                           value={(formik.values.comitetecnico = tieneComiteTecnico)}
                           onChange={(event) => setTieneComiteTecnico(event.target.value)}
                        >
                           <FormControlLabel value="Si" control={<Radio />} label="Sí" />
                           <FormControlLabel value="No" control={<Radio />} label="No" />
                        </RadioGroup>
                     </FormControl>
                  </Grid>
               </Grid>

               {tieneComiteTecnico === "Si" && (
                  <>
                     {/* Campo del presidente */}
                     <Grid
                        item
                        sx={{ width: "100%", display: "flex", justifyContent: "flex-start", marginBottom: "-30px" }}
                     >
                        <Typography
                           sx={{
                              marginLeft: "10px",
                              color: "#152C44",
                              width: "30%",
                              fontWeight: "bold",
                              fontSize: "12px",
                           }}
                        >
                           Cargo
                        </Typography>
                        <Typography sx={{ color: "#152C44", width: "55%", fontWeight: "bold", fontSize: "12px" }}>
                           Nombre
                        </Typography>
                        <Typography sx={{ color: "#152C44", width: "10%", fontWeight: "bold", fontSize: "12px" }}>
                           Agregar
                        </Typography>
                     </Grid>
                     <Grid
                        item
                        sx={{ width: "100%", display: "flex", justifyContent: "flex-start", marginBottom: "-12px" }}
                     >
                        <Box
                           sx={{
                              width: "30%",
                              display: "flex ",
                              border: "1px solid #C4C4C4",
                              borderRadius: "4px",
                              alignItems: "center",
                              height: "40px",
                              flexShrink: 0,
                              textAlign: "center",
                              justifyContent: "center",
                           }}
                        >
                           <Typography sx={{ color: "#2A2A2A" }}>Presidente</Typography>
                        </Box>
                        <InputTextField
                           id="presidente"
                           type="text"
                           label="Nombre Completo"
                           name="presidente"
                           size="small"
                           sx={{ width: "60%", flexGrow: 1 }}
                           value={formik.values.presidente}
                           onChange={formik.handleChange}
                        />
                        <Button
                           onClick={agregarGrid}
                           sx={{ border: "1px solid #C4C4C4", height: "40px", flexShrink: 0, display: "flex" }}
                        >
                           <Edit />
                        </Button>
                     </Grid>

                     {/* Campos de los miembros del comité */}
                     {formik.values.miembrosComite.map((miembro, index) => (
                        <Grid
                           key={miembro.id}
                           item
                           sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "flex-start",
                              gap: "0px",
                              marginBottom: "-12px",
                           }}
                        >
                           <FormControl sx={{ width: "30%" }} size="small">
                              <InputLabel id={`cargo-label-${miembro.id}`}>Seleccionar</InputLabel>
                              <Select
                                 id={`cargo-${miembro.id}`}
                                 name={`miembrosComite[${index}].cargo`}
                                 labelId={`cargo-label-${miembro.id}`}
                                 value={miembro.cargo || ""}
                                 onChange={formik.handleChange}
                                 label="Cargo"
                                 sx={{ width: "100%" }}
                              >
                                 <MenuItem value="Secretario">Secretario</MenuItem>
                                 <MenuItem value="Tesorero">Tesorero</MenuItem>
                                 <MenuItem value="Vocal">Vocal</MenuItem>
                                 <MenuItem value="Otro">Otro</MenuItem>
                              </Select>
                              {formik.touched.miembrosComite?.[index]?.cargo &&
                                 formik.errors.miembrosComite?.[index]?.cargo && (
                                    <FormHelperText error>{formik.errors.miembrosComite[index].cargo}</FormHelperText>
                                 )}
                           </FormControl>

                           <InputTextField
                              id={`nombre-${miembro.id}`}
                              type="text"
                              label="Nombre Completo"
                              name={`miembrosComite[${index}].nombre`}
                              size="small"
                              sx={{ width: "60%" }}
                              value={miembro.nombre || ""}
                              onChange={formik.handleChange}
                           />
                           <Button
                              sx={{ border: "1px solid #C4C4C4", height: "40px", flexShrink: 0 }}
                              onClick={() => eliminarGrid(miembro.id)}
                           >
                              <Close />
                           </Button>
                        </Grid>
                     ))}
                  </>
               )}

               <Grid
                  item
                  sx={{
                     width: "100%",
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "flex-start",
                     gap: "10px",
                  }}
               >
                  <Typography sx={{ color: "#000", fontSize: "14px", fontWeight: "bold" }}>
                     ¿Cuenta con un protector fiduciario?
                  </Typography>
                  <Grid>
                     <FormControl component="fieldset">
                        <RadioGroup
                           row
                           aria-label="protectorfiduciario"
                           name="protectorfiduciario"
                           value={(formik.values.protectorfiduciario = tieneProtectorFiducioso)}
                           onChange={(event) => setTieneProtectorFiducioso(event.target.value)}
                        >
                           <FormControlLabel value="Si" control={<Radio />} label="Sí" />
                           <FormControlLabel value="No" control={<Radio />} label="No" />
                        </RadioGroup>
                     </FormControl>
                  </Grid>
               </Grid>

               {tieneProtectorFiducioso === "Si" && (
                  <Grid item sx={{ width: "100%", display: "flex", justifyContent: "flex-start", gap: "0px" }}>
                     <InputTextField
                        id="protectorFiduciario"
                        type="text"
                        name="protectorFiduciario"
                        size="small"
                        placeholder="Nombre del protector fiduciario"
                        sx={{ width: "100%" }}
                        value={formik.values.protectorFiduciario}
                        onChange={formik.handleChange}
                     />
                     <Button sx={{ border: "1px solid #C4C4C4", height: "40px", flexShrink: 0 }}>
                        <Edit />
                     </Button>
                  </Grid>
               )}
            </Grid>
         );
      case 3:
         return (
            <Grid
               container
               spacing={4}
               sx={{
                  width: "90%",
                  height: "100%",
                  margin: "auto",
                  marginLeft: "12px",
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px",
                  gap: "40px",
               }}
            >
               <Grid item sx={{ width: "100%" }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "8px" }}>
                     Nombre denominación o razón social
                  </Typography>
                  <InputTextField
                     id="nomfideicomitente"
                     type="text"
                     name="nomfideicomitente"
                     fullWidth
                     size="small"
                     label="Nombre,Denominación o Razón Social del fideicomitente"
                  />
               </Grid>

               <Grid item sx={{ width: "100%" }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "8px" }}>
                     Derechos
                  </Typography>
                  <InputTextField
                     id="derechosfideicomitente"
                     type="text"
                     name="derechosfideicomitente"
                     label="Derechos del fideicomitente"
                     fullWidth
                     multiline
                     rows={4}
                  />
               </Grid>

               <Grid item sx={{ width: "100%" }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "8px" }}>
                     Obligaciones
                  </Typography>
                  <InputTextField
                     id="Obligacionesfideicomitente"
                     type="text"
                     name="Obligacionesfideicomitente"
                     label="Obligaciones del fideicomitente"
                     fullWidth
                     multiline
                     rows={4}
                  />
               </Grid>
            </Grid>
         );
      case 4:
         return (
            <Grid
               container
               spacing={4}
               sx={{
                  width: "90%",
                  height: "100%",
                  margin: "auto",
                  marginLeft: "12px",
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px",
                  gap: "40px",
               }}
            >
               <Grid item sx={{ width: "100%" }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "8px" }}>
                     Nombre denominación o razón social
                  </Typography>
                  <InputTextField
                     id="nomfideicomisario"
                     type="text"
                     name="nomfideicomisario"
                     label="Nombre,Denominación o Razon Social del fideicomisario"
                     fullWidth
                     size="small"
                  />
               </Grid>

               <Grid item sx={{ width: "100%" }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "8px" }}>
                     Derechos
                  </Typography>
                  <InputTextField
                     id="derechosfideicomisario"
                     type="text"
                     name="derechosfideicomisario"
                     fullWidth
                     multiline
                     label="Derechos del fideicomisario"
                     rows={4}
                  />
               </Grid>

               <Grid item sx={{ width: "100%" }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "8px" }}>
                     Obligaciones
                  </Typography>
                  <InputTextField
                     id="Obligacionesfideicomisario"
                     type="text"
                     name="Obligacionesfideicomisario"
                     fullWidth
                     multiline
                     label="Obligaciones del fideicomisario"
                     rows={4}
                  />
               </Grid>
            </Grid>
         );
      case 5:
         return (
            <Grid
               container
               spacing={4}
               sx={{
                  width: "90%",
                  height: "100%",
                  margin: "auto",
                  marginLeft: "12px",
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px",
                  gap: "5px",
               }}
            >
               <Grid item sx={{ width: "100%" }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "2px" }}>
                     Nombre denominación o razón social
                  </Typography>
                  <InputTextField
                     id="nomfiduciario"
                     type="text"
                     name="nomfiduciario"
                     fullWidth
                     size="small"
                     label="Nombre,Denominación o razón social del fiduciario"
                  />
               </Grid>

               <Grid item sx={{ width: "100%" }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "2px" }}>
                     Delegados fiduciarios
                  </Typography>
                  <InputTextField
                     id="delegadosfiduciario"
                     type="text"
                     name="delegadosfiduciario"
                     fullWidth
                     size="small"
                     label="Delegados fiduciarios"
                  />
               </Grid>

               <Grid item sx={{ width: "100%" }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "2px" }}>
                     Derechos
                  </Typography>
                  <InputTextField
                     id="derechosfiduciario"
                     type="text"
                     name="derechosfiduciario"
                     fullWidth
                     label="Derechos del fiduciario"
                     multiline
                     rows={4}
                  />
               </Grid>

               <Grid item sx={{ width: "100%" }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "2px" }}>
                     Obligaciones
                  </Typography>
                  <InputTextField
                     id="Obligacionesfiduciario"
                     type="text"
                     name="Obligacionesfiduciario"
                     fullWidth
                     label="Obligaciones del fiduciario"
                     multiline
                     rows={4}
                  />
               </Grid>
            </Grid>
         );
      default:
         return null;
   }
};

export { StepContent, LABELS, CIRCLE_STYLES, LINE_STYLES, CircleWithLabel };
