import { useState, useContext } from "react";
import { Formik, Form } from "formik";
import { CustomModalComponent } from "../../../../CustomModalComponent";
import { Button, Grid, Box, useTheme, useMediaQuery } from "@mui/material";
import {
   StepContent,
   CircleWithLabel,
   LABELS,
} from "../../../../../screens/Beneficiary controller/BeneficiaryFideicomisoFormAux";
import {
   FideicomisoSchemaModal,
   FideicomisoSchemaModal1,
   FideicomisoSchemaModal2,
   FideicomisoSchemaModal3,
   FideicomisoSchemaModal4,
   FideicomisoSchemaModal5,
} from "../../../../../lib/validations/inputSchemas";
import { createFideicomiso } from "../../../../../lib/usersBEClient";
import { AddFileModal } from "../../../../MiLecosy/AddFileModal";
import { UserContext } from "../../../../../context/userContext"; // Asegúrate de importar el contexto
import { uploadFileToS3, UseGetPresignedURLUpload } from "../../../../../lib/s3Client";

const BeneficiaryFideicomisoForm = ({ open, setOpen, onClose }) => {
   const [currentStep, setCurrentStep] = useState(1);
   const [openFileModal, setOpenFileModal] = useState(false);
   const { companySelected } = useContext(UserContext);
   const theme = useTheme();
   const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const dateToday = new Date();
   dateToday.setHours(0, 0, 0, 0);
   const year = dateToday.getFullYear();
   const month = String(dateToday.getMonth() + 1).padStart(2, "0");
   const day = String(dateToday.getDate()).padStart(2, "0");
   const formattedDate = `${year}-${month}-${day}`;

   const handleUploadFile = async (fileName, file) => {
      const folderPath = `gc/companies/${companySelected._id}/fideicomisos`;
      const urlReceipt2 = await UseGetPresignedURLUpload({
         bucket: "files-lecosy",
         folder: folderPath,
         name: fileName,
      });

      await uploadFileToS3(urlReceipt2.data, file);
      return `${folderPath}/${fileName}`;
   };

   const handleSubmit = async (values, { resetForm }) => {
      try {
         if (values.files && values.files.length > 0) {
            for (const file of values.files) {
               const filePath = await handleUploadFile(file.name, file);
            }
         }
         const response = await createFideicomiso(values);
         resetForm();
         onClose();
         setCurrentStep(1);
      } catch (error) {
         console.error("Error al enviar el formulario:", error);
      }
   };

   const handleNextStep = async (formProps) => {
      let schema;
      switch (currentStep) {
         case 1:
            schema = FideicomisoSchemaModal2;
            break;
         case 2:
            schema = FideicomisoSchemaModal1;
            break;
         case 3:
            schema = FideicomisoSchemaModal3;
            break;
         case 4:
            schema = FideicomisoSchemaModal4;
            break;
         case 5:
            schema = FideicomisoSchemaModal5;
            break;
      }

      try {
         await schema.validate(formProps.values, { abortEarly: false });
         setCurrentStep((prev) => prev + 1);
         formProps.setErrors({});
      } catch (err) {
         const errors = err.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
         }, {});
         formProps.setErrors(errors);
      }
   };

   return (
      <CustomModalComponent open={open} setOpen={setOpen} title="Fideicomiso" onClose={onClose}>
         <Formik
            initialValues={{
               fechainicio: "",
               duracion: "",
               numinscripcion: "",
               numescritura: "",
               numnotariocorredor: "",
               numnotariocorredoria: "",
               files: [], // Aquí se almacenarán los archivos
               presidente: "",
               miembrosComite: [],
               protectorFiduciario: "",
               finesdelfideicomiso: "",
               disdelosingresos: "",
               comitetecnico: "No",
               protectorfiduciario: "No",
               nomfideicomitente: "",
               derechosfideicomitente: "",
               Obligacionesfideicomitente: "",
               nomfideicomisario: "",
               derechosfideicomisario: "",
               Obligacionesfideicomisario: "",
               nomfiduciario: "",
               delegadosfiduciario: "",
               derechosfiduciario: "",
               Obligacionesfiduciario: "",
               dateToday: formattedDate,
            }}
            onSubmit={handleSubmit}
            validationSchema={FideicomisoSchemaModal}
            validateOnChange={false}
            validateOnBlur={false}
         >
            {(formProps) => (
               <Form>
                  <Grid
                     container
                     spacing={2}
                     sx={{
                        width: "100%",
                        maxWidth: "900px",
                        height: "auto",
                        maxHeight: "570px",
                        minHeight: "400px",
                        flexDirection: isSmallScreen ? "column" : "row",
                        margin: "auto",
                     }}
                  >
                     <Grid
                        item
                        xs={12}
                        sm={3}
                        sx={{
                           bgcolor: "#F5F5F5",
                           borderRight: isSmallScreen ? "none" : "1px solid #ccc",
                           display: "flex",
                           justifyContent: "center",
                           alignItems: "center",
                           minHeight: "550px",
                           maxHeight: "auto",
                           padding: 2,
                        }}
                     >
                        <Box
                           sx={{
                              display: "flex",
                              overflowY: "auto",
                              flexDirection: "column",
                              alignItems: "center",
                              height: "100%",
                              ...(isSmallScreen && { width: "100%" }),
                              gap: 2,
                           }}
                        >
                           {LABELS.map((label, index) => (
                              <CircleWithLabel
                                 key={index}
                                 number={index + 1}
                                 label={label}
                                 showLine={index < 4}
                                 isActive={currentStep >= index + 1}
                              />
                           ))}
                        </Box>
                     </Grid>

                     <Grid
                        item
                        xs={12}
                        sm={9}
                        sx={{
                           padding: 2,
                           height: "auto",
                        }}
                     >
                        <StepContent
                           currentStep={currentStep}
                           formik={formProps}
                           openFileModal={openFileModal}
                           setOpenFileModal={setOpenFileModal}
                        />
                     </Grid>
                     <Grid
                        item
                        xs={12}
                        sx={{
                           bgcolor: "#F5F5F5",
                           borderTop: "1px solid #ccc",
                           padding: 1,
                           display: "flex",
                           height: "40px",
                           justifyContent: "flex-end",
                           gap: 1,
                           width: "100%",
                           maxWidth: "900px",
                           margin: "auto",
                        }}
                     >
                        <Button
                           variant="text"
                           type="button"
                           disabled={currentStep === 1}
                           onClick={() => setCurrentStep((prev) => prev - 1)}
                           sx={{ color: "#ABAFB1" }}
                        >
                           Anterior
                        </Button>
                        {currentStep !== 5 ? (
                           <Button
                              variant="text"
                              type="button"
                              sx={{ color: "#464F60" }}
                              onClick={() => handleNextStep(formProps)}
                           >
                              Siguiente
                           </Button>
                        ) : (
                           <Button type="submit" variant="text" color="primary">
                              Guardar
                           </Button>
                        )}
                     </Grid>
                     <AddFileModal
                        state={openFileModal}
                        setState={setOpenFileModal}
                        setFile={(files) => formProps.setFieldValue("files", files)} // Usar formProps aquí
                        multiple
                     />
                  </Grid>
               </Form>
            )}
         </Formik>
      </CustomModalComponent>
   );
};

export default BeneficiaryFideicomisoForm;
