import { useContext, useEffect, useState } from "react";
import { Save } from "@mui/icons-material";
import {
   Box,
   Container,
   CssBaseline,
   Divider,
   IconButton,
   MenuItem,
   Modal,
   Stack,
   TextField,
   Typography,
} from "@mui/material";
import { NotificationBar } from "../../components/notificationsBar";
import { HeadCell, TableComponent } from "../../components/TableComponent";
import { getUserPendingSignaturesDocuments, signComplaintDocument } from "../../lib/lecosyBackendClient";
import { UserContext } from "../../context/userContext";
import { SnackBarContext } from "../../context/snackBarContext";
import Close from "@mui/icons-material/Close";
import { LoadingButton } from "../../components/LoadingButton";
import { getUrlS3, getUserBranches } from "../../lib/usersBEClient";
import { ComplaintContext } from "../../context/complaintContext";
import VerifyUserSession from "../../hooks/verifyUserSession";
import { useLocation } from "react-router-dom";

interface SignatureRow {
   name: string;
   branch: string;
   rn: string;
}

export const PendingSignaturesScreen = () => {
   VerifyUserSession();
   const { user } = useContext(UserContext);
   const { branches: complaintBranches } = useContext(ComplaintContext);
   const { pathname } = useLocation();
   const { showSnackBar } = useContext(SnackBarContext);
   const [selectedRow, setSelectedRow] = useState<SignatureRow>({
      branch: "",
      name: "",
      rn: "",
   });
   const [documentUrl, setDocumentUrl] = useState("");
   const [documentFilter, setDocumentFilter] = useState("");
   const [companyFilter, setCompanyFilter] = useState("");
   const [reportNumberFilter, setReportNumberFilter] = useState("");
   const [rows, setRows] = useState([]);
   const [isFetching, setIsFetching] = useState(true);
   const [open, setOpen] = useState(false);
   const [optionValue, setOptionValue] = useState("");
   const [isLoadingSignature, setIsLoadingSignature] = useState(false);

   const handleOpen = () => setOpen(true);
   const handleClose = () => {
      setOptionValue("");
      setDocumentUrl("");
      setOpen(false);
   };

   const headers: HeadCell[] = [
      { field: "name", headerName: "Documento", type: "string" },
      { field: "company", headerName: "Empresa/Sucursal", type: "string" },
      { field: "rn", headerName: "No. de reporte", type: "string" },
   ];

   const fetchDocumentUrl = async (reportNumber: string, clientId: string, name: string) => {
      const noExtFileKey = name.indexOf(".pdf");
      const response = await getUrlS3(
         clientId,
         { folder: `cd/reports/${reportNumber}/docs` },
         name.substring(0, noExtFileKey || name.length)
      );
      setDocumentUrl(response);
   };

   const handleDocumentSignature = async () => {
      const role = user.role.find((role) => role === "Auditor" || role === "Director");
      if (role !== "Auditor" && role !== "Director")
         return showSnackBar("Es necesario ser director o auditor para firmar.", true);
      const response = await signComplaintDocument(selectedRow.rn, selectedRow.name, role);
      if (response.status === 202 || response.status === 204) {
         showSnackBar("Firma guardada con éxito", false);
         fetchDocumentsWithSignatures();
         return;
      }
      showSnackBar("Error, intente más tarde", true);
   };

   const dataFetchError = () => {
      showSnackBar("Error al cargar datos", true);
      setIsFetching(false);
      setRows([]);
   };

   const fetchDocumentsWithSignatures = async () => {
      try {
         let branches;
         if (user.group) branches = await getUserBranches([], false, user);
         else branches = complaintBranches;
         const branchesIds = branches.map((branch) => branch._id);
         const response = await getUserPendingSignaturesDocuments(
            branchesIds,
            user.companies.map((c) => c._id),
            user.role,
            user.companies,
            branches
         );
         if (!response) return dataFetchError();

         setRows(response);
         setIsFetching(false);
      } catch (error) {
         dataFetchError();
      }
   };

   useEffect(() => {
      if (!pathname.includes("firmas")) return;
      fetchDocumentsWithSignatures();
      setIsLoadingSignature(false);
      // eslint-disable-next-line
   }, [pathname]);

   const filteredRows = (rows: any) => {
      const firstFilter = documentFilter === "" ? rows : rows.filter((row) => row.name.startsWith(documentFilter));
      const secondFilter =
         companyFilter === "" ? firstFilter : firstFilter.filter((row) => row.company.startsWith(companyFilter));
      const thirdFilter =
         reportNumberFilter === "" ? secondFilter : secondFilter.filter((row) => row.rn.startsWith(reportNumberFilter));
      return thirdFilter;
   };

   const handleRow = (e, row) => {
      setSelectedRow(row);
      const foundCompanyId = rows.find((signature) => signature.rn === row.rn)?.company;
      fetchDocumentUrl(row.rn, foundCompanyId, row.name);
      handleOpen();
   };

   return (
      <>
         <CssBaseline />
         <Box pt={2}>
            <NotificationBar head="Firmas pendientes" content={<></>} />
         </Box>
         <Container maxWidth="xl">
            <Box>
               <Box
                  sx={{
                     p: "8px 16px",
                     mb: 1,
                  }}
               >
                  <Stack direction="row" spacing={1} alignItems={"center"} display={"flex"} width={"100%"}>
                     <Typography>Filtros:</Typography>
                     <TextField
                        fullWidth
                        label="Documento"
                        size="small"
                        value={documentFilter}
                        onChange={(e) => {
                           setDocumentFilter(e.target.value);
                        }}
                     />
                     <TextField
                        fullWidth
                        label="Empresa/Sucursal"
                        size="small"
                        value={companyFilter}
                        onChange={(e) => {
                           setCompanyFilter(e.target.value);
                        }}
                     />
                     <TextField
                        fullWidth
                        label="No. de reporte"
                        size="small"
                        value={reportNumberFilter}
                        onChange={(e) => {
                           setReportNumberFilter(e.target.value);
                        }}
                        type="number"
                     />
                  </Stack>
               </Box>
               <TableComponent
                  defaultColumnToOrder="doc"
                  defaultOrder="asc"
                  headers={headers}
                  rows={filteredRows(rows)}
                  defaultRowsPerPage={5}
                  rowsPerPageOptions={[5, 10, 20]}
                  loader={isFetching}
                  emptyDataText="No hay firmas pendientes"
                  onClick={handleRow}
                  headerColor
               />
               <Modal open={open} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Box
                     sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "stretch",
                        bgcolor: "white",
                        p: 2,
                        borderRadius: 1,
                        minWidth: "80%",
                        minHeight: "90%",
                     }}
                  >
                     <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography fontWeight={600} variant="h6" component="h2">
                           Firma de documento {selectedRow.name}
                        </Typography>
                        <IconButton size="small" onClick={handleClose}>
                           <Close />
                        </IconButton>
                     </Box>
                     <Divider sx={{ my: 1 }} />
                     <Box component={"iframe"} src={documentUrl} sx={{ flex: 1 }} />
                     <Stack
                        p={2}
                        spacing={2}
                        direction={"row"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                     >
                        <TextField
                           select
                           fullWidth
                           label={"Firma"}
                           value={optionValue}
                           onChange={(e) => {
                              setOptionValue(e.target.value);
                           }}
                           size="small"
                        >
                           {["Aceptar y firmar"].map((e, i) => (
                              <MenuItem key={i} value={e}>
                                 {e}
                              </MenuItem>
                           ))}
                        </TextField>
                        <LoadingButton
                           disabled={optionValue === ""}
                           label="Guardar"
                           isLoading={isLoadingSignature}
                           icon={<Save />}
                           onClick={() => {
                              if (optionValue === "Aceptar y firmar") {
                                 handleDocumentSignature();
                              }
                              handleClose();
                           }}
                        />
                     </Stack>
                  </Box>
               </Modal>
            </Box>
         </Container>
      </>
   );
};
