import {
   Box,
   Grid,
   Table,
   TableBody,
   TableCell,
   TableHead,
   TableRow,
   Typography,
   TableFooter,
   Accordion,
   AccordionSummary,
   AccordionDetails,
} from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useContext, useMemo } from "react";
import { useGetMembersInfo } from "../../../hooks/gob-corp/corporateData/useGetMembersInfo";
import { CorporateDataContext } from "../../../context/governanceContext/corporateDataContext";
import { UserContext } from "../../../context/userContext";

interface TableMemberRow {
   name: string;
   type: string;
   socialParts: string;
   contributions: string;
   hasVotingRights?: boolean;
   hasVoiceRights?: boolean;
}

const AssemblyMembersSocial = () => {
   const { formatedMembers, members } = useGetMembersInfo(false);
   const { governanceBody, partnerTypes } = useContext(CorporateDataContext);

   // Group members by partner type
   const groupedMembers = useMemo(() => {
      if (!formatedMembers?.length) return {};

      return formatedMembers.reduce((acc, member, index) => {
         const partnerType = member.partnerType || "Sin tipo";
         if (!acc[partnerType]) {
            acc[partnerType] = [];
         }

         const originalMember = members[index];
         const memberType =
            originalMember?.businessName && originalMember.businessName !== "" ? "Persona moral" : "Persona física";

         const pType = partnerTypes.find((type) => type.title === member.partnerType) || {
            vote: false,
            voice: false,
         };

         acc[partnerType].push({
            name: member.name,
            type: memberType,
            socialParts: member.totalActions || "0",
            contributions: member.propertyPercentage?.toString() || "0",
            hasVotingRights: pType.vote,
            hasVoiceRights: pType.voice,
         });

         return acc;
      }, {});
   }, [formatedMembers, members, partnerTypes]);

   const headerCells = [
      "Nombre, Denominacion o razon social",
      "Tipo",
      "Partes sociales",
      "Aportaciones",
      "Derecho a voto",
      "Derecho a voz",
   ];

   const commonCellStyle = {
      textAlign: "center" as const,
      fontSize: 14,
      fontWeight: "bold",
      padding: "8px",
      minWidth: "120px",
      maxWidth: "120px",
      whiteSpace: "normal",
      wordBreak: "break-word",
      verticalAlign: "middle",
   };

   const getCellStyle = (index: number, totalColumns: number) => ({
      bgcolor: "white",
      border: "1px solid #d3d3d3",
      textAlign: "center" as const,
      fontSize: 13,
      minWidth: "120px",
      maxWidth: "120px",
      padding: "8px",
      whiteSpace: "normal",
      wordBreak: "break-word",
      verticalAlign: "middle",
      "&:first-of-type": {
         position: "sticky",
         left: 0,
         backgroundColor: "white",
         zIndex: 1,
         "&::after": {
            content: '""',
            position: "absolute",
            right: 0,
            top: 0,
            bottom: 0,
            width: "1px",
            backgroundColor: "#d3d3d3",
            zIndex: 3,
         },
      },
      "&:last-of-type": {
         borderColor: "#d3d3d3",
      },
   });

   const footerCellStyle = {
      ...commonCellStyle,
      backgroundColor: "#eeeeee",
      fontWeight: "bold",
      borderLeft: "1px solid #eeeeee",
      borderRight: "1px solid ##eeeeee",
      position: "sticky",
      bottom: 0,
      "&:first-of-type": {
         position: "sticky",
         left: 0,
         zIndex: 4,
         color: "#000000",
      },
      "&:last-of-type": {
         borderColor: "#eeeeee",
         backgroundColor: "#eeeeee",
         color: "#000000",
         fontWeight: 900,
      },
   };

   const MembersTable = ({ data, title }) => {
      return (
         <Accordion
            sx={{ mb: 2, mx: 2, maxWidth: "95%", borderRadius: "5px", "& .MuiPaper-root": { borderRadius: "5px" } }}
         >
            <AccordionSummary
               expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
               sx={{
                  bgcolor: "#738090",
                  color: "white",
                  minHeight: "40px !important",
                  borderRadius: "5px 5px 0 0",
                  "& .MuiAccordionSummary-content": {
                     margin: "0 !important",
                     justifyContent: "center",
                  },
               }}
            >
               <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {title}
               </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0, overflowX: "auto" }}>
               <Box
                  sx={{
                     border: "1px solid #ccc",
                     borderRadius: "0 0 5px 5px",
                     overflowX: "auto",
                     width: "100%",
                     "& .MuiTableHead-root": {
                        backgroundColor: "#eeeeee",
                     },
                     "& .MuiTableFooter-root": {
                        backgroundColor: "#eeeeee",
                     },
                     "& .MuiTable-root": {
                        tableLayout: "fixed",
                        minWidth: `${headerCells.length * 120}px`,
                     },
                     "& .MuiTableCell-root": {
                        height: "auto",
                        minHeight: "30px",
                        verticalAlign: "middle",
                     },
                  }}
               >
                  <Table>
                     <TableHead>
                        <TableRow sx={{ bgcolor: "#eeeeee", height: "30px" }}>
                           {headerCells.map((header, index) => (
                              <TableCell
                                 key={header}
                                 sx={{
                                    ...commonCellStyle,
                                    position: index === 0 ? "sticky" : "static",
                                    left: index === 0 ? 0 : "auto",
                                    zIndex: index === 0 ? 3 : 2,
                                    backgroundColor: "#eeeeee",
                                    borderLeft: "1px solid #d3d3d3",
                                    borderRight: "1px solid #d3d3d3",
                                 }}
                              >
                                 {header}
                              </TableCell>
                           ))}
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {data.map((row, rowIndex) => (
                           <TableRow key={rowIndex}>
                              <TableCell sx={getCellStyle(0, headerCells.length)}>{row.name}</TableCell>
                              <TableCell sx={getCellStyle(1, headerCells.length)}>{row.type}</TableCell>
                              <TableCell sx={getCellStyle(2, headerCells.length)}>{row.socialParts}</TableCell>
                              <TableCell sx={getCellStyle(3, headerCells.length)}>
                                 <Box
                                    sx={{
                                       bgcolor: "#eeeeee",
                                       borderRadius: 2,
                                       padding: "4px 8px",
                                       display: "inline-block",
                                       minWidth: "51px",
                                       border: "1px solid #d3d3d3",
                                       textAlign: "center",
                                       margin: "0 auto",
                                    }}
                                 >
                                    {row.contributions}%
                                 </Box>
                              </TableCell>
                              <TableCell sx={getCellStyle(4, headerCells.length)}>
                                 {row.hasVotingRights && <CheckBoxIcon sx={{ color: "#162c44" }} />}
                              </TableCell>
                              <TableCell sx={getCellStyle(5, headerCells.length)}>
                                 {row.hasVoiceRights && <CheckBoxIcon sx={{ color: "#162c44" }} />}
                              </TableCell>
                           </TableRow>
                        ))}
                     </TableBody>
                     <TableFooter>
                        <TableRow>
                           <TableCell sx={footerCellStyle}>TOTAL:</TableCell>
                           <TableCell sx={footerCellStyle}></TableCell>
                           <TableCell sx={footerCellStyle}>
                              {data.reduce((sum, row) => sum + Number(row.socialParts), 0)}
                           </TableCell>
                           <TableCell sx={footerCellStyle}>
                              <Box sx={{ bgcolor: "#eeeeee", borderRadius: 5, fontWeight: 900, color: "#000000" }}>
                                 {Math.floor(data.reduce((sum, row) => sum + Number(row.contributions), 0))}%
                              </Box>
                           </TableCell>
                           <TableCell sx={footerCellStyle}>
                              {data.filter((row) => row.hasVotingRights).length}
                           </TableCell>
                           <TableCell sx={footerCellStyle}>{data.filter((row) => row.hasVoiceRights).length}</TableCell>
                        </TableRow>
                     </TableFooter>
                  </Table>
               </Box>
            </AccordionDetails>
         </Accordion>
      );
   };

   return (
      <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
         <Grid
            sx={{
               bgcolor: "#162c44",
               p: 2,
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               height: 40,
               borderRadius: "5px 5px 5px 5px",
               mb: 2,
            }}
         >
            <Typography sx={{ color: "white", fontWeight: "bold", fontSize: 18 }}>Capital social</Typography>
         </Grid>
         <Box
            sx={{
               flexGrow: 1,
               height: "calc(100vh - 120px)",
               overflow: "auto",
               display: "flex",
               flexDirection: "column",
               alignItems: "center",
               pr: 1,
               "&::-webkit-scrollbar": {
                  width: "8px",
                  height: "8px",
               },
               "&::-webkit-scrollbar-track": {
                  backgroundColor: "#f1f1f1",
                  borderRadius: "4px",
               },
               "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#888",
                  borderRadius: "4px",
               },
            }}
         >
            {Object.entries(groupedMembers).map(([partnerType, members]) => (
               <MembersTable key={partnerType} data={members} title={partnerType} />
            ))}
         </Box>
      </Box>
   );
};

export default AssemblyMembersSocial;
