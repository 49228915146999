import { Breadcrumbs, Typography } from "@mui/material";
import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface Breadcrumb {
   name: string;
   path: string;
}

interface BreadcrumbContextType {
   breadcrumbs: Breadcrumb[];
   addBreadcrumb: (breadcrumb: Breadcrumb) => void;
   setBreadcrumbs: Function;
}

const BreadcrumbContext = createContext<BreadcrumbContextType | undefined>(undefined);

export const BreadcrumbProvider = ({ children }: { children: React.ReactNode }) => {
   const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>(() => {
      const storedBreadcrumbs = localStorage.getItem("breadcrumbs");
      return storedBreadcrumbs ? JSON.parse(storedBreadcrumbs) : [];
   });

   useEffect(() => {
      localStorage.setItem("breadcrumbs", JSON.stringify(breadcrumbs));
   }, [breadcrumbs]);

   const addBreadcrumb = (breadcrumb: Breadcrumb) => {
      setBreadcrumbs((prev) => {
         const exists = prev.find((b) => b.path === breadcrumb.path);
         if (exists) return prev;
         return [...prev, breadcrumb];
      });
   };

   const navigate = useNavigate();

   useEffect(() => {
      const handlePopState = () => {
         setBreadcrumbs((prevBreadcrumbs) => {
            if (prevBreadcrumbs.length > 1) {
               const updatedBreadcrumbs = prevBreadcrumbs.slice(0, -1);
               navigate(updatedBreadcrumbs[updatedBreadcrumbs.length - 1].path);
               return updatedBreadcrumbs;
            }
            return prevBreadcrumbs;
         });
      };

      window.addEventListener("popstate", handlePopState);
      return () => window.removeEventListener("popstate", handlePopState);
   }, []);

   return (
      <BreadcrumbContext.Provider value={{ breadcrumbs, addBreadcrumb, setBreadcrumbs }}>
         {children}
      </BreadcrumbContext.Provider>
   );
};

export const useBreadcrumbs = () => {
   const context = useContext(BreadcrumbContext);
   if (!context) throw new Error("useBreadcrumbs debe usarse dentro de un BreadcrumbProvider");
   return context;
};

export const BreadcrumbsComponent = () => {
   const { breadcrumbs, setBreadcrumbs } = useBreadcrumbs();
   const navigate = useNavigate();

   return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
         {breadcrumbs.map((crumb, index) =>
            index === breadcrumbs.length - 1 ? (
               <Typography key={crumb.path} sx={{ color: "#162c44" }}>
                  {crumb.name}
               </Typography>
            ) : (
               <Typography
                  key={crumb.path}
                  color="inherit"
                  onClick={() => {
                     navigate(crumb.path);
                     setBreadcrumbs((prev) => {
                        const index = prev.findIndex((b) => b.path === crumb.path);
                        return index === 0 ? [] : prev.slice(0, index + 1);
                     });
                  }}
                  sx={{
                     color: "#162c4499",
                     cursor: "pointer",
                     "&:hover": {
                        color: "primary.main",
                     },
                  }}
               >
                  {crumb.name}
               </Typography>
            )
         )}
      </Breadcrumbs>
   );
};
