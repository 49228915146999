import React, { useContext, useState } from "react";
import { Add, Close, FilePresentOutlined, Remove } from "@mui/icons-material";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import { BeneficiaryControllerContext } from "../../../context/beneficiaryContext/beneficiaryContollerContext";
import Sample from "../../Gobierno corporativo/PDFVisualizer/PDFViewer";

const BeneficiaryManualButton = () => {
   const { identificationManual } = useContext(BeneficiaryControllerContext);
   const [zoom, setZoom] = useState<number>(1);
   const [openFile, setOpenFile] = useState(false);

   const handleClick = () => setOpenFile(!openFile);
   const handleZoomIn = () => setZoom((prevZoom) => Math.min(prevZoom + 0.1, 2));
   const handleZoomOut = () => setZoom((prevZoom) => Math.max(prevZoom - 0.1, 0.5));

   return (
      <>
         <Button variant="text" disabled={!identificationManual} onClick={handleClick} sx={{ borderRadius: 1, gap: 1 }}>
            <FilePresentOutlined />
            Manual de identificación
         </Button>
         <Modal open={openFile} style={{ backgroundColor: "#00000050" }}>
            <Box sx={{ overflow: "auto", maxHeight: "100vh" }}>
               <Box
                  sx={{
                     position: "absolute",
                     bottom: 15,
                     left: "50%",
                     transform: "translateX(-50%)",
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                     bgcolor: "rgba(255, 255, 255, 0.8)",
                     borderRadius: 1,
                     p: 1,
                     boxShadow: 3,
                     zIndex: 1000,
                  }}
               >
                  <Button variant="contained" onClick={handleZoomOut} sx={{ mx: 1 }}>
                     <Remove />
                  </Button>
                  <Typography variant="body1" sx={{ mx: 1 }}>
                     Zoom: {(zoom * 100).toFixed(0)}%
                  </Typography>
                  <Button variant="contained" onClick={handleZoomIn} sx={{ mx: 1 }}>
                     <Add />
                  </Button>
               </Box>

               <Box sx={{ display: "flex", justifyContent: "flex-end", position: "absolute", right: 20, top: 0 }}>
                  <IconButton onClick={() => setOpenFile(false)}>
                     <Close sx={{ color: "white", fontSize: "37px" }} />
                  </IconButton>
               </Box>
               <Sample file={identificationManual} zoom={zoom} />
            </Box>
         </Modal>
      </>
   );
};

export default BeneficiaryManualButton;
