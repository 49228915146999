import { Document, Page, Text, View, Svg, Path, Font, Image } from "@react-pdf/renderer";
import { Table, TR, TD } from "@ag-media/react-pdf-table";
import { Companies } from "../../../types/BaseTypes";
import { IBeneficiary, IQuestionaryAnswer } from "../../../types/beneficiaryTypes";
import { getContrastYIQ } from "../../../const/globalConst";

interface beneficiaryIdentificationProps {
   type?: "Fisica" | "Moral";
   logo: string;
   company: Companies;
   beneficiary: IBeneficiary;
   questions: IQuestionaryAnswer[];
}

export const BenenficiaryQuestionaryPDF = (props: beneficiaryIdentificationProps) => {
   const { questions, beneficiary, company, logo, type } = props;

   const fecha = new Date();
   const day = fecha.getDate();
   const month = fecha.toLocaleString("es-ES", { month: "long" });
   const year = fecha.getFullYear();

   const renderChildren = (children: IQuestionaryAnswer[], indexFromDad) => {
      return children.map((child, index) => (
         <View style={{ marginLeft: 10 }}>
            <Text key={index} style={{ fontSize: 10 }}>{`${indexFromDad}.${index + 1}. ${
               child.question.question
            }`}</Text>
            {child.answer.map((answer) => (
               <View style={{ marginLeft: 10 }}>
                  <Text key={index} style={{ fontSize: 10 }}>{`- ${answer.answer}`}</Text>
                  {renderChildren(answer.children, `${indexFromDad}.${index + 1}`)}
               </View>
            ))}
         </View>
      ));
   };

   return (
      <Document>
         <Page size="A4" style={{ padding: 40, fontFamily: "Open Sans", paddingTop: 80 }}>
            <View
               style={{
                  position: "absolute",
                  top: 27,
                  left: 40,
                  display: "flex",
                  flexDirection: "column",
               }}
               fixed
            >
               <Image
                  src={logo}
                  style={{
                     width: "auto",
                     height: 50,
                     marginBottom: 5,
                  }}
               />
            </View>
            <View
               style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
               }}
               fixed
            >
               <View
                  style={{
                     position: "absolute",
                     top: 0,
                     left: 0,
                     right: 0,
                     zIndex: 2,
                  }}
               >
                  <Svg width={414} height={13} viewBox="0 0 414 13">
                     <Path d="M0 0H414V13H0z" fill={company?.company_details?.primaryColor || "#162C44"} />
                  </Svg>
               </View>

               <View
                  style={{
                     position: "absolute",
                     top: 0,
                     right: -100,
                     zIndex: 1,
                  }}
               >
                  <Svg width={400} height={16} viewBox="0 0 400 16" style={{ position: "absolute", top: 0, right: 0 }}>
                     <Path d="M0 0H500V20H52.625L0 0Z" fill={company?.company_details?.secondaryColor || "#64748B"} />
                  </Svg>
               </View>
            </View>

            <View>
               <View>
                  <Text style={{ fontSize: 13, fontWeight: "bold", textAlign: "center" }}>
                     {"\n"}CUESTIONARIO DE DETERMINACIÓN DEL BENEFICIARIO CONTROLADOR
                  </Text>
                  <Text style={{ fontSize: 12, fontWeight: "bold", textAlign: "center", marginBottom: 5 }}>
                     {company?.person_details?.businessName?.toUpperCase() ||
                        company?.person_details?.comercialName?.toUpperCase() ||
                        ""}
                  </Text>
                  <Text style={{ fontSize: 12, textAlign: "center", color: "gray" }}>
                     {company?.invoice_details?.rfc?.toUpperCase() || ""}
                  </Text>
                  <Text
                     style={{
                        fontSize: 10,
                        textAlign: "right",
                        color: "#152C44",
                        marginBottom: 10,
                     }}
                  >
                     {`${day} de ${month} del ${year}`}
                  </Text>
               </View>

               <View>
                  <Table style={{ width: "100%", padding: 5, gap: 5, borderColor: "transparent", marginBottom: 10 }}>
                     <TR>
                        <TD
                           style={{
                              backgroundColor: company?.company_details?.primaryColor || "#162c44",
                              color: getContrastYIQ(company?.company_details?.primaryColor),
                              padding: 5,
                              fontSize: 10,
                              textAlign: "left",
                              borderRadius: 5,
                              width: "25%",
                              maxWidth: "25%",
                              borderColor: company?.company_details?.primaryColor || "#162c44",
                              fontWeight: 600,
                           }}
                        >
                           {type === "Moral" ? "DENOMINACIÓN O RAZÓN SOCIAL" : "NOMBRE COMPLETO"}
                        </TD>
                        <TD
                           style={{
                              backgroundColor: "#F5F5F5",
                              padding: 5,
                              fontSize: 10,
                              borderRadius: 5,
                              textAlign: "left",
                              width: "75%",
                              maxWidth: "75%",
                              borderColor: "#F5F5F5",
                              left: 5,
                           }}
                        >
                           <Text>
                              {beneficiary.businessName ||
                                 (beneficiary.user
                                    ? `${beneficiary.user.firstName} ${beneficiary.user.lastName}`
                                    : beneficiary.name)}
                           </Text>
                        </TD>
                     </TR>
                     <TR>
                        <TD
                           style={{
                              backgroundColor: company?.company_details?.primaryColor || "#162c44",
                              color: getContrastYIQ(company?.company_details?.primaryColor),
                              padding: 5,
                              fontSize: 10,
                              borderRadius: 5,
                              textAlign: "left",
                              width: "25%",
                              maxWidth: "25%",
                              borderColor: company?.company_details?.primaryColor || "#162c44",
                              fontWeight: 600,
                           }}
                        >
                           RFC
                        </TD>
                        <TD
                           style={{
                              backgroundColor: "#F5F5F5",
                              padding: 5,
                              fontSize: 10,
                              borderRadius: 5,
                              textAlign: "left",
                              width: "75%",
                              maxWidth: "75%",
                              borderColor: "#F5F5F5",
                              left: 5,
                           }}
                        >
                           {beneficiary?.rfc}
                        </TD>
                     </TR>
                  </Table>
               </View>
            </View>
            <View>
               <Text style={{ fontWeight: "bold", marginVertical: 5, fontSize: 11 }}>
                  RESPUESTAS DEL CUESTIONARIO DE DETERMINACIÓN DEL BENEFICIARIO CONTROLADOR
               </Text>
            </View>
            <View>
               {questions.map((question, index) => (
                  <View style={{ marginTop: 10 }}>
                     <Text key={index} style={{ fontSize: 10, fontWeight: 800 }}>{`${index + 1}. ${
                        question.question.question
                     }`}</Text>
                     {question.answer.map((answer) => (
                        <View style={{ marginLeft: 10 }}>
                           <Text key={index} style={{ fontSize: 10 }}>{`- ${answer.answer}`}</Text>
                           {renderChildren(answer.children, index + 1)}
                        </View>
                     ))}
                  </View>
               ))}
            </View>
         </Page>
      </Document>
   );
};

Font.register({
   family: "Open Sans",
   fonts: [
      { src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf" },
      { src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf", fontWeight: 600 },
      { src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf", fontWeight: 800 },
   ],
});
