import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Button, Typography } from "@mui/material";
import { ComplaintStepperContext } from "../../context/complaintStepperContext";
import { useContext, useState } from "react";
import { SnackBarContext } from "../../context/snackBarContext";
import { ConfirmationModal } from "../ConfirmationModal";
import { deletePdfEvidence, getUrlForDocumentationFile } from "../../lib/usersBEClient";

interface EvidenceTableProps {
   rows: any;
   headers: any[];
   reportNumber?: string;
   subject?: string;
   selectedUser?: string;
}

export default function EvidenceTable(props: EvidenceTableProps) {
   const { getComplaint, complaint } = useContext(ComplaintStepperContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
   const [document, setDocument] = useState("");

   const getFileSignedUrl = async (fileKey: string) => {
      const imagesUrlResponse = await getUrlForDocumentationFile(
         complaint.reportNumber,
         complaint.companyBranch.company._id,
         {
            folder: `cd/reports/${complaint.reportNumber}/evidences/${props.subject}/${
               props.selectedUser || "anonimo"
            }`,
         },
         fileKey[0]
      );
      window.open(imagesUrlResponse, "_blank", "noopener,noreferrer");
   };

   const handleDeleteEvidence = async (fileKey: string) => {
      try {
         await deletePdfEvidence(
            complaint.companyBranch.company._id,
            {
               folder: `cd/reports/${complaint.reportNumber}/evidences/${props.subject}/${
                  props.selectedUser || "anonimo"
               }`,
            },
            fileKey
         );
         getComplaint(props.reportNumber);
         showSnackBar("Evidencia eliminada correctamente.", false);
         setOpenConfirmationModal(false);
      } catch (error) {
         showSnackBar("Hubo un error al borar la evidencia.", true);
         setOpenConfirmationModal(false);
      }
   };

   const rows = (row: any) => {
      return (
         <TableRow key={row} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row">
               {row}
            </TableCell>
            {props.subject !== "anonima" && props.subject !== "Denunciante" ? (
               <TableCell align="center">
                  <Button
                     onClick={() => {
                        setDocument(row);
                        setOpenConfirmationModal(true);
                     }}
                  >
                     <DeleteForeverIcon fontSize="small" />
                  </Button>
               </TableCell>
            ) : null}
            <TableCell align="center">
               <Button
                  onClick={() => {
                     getFileSignedUrl(row);
                  }}
               >
                  <OpenInNewIcon fontSize="small" />
               </Button>
            </TableCell>
         </TableRow>
      );
   };

   return (
      <>
         <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="a dense table" size="small">
               <TableHead>
                  <TableRow>
                     {props.headers.map((header, i) => {
                        if (!(props.subject !== "anonima" && props.subject !== "Denunciante")) {
                           if (header.headerName === "Borrar") return;
                           return (
                              <TableCell variant="head" sx={{ fontWeight: 600 }} align={i > 0 ? "center" : "left"}>
                                 {header.headerName}
                              </TableCell>
                           );
                        }
                        return (
                           <TableCell variant="head" sx={{ fontWeight: 600 }} align={i > 0 ? "center" : "left"}>
                              {header.headerName}
                           </TableCell>
                        );
                     })}
                  </TableRow>
               </TableHead>
               <TableBody>{props.rows.map((row) => rows(row))}</TableBody>
            </Table>
         </TableContainer>
         <ConfirmationModal
            open={openConfirmationModal}
            setOpen={setOpenConfirmationModal}
            title={"Borrar evidencia"}
            body={
               <Box>
                  <Typography>¿Estás seguro de borrar la evidencia?</Typography>
                  <Typography variant="body2">Se va a borrar la siguiente evidencia: </Typography>
                  <Typography variant="body2">{document}</Typography>
               </Box>
            }
            onConfirm={() => handleDeleteEvidence(document)}
         />
      </>
   );
}
