import { useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Grid, Typography, CircularProgress, Container } from "@mui/material";
import { NotificationDocumentsBar } from "../../../../components/notificationsDocumentsBar";
import { capitalizeFirstLetter, getDateAndTimeFromISODate } from "../../../../const/globalConst";
import { anonimousComplainant } from "../../../../lib/documentsTemplates/constancyTemplates";
import { createDocumentPdf } from "../../../../lib/uploadDocuments";
import { SnackBarContext } from "../../../../context/snackBarContext";
import pdfMake from "../../../../const/pdfMakeFonts";
import { ComplaintStepperContext } from "../../../../context/complaintStepperContext";

export const AnonymousInterviewScreen = () => {
   const { reportNumber } = useParams();
   const { showSnackBar } = useContext(SnackBarContext);
   const { complaint } = useContext(ComplaintStepperContext);
   const [isSendLoading, setSendLoading] = useState<boolean>(false);
   const navigate = useNavigate();

   const handleSendForm = async () => {
      setSendLoading(true);
      const necesaryDocuments = complaint.documentation.find(
         (doc) => doc.name === `constancia-anonimo-${complaint.reportNumber}.pdf`
      );
      if (!necesaryDocuments) {
         const dd = anonimousComplainant(complaint.reportNumber, complaint.createdAt.toString());
         const pdfDocGenerator = pdfMake.createPdf(dd);
         pdfDocGenerator.getBlob(async (blob) => {
            await createDocumentPdf(
               complaint.reportNumber,
               complaint.companyBranch.company._id,
               `constancia-anonimo-${complaint.reportNumber}`,
               blob
            );
            showSnackBar("Documento creado y subido correctamente.", false);
            setSendLoading(false);
            setTimeout(() => navigate(-1), 1000);
         });
      } else {
         showSnackBar("Este documento ya fue enviado.", true);
         setSendLoading(false);
         setTimeout(() => navigate(-1), 1000);
      }
   };

   return (
      <Box p={2}>
         <NotificationDocumentsBar head="Denuncia anónima" isScheduleAppointment={true} />
         <Container maxWidth="xl">
            <Box display={"flex"} sx={{ px: 4, minWidth: 950, flexDirection: "column", alignItems: "center", m: 2 }}>
               <Box sx={{ width: "100%" }}>
                  <Box
                     display={"flex"}
                     sx={{
                        bgcolor: "#FFFFFF",
                        border: 1,
                        borderColor: "#8A95A0",
                        borderRadius: 2,
                     }}
                  >
                     <Grid container>
                        <Grid item xs={4}>
                           <Box display={"flex"} sx={dataFieldSX}>
                              <Typography fontWeight={500} variant="body1">
                                 Reporte de denuncia
                              </Typography>
                              <Typography variant="subtitle1">{reportNumber}</Typography>
                           </Box>
                        </Grid>
                        <Grid item xs={4}>
                           <Box display={"flex"} sx={dataFieldSX}>
                              <Typography fontWeight={500} variant="body1">
                                 Fecha de interposición
                              </Typography>
                              <Typography variant="subtitle1">
                                 {getDateAndTimeFromISODate(complaint.sentDate)}
                              </Typography>
                           </Box>
                        </Grid>
                        <Grid item xs={4}>
                           <Box display={"flex"} sx={dataFieldSX}>
                              <Typography fontWeight={500} variant="body1">
                                 Estatus
                              </Typography>
                              <Typography variant="subtitle1">{capitalizeFirstLetter(complaint.status)}</Typography>
                           </Box>
                        </Grid>
                     </Grid>
                  </Box>
                  <Box display={"flex"} sx={{ justifyContent: "flex-end" }}>
                     <Button variant="contained" sx={{ mt: 2, width: "50px" }} onClick={() => handleSendForm()}>
                        {isSendLoading ? <CircularProgress size={25} sx={{ color: "white" }} /> : "Enviar"}
                     </Button>
                  </Box>
               </Box>
            </Box>
         </Container>
      </Box>
   );
};

const dataFieldSX = {
   alignItems: "center",
   justifyContent: "center",
   flexDirection: "column",
   py: 2,
   px: 4,
};
