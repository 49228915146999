import * as yup from "yup";
import { freeTextRegex, textOnlyRegex, textandNumbersOnlyRegex } from "../../const/globalConst";
import { te } from "date-fns/locale";
import moment from "moment";


export const expression = {
   onlylowercase: /(^[a-z]+-?[a-z]+$)/, // Only letters
   password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*-_])(?=.{8,})/, // mínimo 8, máximo 15, al menos una mayuscula y una minuscula, al menos un dígito, no espacios en blanco, al menos un carácter especial
   onlyNumbers: /^\d+$/,
   numbersDecimal: /^\d+(\.\d+)?$/,
   rfc: /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
   textOnly: /^[a-zA-ZáéíóúÁÉÍÓÚñ\s]*$/,
   textandNumbersOnly: /^[a-zA-Z0-9áéíóúÁÉÍÓÚñ\s]*$/,
};

// Common usage =================================
export const textValidation = yup
   .string()
   .min(2, "Se requiere un mínimo de 2 caracteres.")
   .max(50, "Se requiere un máximo de 50 caracteres.")
   .required("Este campo es obligatorio");

export const passwordValidation = yup
   .string()
   .matches(expression.password, "Formato inválido")
   .min(8, "Se requiere de un mínimo de 8 caracteres")
   .max(50, "Se requiere de un máximo de 50 caracteres")
   .required("Este campo es obligatorio");

export const phoneNumberValidation = yup
   .string()
   .matches(expression.onlyNumbers, "Favor de introducir únicamente números.")
   .min(10, "Se requiere exactamente 10 números.")
   .max(10, "Se requiere exactamente 10 números.")
   .required("Este campo es obligatorio");

export const emailValidation = yup
   .string()
   .min(3, "Se requiere un mínimo de 3 caracteres.")
   .max(50, "Se requiere un máximo de 50 caracteres.")
   .required("Este campo es obligatorio")
   .email("Correo electrónico inválido");

export const emailValidationNotRequired = yup
   .string()
   .min(3, "Se requiere un mínimo de 3 caracteres.")
   .max(50, "Se requiere un máximo de 50 caracteres.")
   .email("Correo electrónico inválido");

const dependenceValidation = (dependenceName: string) => {
   return yup.string().when(dependenceName, {
      is: "Si",
      then: longTextValidation,
      otherwise: yup.string(),
   });
};





export const zipCodeValidation = yup
   .string()
   .matches(expression.onlyNumbers, "Favor de introducir únicamente números.")
   .min(5, "C.P inválido")
   .max(5, "C.P inválido")
   .required("Este campo es obligatorio");

const dependenceRadioValidation = (dependenceName: string) => {
   return yup.string().when(dependenceName, {
      is: "Si",
      then: yup.string().required("Este campo es requerido"),
      otherwise: yup.string(),
   });
};

export const longTextValidation = yup
   .string()
   .min(2, "Se requiere un mínimo de 2 caracteres.")
   .max(1000, "Se requiere un máximo de 1000 caracteres.")
   .required("Este campo es obligatorio");

export const mediumTextValidation = yup
   .string()
   .min(2, "Se requiere un mínimo de 2 caracteres.")
   .max(200, "Se requiere un máximo de 200 caracteres.")
   .required("Este campo es obligatorio");

const phoneNumberValidationNotRequired = yup
   .string()
   .matches(expression.onlyNumbers, "Favor de introducir únicamente números.")
   .min(10, "Se requiere exactamente 10 números.")
   .max(10, "Se requiere exactamente 10 números.");

const zipcodeValidation = yup
   .string()
   .matches(expression.onlyNumbers, "Favor de introducir únicamente números.")
   .min(5, "C.P inválido")
   .max(5, "C.P inválido")
   .required("Este campo es obligatorio");

// ================================================

export const companyWithoutCDSchema = yup.object().shape({
   name: textValidation,
});

export const companyModalSchema = yup.object().shape({
   name: textValidation,
   subName: yup
      .string()
      .min(3, "Se requiere un mínimo de 3 caracteres.")
      .max(30, "Se requiere un máximo de 30 caracteres.")
      .required("Este campo es obligatorio")
      .matches(expression.onlylowercase, "Formato inválido"),
   message: yup
      .string()
      .min(3, "Se requiere un mínimo de 3 caracteres.")
      .max(500, "Se requiere un máximo de 500 caracteres.")
      .required("Este campo es obligatorio"),
});

export const userCreateSchema = yup.object().shape({
   firstName: textValidation,
   lastName: textValidation,
   email: emailValidation,
   phone: phoneNumberValidation,
   modules: yup.array().required("Este campo es obligatorio").min(1, "Se requiere mínimo de un módulo."),
   roles: yup.array().required("Este campo es obligatorio").min(1, "Se requiere mínimo de un rol."),
   branches: yup.array().required("Este campo es obligatorio").min(1, "Se requiere mínimo de una sucursal."),
});

export const userCreateSchemaNew = (basicInfo) => {
   // const roleSchema = Object.keys(roles);
   // const newMap:ObjectShape[] = roleSchema.map((role)=>{
   //    return {
   //       role: validationType[role].validation
   //    }
   // })
   return yup.object().shape({
      firstName: textValidation,
      lastName: textValidation,
      email: emailValidation,
      phoneNumber: phoneNumberValidation,
      role: basicInfo ? textValidation : yup.string(),
   });
};

export const createCD = yup.object().shape({
   firstName: textValidation,
   lastName: textValidation,
   email: emailValidation,
   phone: phoneNumberValidation,
});

export const userCreateSchemaWoCD = yup.object().shape({
   firstName: textValidation,
   lastName: textValidation,
   email: emailValidation,
   phone: phoneNumberValidation,
   modules: yup.array().required("Este campo es obligatorio").min(1, "Se requiere mínimo de un módulo."),
   roles: yup.array().required("Este campo es obligatorio").min(1, "Se requiere mínimo de un rol."),
});

export const userChangePasswordSchema = yup.object().shape({
   passwordOriginal: yup
      .string()
      .min(8, "Se requiere de un mínimo de 8 caracteres")
      .max(50, "Se requiere de un máximo de 50 caracteres")
      .required("Este campo es obligatorio")
      .test(
         "match",
         "Las contraseñas no deben ser iguales", // your error message
         function () {
            return this.parent.passwordOriginal !== this.parent.passwordNew;
         }
      ),
   passwordNew: passwordValidation,
   passwordNewConfirmation: yup
      .string()
      .oneOf([yup.ref("passwordNew"), null], "Las contraseñas no coinciden.")
      .required("Este campo es obligatorio"),
});

export const securityQuestionSchema = yup.object().shape({
   securityQuestion: yup.string().min(1, "Seleccione una pregunta"),
   securityAnswer: yup
      .string()
      .min(2, "Se requiere de un mínimo de 2 caracteres")
      .required("Este campo es obligatorio"),
});

export const userModifySchema = yup.object().shape({
   firstName: textValidation,
   lastName: textValidation,
   email: emailValidation,
   phone: phoneNumberValidation,
   modules: yup.array().required("Este campo es obligatorio").min(1, "Se requiere mínimo de un módulo."),
   roles: yup.array().required("Este campo es obligatorio").min(1, "Se requiere mínimo de un rol."),
   branches: yup.array().required("Este campo es obligatorio").min(1, "Se requiere mínimo de una sucursal."),
});

export const aditionalContactModifySchema = yup.object().shape({
   firstName: textValidation,
   lastName: textValidation,
   email: emailValidation,
   phoneNumber: yup
      .string()
      .matches(expression.onlyNumbers, "Favor de introducir únicamente números.")
      .min(10, "Se requiere exactamente 10 caracteres.")
      .max(10, "Se requiere exactamente 10 caracteres.")
      .required("Este campo es obligatorio"),
});

export const userInformationModifySchema = yup.object().shape({
   userName: textValidation,
   userLastName: textValidation,
   phoneNumber: yup
      .string()
      .matches(expression.onlyNumbers, "Favor de introducir únicamente números.")
      .min(10, "Se requiere exactamente 10 caracteres.")
      .max(10, "Se requiere exactamente 10 caracteres.")
      .required("Este campo es obligatorio"),
   email: emailValidation,
});

export const emailSchema = yup.object().shape({
   email: emailValidation,
});

export const tagSchema = yup.object().shape({
   title: textValidation,
   color: yup.string().required("Este campo es obligatorio"),
});

export const noteSchema = yup.object().shape({
   title: yup.string().required("Este campo es obligatorio"),
   description: yup.string().optional(),
   allowedComments: yup.boolean().optional(),
});
export const branchModalSchema = yup.object().shape({
   name: textValidation,
   address: textValidation,
   state: yup.string().required("Este campo es obligatorio"),
   city: yup.string().required("Este campo es obligatorio"),
   zipCode: yup
      .string()
      .matches(expression.onlyNumbers, "Favor de introducir únicamente números.")
      .min(5, "Se requiere exactamente 5 caracteres.")
      .max(5, "Se requiere exactamente 5 caracteres.")
      .required("Este campo es obligatorio"),
   phoneNumber: yup
      .string()
      .matches(expression.onlyNumbers, "Favor de introducir únicamente números.")
      .min(10, "Se requiere exactamente 10 caracteres.")
      .max(10, "Se requiere exactamente 10 caracteres.")
      .required("Este campo es obligatorio"),
   handleComplaints: yup.boolean(),
});


export const disseminationsMethods = yup.object().shape({
   email: emailValidation,
   message: yup
      .string()
      .min(2, "Se requiere un mínimo de 2 caracteres.")
      .max(150, "Se requiere un máximo de 150 caracteres.")
      .required("Este campo es obligatorio"),
});

export const incompetenceSchema = (type: boolean) => {
   return yup.object().shape({
      state: textValidation,
      city: textValidation,
      dateTime: yup.string().required("Este campo es obligatorio"),
      competentWork: type ? textValidation : yup.string(),
      competentName: type ? textValidation : yup.string(),
      involvedName: textValidation,
      involvedJob: !type ? textValidation : yup.string(),
      additionalMember: textValidation,
   });
};

export const witnessInterviewSchema = yup.object().shape({
   online: yup.string().required("Este campo es requerido."),
   witnessName: textValidation,
   worksInPlace: yup.string().required("Este campo es requerido"),
   witnessActivity: dependenceValidation("worksInPlace"),
   witnessTime: dependenceValidation("worksInPlace"),
   witnessKnowledge: textValidation,
   presentInComplaint: yup.string().required("Este campo es requerido"),
   trueComplaint: yup.string().required("Este campo es requerido"),
   knowsPeople: dependenceRadioValidation("trueComplaint"),
   witnessEvidence: yup.string().required("Este campo es requerido"),
   witnessHasEvidence: dependenceRadioValidation("witnessEvidence"),
   witnessPeople: dependenceRadioValidation("knowsPeople"),
   perceptionVariation: yup.string().required("Este campo es requerido"),
   witnessNarration: dependenceRadioValidation("perceptionVariation"),
   witnessDateActivities: longTextValidation,
   witnessAddWitnessNames: dependenceValidation("witnessOfWitness"),
   witnessOfWitness: yup.string().required("Este campo es requerido"),
   witnessAddInfo: longTextValidation,
   modifyActions: longTextValidation,
   witnessTruth: yup.string().required("Este campo es requerido"),
});

export const denouncedInterviewSchema = yup.object().shape({
   online: yup.string().required("Este campo es requerido."),
   denouncedName: textValidation,
   denouncedActivity: textValidation,
   denouncedTime: textValidation,
   denouncedRecognition: yup.string().required("Este campo es requerido"),
   denouncedFacts: yup.string().required("Este campo es requerido"),
   denouncedFactsAnswer: dependenceValidation("denouncedFacts"),
   denouncedResponsible: yup.string().required("Este campo es requerido"),
   denouncedPersonName: dependenceRadioValidation("denouncedResponsible"),
   denouncedPersonNameAnswer: dependenceValidation("denouncedPersonName"),
   denouncedDayActivities: textValidation,
   denouncedWitness: yup.string().required("Este campo es requerido"),
   denouncedWitnessAnswer: dependenceValidation("denouncedWitness"),
   denouncedFundamentals: yup.string().required("Este campo es requerido"),
   denouncedFundamentalsAnswer: dependenceValidation("denouncedFundamentals"),
   denouncedCategoryPartake: yup.string().required("Este campo es requerido"),
   denouncedNarration: dependenceValidation("denouncedCategoryPartake"),
   denouncedTruth: yup.string().required("Este campo es requerido"),
});

export const userLoginSchema = yup.object().shape({
   email: emailValidation,
   password: yup
      .string()
      .min(6, "Se requiere de un mínimo de 6 caracteres")
      .max(50, "Se requiere de un máximo de 50 caracteres")
      .required("Este campo es obligatorio"),
});

export const userResetPasswordSchema = yup.object().shape({
   passwordNew: passwordValidation,
   passwordNewConfirmation: yup
      .string()
      .oneOf([yup.ref("passwordNew"), null], "Las contraseñas no coinciden.")
      .required("Este campo es obligatorio"),
});

export const aditionalInformationSchema = yup.object().shape({
   email: emailValidation,
   phoneNumber: yup
      .string()
      .matches(expression.onlyNumbers, "Favor de introducir únicamente números.")
      .min(10, "Se requiere exactamente 10 caracteres.")
      .max(10, "Se requiere exactamente 10 caracteres.")
      .required("Este campo es obligatorio"),
});

export const complainantInterviewSchema = yup.object().shape({
   online: yup.string().required("Este campo es requerido."),
   complainantName: textValidation,
   complainantLabors: yup.string().required("Este campo es requerido"),
   complainantActivity: dependenceValidation("complainantLabors"),
   complainantTime: dependenceValidation("complainantLabors"),
   complainantExplanation: yup.string().when("complainantLabors", {
      is: "No",
      then: longTextValidation,
      otherwise: yup.string(),
   }),
   complainantIsComplainter: yup.string().required("Este campo es requerido"),
   complainantInvolved: yup.string().required("Este campo es requerido"),
   complainantInvolvedNarration: dependenceRadioValidation("complainantInvolved"),
   complainantConfirmation: yup.string().required("Este campo es requerido"),
   complainantWitness: yup.string().required("Este campo es requerido"),
   complainantWitnessAnswer: dependenceValidation("complainantWitness"),
   complainantMainVictim: yup.string().required("Este campo es requerido"),
   complainantEvidence: yup.string().required("Este campo es requerido"),
   complainantGivesEvidence: dependenceRadioValidation("complainantEvidence"),
   complainantMeasures: longTextValidation,
   complainantFixDamage: longTextValidation,
   complainantTruth: yup.string().required("Este campo es requerido"),
});

const dateToday = new Date();
   dateToday.setHours(0, 0, 0, 0);
   
export const FideicomisoSchemaModal1 = yup.object().shape({
   presidente: yup.string().when('comitetecnico', {
      is: "Si",
      then: yup.string().required("El nombre del presidente es requerido"),
      otherwise: yup.string().notRequired(),
   }),
   miembrosComite: yup.array().when('comitetecnico', {
      is: "Si",
      then: yup.array().of(
         yup.object().shape({
            cargo: yup.string().required("El cargo es requerido"),
            nombre: yup.string().required("El nombre es requerido"),
         })
      ),
      otherwise: yup.array().notRequired(),
   }),
   protectorFiduciario: yup.string().when('protectorfiduciario', {
      is: "Si",
      then: yup.string().required("El nombre del protector fiduciario es requerido"),
      otherwise: yup.string().notRequired(),
   }),
});

export const FideicomisoSchemaModal2 = yup.object().shape({
   files: yup.mixed()
   .required('Debes seleccionar al menos un archivo')
   .test('fileSize', 'El archivo no puede ser mayor a 12MB', (value) => {
     if (!value) return true; 
     const files = Array.from(value as FileList);
     return files.every(file => file.size <= 12 * 1024 * 1024); 
   })
   .test('fileType', 'Formato de archivo no soportado', (value) => {
     if (!value) return true; 
     const files = Array.from(value as FileList);
     const supportedFormats = ['image/jpeg', 'image/png', 'application/pdf'];
     return files.every(file => supportedFormats.includes(file.type));
   }),
   fechainicio:yup.date()
   .transform(value => {
      return value ? moment(value).toDate() : value;
    })
    .required("Es necesario seleccionar una fecha")
    .min(dateToday, "La fecha debe ser a partir de hoy"),

   duracion : yup.string()
   .matches(/^(?:(?:1[0-9]|20|[1-9])[aA](?: |$))?(?:(?:1[0-2]|[1-9])[mM](?: |$))?(?:(?:[1-4])[sS](?: |$))?(?:(?:[1-9]|[12][0-9]|3[01])[dD](?: |$))?$/,
      'Formato no válido. Ejemplos válidos:"20a 11m 8d".')
   .required('Este campo es obligatorio'),
   numinscripcion: textValidation,
   numescritura: textValidation,
   numnotariocorredor: textValidation,
   numnotariocorredoria: textValidation,
   finesdelfideicomiso: longTextValidation,
   disdelosingresos: longTextValidation,
});
export const FideicomisoSchemaModal3 = yup.object().shape({
   nomfideicomitente: textValidation,
   derechosfideicomitente: longTextValidation,
   Obligacionesfideicomitente: longTextValidation,

});
export const FideicomisoSchemaModal4 = yup.object().shape({
   nomfideicomisario: textValidation,
   derechosfideicomisario: longTextValidation,
   Obligacionesfideicomisario: longTextValidation,

});
export const FideicomisoSchemaModal5 = yup.object().shape({
   nomfiduciario: textValidation,
   delegadosfiduciario: textValidation,
   derechosfiduciario: longTextValidation,
   Obligacionesfiduciario: longTextValidation,
});

export const FideicomisoSchemaModal = yup.object().shape({
   ...FideicomisoSchemaModal2.fields,
   ...FideicomisoSchemaModal1.fields,
   ...FideicomisoSchemaModal3.fields,
   ...FideicomisoSchemaModal4.fields,
   ...FideicomisoSchemaModal5.fields,
   });


   export const CoOwnerSchemaModal = yup.object().shape({

      object: textValidation,
      contributionsCoOwner: textValidation,
      rights: longTextValidation,
      obligations: longTextValidation,
      commonRepresentative: textValidation,
      businessSupervisor: textValidation,
      validity: yup
      .string()
      .matches(/^(?:(?:1[0-9]|20|[1-9])[aA](?: |$))?(?:(?:1[0-2]|[1-9])[mM](?: |$))?(?:(?:[1-4])[sS](?: |$))?(?:(?:[1-9]|[12][0-9]|3[01])[dD](?: |$))?$/,
         'Formato no válido. Ejemplos válidos:"20a 11m 8d".')
      .required('Este campo es obligatorio'),
      termination: yup.date()
      .transform(value => {
         return value ? moment(value).toDate() : value;
       })
       .required("Es necesario seleccionar una fecha")
       .min(dateToday, "La fecha debe ser a partir de hoy"),
      });


      export const BCGenericSchemaModal = yup.object().shape({

         object: textValidation,
         coInvestors : longTextValidation,
         contributionsCoInvestment: textValidation,
         rights: longTextValidation,
         obligations: longTextValidation,
         commonRepresentative: textValidation,
         supervisor: textValidation,
         administration : textValidation,
         validity: yup
         .string()
         .matches(/^(?:(?:1[0-9]|20|[1-9])[aA](?: |$))?(?:(?:1[0-2]|[1-9])[mM](?: |$))?(?:(?:[1-4])[sS](?: |$))?(?:(?:[1-9]|[12][0-9]|3[01])[dD](?: |$))?$/,
            'Formato no válido. Ejemplos válidos:"20a 11m 1s 8d".')
         .required('Este campo es obligatorio'),
         termination: yup.date()
         .transform(value => {
            return value ? moment(value).toDate() : value;
          })
          .required("Es necesario seleccionar una fecha")
          .min(dateToday, "La fecha debe ser a partir de hoy"),
         });
export const notificationsSchema = (type: boolean) => {
   return yup.object().shape({
      name: textValidation,
      organization: textValidation,
      state: textValidation,
      city: textValidation,
      dateTime: yup.string().required("Este campo es obligatorio"),
      value: yup.string().required("Este campo es obligatorio"),
      interviewPlace: yup
         .string()
         .min(2, "Se requiere un mínimo de 2 caracteres.")
         .max(200, "Se requiere un máximo de 200 caracteres.")
         .required("Este campo es obligatorio"),
      email: type ? emailValidation : yup.string(),
      emailTo: type ? emailValidation : yup.string(),
      // issue: type ? textValidation :0 yup.string(),
      extract: type ? longTextValidation : yup.string(),
   });
};

export const resolutionNotificationSchema = (measures: boolean) => {
   return yup.object().shape({
      city: textValidation,
      state: textValidation,
      measures: measures ? longTextValidation : yup.string(),
      dateTime: yup.string().required("Este campo es obligatorio"),
   });
};

export const resolutionScreenSchema = yup.object().shape({
   complainantStatedFacts: longTextValidation,
   organization: textValidation,
   address: textValidation,
   complainantEvidence: longTextValidation,
   clasificationComplaint: longTextValidation,
   fundamentals: longTextValidation,
   measures: longTextValidation,
   dateTime: yup.string().required("Este campo es obligatorio"),
   auditor: textValidation,
   director: textValidation,
   queryBank: yup.boolean().default(false),
});

export const sugestionResolutionSchema = yup.object().shape({
   complainantStatedFacts: longTextValidation,
   organization: textValidation,
   address: textValidation,
   complainantEvidence: longTextValidation,
   measures: longTextValidation,
   dateTime: yup.string().required("Este campo es obligatorio"),
   auditor: textValidation,
   director: textValidation,
   queryBank: yup.boolean().default(false),
});

export const complaintResolutionScreenSchema = (denounce: boolean) => {
   return yup.object().shape({
      offenderFacts: longTextValidation,
      organization: textValidation,
      address: textValidation,
      offenderEvidence: longTextValidation,
      clasificationComplaint: denounce ? longTextValidation : yup.string(),
      fundamentals: longTextValidation,
      measures: longTextValidation,
      queryBank: yup.boolean().default(false),
      auditor: textValidation,
      director: textValidation,
   });
};

export const recordClousureSchema = yup.object().shape({
   investigationProcess: longTextValidation,
   measures: longTextValidation,
   evidences: yup
      .string()
      .min(2, "Se requiere un mínimo de 2 caracteres.")
      .max(500, "Se requiere un máximo de 500 caracteres.")
      .optional(),
   // resolution: longTextValidation,
});

export const absenseConstancySchema = yup.object().shape({
   city: textValidation,
   state: textValidation,
   organization: textValidation,
   involvedName: textValidation,
   dateTime: yup.string().required("Este campo es obligatorio"),
});

export const witnessInfractorSchema = yup.object().shape(
   {
      name: yup.string().when("name", (val, schema) => {
         if (val?.length > 0) {
            return yup
               .string()
               .matches(textandNumbersOnlyRegex, "Formato inválido")
               .max(50, "Máximo 50 caracteres")
               .required("Campo obligatorio");
         } else {
            return yup.string().optional();
         }
      }),
      lastName: yup.string().when("name", (val, schema) => {
         if (val?.length > 0) {
            return yup
               .string()
               .matches(textandNumbersOnlyRegex, "Formato inválido")
               .max(50, "Máximo 50 caracteres")
               .required("Campo obligatorio");
         } else {
            return yup.string().optional();
         }
      }),
      gender: yup.string().matches(textOnlyRegex, "Formato inválido").required("Campo obligatorio"),
      position: yup.string().when("position", (val, schema) => {
         if (val?.length > 0) {
            return yup
               .string()
               .matches(freeTextRegex, "Formato inválido")
               .min(3, "Mínimo 3 caracteres")
               .max(50, "Máximo 50 caracteres")
               .required("Campo obligatorio");
         } else {
            return yup.string().optional();
         }
      }),
      observations: yup
         .string()
         .min(5, "Mínimo 5 caracteres")
         .max(500, "Máximo 500 caracteres")
         .notRequired()
         .when(["name", "lastName"], (val, schema) => {
            if (val?.length > 0) {
               return yup.string().optional();
            } else {
               return (
                  yup
                     .string()
                     // .matches(textOnlyRegex, "Formato inválido")
                     .max(500, "Máximo 500 caracteres")
                     .required("Campo obligatorio")
               );
            }
         }),
   },
   [
      ["name", "name"],
      ["lastName", "lastName"],
      ["position", "position"],
   ]
);

export const supportSchema = (supportType: string) => {
   return yup.object().shape({
      title: yup
         .string()
         .min(3, "Mínimo de tres caracteres")
         .max(50, "Máximo de caracteres")
         .required("Este campo es obligatorio"),
      description:
         supportType === "Haz una pregunta"
            ? yup.string().min(3, "Mínimo de tres caracteres").max(1000, "Máximo de caracteres")
            : yup
                 .string()
                 .min(3, "Mínimo de tres caracteres")
                 .max(1000, "Máximo de caracteres")
                 .required("Este campo es obligatorio"),
      email: supportType === "Sugiere una mejora" ? emailValidationNotRequired : emailValidation,
      type: yup.string().required("Este campo es obligatorio"),
   });
};

export const ModifyInvoiceDetailsSchema = yup.object().shape({
   denomination: textValidation,
   rfc: textValidation.matches(expression.rfc, "Ingrese un RFC valido"),
   email: emailValidation,
   city: textValidation,
   state: textValidation,
   address: textValidation,
   suburb: textValidation,
   phone: phoneNumberValidation,
   zipCode: zipCodeValidation,
   regime: textValidation,
   amount: textValidation.matches(expression.numbersDecimal, "Favor de introducir únicamente números."),
});

export const BasicInformationSchema = yup.object().shape({
   userName: longTextValidation,
   userLastName: longTextValidation,
   email: emailValidation,
   gender: yup.string().required("Seleccione al menos un genero"),
   phone: phoneNumberValidation,
   dob: yup.string().required("Seleccione una fecha de nacimiento"),
   birthLocation: longTextValidation,
   address: longTextValidation,
});

export const PersonalInformationSchema = yup.object().shape({
   company: longTextValidation,
   actualPosition: mediumTextValidation,
   startDate: yup.string().required(),
});

export const ProfessionalDataSchema = yup.object().shape({
   title: textValidation,
   institution: textValidation,
   startTime: yup.string().required("Seleccione una fecha de inicio"),
   endTime: yup.string().required("Seleccione una fecha de terminación"),
   degree: yup.string(),
});

export const governingBodySchema = yup.object().shape({
   title: yup.string().required("Obligatorio"),
   functions: yup.array().of(yup.string().required("Obligatorio")).min(1, "Mínimo uno"),
   name: yup.string().optional(),
   integrationMin: yup.number().required("Obligatorio").integer("Entero").typeError("Número").positive("Mayor a 0"),
   integrationMax: yup.number().required("Obligatorio").integer("Entero").typeError("Número").positive("Mayor a 0"),
   chargesDuration: yup.string().required("Obligatorio"),
   quorum: yup
      .number()
      .required("Obligatorio")
      .integer("Entero")
      .typeError("Número")
      .positive("Mayor a 0")
      .max(100, "Máximo 100"),
   sendAnnouncementDays: yup
      .number()
      .required("Obligatorio")
      .integer("Entero")
      .typeError("Número")
      .positive("Mayor a 0"),
   confirmationDays: yup.number().required("Obligatorio").integer("Entero").typeError("Número"),
   resolutionVotes: yup.number().required("Obligatorio").integer("Entero").typeError("Número").positive("Mayor a 0"),
   compositionP: yup
      .number()
      .required("Obligatorio")
      .integer("Entero")
      .typeError("Número")
      .moreThan(-1)
      .max(100, "Máximo 100"),
   compositionR: yup
      .number()
      .required("Obligatorio")
      .integer("Entero")
      .typeError("Número")
      .moreThan(-1)
      .max(100, "Máximo 100"),
   compositionI: yup
      .number()
      .required("Obligatorio")
      .integer("Entero")
      .typeError("Número")
      .moreThan(-1)
      .max(100, "Máximo 100"),
   watchman: yup.boolean(),
});

export const shareholdersBodySchema = yup.object().shape({
   title: yup.string().required("Obligatorio"),
   name: yup.string().optional(),
   sendAnnouncementDays: yup
      .number()
      .required("Obligatorio")
      .integer("Entero")
      .typeError("Número")
      .positive("Mayor a 0"),
   confirmationDays: yup.number().required("Obligatorio").integer("Entero").typeError("Número"),
});

export const userGoverningBody = yup.object().shape({
   userId: yup.string().optional(),
   charge: yup.string().required("Este campo es obligatorio"),
   character: yup.array().of(yup.string().required("Este campo es obligatorio")),
   specialP: yup.boolean(),
   firstName: textValidation,
   lastName: textValidation,
   email: emailValidation,
   phoneNumber: phoneNumberValidation,
});

export const shareholderSchema = yup.object().shape({
   name: yup.string().required("Este campo es obligatorio"),
   founder: yup.boolean().required("Este campo es obligatorio"),
   legalRepresentative: yup.boolean().required("Este campo es obligatorio"),
});

export const corporateDataSchema = yup.object().shape({
   societyKind: yup.string().required("Obligatorio"),
   legalConcept: yup.string().required("Obligatorio"),
   legalConceptOther: yup.string().optional(),
   physicalAddress: yup.string().required("Obligatorio"),
   taxRegime: yup.string().required("Obligatorio"),
   operatingCountries: yup.array().of(yup.string().required("Obligatorio")).min(1, "Seleccionar un país."),
   watchman: yup.array().of(yup.string().optional()).optional(),
   socialObject: yup.array().of(yup.string().required("Obligatorio")).min(1, "Mínimo uno"),
   legalRepresentative: yup.string().optional(),
   administration: yup.string().required("Obligatorio"),
   councilType: yup.string().required("Obligatorio"),
   administratorFaculties: yup.string().optional(),
   businessFolio: yup.string().optional(),
   notaryName: yup.string(),
   notaryNumber: yup.number().typeError("Número").positive("Mayor a 0").integer("Entero").optional(),
   recordType: yup.string().required("Obligatorio"),
   actNumber: yup.number().required("Obligatorio").typeError("Número").positive("Mayor a 0").integer("Entero"),
});

export const classificationSchema = yup.object().shape({
   quorumA0: yup
      .number()
      .typeError("Número")
      .required("Obligatorio")
      .positive("Mayor a 0")
      .integer("Entero")
      .max(100, "Máximo 100"),
   quorumA1: yup
      .number()
      .typeError("Número")
      .required("Obligatorio")
      .positive("Mayor a 0")
      .integer("Entero")
      .max(100, "Máximo 100"),
   quorumV0: yup
      .number()
      .typeError("Número")
      .required("Obligatorio")
      .positive("Mayor a 0")
      .integer("Entero")
      .max(100, "Máximo 100"),
   quorumV1: yup
      .number()
      .typeError("Número")
      .required("Obligatorio")
      .positive("Mayor a 0")
      .integer("Entero")
      .max(100, "Máximo 100"),
});

export const createVoteQuestion = yup.object().shape({
   title: yup.string().required("Este campo es obligatorio"),
});

export const seriesSchema = yup.object().shape({
   title: yup.string().required("Obligatorio").max(25, "Máximo 25 caracteres"),
   nominalValue: yup.number().required("Obligatorio").positive("Mayor a 0").integer("Entero").typeError("Número"),
});

export const partnerTypeSchema = yup.object().shape({
   title: yup.string().required("Obligatorio").max(25, "Máximo 25 caracteres"),
   voice: yup.boolean().notRequired(),
   vote: yup.boolean().notRequired(),
   remnant: yup.boolean().notRequired(),
});

export const controllingBeneficiarySchema = yup.object().shape({
   shareHolderType: yup.string().required("Este campo es obligatorio"),
   legalPerson: yup.string().when("shareHolderType", {
      is: "Persona moral",
      then: yup.string().required("Este campo es obligatorio"),
      otherwise: yup.string().notRequired(),
   }),
   identification: yup.string().when("selectedIdentification", {
      is: "Otro",
      then: textValidation.required("Favor de agregar identificador"),
      otherwise: yup.string().notRequired(),
   }),
   name: textValidation,
   // gender: yup.string().when("shareHolderType", {
   //    is: "Persona física",
   //    then: textValidation.required("Seleccione al menos un genero"),
   //    otherwise: yup.string().notRequired(),
   // }),
   // birthDate: yup.date().when("shareHolderType", {
   //    is: "Persona física",
   //    then: yup.date().required("Este campo es obligatorio"),
   //    otherwise: yup.date().notRequired(),
   // }),
   email: yup.string().email("El correo electrónico no es válido").required("El correo electrónico es obligatorio"),
   email2: yup.string().email("El correo electrónico no es válido"),
   phoneNumber: yup.string().matches(expression.onlyNumbers, "El número de celular debe contener solo números"),
   // placeOfBirth: yup.string().when("shareHolderType", {
   //    is: "Persona física",
   //    then: yup.string().required("Este campo es obligatorio"),
   //    otherwise: yup.string().notRequired(),
   // }),
   // address: yup.string().when("shareHolderType", {
   //    is: "Persona física",
   //    then: yup.string().required("Este campo es obligatorio"),
   //    otherwise: yup.string().notRequired(),
   // }),
   businessName: yup.string().when("shareHolderType", {
      is: "Persona moral",
      then: yup.string().required("Este campo es obligatorio"),
      otherwise: yup.string().notRequired(),
   }),
   // rfc: yup
   //    .string()
   //    .matches(expression.rfc, "RFC no es válido")
   //    .when("shareHolderType", {
   //       is: "Persona fisica",
   //       then: yup.string().required("Este campo es obligatorio"),
   //       otherwise: yup.string().notRequired(),
   //    }),
   businessRFC: yup.string().when("shareHolderType", {
      is: "Persona moral",
      then: yup.string().required("Este campo es obligatorio"),
      otherwise: yup.string().notRequired(),
   }),
   constitutionCountry: yup.string().when("shareHolderType", {
      is: "Persona moral",
      then: textValidation,
      otherwise: yup.string().notRequired(),
   }),
   fiscalCountry: yup.string().when("shareHolderType", {
      is: "Persona moral",
      then: textValidation,
      otherwise: yup.string().notRequired(),
   }),
   fiscalAddress: yup.string().when("shareHolderType", {
      is: "Persona moral",
      then: textValidation,
      otherwise: yup.string().notRequired(),
   }),
   lastname: yup.string().when("shareHolderType", {
      is: "Persona moral",
      then: yup.string().required("Este campo es obligatorio"),
      otherwise: yup.string().notRequired(),
   }),
});

export const affairVotesSchema = yup.object().shape({
   question: yup.string().max(100, "Máximo 100 carácateres permitidos").optional(),
   selectedOrder: yup.object().required("Selecciona una orden del día"),
});

export const companyLegalSchema = yup.object().shape({
   businessName: mediumTextValidation,
   comercialName: textValidation,
   activity: mediumTextValidation,
});
export const groupSchema = yup.object().shape({
   name: textValidation,
   phoneNumber: phoneNumberValidationNotRequired,
});

export const addInvoiceDetailsSchema = yup.object().shape({
   denomination: mediumTextValidation,
   rfc: textValidation.matches(expression.rfc, "Ingrese un RFC válido"),
   email: emailValidation,
   city: textValidation,
   state: textValidation,
   address: textValidation,
   suburb: textValidation,
   phone: phoneNumberValidation,
   zipCode: zipcodeValidation,
   regime: textValidation,
   amount: textValidation.matches(expression.numbersDecimal, "Favor de introducir únicamente números."),
});

export const createUserSchema = yup.object().shape({
   firstName: textValidation,
   lastName: textValidation,
   email: emailValidation,
   phone: phoneNumberValidation,
});

export const possibleBeneficiarySchema = yup
   .object()
   .shape({
      shareHolderType: yup.string().required("Seleccione un tipo de beneficiario"),
      name: yup.string(),
      businessName: yup.string(),
      rfc: yup.string().matches(expression.rfc, "Ingrese un RFC válido"),
      businessRFC: yup.string().matches(expression.rfc, "Ingrese un RFC válido"),
      email: yup.string().required("Ingrese un correo electrónico válido").email("Formato de correo inválido"),
      additionalEmail: yup.string().nullable().notRequired().email("Formato de correo inválido"),
      phoneNumber: phoneNumberValidation,
      moralAssociation: yup.string().nullable().notRequired(),
   })
   .test("name-or-businessName-required", "Debe proporcionar Nombre o Denominación / Razón social", (values) => {
      return !!values.name || !!values.businessName;
   })
   .test("rfc-required", "Debe proporcionar RFC", (values) => {
      return !!values.rfc || !!values.businessRFC;
   });
