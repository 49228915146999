import { Box, Grid, Typography } from "@mui/material";
import React, { useContext, useMemo } from "react";
import { CorporateDataContext } from "../../../context/governanceContext/corporateDataContext";
import { UserContext } from "../../../context/userContext";
import { useGetMembersInfo } from "../../../hooks/gob-corp/corporateData/useGetMembersInfo";

interface CompanyInfo {
   label: string;
   value: string;
}

const MostRecentCompanyInfo = () => {
   const { corporateData } = useContext(CorporateDataContext);
   const { companySelected } = useContext(UserContext);

   const companyData: CompanyInfo[] = useMemo(() => {
      return [
         {
            label: "Nombre / Denominacion o razon social",
            value: companySelected?.person_details?.businessName || "--",
         },
         { label: "RFC", value: companySelected?.invoice_details?.rfc || "--" },
         { label: "Nombre comercial", value: companySelected?.person_details?.comercialName || "--" },
         { label: "Figura juridica", value: corporateData?.legalConcept || "--" },
      ];
   }, [corporateData, companySelected]);

   const InfoField = ({ label, value }: CompanyInfo) => (
      <Grid
         item
         xs={6}
         sx={{
            p: 2,
            display: "flex",
            alignItems: "center",
            bgcolor: "white",
            justifyContent: "space-between",
            borderRight: label === "RFC" || label === "Figura juridica" ? "none" : "2px solid white",
         }}
      >
         <Typography variant="subtitle2" sx={{ mr: 2, flex: 1 }}>
            {label}
         </Typography>
         <Box
            sx={{
               bgcolor: "#f2f3f5",
               p: 1,
               borderRadius: 1,
               width: "375px",
               height: "30px",
               display: "flex",
               alignItems: "center",
               justifyContent: "center",
            }}
         >
            <Typography>{value}</Typography>
         </Box>
      </Grid>
   );

   return (
      <Grid
         container
         direction="column"
         sx={{
            width: "100%",
            border: "1px solid #ccc",
            borderRadius: "8px",
            overflow: "hidden",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            "& .MuiGrid-root": { margin: 0 },
         }}
      >
         <Grid
            item
            sx={{
               bgcolor: "#738090",
               p: 2,
               width: "100%",
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               height: 40,
            }}
         >
            <Typography variant="h6" sx={{ color: "white", fontWeight: "bold" }}>
               Informacion mas actualizada de la empresa
            </Typography>
         </Grid>

         <Grid container sx={{ width: "100%" }}>
            {companyData.map((field, index) => (
               <InfoField key={index} {...field} />
            ))}
         </Grid>
      </Grid>
   );
};

export default MostRecentCompanyInfo;
