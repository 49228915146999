import React, { useContext, useState } from "react";
import { CustomModalComponent } from "../../../CustomModalComponent";
import { Form, Formik, FormikProps } from "formik";
import {
   Box,
   Button,
   CircularProgress,
   darken,
   Grid,
   ListItemText,
   MenuItem,
   Select,
   Stack,
   Typography,
} from "@mui/material";
import { InputTextField } from "../../../Inputs/InputTextField";
import { IBeneficiary } from "../../../../types/beneficiaryTypes";
import {
   createBeneficiary,
   getBeneficiaryByRFC,
   getUserByEmail,
   sendBeneficiarySurvey,
   updateBeneficiaryCompanies,
} from "../../../../lib/usersBEClient";
import { BeneficiaryControllerContext } from "../../../../context/beneficiaryContext/beneficiaryContollerContext";
import { CorporateDataContext } from "../../../../context/governanceContext/corporateDataContext";
import { SnackBarContext } from "../../../../context/snackBarContext";
import { UserContext } from "../../../../context/userContext";
import { possibleBeneficiarySchema } from "../../../../lib/validations/inputSchemas";
import { BeneficiaryQuestionaryContext } from "../../../../context/beneficiaryContext/beneficiaryQuestionaryContext";

const shareHolderTypes = ["Persona física", "Persona moral"];

interface CreatePossibleBeneficiaryModalProps {
   state: boolean;
   setState: Function;
   moralAssociation?: string;
   setOpenQuestionary: Function;
}

const CreatePossibleBeneficiaryModal = (props: CreatePossibleBeneficiaryModalProps) => {
   const { state, setState, moralAssociation = undefined, setOpenQuestionary } = props;
   //CONTEXTS
   const { beneficiaries, refetch, possibleBeneficiaries } = useContext(BeneficiaryControllerContext);
   const { setSelectedPossibleBeneficiary } = useContext(BeneficiaryQuestionaryContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const { governanceBody } = useContext(CorporateDataContext);
   const { companySelected } = useContext(UserContext);
   //STATES
   const [isLoading, setIsLoading] = useState(false);
   const [openQuestionaryModal, setOpenQuestionaryModal] = useState(false);
   const [foundByRFC, setFoundByRFC] = useState<IBeneficiary>(null);
   const [createdBeneficiaryId, setCreatedBeneficiaryId] = useState(null);

   const handleSubmit = async (values) => {
      if (!governanceBody) return showSnackBar("Favor de registrar información societaria", true);
      try {
         setIsLoading(true);
         if (foundByRFC) {
            await updateBeneficiaryCompanies(foundByRFC._id, companySelected._id);
            await sendBeneficiarySurvey(foundByRFC._id, companySelected._id);
            setCreatedBeneficiaryId(foundByRFC._id);
            showSnackBar("Posible beneficiario controlador registrado con éxito", false);
            refetch();
         } else {
            const userFound = await getUserByEmail(values.email);
            if (userFound) {
               const userInCompany = beneficiaries.some((b) => b.user === userFound._id || b.email === values.email);
               if (userInCompany) {
                  setIsLoading(false);
                  return showSnackBar("Ya se registró un beneficiario controlador con ese correo", true);
               }
            }
            const data = {
               ...values,
               companyId: [companySelected._id],
               user: userFound._id ?? undefined,
            };
            const response = await createBeneficiary(data);
            await sendBeneficiarySurvey(response.id, companySelected._id);
            setCreatedBeneficiaryId(response.id);
            if (response) showSnackBar("Posible beneficiario controlador registrado con éxito", false);
            refetch();
         }
         setIsLoading(false);
         setFoundByRFC(null);
         setOpenQuestionaryModal(true);
      } catch (error) {
         showSnackBar("Error al registrar posible beneficiario controlador", true);
      }
   };

   const handleClose = () => {
      setState(false);
      setIsLoading(false);
      setOpenQuestionaryModal(false);
      setFoundByRFC(null);
   };

   const handleOpenQuestionary = () => {
      const beneficiary = possibleBeneficiaries.find((b) => b._id === createdBeneficiaryId);
      if (beneficiary) {
         setSelectedPossibleBeneficiary(beneficiary);
         setOpenQuestionary(true);
         setOpenQuestionaryModal(false);
         setState(false);
         setIsLoading(false);
      }
   };

   const searchBeneficiaryByRFC = async (rfc: string, setFieldValue) => {
      if (!rfc) return;

      const beneficiaryFound = await getBeneficiaryByRFC(rfc);
      if (!beneficiaryFound) return setFoundByRFC(null);

      if (beneficiaries.some((b) => b._id === beneficiaryFound._id)) {
         setFieldValue("rfc", undefined);
         setFieldValue("businessRFC", undefined);
         setFieldValue("name", undefined);
         setFieldValue("businessName", undefined);
         setFieldValue("phoneNumber", undefined);
         showSnackBar("Ya existe un beneficiario con ese RFC dentro de esta empresa", true);
         return;
      }

      setFoundByRFC(beneficiaryFound);

      const { shareHolderType, name, email, additionalEmail, phoneNumber } = beneficiaryFound;

      setFieldValue("shareHolderType", shareHolderType);
      if (shareHolderType === "Persona física") setFieldValue("name", name);
      else setFieldValue("businessName", name);
      setFieldValue("email", email);
      setFieldValue("additionalEmail", additionalEmail);
      setFieldValue("phoneNumber", phoneNumber);
   };

   return (
      <div>
         <CustomModalComponent open={state} setOpen={setState} title="Registro de beneficiario controlador" timeStamp>
            <Formik
               initialValues={{
                  shareHolderType: "Persona física",
                  name: undefined,
                  businessName: undefined,
                  rfc: undefined,
                  businessRFC: undefined,
                  email: undefined,
                  phoneNumber: undefined,
                  additionalEmail: undefined,
                  moralAssociation: moralAssociation,
               }}
               validationSchema={possibleBeneficiarySchema}
               onSubmit={handleSubmit}
            >
               {({ handleChange, setFieldValue, handleBlur, values, errors }) => (
                  <Form>
                     <Grid
                        container
                        direction="column"
                        sx={{
                           width: 750,
                           display: "flex",
                           flexWrap: "nowrap",
                           justifyContent: "space-between",
                           alignItems: "center",
                           height: "auto",
                           margin: "auto",
                           padding: "25px",
                        }}
                     >
                        <Grid
                           item
                           xs={12}
                           sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: 2,
                              gap: 4,
                           }}
                        >
                           <Box sx={{ flex: 1 }}>
                              <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                                 Tipo de beneficiario controlador
                              </Typography>
                              <Select
                                 size="small"
                                 variant="outlined"
                                 id="shareHolderType"
                                 name="shareHolderType"
                                 value={values.shareHolderType}
                                 disabled={!!foundByRFC}
                                 onChange={(e) => {
                                    handleChange(e);
                                    const selectedType = e.target.value;
                                    if (selectedType === "Persona física") {
                                       setFieldValue("businessName", undefined);
                                       setFieldValue("businessRFC", undefined);
                                    } else {
                                       setFieldValue("name", undefined);
                                       setFieldValue("rfc", undefined);
                                    }
                                 }}
                                 onBlur={handleBlur}
                                 fullWidth
                              >
                                 {shareHolderTypes.map((e) => (
                                    <MenuItem key={e} value={e} sx={{ zIndex: 5002 }}>
                                       <ListItemText primary={e} sx={{ my: -0.1 }} />
                                    </MenuItem>
                                 ))}
                              </Select>
                           </Box>

                           <Box sx={{ flex: 1 }}>
                              <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                                 {values.shareHolderType === "Persona física"
                                    ? "Nombre del beneficiario controlador"
                                    : "Denominación / Razón social"}
                              </Typography>
                              <InputTextField
                                 id={values.shareHolderType === "Persona física" ? "name" : "businessName"}
                                 name={values.shareHolderType === "Persona física" ? "name" : "businessName"}
                                 type="text"
                                 placeholder={
                                    values.shareHolderType === "Persona física"
                                       ? "Nombre"
                                       : "Denominación / Razón social"
                                 }
                                 size="small"
                                 sx={{ width: "100%" }}
                                 value={values.shareHolderType === "Persona física" ? values.name : values.businessName}
                                 disabled={!!foundByRFC}
                              />
                           </Box>
                        </Grid>

                        <Grid
                           item
                           xs={12}
                           sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: 2,
                              gap: 4,
                           }}
                        >
                           <Box sx={{ flex: 1 }}>
                              <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                                 RFC
                              </Typography>
                              <InputTextField
                                 fullWidth
                                 type="text"
                                 size="small"
                                 name={values.shareHolderType === "Persona física" ? "rfc" : "businessRFC"}
                                 id={values.shareHolderType === "Persona física" ? "rfc" : "businessRFC"}
                                 placeholder="XXXXXXXXXX"
                                 value={values.shareHolderType === "Persona física" ? values.rfc : values.businessRFC}
                                 onChange={(e) => {
                                    if (values.shareHolderType === "Persona física")
                                       setFieldValue("rfc", e.target.value.toUpperCase());
                                    else setFieldValue("businessRFC", e.target.value.toUpperCase());
                                 }}
                                 onBlur={() => {
                                    if (values.shareHolderType === "Persona física")
                                       searchBeneficiaryByRFC(values.rfc, setFieldValue);
                                    else searchBeneficiaryByRFC(values.businessRFC, setFieldValue);
                                 }}
                                 sx={{ width: "100%" }}
                              />
                           </Box>

                           <Box sx={{ flex: 1 }}>
                              <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                                 Correo Electrónico
                              </Typography>
                              <InputTextField
                                 id="email"
                                 name="email"
                                 type="email"
                                 placeholder="correo@correo.com.mx"
                                 value={values.email}
                                 size={"small"}
                                 sx={{ width: "100%" }}
                                 disabled={!!foundByRFC}
                              />
                           </Box>
                        </Grid>

                        <Grid
                           item
                           xs={12}
                           sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: 2,
                              gap: 4,
                           }}
                        >
                           <Box sx={{ flex: 1 }}>
                              <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                                 Teléfono
                              </Typography>
                              <InputTextField
                                 id="phoneNumber"
                                 name="phoneNumber"
                                 type="text"
                                 placeholder="xxxxxxxxxx"
                                 size={"small"}
                                 value={values.phoneNumber}
                                 sx={{ width: "100%" }}
                                 disabled={!!foundByRFC}
                              />
                           </Box>

                           <Box sx={{ flex: 1 }}>
                              <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                                 Correo electrónico alternativo
                              </Typography>
                              <InputTextField
                                 id="additionalEmail"
                                 name="additionalEmail"
                                 type="email"
                                 placeholder="correo@correo.com.mx"
                                 value={values.additionalEmail}
                                 size={"small"}
                                 sx={{ width: "100%" }}
                                 disabled={!!foundByRFC}
                              />
                           </Box>
                        </Grid>
                     </Grid>
                     <Box
                        sx={{
                           display: "flex",
                           justifyContent: "flex-end",
                           bgcolor: "#F3F3F3",
                           width: "100%",
                           p: 1,
                           height: 50,
                           borderBottomRightRadius: 5,
                           borderBottomLeftRadius: 5,
                        }}
                     >
                        <Button
                           onClick={handleClose}
                           sx={{ color: "#c4c4c4", height: 35, ":hover": { bgcolor: "#E5E6EB" }, mr: 2 }}
                        >
                           Cancelar
                        </Button>
                        <Button
                           type="submit"
                           disabled={isLoading}
                           sx={{ height: 35, ":hover": { bgcolor: "success" } }}
                        >
                           {isLoading ? <CircularProgress size={25} /> : "Guardar"}
                        </Button>
                     </Box>
                  </Form>
               )}
            </Formik>
         </CustomModalComponent>
         <CustomModalComponent title="Registro de beneficiario controlador" open={openQuestionaryModal}>
            <Stack sx={{ bgcolor: "white", p: 4, maxWidth: 500, gap: 2 }}>
               <Typography sx={{ textAlign: "center", color: "162c44", fontSize: 20, fontWeight: "bold" }}>
                  Registro realizado con éxito
               </Typography>
               <Typography sx={{ textAlign: "center" }}>
                  ¿Desea iniciar con el cuestionario de identificación de beneficiario controlador?
               </Typography>
               <Box sx={{ display: "flex", justifyContent: "space-around", mt: 2 }}>
                  <Button
                     sx={{
                        bgcolor: "#D8D8D8",
                        color: "#A3A3A3",
                        px: 2,
                        "&:hover": { bgcolor: darken("#D8D8D8", 0.1) },
                     }}
                     onClick={handleClose}
                  >
                     Cancelar
                  </Button>
                  <Button
                     sx={{
                        bgcolor: "#2D4357",
                        color: "white",
                        px: 2,
                        "&:hover": { bgcolor: darken("#2D4357", 0.1) },
                     }}
                     onClick={handleOpenQuestionary}
                  >
                     Iniciar identificación
                  </Button>
               </Box>
            </Stack>
         </CustomModalComponent>
      </div>
   );
};

export default CreatePossibleBeneficiaryModal;
