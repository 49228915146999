import { AddCircle, Folder } from "@mui/icons-material";
import { Box, Button, Stack, Grid, Card } from "@mui/material";
import React, { useContext } from "react";
import MostRecentCompanyInfo from "../../components/Beneficiary Controller/BeneficiaryDashboard/MostRecentCompanyInfo";
import DeedInformation from "../../components/Beneficiary Controller/BeneficiaryDashboard/DeedInformation";
import PowersGranted from "../../components/Beneficiary Controller/BeneficiaryDashboard/PowersGranted";
import PossibleBeneficiaryController from "../../components/Beneficiary Controller/BeneficiaryDashboard/PossibleBeneficiaries/PossibleBeneficiaryController";
import LegalFigures from "../../components/Beneficiary Controller/BeneficiaryDashboard/LegalFigures/LegalFigures";
import AssemblyMembers from "../../components/Beneficiary Controller/BeneficiaryDashboard/AssemblyMembers";
import AssemblyMembersSocial from "../../components/Beneficiary Controller/BeneficiaryDashboard/AssemblyMembersCivil";
import BeneficiaryManualButton from "../../components/Beneficiary Controller/Subcomponents/BeneficiaryManualButton";
import { CorporateDataContext } from "../../context/governanceContext/corporateDataContext";
import RegisterOfRecordsModal from "../../components/Beneficiary Controller/BeneficiaryDashboard/Subcomponents/RegisterOfRecordsModal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BeneficiaryControllerContext } from "../../context/beneficiaryContext/beneficiaryContollerContext";
import { useBreadcrumbs } from "../../context/breadCrumbContext";

const CorporateDataDashboard = () => {
   const { corporateData } = useContext(CorporateDataContext);
   const { generalFolder } = useContext(BeneficiaryControllerContext);
   const { companyId } = useParams();
   const navigate = useNavigate();
   const { addBreadcrumb, setBreadcrumbs } = useBreadcrumbs();
   const location = useLocation();
   const [isModalOpenROR, setIsModalOpenROR] = React.useState(false); 

   const handleOpenExpediente = () => {
      setBreadcrumbs([]);
      addBreadcrumb({
         name: "Beneficiario Controlador",
         path: location.pathname,
      });
      addBreadcrumb({
         name: "Expediente",
         path: `${location.pathname.replace(/[^/]+$/, `expediente/${generalFolder._id}`)}`,
      });
      return navigate(`/beneficiario-controlador/${companyId}/expediente/${generalFolder._id}`);
   }

   const handleClick = (event) => {
      setIsModalOpenROR(true); // Open RegisterOfRecordsModal directly
   };

   return (
      <Stack
         sx={{
            justifyContent: "center",
            flexDirection: "column",
            py: 2,
         }}
      >
         <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
               <Button
                  variant="contained"
                  sx={{ borderRadius: 1, px: 3, left: 0 }}
                  disabled={!generalFolder}
                  onClick={handleOpenExpediente}
               >
                  <Folder sx={{ fontSize: 19, marginRight: 1 }} />
                  Estructura documental
               </Button>
               <BeneficiaryManualButton />
            </Box>
            <Button variant="contained" sx={{ borderRadius: 1, px: 3, right: 0 }} onClick={handleClick}>
               <AddCircle sx={{ fontSize: 19, marginRight: 1 }} />
               Agregar registro
            </Button>
         </Stack>

         <Box sx={{ mt: 3, display: "flex", flexDirection: "column", gap: 3 }}>
            <MostRecentCompanyInfo />
            <DeedInformation />

            <Grid container spacing={2}>
               <Grid item xs={4} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Card sx={{ m: 0, p: 0, borderRadius: "8px" }}>
                     <PowersGranted />
                  </Card>
                  <Card sx={{ m: 0, p: 0, borderRadius: "8px" }}>
                     <PossibleBeneficiaryController />
                  </Card>
               </Grid>

               <Grid item xs={8} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Card
                     sx={{
                        m: 0,
                        p: 0,
                        borderRadius: "8px",
                        maxWidth: { md: "916.66px", lg: "1500px" },
                        maxHeight: 395,
                     }}
                  >
                     {corporateData?.societyKind === "Sociedad mercantil" ? (
                        <AssemblyMembers />
                     ) : (
                        <AssemblyMembersSocial />
                     )}
                  </Card>
                  <Card sx={{ m: 0, p: 0, borderRadius: "8px" }}>
                     <LegalFigures />
                  </Card>
               </Grid>
            </Grid>
         </Box>
         <RegisterOfRecordsModal open={isModalOpenROR} setOpenModalROR={setIsModalOpenROR} />
      </Stack>
   );
};

export default CorporateDataDashboard;
