import { Box, Divider, FormControlLabel, Radio, RadioGroup, Checkbox, Typography, Stack } from "@mui/material";
import React, { useContext } from "react";
import { BeneficiaryQuestionaryContext } from "../../../../../context/beneficiaryContext/beneficiaryQuestionaryContext";
import { IBeneficiaryQuestion } from "../../../../../types/beneficiaryTypes";
import ChildQuestionComponent from "./ChildQuestionComponent";

interface BeneficiaryQuestionProps {
   question: IBeneficiaryQuestion;
   index: number;
   multiple: boolean;
}

const BeneficiaryQuestionComponent = (props: BeneficiaryQuestionProps) => {
   const { question, index, multiple } = props;
   //CONTEXT
   const { handleSave, questionary, setError } = useContext(BeneficiaryQuestionaryContext);

   const handleUpdate = async (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
         const { questions } = questionary;
         const { answers } = questions[index].question;
         const answerFound = answers.find((a) => a._id === event.target.value);
         if (!answerFound) return;

         const newSelectedAnswers = multiple
            ? event.target.checked
               ? [...questions[index].answer, { ...answerFound }]
               : questions[index].answer.filter((ans) => ans._id !== answerFound._id)
            : [{ ...answerFound }];

         const tempQuest: any = questionary;
         tempQuest.questions[index].answer = newSelectedAnswers;

         await handleSave(tempQuest.questions);
      } catch {
         setError(true);
      }
   };

   return (
      <Box sx={{ border: 1, borderColor: "#E0E0E0", px: 1 }}>
         <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
               sx={{
                  borderRadius: "50%",
                  color: "#152C44",
                  bgcolor: "#CBD5E0",
                  fontWeight: "bold",
                  width: 24,
                  height: 24,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: 14,
                  px: 1,
               }}
            >
               {index + 1}
            </Typography>
            <Typography sx={{ p: 1, fontSize: 14, fontWeight: "bold", flex: 5 }}>{question.question}</Typography>
         </Box>
         <Divider />
         {multiple ? (
            <Stack sx={{ px: 3 }}>
               {questionary.questions[index].question.answers.map((answer) => (
                  <FormControlLabel
                     key={answer._id}
                     control={
                        <Checkbox
                           size="small"
                           checked={questionary.questions[index].answer.some((a) => a._id === answer._id)}
                           onChange={handleUpdate}
                           value={answer._id}
                        />
                     }
                     label={<Typography sx={{ fontSize: 14 }}>{answer.answer}</Typography>}
                  />
               ))}
            </Stack>
         ) : (
            <RadioGroup value={questionary.questions[index]?.answer[0]?._id} onChange={handleUpdate} sx={{ pl: 3 }}>
               {questionary.questions[index]?.question?.answers.map((answer) => (
                  <React.Fragment key={answer._id}>
                     <FormControlLabel
                        value={answer._id}
                        control={<Radio size="small" />}
                        label={<Typography sx={{ fontSize: 14 }}>{answer.answer}</Typography>}
                     />
                     {Boolean(answer.children?.length) &&
                        questionary.questions[index]?.answer[0]?._id === answer?._id &&
                        questionary.questions[index]?.answer[0]?.children.map((child) => (
                           <ChildQuestionComponent
                              key={child?.question?.answers.length}
                              child={child}
                              mainQuestionIndex={index}
                           />
                        ))}
                  </React.Fragment>
               ))}
            </RadioGroup>
         )}
      </Box>
   );
};

export default BeneficiaryQuestionComponent;
