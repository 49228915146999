import { useState, useContext, useEffect } from "react";
import { CustomModalComponent } from "../../../CustomModalComponent";
import { Form, Formik } from "formik";
import {
   Box,
   Button,
   Grid,
   Typography,
   useTheme,
   useMediaQuery,
   Select,
   MenuItem,
   FormControl,
   InputLabel,
   TextField,
   Radio,
   RadioGroup,
   FormControlLabel,
   FormGroup,
   Checkbox,
} from "@mui/material";
import { CircleWithLabel } from "../../../../screens/Beneficiary controller/BeneficiaryFideicomisoFormAux";
import { InputTextField } from "../../../Inputs/InputTextField";
import { AddCircle } from "@mui/icons-material";
import AssemblyMembers from "../AssemblyMembers";
import AssemblyMembersSocial from "../AssemblyMembersCivil";
import { CorporateDataContext } from "../../../../context/governanceContext/corporateDataContext";

// Define labels for different types of actas
const LABELS_CONSTITUTIVA = [
   "INFORMACIÓN DE ACTA",
   "CAPITAL SOCIAL",
   "ORGANO DE ADMINISTRACION",
   "ORGANO DE VIGILANCIA",
   "PODERES OTORGADOS",
];

const LABELS_ORDINARIA = [
   "INFORMACIÓN DE ACTA",
   "ORDEN DEL DIA",
   "ORGANO DE ADMINISTRACION",
   "ORGANO DE VIGILANCIA",
   "PODERES OTORGADOS",
];

const LABELS_EXTRAORDINARIA = [
   "INFORMACIÓN DE ACTA",
   "ORDEN DEL DIA",
   "ORGANO DE ADMINISTRACION",
   "ORGANO DE VIGILANCIA",
   "PODERES OTORGADOS",
];

const LABELS_MIXTA = [
   "INFORMACIÓN DE ACTAS",
   "ESTRUCTURA CORPORATIVA",
   "CAMBIOS ESTATUTARIOS",
   "RESOLUCIONES",
   "PODERES OTORGADOS",
];

const LABELS_ESPECIAL = [
   "INFORMACIÓN DE ACTA",
   "CAPITAL SOCIAL",
   "ORGANO DE ADMINISTRACION",
   "ORGANO DE VIGILANCIA",
   "PODERES OTORGADOS",
];

interface RegisterOfActaModalProps {
   setOpenModalROR: (open: boolean) => void;
   open: boolean;
}

export const RegisterOfRecordsModal = ({ setOpenModalROR, open }: RegisterOfActaModalProps) => {
   const [currentStep, setCurrentStep] = useState(1);
   const [currentLabels, setCurrentLabels] = useState(LABELS_CONSTITUTIVA);
   const [maxSteps, setMaxSteps] = useState(5);
   const [isLoadingSave, setIsLoadingSave] = useState(false);
   const theme = useTheme();
   const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const { corporateData } = useContext(CorporateDataContext);
   const [openNewModal, setOpenNewModal] = useState(false);
   const [tipoAccionista, setTipoAccionista] = useState("");

   // Add this handler to open the new modal
   const handleOpenNewModal = () => {
      setOpenNewModal(true);
   };

   // Add this handler to close the new modal
   const handleCloseNewModal = () => {
      setOpenNewModal(false);
   };

   // Update handleNextStep function to use dynamic max steps
   const handleNextStep = async (formProps) => {
      try {
         setCurrentStep((prev) => Math.min(prev + 1, maxSteps));
      } catch (err) {
         console.error(err);
      }
   };

   const handleSubmit = (values: any) => {
      console.log("Datos a enviar:", JSON.stringify(values, null, 2));
      setIsLoadingSave(true);
      setTimeout(() => {
         setIsLoadingSave(false);
         setOpenModalROR(false);
      }, 1000);
   };

   const handleClose = () => setOpenModalROR(false);

   // Function to update labels and max steps based on tipo de acta
   const updateFlowBasedOnTipoActa = (tipoActa) => {
      switch (tipoActa) {
         case "constitutiva":
            setCurrentLabels(LABELS_CONSTITUTIVA);
            setMaxSteps(5);
            break;
         case "ordinaria":
            setCurrentLabels(LABELS_ORDINARIA);
            setMaxSteps(5);
            break;
         case "extraordinaria":
            setCurrentLabels(LABELS_EXTRAORDINARIA);
            setMaxSteps(5);
            break;
         case "mixta":
            setCurrentLabels(LABELS_MIXTA);
            setMaxSteps(5);
            break;
         case "Especial":
            setCurrentLabels(LABELS_ESPECIAL);
            setMaxSteps(5);
            break;
         default:
            setCurrentLabels(LABELS_CONSTITUTIVA);
            setMaxSteps(5);
      }
   };

   // Function to render fields based on tipo de acta and step
   const renderConditionalContent = (formik, tipoActa, step) => {
      // First step is always standard information fields with some conditional additions
      if (step === 1) {
         return (
            <>
               {/* Standard fields section */}
               <Grid item sx={{ width: "100%" }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                     Número de acta
                  </Typography>
                  <InputTextField
                     id="numeroActa"
                     name="numeroActa"
                     type="text"
                     label="Número de acta"
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item sx={{ width: "100%" }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                     Tipo de acta
                  </Typography>
                  <FormControl fullWidth size="small">
                     <InputLabel id="tipo-acta-label">Tipo de acta</InputLabel>
                     <Select
                        labelId="tipo-acta-label"
                        id="tipoActa"
                        name="tipoActa"
                        label="Tipo de acta"
                        value={formik.values.tipoActa}
                        onChange={(e) => {
                           formik.handleChange(e);
                           updateFlowBasedOnTipoActa(e.target.value);
                        }}
                     >
                        <MenuItem value="constitutiva">Constitutiva</MenuItem>
                        <MenuItem value="ordinaria">Ordinaria</MenuItem>
                        <MenuItem value="extraordinaria">Extraordinaria</MenuItem>
                        <MenuItem value="mixta">Mixta</MenuItem>
                        <MenuItem value="Especial">Especial</MenuItem>
                     </Select>
                  </FormControl>
               </Grid>

               <Grid item sx={{ width: "100%" }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                     Fecha de protocolizacion
                  </Typography>
                  <InputTextField id="fechaActa" name="fechaActa" type="date" size="small" fullWidth />
               </Grid>

               <Grid item sx={{ width: "100%" }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                     Fecha de celebracion
                  </Typography>
                  <InputTextField
                     id="fechadecelebracion"
                     name="fechadecelebracion"
                     type="date"
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item sx={{ width: "100%" }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                     Fecha de inscripcion
                  </Typography>
                  <InputTextField
                     id="fechadeinscripcion"
                     name="fechaDeInscripcion"
                     type="date" // Changed from time to date
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item sx={{ width: "100%" }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                     Nombre del notario
                  </Typography>
                  <InputTextField
                     id="nombreDelNotario"
                     name="nombreDelNotario"
                     type="text" // Changed from time to text
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item sx={{ width: "100%" }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                     Numero de notaria
                  </Typography>
                  <InputTextField
                     id="numerodenotaria"
                     name="numerodenotaria"
                     type="text"
                     label="Numero de notaria"
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item sx={{ width: "100%" }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                     FEM
                  </Typography>
                  <InputTextField id="fem" name="fem" type="text" label="FEM" size="small" fullWidth />
               </Grid>

               {/* Conditional additions based on acta type */}
               {tipoActa === "constitutiva" && (
                  <>
                     <Grid item sx={{ width: "100%" }}>
                        <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                           Duración
                        </Typography>
                        <InputTextField
                           id="duracion"
                           name="duracion"
                           type="text"
                           label="Duración"
                           size="small"
                           fullWidth
                        />
                     </Grid>

                     <Grid item sx={{ width: "100%" }}>
                        <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                           Acta constitutiva
                        </Typography>
                        <InputTextField
                           id="actaConstitutiva"
                           name="actaConstitutiva"
                           type="text"
                           label="Acta constitutiva"
                           size="small"
                           fullWidth
                        />
                     </Grid>

                     <Grid item sx={{ width: "100%", gridColumn: "1 / span 2", marginTop: "20px" }}>
                        <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                           Objeto social
                        </Typography>
                        <TextField
                           id="objetoSocial"
                           name="objetoSocial"
                           multiline
                           rows={4}
                           fullWidth
                           size="small"
                           value={formik.values.objetoSocial || ""}
                           onChange={formik.handleChange}
                        />
                     </Grid>

                     <Grid item sx={{ width: "100%", gridColumn: "1 / span 2", marginTop: "20px" }}>
                        <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                           Comentarios
                        </Typography>
                        <TextField
                           id="comentarios"
                           name="comentarios"
                           multiline
                           rows={4}
                           fullWidth
                           size="small"
                           value={formik.values.comentarios || ""}
                           onChange={formik.handleChange}
                        />
                     </Grid>
                  </>
               )}

               {tipoActa === "ordinaria" && <></>}

               {tipoActa === "extraordinaria" && (
                  <>
                     <Grid item sx={{ width: "100%", gridColumn: "1 / span 2", marginTop: "20px" }}>
                        <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                           Asunto extraordinario
                        </Typography>
                        <TextField
                           id="asuntoExtraordinario"
                           name="asuntoExtraordinario"
                           multiline
                           rows={3}
                           fullWidth
                           size="small"
                           value={formik.values.asuntoExtraordinario || ""}
                           onChange={formik.handleChange}
                        />
                     </Grid>
                  </>
               )}
            </>
         );
      }

      // For subsequent steps, render based on acta type and step number
      switch (tipoActa) {
         case "constitutiva":
            switch (step) {
               case 2:
                  return (
                     <Box sx={{ padding: "20px", display: "flex", flexDirection: "column", gap: 2 }}>
                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                           <Button variant="contained" sx={{ borderRadius: 1, px: 3 }} onClick={handleOpenNewModal}>
                              <AddCircle sx={{ fontSize: 19, marginRight: 1 }} />
                              Agregar nuevo
                           </Button>
                        </Box>

                        {/* Render the appropriate assembly component */}
                        <Box sx={{ mt: 2, border: "1px solid #ccc", borderRadius: "8px", overflow: "hidden" }}>
                           {renderAssemblyComponent()}
                        </Box>

                        {/* Question about changes in share table */}
                        <Box
                           sx={{
                              mt: 2,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "10px 20px",
                              borderRadius: "4px",
                           }}
                        >
                           <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                              ¿Cambios en el cuadro accionario?
                           </Typography>
                           <RadioGroup
                              row
                              name="cambiosCuadroAccionario"
                              value={formik.values.cambiosCuadroAccionario}
                              onChange={formik.handleChange}
                           >
                              <FormControlLabel value="si" control={<Radio size="small" />} label="Sí" />
                              <FormControlLabel value="no" control={<Radio size="small" />} label="No" />
                           </RadioGroup>
                        </Box>
                     </Box>
                  );
               case 3:
                  return (
                     <Box sx={{ padding: "20px" }}>
                        <FormControl sx={{ width: "50%", mb: 3 }} size="small">
                           <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                              Organo de gobierno
                           </Typography>

                           <Select
                              labelId="seleccionar-label"
                              id="seleccionar"
                              name="seleccionar"
                              value={formik.values.seleccionar || ""}
                              onChange={formik.handleChange}
                              label="Seleccionar"
                           >
                              <MenuItem value="lorem1">Lorem ipsum dolor sit amet</MenuItem>
                              <MenuItem value="lorem2">Lorem ipsum dolor sit amet consectetur</MenuItem>
                              <MenuItem value="lorem3">Lorem ipsum dolor sit amet consectetur adipiscing</MenuItem>
                           </Select>
                        </FormControl>

                        {/* Three fields in one line: Cargo, Nombre, Agregar */}
                        <Box sx={{ mb: 3 }}>
                           <Box sx={{ display: "flex", mb: 1 }}>
                              <Typography variant="body1" sx={{ fontWeight: "bold", width: "20%" }}>
                                 Cargo
                              </Typography>
                              <Typography variant="body1" sx={{ fontWeight: "bold", width: "65%" }}>
                                 Nombre
                              </Typography>
                              <Typography variant="body1" sx={{ fontWeight: "bold", width: "15%" }}>
                                 Agregar
                              </Typography>
                           </Box>

                           <Box sx={{ display: "flex", alignItems: "center" }}>
                              <FormControl size="small" sx={{ width: "20%" }}>
                                 <Select
                                    id="cargo"
                                    name="cargo"
                                    value={formik.values.cargo || ""}
                                    onChange={formik.handleChange}
                                    displayEmpty
                                    sx={{ borderRadius: "4px 0 0 4px" }}
                                 >
                                    <MenuItem value="lorem1">Lorem ipsum</MenuItem>
                                    <MenuItem value="lorem2">Lorem ipsum dolor</MenuItem>
                                    <MenuItem value="lorem3">Lorem ipsum dolor sit</MenuItem>
                                 </Select>
                              </FormControl>

                              <TextField
                                 id="nombre"
                                 name="nombre"
                                 size="small"
                                 value={formik.values.nombre || ""}
                                 onChange={formik.handleChange}
                                 sx={{
                                    width: "65%",
                                    "& .MuiOutlinedInput-root": {
                                       borderRadius: 0,
                                    },
                                 }}
                              />

                              <Button
                                 sx={{
                                    width: "15%",
                                    height: "40px",
                                    borderRadius: "0 4px 4px 0",
                                    bgcolor: "#f5f5f5",
                                    border: "1px solid rgba(0, 0, 0, 0.23)",
                                    borderLeft: 0,
                                 }}
                              >
                                 <AddCircle color="primary" />
                              </Button>
                           </Box>
                        </Box>

                        {/* New section for Poderes y facultades */}
                        <Box sx={{ mb: 3, mt: 4 }}>
                           <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "10px" }}>
                              Poderes y facultares del organo de administracion
                           </Typography>
                           <FormControl fullWidth size="small">
                              <Select
                                 id="poderesYFacultades"
                                 name="poderesYFacultades"
                                 value={formik.values.poderesYFacultades || ""}
                                 onChange={formik.handleChange}
                                 displayEmpty
                              >
                                 <MenuItem value="lorem1">Lorem ipsum dolor sit amet</MenuItem>
                                 <MenuItem value="lorem2">Lorem ipsum dolor sit amet consectetur</MenuItem>
                                 <MenuItem value="lorem3">
                                    Lorem ipsum dolor sit amet consectetur adipiscing elit
                                 </MenuItem>
                              </Select>
                           </FormControl>
                        </Box>
                     </Box>
                  );
               case 4:
                  return (
                     <Box sx={{ padding: "20px" }}>
                        <Box
                           sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "10px 20px",
                              borderRadius: "4px",
                           }}
                        >
                           <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                              Organo de vigilancia
                           </Typography>
                           <RadioGroup
                              row
                              name="organoVigilancia"
                              value={formik.values.organoVigilancia}
                              onChange={formik.handleChange}
                           >
                              <FormControlLabel value="comisario" control={<Radio size="small" />} label="Comisario" />
                              <FormControlLabel
                                 value="comiteAuditoria"
                                 control={<Radio size="small" />}
                                 label="Comité de auditoría"
                              />
                           </RadioGroup>
                        </Box>

                        {/* Add back the fields section similar to Cargo, Nombre, Agregar */}
                        <Box sx={{ mt: 4, mb: 3 }}>
                           <Box sx={{ display: "flex", mb: 1 }}>
                              <Typography variant="body1" sx={{ fontWeight: "bold", width: "20%" }}>
                                 Cargo
                              </Typography>
                              <Typography variant="body1" sx={{ fontWeight: "bold", width: "65%" }}>
                                 Nombre
                              </Typography>
                              <Typography variant="body1" sx={{ fontWeight: "bold", width: "15%" }}>
                                 Agregar
                              </Typography>
                           </Box>

                           <Box sx={{ display: "flex", alignItems: "center" }}>
                              <FormControl size="small" sx={{ width: "20%" }}>
                                 <Select
                                    id="puesto"
                                    name="puesto"
                                    value={formik.values.puesto || ""}
                                    onChange={formik.handleChange}
                                    displayEmpty
                                    sx={{ borderRadius: "4px 0 0 4px" }}
                                 >
                                    <MenuItem value="presidente">Presidente</MenuItem>
                                    <MenuItem value="secretario">Secretario</MenuItem>
                                    <MenuItem value="vocal">Vocal</MenuItem>
                                 </Select>
                              </FormControl>

                              <TextField
                                 id="integrante"
                                 name="integrante"
                                 size="small"
                                 value={formik.values.integrante || ""}
                                 onChange={formik.handleChange}
                                 sx={{
                                    width: "65%",
                                    "& .MuiOutlinedInput-root": {
                                       borderRadius: 0,
                                    },
                                 }}
                              />

                              <Button
                                 sx={{
                                    width: "15%",
                                    height: "40px",
                                    borderRadius: "0 4px 4px 0",
                                    bgcolor: "#f5f5f5",
                                    border: "1px solid rgba(0, 0, 0, 0.23)",
                                    borderLeft: 0,
                                 }}
                              >
                                 <AddCircle color="primary" />
                              </Button>
                           </Box>
                        </Box>
                     </Box>
                  );
               case 5:
                  return (
                     <Box sx={{ padding: "20px" }}>
                        <Box
                           sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "10px 20px",
                              borderRadius: "4px",
                           }}
                        >
                           <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                              ¿Se otorgaron poderes?
                           </Typography>
                           <RadioGroup
                              row
                              name="otorgaronPoderes"
                              value={formik.values.otorgaronPoderes}
                              onChange={formik.handleChange}
                           >
                              <FormControlLabel value="si" control={<Radio size="small" />} label="Sí" />
                              <FormControlLabel value="no" control={<Radio size="small" />} label="No" />
                           </RadioGroup>
                        </Box>

                        {/* Add Numero de escritura field */}
                        <Box sx={{ mt: 3, width: "50%" }}>
                           <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                              Numero de escritura
                           </Typography>
                           <InputTextField
                              id="numeroEscritura"
                              name="numeroEscritura"
                              type="text"
                              label="Numero de escritura"
                              size="small"
                              fullWidth
                              value={formik.values.numeroEscritura || ""}
                              onChange={formik.handleChange}
                           />
                        </Box>

                        {/* New row with 5 fields with adjusted widths */}
                        <Box sx={{ mt: 4 }}>
                           {/* Titles row */}
                           <Box sx={{ display: "flex", mb: 1 }}>
                              <Typography variant="body1" sx={{ fontWeight: "bold", width: "24%" }}>
                                 Poderante
                              </Typography>
                              <Typography variant="body1" sx={{ fontWeight: "bold", width: "24%" }}>
                                 Apoderado
                              </Typography>
                              <Typography variant="body1" sx={{ fontWeight: "bold", width: "24%" }}>
                                 Otorgamiento
                              </Typography>
                              <Typography variant="body1" sx={{ fontWeight: "bold", width: "23%" }}>
                                 Vencimiento
                              </Typography>
                              <Box sx={{ width: "5%" }}></Box> {/* Empty space for the button column */}
                           </Box>

                           {/* Fields row */}
                           <Box sx={{ display: "flex", alignItems: "center" }}>
                              {/* Poderante field */}
                              <TextField
                                 id="poderante"
                                 name="poderante"
                                 size="small"
                                 value={formik.values.poderante || ""}
                                 onChange={formik.handleChange}
                                 sx={{
                                    width: "24%",
                                    "& .MuiOutlinedInput-root": {
                                       borderRadius: "4px 0 0 4px",
                                    },
                                 }}
                              />

                              {/* Apoderado field */}
                              <TextField
                                 id="apoderado"
                                 name="apoderado"
                                 size="small"
                                 value={formik.values.apoderado || ""}
                                 onChange={formik.handleChange}
                                 sx={{
                                    width: "24%",
                                    "& .MuiOutlinedInput-root": {
                                       borderRadius: 0,
                                       borderLeft: 0,
                                    },
                                 }}
                              />

                              {/* Otorgamiento date field */}
                              <InputTextField
                                 id="fechaOtorgamiento"
                                 name="fechaOtorgamiento"
                                 type="date"
                                 size="small"
                                 value={formik.values.fechaOtorgamiento || ""}
                                 onChange={formik.handleChange}
                                 sx={{
                                    width: "24%",
                                    "& .MuiOutlinedInput-root": {
                                       borderRadius: 0,
                                       borderLeft: 0,
                                    },
                                 }}
                              />

                              {/* Vencimiento date field */}
                              <InputTextField
                                 id="fechaVencimiento"
                                 name="fechaVencimiento"
                                 type="date"
                                 size="small"
                                 value={formik.values.fechaVencimiento || ""}
                                 onChange={formik.handleChange}
                                 sx={{
                                    width: "23%",
                                    "& .MuiOutlinedInput-root": {
                                       borderRadius: 0,
                                       borderLeft: 0,
                                    },
                                 }}
                              />

                              {/* Add button with smaller width */}
                              <Button
                                 sx={{
                                    width: "5%",
                                    minWidth: "40px",
                                    height: "40px",
                                    borderRadius: "0 4px 4px 0",
                                    padding: 0,
                                    boxShadow: "none",
                                    backgroundColor: "transparent",
                                 }}
                              >
                                 <AddCircle color="primary" fontSize="small" />
                              </Button>
                           </Box>
                        </Box>
                     </Box>
                  );
               default:
                  return null;
            }

         case "ordinaria":
            // Implement specific steps for "ordinaria" type
            switch (step) {
               case 2:
                  return (
                     <Box sx={{ padding: "20px" }}>
                        <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "10px" }}>
                           Cambios en Estructura Ordinaria
                        </Typography>

                        {/* Add Orden del dia field */}
                        <Box sx={{ mt: 3, mb: 3 }}>
                           <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                              Orden del día
                           </Typography>
                           <FormControl fullWidth size="small">
                              <Select
                                 id="ordenDelDia"
                                 name="ordenDelDia"
                                 value={formik.values.ordenDelDia || ""}
                                 onChange={formik.handleChange}
                                 displayEmpty
                                 MenuProps={{
                                    PaperProps: {
                                       style: {
                                          maxWidth: "100%",
                                          width: "inherit",
                                          boxSizing: "border-box",
                                       },
                                    },
                                    anchorOrigin: {
                                       vertical: "bottom",
                                       horizontal: "left",
                                    },
                                    transformOrigin: {
                                       vertical: "top",
                                       horizontal: "left",
                                    },
                                 }}
                              >
                                 <MenuItem
                                    value="discutir"
                                    sx={{
                                       whiteSpace: "normal",
                                       wordBreak: "break-word",
                                       maxWidth: "inherit",
                                       overflowWrap: "break-word",
                                    }}
                                 >
                                    <Typography noWrap>
                                       Discutir, aprobar o modificar el informe de los administradores...
                                    </Typography>
                                 </MenuItem>
                                 <MenuItem
                                    value="nombrar"
                                    sx={{
                                       whiteSpace: "normal",
                                       wordBreak: "break-word",
                                       maxWidth: "inherit",
                                       overflowWrap: "break-word",
                                    }}
                                 >
                                    <Typography noWrap>
                                       En su caso, nombrar al Administrador o Consejo de Administracion...
                                    </Typography>
                                 </MenuItem>
                                 <MenuItem
                                    value="determinar"
                                    sx={{
                                       whiteSpace: "normal",
                                       wordBreak: "break-word",
                                       maxWidth: "inherit",
                                       overflowWrap: "break-word",
                                    }}
                                 >
                                    <Typography noWrap>
                                       Determinar los emolumentos correspondientes a los Administradores...
                                    </Typography>
                                 </MenuItem>
                              </Select>
                           </FormControl>

                           {/* Dynamic selected text display with light blue background */}
                           {formik.values.ordenDelDia && (
                              <Box
                                 sx={{
                                    mt: 2,
                                    p: 2,
                                    bgcolor: "#e3f2fd",
                                    borderRadius: "4px",
                                    border: "1px solid #bbdefb",
                                 }}
                              >
                                 <Typography variant="body2">
                                    {formik.values.ordenDelDia === "discutir" &&
                                       "Discutir, aprobar o modificar el informe de los administradores a que se refier eel enunciado general del articulo 172, tomando en cuenta el informe de los comisarios, y tomar las medidas que juzgue..."}
                                    {formik.values.ordenDelDia === "nombrar" &&
                                       "En su caso, nombrar al Administrador o Consejo de Administracion y a los comisarios"}
                                    {formik.values.ordenDelDia === "determinar" &&
                                       "Determinar los emolumentos correspondientes a los Administradores y Comisarios, cuando no hayan sido fijados en los estatutos"}
                                 </Typography>
                              </Box>
                           )}

                           {/* Conditional comments field when "discutir" option is selected */}
                           {formik.values.ordenDelDia === "discutir" && (
                              <Box sx={{ mt: 3 }}>
                                 <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                                    Comentarios
                                 </Typography>
                                 <TextField
                                    id="comentariosDiscutir"
                                    name="comentariosDiscutir"
                                    multiline
                                    rows={3}
                                    fullWidth
                                    size="small"
                                    value={formik.values.comentariosDiscutir || ""}
                                    onChange={formik.handleChange}
                                 />
                              </Box>
                           )}

                           {/* Conditional "Organos de gobierno" field when "nombrar" option is selected */}
                           {formik.values.ordenDelDia === "nombrar" && (
                              <Box sx={{ mt: 3 }}>
                                 <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                                    Organos de gobierno
                                 </Typography>
                                 <FormControl sx={{ width: "50%" }} size="small">
                                    <Select
                                       id="organosDeGobierno"
                                       name="organosDeGobierno"
                                       value={formik.values.organosDeGobierno || ""}
                                       onChange={formik.handleChange}
                                       displayEmpty
                                    >
                                       <MenuItem value="lorem1">Lorem ipsum dolor sit amet</MenuItem>
                                       <MenuItem value="lorem2">Lorem ipsum dolor sit amet consectetur</MenuItem>
                                       <MenuItem value="lorem3">
                                          Lorem ipsum dolor sit amet consectetur adipiscing
                                       </MenuItem>
                                    </Select>
                                 </FormControl>

                                 {/* Add the three-field component: Cargo, Nombre, Agregar */}
                                 <Box sx={{ mt: 3, mb: 3 }}>
                                    <Box sx={{ display: "flex", mb: 1 }}>
                                       <Typography variant="body1" sx={{ fontWeight: "bold", width: "20%" }}>
                                          Cargo
                                       </Typography>
                                       <Typography variant="body1" sx={{ fontWeight: "bold", width: "65%" }}>
                                          Nombre
                                       </Typography>
                                       <Typography variant="body1" sx={{ fontWeight: "bold", width: "15%" }}>
                                          Agregar
                                       </Typography>
                                    </Box>

                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                       <FormControl size="small" sx={{ width: "20%" }}>
                                          <Select
                                             id="cargoOrdinaria"
                                             name="cargoOrdinaria"
                                             value={formik.values.cargoOrdinaria || ""}
                                             onChange={formik.handleChange}
                                             displayEmpty
                                             sx={{ borderRadius: "4px 0 0 4px" }}
                                          >
                                             <MenuItem value="presidente">Presidente</MenuItem>
                                             <MenuItem value="secretario">Secretario</MenuItem>
                                             <MenuItem value="vocal">Vocal</MenuItem>
                                          </Select>
                                       </FormControl>

                                       <TextField
                                          id="nombreOrdinaria"
                                          name="nombreOrdinaria"
                                          size="small"
                                          value={formik.values.nombreOrdinaria || ""}
                                          onChange={formik.handleChange}
                                          sx={{
                                             width: "65%",
                                             "& .MuiOutlinedInput-root": {
                                                borderRadius: 0,
                                             },
                                          }}
                                       />

                                       <Button
                                          sx={{
                                             width: "15%",
                                             height: "40px",
                                             borderRadius: "0 4px 4px 0",
                                             bgcolor: "#f5f5f5",
                                             border: "1px solid rgba(0, 0, 0, 0.23)",
                                             borderLeft: 0,
                                          }}
                                       >
                                          <AddCircle color="primary" />
                                       </Button>
                                    </Box>
                                 </Box>
                              </Box>
                           )}

                           {/* Conditional fields for "determinar" option is selected */}
                           {formik.values.ordenDelDia === "determinar" && (
                              <Box sx={{ mt: 3 }}>
                                 <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "10px" }}>
                                    Emolumentos correspondientes
                                 </Typography>

                                 {/* Four-field component with Nombre, Cargo, Emolumento correspondiente, and button */}
                                 <Box sx={{ mb: 3 }}>
                                    <Box sx={{ display: "flex", mb: 1 }}>
                                       <Typography
                                          variant="body2"
                                          sx={{ fontWeight: "bold", width: "25%", fontSize: "0.85rem" }}
                                       >
                                          Nombre
                                       </Typography>
                                       <Typography
                                          variant="body2"
                                          sx={{ fontWeight: "bold", width: "25%", fontSize: "0.85rem" }}
                                       >
                                          Cargo
                                       </Typography>
                                       <Typography
                                          variant="body2"
                                          sx={{ fontWeight: "bold", width: "40%", fontSize: "0.85rem" }}
                                       >
                                          Emolumento correspondiente
                                       </Typography>
                                       <Box sx={{ width: "10%" }}></Box> {/* Empty space for button column */}
                                    </Box>

                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                       <TextField
                                          id="nombreEmolumento"
                                          name="nombreEmolumento"
                                          size="small"
                                          value={formik.values.nombreEmolumento || ""}
                                          onChange={formik.handleChange}
                                          sx={{
                                             width: "25%",
                                             "& .MuiOutlinedInput-root": {
                                                borderRadius: "4px 0 0 4px",
                                             },
                                          }}
                                       />

                                       <TextField
                                          id="cargoEmolumento"
                                          name="cargoEmolumento"
                                          size="small"
                                          value={formik.values.cargoEmolumento || ""}
                                          onChange={formik.handleChange}
                                          sx={{
                                             width: "25%",
                                             "& .MuiOutlinedInput-root": {
                                                borderRadius: 0,
                                                borderLeft: 0,
                                             },
                                          }}
                                       />

                                       <TextField
                                          id="emolumentoCorrespondiente"
                                          name="emolumentoCorrespondiente"
                                          size="small"
                                          value={formik.values.emolumentoCorrespondiente || ""}
                                          onChange={formik.handleChange}
                                          sx={{
                                             width: "40%",
                                             "& .MuiOutlinedInput-root": {
                                                borderRadius: 0,
                                                borderLeft: 0,
                                             },
                                          }}
                                       />

                                       <Button
                                          sx={{
                                             width: "10%",
                                             height: "40px",
                                             borderRadius: "0 4px 4px 0",
                                             bgcolor: "#f5f5f5",
                                             border: "1px solid rgba(0, 0, 0, 0.23)",
                                             borderLeft: 0,
                                          }}
                                       >
                                          <AddCircle color="primary" />
                                       </Button>
                                    </Box>
                                 </Box>
                              </Box>
                           )}
                        </Box>

                        {/* Additional fields specific to ordinaria step 2 */}
                     </Box>
                  );
               case 3:
                  return (
                     <Box sx={{ padding: "20px" }}>
                        <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "10px" }}>
                           Organo de gobierno
                        </Typography>

                        <FormControl sx={{ width: "50%" }} size="small">
                           <Select
                              id="organoDeGobierno"
                              name="organoDeGobierno"
                              value={formik.values.organoDeGobierno || ""}
                              onChange={formik.handleChange}
                              displayEmpty
                           >
                              <MenuItem value="lorem1">Lorem ipsum dolor sit amet</MenuItem>
                              <MenuItem value="lorem2">Lorem ipsum dolor sit amet consectetur</MenuItem>
                              <MenuItem value="lorem3">Lorem ipsum dolor sit amet consectetur adipiscing</MenuItem>
                           </Select>
                        </FormControl>

                        {/* Add the three-field component: Cargo, Nombre, Agregar */}
                        <Box sx={{ mt: 3, mb: 3 }}>
                           <Box sx={{ display: "flex", mb: 1 }}>
                              <Typography variant="body1" sx={{ fontWeight: "bold", width: "20%" }}>
                                 Cargo
                              </Typography>
                              <Typography variant="body1" sx={{ fontWeight: "bold", width: "65%" }}>
                                 Nombre
                              </Typography>
                              <Typography variant="body1" sx={{ fontWeight: "bold", width: "15%" }}>
                                 Agregar
                              </Typography>
                           </Box>

                           <Box sx={{ display: "flex", alignItems: "center" }}>
                              <FormControl size="small" sx={{ width: "20%" }}>
                                 <Select
                                    id="cargoOrganoGobierno"
                                    name="cargoOrganoGobierno"
                                    value={formik.values.cargoOrganoGobierno || ""}
                                    onChange={formik.handleChange}
                                    displayEmpty
                                    sx={{ borderRadius: "4px 0 0 4px" }}
                                 >
                                    <MenuItem value="presidente">Presidente</MenuItem>
                                    <MenuItem value="secretario">Secretario</MenuItem>
                                    <MenuItem value="vocal">Vocal</MenuItem>
                                 </Select>
                              </FormControl>

                              <TextField
                                 id="nombreOrganoGobierno"
                                 name="nombreOrganoGobierno"
                                 size="small"
                                 value={formik.values.nombreOrganoGobierno || ""}
                                 onChange={formik.handleChange}
                                 sx={{
                                    width: "65%",
                                    "& .MuiOutlinedInput-root": {
                                       borderRadius: 0,
                                    },
                                 }}
                              />

                              <Button
                                 sx={{
                                    width: "15%",
                                    height: "40px",
                                    borderRadius: "0 4px 4px 0",
                                    bgcolor: "#f5f5f5",
                                    border: "1px solid rgba(0, 0, 0, 0.23)",
                                    borderLeft: 0,
                                 }}
                              >
                                 <AddCircle color="primary" />
                              </Button>
                           </Box>
                        </Box>

                        {/* Add Poderes y facultades field */}
                        <Box sx={{ mb: 3, mt: 4 }}>
                           <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "10px" }}>
                              Poderes y facultades del organo de administracion
                           </Typography>
                           <FormControl fullWidth size="small">
                              <Select
                                 id="poderesYFacultadesOrdinaria"
                                 name="poderesYFacultadesOrdinaria"
                                 value={formik.values.poderesYFacultadesOrdinaria || ""}
                                 onChange={formik.handleChange}
                                 displayEmpty
                              >
                                 <MenuItem value="lorem1">Lorem ipsum dolor sit amet</MenuItem>
                                 <MenuItem value="lorem2">Lorem ipsum dolor sit amet consectetur</MenuItem>
                                 <MenuItem value="lorem3">
                                    Lorem ipsum dolor sit amet consectetur adipiscing elit
                                 </MenuItem>
                              </Select>
                           </FormControl>
                        </Box>
                     </Box>
                  );
               case 4:
                  return (
                     <Box sx={{ padding: "20px" }}>
                        <Box
                           sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "10px 20px",
                              borderRadius: "4px",
                           }}
                        >
                           <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                              Organo de vigilancia
                           </Typography>
                           <RadioGroup
                              row
                              name="organoVigilanciaOrdinaria"
                              value={formik.values.organoVigilanciaOrdinaria}
                              onChange={formik.handleChange}
                           >
                              <FormControlLabel value="comisario" control={<Radio size="small" />} label="Comisario" />
                              <FormControlLabel
                                 value="comiteAuditoria"
                                 control={<Radio size="small" />}
                                 label="Comité de auditoría"
                              />
                           </RadioGroup>
                        </Box>

                        {/* Add the three-field component: Cargo, Nombre, Agregar */}
                        <Box sx={{ mt: 4, mb: 3 }}>
                           <Box sx={{ display: "flex", mb: 1 }}>
                              <Typography variant="body1" sx={{ fontWeight: "bold", width: "20%" }}>
                                 Cargo
                              </Typography>
                              <Typography variant="body1" sx={{ fontWeight: "bold", width: "65%" }}>
                                 Nombre
                              </Typography>
                              <Typography variant="body1" sx={{ fontWeight: "bold", width: "15%" }}>
                                 Agregar
                              </Typography>
                           </Box>

                           <Box sx={{ display: "flex", alignItems: "center" }}>
                              <FormControl size="small" sx={{ width: "20%" }}>
                                 <Select
                                    id="cargoVigilancia"
                                    name="cargoVigilancia"
                                    value={formik.values.cargoVigilancia || ""}
                                    onChange={formik.handleChange}
                                    displayEmpty
                                    sx={{ borderRadius: "4px 0 0 4px" }}
                                 >
                                    <MenuItem value="presidente">Presidente</MenuItem>
                                    <MenuItem value="secretario">Secretario</MenuItem>
                                    <MenuItem value="vocal">Vocal</MenuItem>
                                 </Select>
                              </FormControl>

                              <TextField
                                 id="nombreVigilancia"
                                 name="nombreVigilancia"
                                 size="small"
                                 value={formik.values.nombreVigilancia || ""}
                                 onChange={formik.handleChange}
                                 sx={{
                                    width: "65%",
                                    "& .MuiOutlinedInput-root": {
                                       borderRadius: 0,
                                    },
                                 }}
                              />

                              <Button
                                 sx={{
                                    width: "15%",
                                    height: "40px",
                                    borderRadius: "0 4px 4px 0",
                                    bgcolor: "#f5f5f5",
                                    border: "1px solid rgba(0, 0, 0, 0.23)",
                                    borderLeft: 0,
                                 }}
                              >
                                 <AddCircle color="primary" />
                              </Button>
                           </Box>
                        </Box>
                     </Box>
                  );
               case 5:
                  return (
                     <Box sx={{ padding: "20px" }}>
                        <Box
                           sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "10px 20px",
                              borderRadius: "4px",
                           }}
                        >
                           <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                              ¿Se otorgaron poderes?
                           </Typography>
                           <RadioGroup
                              row
                              name="otorgaronPoderesOrdinaria"
                              value={formik.values.otorgaronPoderesOrdinaria}
                              onChange={formik.handleChange}
                           >
                              <FormControlLabel value="si" control={<Radio size="small" />} label="Sí" />
                              <FormControlLabel value="no" control={<Radio size="small" />} label="No" />
                           </RadioGroup>
                        </Box>

                        {/* Add Numero de escritura field */}
                        <Box sx={{ mt: 3, width: "50%" }}>
                           <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                              Numero de escritura
                           </Typography>
                           <InputTextField
                              id="numeroEscrituraOrdinaria"
                              name="numeroEscrituraOrdinaria"
                              type="text"
                              label="Numero de escritura"
                              size="small"
                              fullWidth
                              value={formik.values.numeroEscrituraOrdinaria || ""}
                              onChange={formik.handleChange}
                           />
                        </Box>

                        {/* New row with 5 fields with adjusted widths */}
                        <Box sx={{ mt: 4 }}>
                           {/* Titles row */}
                           <Box sx={{ display: "flex", mb: 1 }}>
                              <Typography variant="body1" sx={{ fontWeight: "bold", width: "24%" }}>
                                 Poderante
                              </Typography>
                              <Typography variant="body1" sx={{ fontWeight: "bold", width: "24%" }}>
                                 Apoderado
                              </Typography>
                              <Typography variant="body1" sx={{ fontWeight: "bold", width: "24%" }}>
                                 Otorgamiento
                              </Typography>
                              <Typography variant="body1" sx={{ fontWeight: "bold", width: "23%" }}>
                                 Vencimiento
                              </Typography>
                              <Box sx={{ width: "5%" }}></Box> {/* Empty space for the button column */}
                           </Box>

                           {/* Fields row */}
                           <Box sx={{ display: "flex", alignItems: "center" }}>
                              {/* Poderante field */}
                              <TextField
                                 id="poderanteOrdinaria"
                                 name="poderanteOrdinaria"
                                 size="small"
                                 value={formik.values.poderanteOrdinaria || ""}
                                 onChange={formik.handleChange}
                                 sx={{
                                    width: "24%",
                                    "& .MuiOutlinedInput-root": {
                                       borderRadius: "4px 0 0 4px",
                                    },
                                 }}
                              />

                              {/* Apoderado field */}
                              <TextField
                                 id="apoderadoOrdinaria"
                                 name="apoderadoOrdinaria"
                                 size="small"
                                 value={formik.values.apoderadoOrdinaria || ""}
                                 onChange={formik.handleChange}
                                 sx={{
                                    width: "24%",
                                    "& .MuiOutlinedInput-root": {
                                       borderRadius: 0,
                                       borderLeft: 0,
                                    },
                                 }}
                              />

                              {/* Otorgamiento date field */}
                              <InputTextField
                                 id="fechaOtorgamientoOrdinaria"
                                 name="fechaOtorgamientoOrdinaria"
                                 type="date"
                                 size="small"
                                 value={formik.values.fechaOtorgamientoOrdinaria || ""}
                                 onChange={formik.handleChange}
                                 sx={{
                                    width: "24%",
                                    "& .MuiOutlinedInput-root": {
                                       borderRadius: 0,
                                       borderLeft: 0,
                                    },
                                 }}
                              />

                              {/* Vencimiento date field */}
                              <InputTextField
                                 id="fechaVencimientoOrdinaria"
                                 name="fechaVencimientoOrdinaria"
                                 type="date"
                                 size="small"
                                 value={formik.values.fechaVencimientoOrdinaria || ""}
                                 onChange={formik.handleChange}
                                 sx={{
                                    width: "23%",
                                    "& .MuiOutlinedInput-root": {
                                       borderRadius: 0,
                                       borderLeft: 0,
                                    },
                                 }}
                              />

                              {/* Add button with smaller width */}
                              <Button
                                 sx={{
                                    width: "5%",
                                    minWidth: "40px",
                                    height: "40px",
                                    borderRadius: "0 4px 4px 0",
                                    padding: 0,
                                    boxShadow: "none",
                                    backgroundColor: "transparent",
                                 }}
                              >
                                 <AddCircle color="primary" fontSize="small" />
                              </Button>
                           </Box>
                        </Box>
                     </Box>
                  );
               default:
                  return null;
            }

         case "extraordinaria":
            switch (step) {
               case 2:
                  return (
                     <Box sx={{ padding: "20px" }}>
                        <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "10px" }}>
                           Cambios Extraordinarios
                        </Typography>

                        {/* Add Orden del dia field */}
                        <Box sx={{ mt: 3, mb: 3 }}>
                           <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                              Orden del día
                           </Typography>
                           <FormControl fullWidth size="small">
                              <Select
                                 id="ordenDelDiaExtraordinaria"
                                 name="ordenDelDiaExtraordinaria"
                                 value={formik.values.ordenDelDiaExtraordinaria || ""}
                                 onChange={formik.handleChange}
                                 displayEmpty
                                 MenuProps={{
                                    PaperProps: {
                                       style: {
                                          maxWidth: "100%",
                                          width: "30%", // Set to 100% of the parent's width
                                       },
                                    },
                                    anchorOrigin: {
                                       vertical: "bottom",
                                       horizontal: "left",
                                    },
                                    transformOrigin: {
                                       vertical: "top",
                                       horizontal: "left",
                                    },
                                    // Ensure dropdown doesn't resize based on content
                                    disableAutoFocusItem: true,
                                    disableScrollLock: false,
                                 }}
                              >
                                 <MenuItem
                                    value="fusion"
                                    sx={{
                                       width: "100%",
                                       overflow: "hidden",
                                    }}
                                 >
                                    <Typography
                                       sx={{
                                          width: "100%",
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                       }}
                                    >
                                       Discutir, aprobar o modificar el informe de los administradores a que se refiere
                                       el enunciado general del articulo 172, tomando en cuenta el informe de losss
                                       comisarios y tomar las medidas que juzgue...
                                    </Typography>
                                 </MenuItem>
                                 <MenuItem
                                    value="modificacion"
                                    sx={{
                                       width: "100%",
                                       overflow: "hidden",
                                    }}
                                 >
                                    <Typography
                                       sx={{
                                          width: "100%",
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                       }}
                                    >
                                       En su caso, nombrar al administrador o consejo de administracion y a los
                                       comisarios
                                    </Typography>
                                 </MenuItem>
                                 <MenuItem
                                    value="disolucion"
                                    sx={{
                                       width: "100%",
                                       overflow: "hidden",
                                    }}
                                 >
                                    <Typography
                                       sx={{
                                          width: "100%",
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                       }}
                                    >
                                       Determinar los emolumentos correspondientes a los administradores y comisarios,
                                       cuando no hayan sido fijados en los estatuos
                                    </Typography>
                                 </MenuItem>
                              </Select>
                           </FormControl>

                           {/* Dynamic selected text display with light blue background */}
                           {formik.values.ordenDelDiaExtraordinaria && (
                              <Box
                                 sx={{
                                    mt: 2,
                                    p: 2,
                                    bgcolor: "#e3f2fd",
                                    borderRadius: "4px",
                                    border: "1px solid #bbdefb",
                                 }}
                              >
                                 <Typography variant="body2">
                                    {formik.values.ordenDelDiaExtraordinaria === "fusion" &&
                                       "Discutir, aprobar o modificar el informe de los administradores a que se refiere el enunciado general del articulo 172, tomando en cuenta el informe de los comisarios y tomar las medidas que juzgue..."}
                                    {formik.values.ordenDelDiaExtraordinaria === "modificacion" &&
                                       "En su caso, nombrar al administrador o consejo de administracion y a los comisarios"}
                                    {formik.values.ordenDelDiaExtraordinaria === "disolucion" &&
                                       "Determinar los emolumentos correspondientes a los administradores y comisarios, cuando no hayan sido fijados en los estatuos"}
                                 </Typography>
                              </Box>
                           )}

                           {/* Conditional fields based on selected option */}
                           {formik.values.ordenDelDiaExtraordinaria === "fusion" && (
                              <Box sx={{ mt: 3 }}>
                                 {/* Remove the Radio buttons for "Tipo de operación" */}

                                 <Box sx={{ mt: 3 }}>
                                    <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                                       Comentarios
                                    </Typography>
                                    <TextField
                                       id="detallesOperacionExtraordinaria"
                                       name="detallesOperacionExtraordinaria"
                                       multiline
                                       rows={3}
                                       fullWidth
                                       size="small"
                                       value={formik.values.detallesOperacionExtraordinaria || ""}
                                       onChange={formik.handleChange}
                                    />
                                 </Box>
                              </Box>
                           )}

                           {formik.values.ordenDelDiaExtraordinaria === "modificacion" && (
                              <Box sx={{ mt: 3 }}>
                                 <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                                    Organo de gobierno
                                 </Typography>
                                 <FormControl sx={{ width: "50%" }} size="small">
                                    <Select
                                       id="organoGobiernoExtraordinaria"
                                       name="organoGobiernoExtraordinaria"
                                       value={formik.values.organoGobiernoExtraordinaria || ""}
                                       onChange={formik.handleChange}
                                       displayEmpty
                                    >
                                       <MenuItem value="lorem1">Lorem ipsum dolor sit amet</MenuItem>
                                       <MenuItem value="lorem2">Lorem ipsum dolor sit amet consectetur</MenuItem>
                                       <MenuItem value="lorem3">
                                          Lorem ipsum dolor sit amet consectetur adipiscing
                                       </MenuItem>
                                    </Select>
                                 </FormControl>

                                 {/* Three fields in one line: Cargo, Nombre, Agregar */}
                                 <Box sx={{ mt: 3, mb: 3 }}>
                                    <Box sx={{ display: "flex", mb: 1 }}>
                                       <Typography variant="body1" sx={{ fontWeight: "bold", width: "20%" }}>
                                          Cargo
                                       </Typography>
                                       <Typography variant="body1" sx={{ fontWeight: "bold", width: "65%" }}>
                                          Nombre
                                       </Typography>
                                       <Typography variant="body1" sx={{ fontWeight: "bold", width: "15%" }}>
                                          Agregar
                                       </Typography>
                                    </Box>

                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                       <FormControl size="small" sx={{ width: "20%" }}>
                                          <Select
                                             id="cargoExtraordinaria"
                                             name="cargoExtraordinaria"
                                             value={formik.values.cargoExtraordinaria || ""}
                                             onChange={formik.handleChange}
                                             displayEmpty
                                             sx={{ borderRadius: "4px 0 0 4px" }}
                                          >
                                             <MenuItem value="presidente">Presidente</MenuItem>
                                             <MenuItem value="secretario">Secretario</MenuItem>
                                             <MenuItem value="vocal">Vocal</MenuItem>
                                          </Select>
                                       </FormControl>

                                       <TextField
                                          id="nombreExtraordinaria"
                                          name="nombreExtraordinaria"
                                          size="small"
                                          value={formik.values.nombreExtraordinaria || ""}
                                          onChange={formik.handleChange}
                                          sx={{
                                             width: "65%",
                                             "& .MuiOutlinedInput-root": {
                                                borderRadius: 0,
                                             },
                                          }}
                                       />

                                       <Button
                                          sx={{
                                             width: "15%",
                                             height: "40px",
                                             borderRadius: "0 4px 4px 0",
                                             bgcolor: "#f5f5f5",
                                             border: "1px solid rgba(0, 0, 0, 0.23)",
                                             borderLeft: 0,
                                          }}
                                       >
                                          <AddCircle color="primary" />
                                       </Button>
                                    </Box>
                                 </Box>
                              </Box>
                           )}

                           {formik.values.ordenDelDiaExtraordinaria === "disolucion" && (
                              <Box sx={{ mt: 3 }}>
                                 {/* Add titles above the fields */}
                                 <Box sx={{ display: "flex", mb: 1 }}>
                                    <Typography variant="body1" sx={{ fontWeight: "bold", width: "30%" }}>
                                       Nombre
                                    </Typography>
                                    <Typography variant="body1" sx={{ fontWeight: "bold", width: "30%" }}>
                                       Cargo
                                    </Typography>
                                    <Typography variant="body1" sx={{ fontWeight: "bold", width: "35%" }}>
                                       Emolumento
                                    </Typography>
                                    <Box sx={{ width: "5%" }}></Box> {/* Space for button column */}
                                 </Box>

                                 {/* Fields row */}
                                 <Box sx={{ display: "flex", alignItems: "center" }}>
                                    {/* Nombre field */}
                                    <TextField
                                       id="nombreEmolumentoExtraordinaria"
                                       name="nombreEmolumentoExtraordinaria"
                                       size="small"
                                       placeholder="Nombre"
                                       value={formik.values.nombreEmolumentoExtraordinaria || ""}
                                       onChange={formik.handleChange}
                                       sx={{
                                          width: "30%",
                                          "& .MuiOutlinedInput-root": {
                                             borderRadius: "4px 0 0 4px",
                                          },
                                       }}
                                    />

                                    {/* Cargo field */}
                                    <TextField
                                       id="cargoEmolumentoExtraordinaria"
                                       name="cargoEmolumentoExtraordinaria"
                                       size="small"
                                       placeholder="Cargo"
                                       value={formik.values.cargoEmolumentoExtraordinaria || ""}
                                       onChange={formik.handleChange}
                                       sx={{
                                          width: "30%",
                                          "& .MuiOutlinedInput-root": {
                                             borderRadius: 0,
                                             borderLeft: 0,
                                          },
                                       }}
                                    />

                                    {/* Emolumento correspondiente field */}
                                    <TextField
                                       id="emolumentoCorrespondienteExtraordinaria"
                                       name="emolumentoCorrespondienteExtraordinaria"
                                       size="small"
                                       placeholder="Emolumento correspondiente"
                                       value={formik.values.emolumentoCorrespondienteExtraordinaria || ""}
                                       onChange={formik.handleChange}
                                       sx={{
                                          width: "35%",
                                          "& .MuiOutlinedInput-root": {
                                             borderRadius: 0,
                                             borderLeft: 0,
                                          },
                                       }}
                                    />

                                    {/* Add button */}
                                    <Button
                                       sx={{
                                          width: "5%",
                                          minWidth: "40px",
                                          height: "40px",
                                          borderRadius: "0 4px 4px 0",
                                          padding: 0,
                                          boxShadow: "none",
                                          bgcolor: "#f5f5f5",
                                          border: "1px solid rgba(0, 0, 0, 0.23)",
                                          borderLeft: 0,
                                       }}
                                    >
                                       <AddCircle color="primary" fontSize="small" />
                                    </Button>
                                 </Box>
                              </Box>
                           )}
                        </Box>
                     </Box>
                  );
               case 3:
                  return (
                     <Box sx={{ padding: "20px" }}>
                        <FormControl sx={{ width: "50%", mb: 3 }} size="small">
                           <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                              Organo de gobierno
                           </Typography>

                           <Select
                              labelId="seleccionar-label-extraordinaria"
                              id="seleccionarExtraordinaria"
                              name="seleccionarExtraordinaria"
                              value={formik.values.seleccionarExtraordinaria || ""}
                              onChange={formik.handleChange}
                              label="Seleccionar"
                           >
                              <MenuItem value="lorem1">Lorem ipsum dolor sit amet</MenuItem>
                              <MenuItem value="lorem2">Lorem ipsum dolor sit amet consectetur</MenuItem>
                              <MenuItem value="lorem3">Lorem ipsum dolor sit amet consectetur adipiscing</MenuItem>
                           </Select>
                        </FormControl>

                        {/* Three fields in one line: Cargo, Nombre, Agregar */}
                        <Box sx={{ mb: 3 }}>
                           <Box sx={{ display: "flex", mb: 1 }}>
                              <Typography variant="body1" sx={{ fontWeight: "bold", width: "20%" }}>
                                 Cargo
                              </Typography>
                              <Typography variant="body1" sx={{ fontWeight: "bold", width: "65%" }}>
                                 Nombre
                              </Typography>
                              <Typography variant="body1" sx={{ fontWeight: "bold", width: "15%" }}>
                                 Agregar
                              </Typography>
                           </Box>

                           <Box sx={{ display: "flex", alignItems: "center" }}>
                              <FormControl size="small" sx={{ width: "20%" }}>
                                 <Select
                                    id="cargoAdminExtraordinaria"
                                    name="cargoAdminExtraordinaria"
                                    value={formik.values.cargoAdminExtraordinaria || ""}
                                    onChange={formik.handleChange}
                                    displayEmpty
                                    sx={{ borderRadius: "4px 0 0 4px" }}
                                 >
                                    <MenuItem value="presidente">Presidente</MenuItem>
                                    <MenuItem value="secretario">Secretario</MenuItem>
                                    <MenuItem value="vocal">Vocal</MenuItem>
                                 </Select>
                              </FormControl>

                              <TextField
                                 id="nombreAdminExtraordinaria"
                                 name="nombreAdminExtraordinaria"
                                 size="small"
                                 value={formik.values.nombreAdminExtraordinaria || ""}
                                 onChange={formik.handleChange}
                                 sx={{
                                    width: "65%",
                                    "& .MuiOutlinedInput-root": {
                                       borderRadius: 0,
                                    },
                                 }}
                              />

                              <Button
                                 sx={{
                                    width: "15%",
                                    height: "40px",
                                    borderRadius: "0 4px 4px 0",
                                    bgcolor: "#f5f5f5",
                                    border: "1px solid rgba(0, 0, 0, 0.23)",
                                    borderLeft: 0,
                                 }}
                              >
                                 <AddCircle color="primary" />
                              </Button>
                           </Box>
                        </Box>

                        {/* Section for Poderes y facultades */}
                        <Box sx={{ mb: 3, mt: 4 }}>
                           <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "10px" }}>
                              Poderes y facultades del organo de administracion
                           </Typography>
                           <FormControl fullWidth size="small">
                              <Select
                                 id="poderesYFacultadesExtraordinaria"
                                 name="poderesYFacultadesExtraordinaria"
                                 value={formik.values.poderesYFacultadesExtraordinaria || ""}
                                 onChange={formik.handleChange}
                                 displayEmpty
                              >
                                 <MenuItem value="lorem1">Lorem ipsum dolor sit amet</MenuItem>
                                 <MenuItem value="lorem2">Lorem ipsum dolor sit amet consectetur</MenuItem>
                                 <MenuItem value="lorem3">
                                    Lorem ipsum dolor sit amet consectetur adipiscing elit
                                 </MenuItem>
                              </Select>
                           </FormControl>
                        </Box>
                     </Box>
                  );
               case 4:
               // Existing code for case 4 if any...
               case 5:
               // Existing code for case 5 if any...
               default:
                  return null;
            }

         // Add cases for other acta types: "extraordinaria", "mixta", "Especial"
         case "Especial":
            switch (step) {
               case 2:
                  return (
                     <Box sx={{ padding: "20px" }}>
                        <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "10px" }}>
                           Orden del día
                        </Typography>
                        <FormControl fullWidth size="small">
                           <Select
                              id="ordenDelDiaEspecial"
                              name="ordenDelDiaEspecial"
                              value={formik.values.ordenDelDiaEspecial || ""}
                              onChange={formik.handleChange}
                              displayEmpty
                              MenuProps={{
                                 PaperProps: {
                                    style: {
                                       maxWidth: "100%",
                                       width: "inherit",
                                       boxSizing: "border-box",
                                    },
                                 },
                              }}
                           >
                              <MenuItem value="lorem1">Lorem ipsum dolor sit amet</MenuItem>
                              <MenuItem value="lorem2">Lorem ipsum dolor sit amet consectetur</MenuItem>
                              <MenuItem value="lorem3">Lorem ipsum dolor sit amet consectetur adipiscing</MenuItem>
                           </Select>
                        </FormControl>

                        {/* Dynamic selected text display with light blue background */}
                        {formik.values.ordenDelDiaEspecial && (
                           <Box
                              sx={{
                                 mt: 2,
                                 p: 2,
                                 bgcolor: "#e3f2fd",
                                 borderRadius: "4px",
                                 border: "1px solid #bbdefb",
                              }}
                           >
                              <Typography variant="body2">
                                 {formik.values.ordenDelDiaEspecial === "lorem1" &&
                                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl eget ultricies aliquam, nunc nisl aliquet nunc."}
                                 {formik.values.ordenDelDiaEspecial === "lorem2" &&
                                    "Lorem ipsum dolor sit amet consectetur, adipiscing elit. Nullam euismod, nisl eget ultricies aliquam, nunc nisl aliquet nunc, eget ultricies nisl."}
                                 {formik.values.ordenDelDiaEspecial === "lorem3" &&
                                    "Lorem ipsum dolor sit amet consectetur adipiscing elit. Nullam euismod, nisl eget ultricies aliquam, nunc nisl aliquet nunc, eget ultricies nisl eget ultricies."}
                              </Typography>
                           </Box>
                        )}

                        {/* Fields for Valor nominal and Capital social in one row */}
                        <Box sx={{ mt: 3, display: "flex", gap: 2 }}>
                           {/* Valor nominal por accion field */}
                           <Box sx={{ width: "50%" }}>
                              <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                                 Valor nominal por acción
                              </Typography>
                              <TextField
                                 id="valorNominalPorAccion"
                                 name="valorNominalPorAccion"
                                 type="number"
                                 size="small"
                                 fullWidth
                                 InputProps={{
                                    startAdornment: (
                                       <Box component="span" sx={{ mr: 1 }}>
                                          $
                                       </Box>
                                    ),
                                 }}
                              />
                           </Box>

                           {/* Capital social field */}
                           <Box sx={{ width: "50%" }}>
                              <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                                 Capital social
                              </Typography>
                              <TextField
                                 id="capitalSocial"
                                 name="capitalSocial"
                                 type="number"
                                 size="small"
                                 fullWidth
                                 InputProps={{
                                    startAdornment: (
                                       <Box component="span" sx={{ mr: 1 }}>
                                          $
                                       </Box>
                                    ),
                                 }}
                              />
                           </Box>
                        </Box>

                        {/* New Comentarios field with full width */}
                        <Box sx={{ mt: 3 }}>
                           <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                              Comentarios
                           </Typography>
                           <TextField
                              id="comentariosEspecial"
                              name="comentariosEspecial"
                              multiline
                              rows={4}
                              fullWidth
                              size="small"
                              value={formik.values.comentariosEspecial || ""}
                              onChange={formik.handleChange}
                           />
                        </Box>
                     </Box>
                  );
               case 3:
                  return (
                     <Box sx={{ padding: "20px" }}>
                        <FormControl sx={{ width: "50%", mb: 3 }} size="small">
                           <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                              Organo de gobierno
                           </Typography>

                           <Select
                              labelId="seleccionar-label-especial"
                              id="seleccionarEspecial"
                              name="seleccionarEspecial"
                              value={formik.values.seleccionarEspecial || ""}
                              onChange={formik.handleChange}
                              label="Seleccionar"
                           >
                              <MenuItem value="lorem1">Lorem ipsum dolor sit amet</MenuItem>
                              <MenuItem value="lorem2">Lorem ipsum dolor sit amet consectetur</MenuItem>
                              <MenuItem value="lorem3">Lorem ipsum dolor sit amet consectetur adipiscing</MenuItem>
                           </Select>
                        </FormControl>

                        {/* Three fields in one line: Cargo, Nombre, Agregar */}
                        <Box sx={{ mb: 3 }}>
                           <Box sx={{ display: "flex", mb: 1 }}>
                              <Typography variant="body1" sx={{ fontWeight: "bold", width: "20%" }}>
                                 Cargo
                              </Typography>
                              <Typography variant="body1" sx={{ fontWeight: "bold", width: "65%" }}>
                                 Nombre
                              </Typography>
                              <Typography variant="body1" sx={{ fontWeight: "bold", width: "15%" }}>
                                 Agregar
                              </Typography>
                           </Box>

                           <Box sx={{ display: "flex", alignItems: "center" }}>
                              <FormControl size="small" sx={{ width: "20%" }}>
                                 <Select
                                    id="cargoEspecial"
                                    name="cargoEspecial"
                                    value={formik.values.cargoEspecial || ""}
                                    onChange={formik.handleChange}
                                    displayEmpty
                                    sx={{ borderRadius: "4px 0 0 4px" }}
                                 >
                                    <MenuItem value="lorem1">Lorem ipsum</MenuItem>
                                    <MenuItem value="lorem2">Lorem ipsum dolor</MenuItem>
                                    <MenuItem value="lorem3">Lorem ipsum dolor sit</MenuItem>
                                 </Select>
                              </FormControl>

                              <TextField
                                 id="nombreEspecial"
                                 name="nombreEspecial"
                                 size="small"
                                 value={formik.values.nombreEspecial || ""}
                                 onChange={formik.handleChange}
                                 sx={{
                                    width: "65%",
                                    "& .MuiOutlinedInput-root": {
                                       borderRadius: 0,
                                    },
                                 }}
                              />

                              <Button
                                 sx={{
                                    width: "15%",
                                    height: "40px",
                                    borderRadius: "0 4px 4px 0",
                                    bgcolor: "#f5f5f5",
                                    border: "1px solid rgba(0, 0, 0, 0.23)",
                                    borderLeft: 0,
                                 }}
                              >
                                 <AddCircle color="primary" />
                              </Button>
                           </Box>
                        </Box>

                        {/* New section for Poderes y facultades */}
                        <Box sx={{ mb: 3, mt: 4 }}>
                           <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "10px" }}>
                              Poderes y facultares del organo de administracion
                           </Typography>
                           <FormControl fullWidth size="small">
                              <Select
                                 id="poderesYFacultadesEspecial"
                                 name="poderesYFacultadesEspecial"
                                 value={formik.values.poderesYFacultadesEspecial || ""}
                                 onChange={formik.handleChange}
                                 displayEmpty
                              >
                                 <MenuItem value="lorem1">Lorem ipsum dolor sit amet</MenuItem>
                                 <MenuItem value="lorem2">Lorem ipsum dolor sit amet consectetur</MenuItem>
                                 <MenuItem value="lorem3">
                                    Lorem ipsum dolor sit amet consectetur adipiscing elit
                                 </MenuItem>
                              </Select>
                           </FormControl>
                        </Box>
                     </Box>
                  );
               case 4:
                  return (
                     <Box sx={{ padding: "20px" }}>
                        <Box
                           sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "10px 20px",
                              borderRadius: "4px",
                           }}
                        >
                           <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                              Organo de vigilancia
                           </Typography>
                           <RadioGroup
                              row
                              name="organoVigilanciaEspecial"
                              value={formik.values.organoVigilanciaEspecial}
                              onChange={formik.handleChange}
                           >
                              <FormControlLabel value="comisario" control={<Radio size="small" />} label="Comisario" />
                              <FormControlLabel
                                 value="comiteAuditoria"
                                 control={<Radio size="small" />}
                                 label="Comité de auditoría"
                              />
                           </RadioGroup>
                        </Box>

                        {/* Add back the fields section similar to Cargo, Nombre, Agregar */}
                        <Box sx={{ mt: 4, mb: 3 }}>
                           <Box sx={{ display: "flex", mb: 1 }}>
                              <Typography variant="body1" sx={{ fontWeight: "bold", width: "20%" }}>
                                 Cargo
                              </Typography>
                              <Typography variant="body1" sx={{ fontWeight: "bold", width: "65%" }}>
                                 Nombre
                              </Typography>
                              <Typography variant="body1" sx={{ fontWeight: "bold", width: "15%" }}>
                                 Agregar
                              </Typography>
                           </Box>

                           <Box sx={{ display: "flex", alignItems: "center" }}>
                              <FormControl size="small" sx={{ width: "20%" }}>
                                 <Select
                                    id="cargoVigilanciaEspecial"
                                    name="cargoVigilanciaEspecial"
                                    value={formik.values.cargoVigilanciaEspecial || ""}
                                    onChange={formik.handleChange}
                                    displayEmpty
                                    sx={{ borderRadius: "4px 0 0 4px" }}
                                 >
                                    <MenuItem value="presidente">Presidente</MenuItem>
                                    <MenuItem value="secretario">Secretario</MenuItem>
                                    <MenuItem value="vocal">Vocal</MenuItem>
                                 </Select>
                              </FormControl>

                              <TextField
                                 id="nombreVigilanciaEspecial"
                                 name="nombreVigilanciaEspecial"
                                 size="small"
                                 value={formik.values.nombreVigilanciaEspecial || ""}
                                 onChange={formik.handleChange}
                                 sx={{
                                    width: "65%",
                                    "& .MuiOutlinedInput-root": {
                                       borderRadius: 0,
                                    },
                                 }}
                              />

                              <Button
                                 sx={{
                                    width: "15%",
                                    height: "40px",
                                    borderRadius: "0 4px 4px 0",
                                    bgcolor: "#f5f5f5",
                                    border: "1px solid rgba(0, 0, 0, 0.23)",
                                    borderLeft: 0,
                                 }}
                              >
                                 <AddCircle color="primary" />
                              </Button>
                           </Box>
                        </Box>
                     </Box>
                  );
               case 5:
                  return (
                     <Box sx={{ padding: "20px" }}>
                        <Box
                           sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "10px 20px",
                              borderRadius: "4px",
                           }}
                        >
                           <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                              ¿Se otorgaron poderes?
                           </Typography>
                           <RadioGroup
                              row
                              name="otorgaronPoderesEspecial"
                              value={formik.values.otorgaronPoderesEspecial}
                              onChange={formik.handleChange}
                           >
                              <FormControlLabel value="si" control={<Radio size="small" />} label="Sí" />
                              <FormControlLabel value="no" control={<Radio size="small" />} label="No" />
                           </RadioGroup>
                        </Box>

                        {/* Add Numero de escritura field */}
                        <Box sx={{ mt: 3, width: "50%" }}>
                           <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                              Numero de escritura
                           </Typography>
                           <InputTextField
                              id="numeroEscrituraEspecial"
                              name="numeroEscrituraEspecial"
                              type="text"
                              label="Numero de escritura"
                              size="small"
                              fullWidth
                              value={formik.values.numeroEscrituraEspecial || ""}
                              onChange={formik.handleChange}
                           />
                        </Box>

                        {/* New row with 5 fields with adjusted widths */}
                        <Box sx={{ mt: 4 }}>
                           {/* Titles row */}
                           <Box sx={{ display: "flex", mb: 1 }}>
                              <Typography variant="body1" sx={{ fontWeight: "bold", width: "24%" }}>
                                 Poderante
                              </Typography>
                              <Typography variant="body1" sx={{ fontWeight: "bold", width: "24%" }}>
                                 Apoderado
                              </Typography>
                              <Typography variant="body1" sx={{ fontWeight: "bold", width: "24%" }}>
                                 Otorgamiento
                              </Typography>
                              <Typography variant="body1" sx={{ fontWeight: "bold", width: "23%" }}>
                                 Vencimiento
                              </Typography>
                              <Box sx={{ width: "5%" }}></Box> {/* Empty space for the button column */}
                           </Box>

                           {/* Fields row */}
                           <Box sx={{ display: "flex", alignItems: "center" }}>
                              {/* Poderante field */}
                              <TextField
                                 id="poderanteEspecial"
                                 name="poderanteEspecial"
                                 size="small"
                                 value={formik.values.poderanteEspecial || ""}
                                 onChange={formik.handleChange}
                                 sx={{
                                    width: "24%",
                                    "& .MuiOutlinedInput-root": {
                                       borderRadius: "4px 0 0 4px",
                                    },
                                 }}
                              />

                              {/* Apoderado field */}
                              <TextField
                                 id="apoderadoEspecial"
                                 name="apoderadoEspecial"
                                 size="small"
                                 value={formik.values.apoderadoEspecial || ""}
                                 onChange={formik.handleChange}
                                 sx={{
                                    width: "24%",
                                    "& .MuiOutlinedInput-root": {
                                       borderRadius: 0,
                                       borderLeft: 0,
                                    },
                                 }}
                              />

                              {/* Otorgamiento date field */}
                              <InputTextField
                                 id="fechaOtorgamientoEspecial"
                                 name="fechaOtorgamientoEspecial"
                                 type="date"
                                 size="small"
                                 value={formik.values.fechaOtorgamientoEspecial || ""}
                                 onChange={formik.handleChange}
                                 sx={{
                                    width: "24%",
                                    "& .MuiOutlinedInput-root": {
                                       borderRadius: 0,
                                       borderLeft: 0,
                                    },
                                 }}
                              />

                              {/* Vencimiento date field */}
                              <InputTextField
                                 id="fechaVencimientoEspecial"
                                 name="fechaVencimientoEspecial"
                                 type="date"
                                 size="small"
                                 value={formik.values.fechaVencimientoEspecial || ""}
                                 onChange={formik.handleChange}
                                 sx={{
                                    width: "23%",
                                    "& .MuiOutlinedInput-root": {
                                       borderRadius: 0,
                                       borderLeft: 0,
                                    },
                                 }}
                              />

                              {/* Add button with smaller width */}
                              <Button
                                 sx={{
                                    width: "5%",
                                    minWidth: "40px",
                                    height: "40px",
                                    borderRadius: "0 4px 4px 0",
                                    padding: 0,
                                    boxShadow: "none",
                                    backgroundColor: "transparent",
                                 }}
                              >
                                 <AddCircle color="primary" fontSize="small" />
                              </Button>
                           </Box>
                        </Box>
                     </Box>
                  );
               default:
                  return null;
            }

         default:
            return (
               <Box sx={{ padding: "20px" }}>
                  <Typography>
                     Contenido para paso {step} de tipo {tipoActa} (por implementar)
                  </Typography>
               </Box>
            );
      }
   };

   // Function to render the appropriate assembly component based on society kind
   const renderAssemblyComponent = () => {
      if (corporateData?.societyKind === "Sociedad mercantil") {
         return <AssemblyMembers />;
      }
      return <AssemblyMembersSocial />;
   };

   // Form content based on step and tipo de acta
   const StepContent = ({ formik }) => {
      const tipoActa = formik.values.tipoActa || "";

      if (currentStep === 1) {
         // First step - render in a grid layout
         return (
            <Grid container spacing={0} sx={{ width: "90%", height: "100%", margin: 3 }}>
               <Grid
                  item
                  sx={{
                     display: "grid",
                     gridTemplateColumns: "repeat(2, 1fr)",
                     columnGap: "20px",
                     rowGap: "0px",
                     width: "100%",
                  }}
               >
                  {renderConditionalContent(formik, tipoActa, currentStep)}
               </Grid>
            </Grid>
         );
      }

      // For other steps, we just return the content directly
      return renderConditionalContent(formik, tipoActa, currentStep);
   };

   return (
      <CustomModalComponent open={open} setOpen={setOpenModalROR} title="Registro de acta" maxWidth="1000px">
         <Formik
            initialValues={{
               // Standard fields
               numeroActa: "",
               tipoActa: "",
               fechaActa: "",
               lugarReunion: "",
               horaInicio: "",
               horaFin: "",
               fechadecelebracion: "",
               fechaDeInscripcion: "",
               nombreDelNotario: "",
               numerodenotaria: "",
               fem: "",
               // Fields for constitutiva
               duracion: "",
               actaConstitutiva: "",
               objetoSocial: "",
               comentarios: "",
               cambiosCuadroAccionario: "",
               seleccionar: "",
               cargo: "",
               nombre: "",
               poderesYFacultades: "",
               organoVigilancia: "",
               otorgaronPoderes: "",
               numeroEscritura: "",
               poderante: "",
               apoderado: "",
               fechaOtorgamiento: "",
               fechaVencimiento: "",
               // Fields for ordinaria
               propositoSesion: "",
               huboEstructuraCambios: "",
               resolucionesOrdinarias: "",
               otorgaronPoderesOrdinaria: "",
               ordenDelDia: "",
               comentariosDiscutir: "",
               organosDeGobierno: "",
               // Fields for extraordinaria
               asuntoExtraordinario: "",
               // Add other tipo-specific fields as needed
               organoDeGobierno: "",
               numeroEscrituraOrdinaria: "",
               poderanteOrdinaria: "",
               apoderadoOrdinaria: "",
               fechaOtorgamientoOrdinaria: "",
               fechaVencimientoOrdinaria: "",
               // Add new fields for Especial type, step 3
               seleccionarEspecial: "",
               cargoEspecial: "",
               nombreEspecial: "",
               poderesYFacultadesEspecial: "",
               // Add new fields for Extraordinaria type
               ordenDelDiaExtraordinaria: "",
               tipoOperacionExtraordinaria: "",
               detallesOperacionExtraordinaria: "",
               modificarObjeto: false,
               modificarNacionalidad: false,
               modificarDenominacion: false,
               nuevaDenominacion: "",
               nuevoObjetoSocial: "",
               tipoAcuerdoDisolucion: "",
               nuevaFechaTermino: "",
               motivoDisolucionProrroga: "",
               cargoExtraordinaria: "",
               nombreExtraordinaria: "",
               nombreEmolumentoExtraordinaria: "",
               cargoEmolumentoExtraordinaria: "",
               emolumentoCorrespondienteExtraordinaria: "",
               seleccionarExtraordinaria: "",
               cargoAdminExtraordinaria: "",
               nombreAdminExtraordinaria: "",
               poderesYFacultadesExtraordinaria: "",
            }}
            onSubmit={handleSubmit}
            validateOnChange={false}
            validateOnBlur={false}
         >
            {(formProps) => (
               <Form>
                  <Grid
                     container
                     spacing={2}
                     sx={{
                        width: "850px",

                        height: "600px",

                        flexDirection: isSmallScreen ? "column" : "row",
                        margin: "auto",
                     }}
                  >
                     {/* Left sidebar with dynamic stepper based on acta type */}
                     <Grid
                        item
                        xs={12}
                        sm={3}
                        sx={{
                           bgcolor: "#F5F5F5",
                           borderRight: isSmallScreen ? "none" : "1px solid #ccc",
                           display: "flex",
                           justifyContent: "center",
                           alignItems: "center",
                           minHeight: "550px",
                           maxHeight: "auto",
                           padding: 2,
                        }}
                     >
                        <Box
                           sx={{
                              display: "flex",
                              overflowY: "auto",
                              flexDirection: "column",
                              alignItems: "center",
                              height: "100%",
                              ...(isSmallScreen && { width: "100%" }),
                              gap: 2,
                           }}
                        >
                           {currentLabels.map((label, index) => (
                              <CircleWithLabel
                                 key={index}
                                 number={index + 1}
                                 label={label}
                                 showLine={index < currentLabels.length - 1}
                                 isActive={currentStep >= index + 1}
                              />
                           ))}
                        </Box>
                     </Grid>

                     {/* Main content area */}
                     <Grid
                        item
                        xs={12}
                        sm={9}
                        sx={{
                           padding: 2,
                           height: "auto",
                        }}
                     >
                        <StepContent formik={formProps} />
                     </Grid>

                     {/* Footer buttons */}
                     <Grid
                        item
                        xs={12}
                        sx={{
                           bgcolor: "#F5F5F5",
                           borderTop: "1px solid #ccc",
                           padding: 2,
                           display: "flex",
                           height: "50px",
                           justifyContent: "flex-end",
                           gap: 1,
                           width: "100%",
                           maxWidth: "900px",
                           margin: "auto",
                           position: "sticky",
                           bottom: 0,
                           zIndex: 10,
                        }}
                     >
                        <Button
                           variant="text"
                           type="button" // Ensure it's type button to avoid form submission
                           disabled={currentStep === 1}
                           onClick={(e) => {
                              e.preventDefault(); // Prevent default action
                              e.stopPropagation(); // Stop event propagation
                              setCurrentStep((prev) => Math.max(prev - 1, 1)); // Go back but not below 1
                           }}
                           sx={{ color: "#ABAFB1" }}
                        >
                           Atrás
                        </Button>

                        {currentStep !== maxSteps ? (
                           <Button
                              variant="text"
                              type="button"
                              sx={{ color: "#464F60" }}
                              onClick={(e) => {
                                 e.preventDefault(); // Prevent default action
                                 e.stopPropagation(); // Stop event propagation
                                 setCurrentStep((prev) => Math.min(prev + 1, maxSteps));
                              }}
                           >
                              Continuar
                           </Button>
                        ) : (
                           <Button
                              type="button" // Changed from submit to button
                              variant="text"
                              color="primary"
                              disabled={isLoadingSave}
                              onClick={(e) => {
                                 e.preventDefault(); // Prevent default action
                                 e.stopPropagation(); // Stop event propagation
                                 formProps.handleSubmit(); // Manually trigger form submission
                              }}
                           >
                              Guardar
                           </Button>
                        )}
                     </Grid>
                  </Grid>
               </Form>
            )}
         </Formik>

         {/* New smaller modal */}
         <CustomModalComponent open={openNewModal} setOpen={setOpenNewModal} title="Agregar nuevo" maxWidth="700px">
            <Box
               sx={{
                  width: "700px",
                  height: "500px",
                  display: "flex",
                  flexDirection: "column",
               }}
            >
               {/* Content area with dynamic fields */}
               <Box sx={{ flex: 1, p: 3 }}>
                  {/* Type selection field always shown */}
                  <Box sx={{ mb: 3 }}>
                     <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                        Tipo de accionista/socio
                     </Typography>
                     <FormControl sx={{ width: "50%" }} size="small">
                        <Select
                           id="tipoAccionista"
                           name="tipoAccionista"
                           displayEmpty
                           value={tipoAccionista}
                           onChange={(e) => setTipoAccionista(e.target.value)}
                        >
                           <MenuItem value="personaFisica">Persona física</MenuItem>
                           <MenuItem value="personaMoral">Persona moral</MenuItem>
                        </Select>
                     </FormControl>
                  </Box>

                  {/* Conditional fields based on selection */}
                  {tipoAccionista === "personaFisica" ? (
                     <>
                        {/* First row with name and button */}
                        <Box sx={{ mb: 3, display: "flex", alignItems: "flex-end", gap: 2 }}>
                           {/* Nombre field for Persona Física */}
                           <Box sx={{ width: "80%" }}>
                              <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                                 Nombre de accionista/socio
                              </Typography>
                              <TextField
                                 id="nombreAccionista"
                                 name="nombreAccionista"
                                 size="small"
                                 fullWidth
                                 placeholder="Introduce el nombre completo"
                              />
                           </Box>

                           {/* Button positioned to the right of the field */}
                           <Box sx={{ width: "50%" }}>
                              <Button
                                 variant="contained"
                                 sx={{
                                    borderRadius: 1,
                                    px: 3,
                                    height: "40px",
                                    left: 40,
                                 }}
                              >
                                 Agregar nuevo usuario
                              </Button>
                           </Box>
                        </Box>

                        {/* New row with RFC field and Fundador */}
                        <Box sx={{ mb: 3, display: "flex", alignItems: "flex-start", gap: 2 }}>
                           {/* RFC field */}
                           <Box sx={{ width: "50%" }}>
                              <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                                 RFC
                              </Typography>
                              <TextField
                                 id="rfcPersonaFisica"
                                 name="rfcPersonaFisica"
                                 size="small"
                                 fullWidth
                                 placeholder="Introduce el RFC"
                              />
                           </Box>

                           {/* Fundador radio group */}
                           <Box sx={{ width: "50%" }}>
                              <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                                 Fundador
                              </Typography>
                              <RadioGroup row name="fundadorPersonaFisica" defaultValue="">
                                 <FormControlLabel value="si" control={<Radio size="small" />} label="Sí" />
                                 <FormControlLabel value="no" control={<Radio size="small" />} label="No" />
                              </RadioGroup>
                           </Box>
                        </Box>

                        {/* Apoderado legal title */}

                        {/* Participación field for Persona Física */}
                        <Box sx={{ mb: 3 }}>
                           <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                              Participación
                           </Typography>
                           <FormControl sx={{ width: "50%" }} size="small">
                              <Select id="participacionFisica" name="participacionFisica" displayEmpty defaultValue="">
                                 <MenuItem value="serieA">Serie A</MenuItem>
                                 <MenuItem value="serieB">Serie B</MenuItem>
                                 <MenuItem value="serieC">Serie C</MenuItem>
                              </Select>
                           </FormControl>
                        </Box>

                        {/* Table-like component for Persona Física */}
                        <Box sx={{ mb: 3 }}>
                           <Box
                              sx={{
                                 display: "flex",
                                 alignItems: "center",
                                 height: "40px",
                                 borderRadius: "4px",
                                 overflow: "hidden",
                                 bgcolor: "#e3f2fd",
                              }}
                           >
                              {/* First column - Static "Serie A" text */}
                              <Box
                                 sx={{
                                    width: "33%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    p: 1,
                                 }}
                              >
                                 <Typography variant="body2" fontWeight="medium">
                                    Serie A
                                 </Typography>
                              </Box>

                              {/* Second column - Acciones with input */}
                              <Box
                                 sx={{
                                    width: "33%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    px: 2,
                                    borderLeft: "1px solid #bbdefb",
                                    borderRight: "1px solid #bbdefb",
                                 }}
                              >
                                 <Typography variant="body2" fontWeight="medium" sx={{ mr: 1 }}>
                                    Acciones
                                 </Typography>
                                 <TextField
                                    size="small"
                                    type="number"
                                    InputProps={{
                                       sx: {
                                          height: "30px",
                                          width: "80px",
                                          bgcolor: "white",
                                       },
                                    }}
                                 />
                              </Box>

                              {/* Third column - Votos with input */}
                              <Box
                                 sx={{
                                    width: "33%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    px: 2,
                                 }}
                              >
                                 <Typography variant="body2" fontWeight="medium" sx={{ mr: 1 }}>
                                    Votos
                                 </Typography>
                                 <TextField
                                    size="small"
                                    type="number"
                                    InputProps={{
                                       sx: {
                                          height: "30px",
                                          width: "80px",
                                          bgcolor: "white",
                                       },
                                    }}
                                 />
                              </Box>
                           </Box>
                        </Box>

                        {/* Asignar como section for Persona Física */}
                        <Box sx={{ mb: 3 }}>
                           <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                              Asignar como:
                           </Typography>
                           <FormGroup row sx={{ ml: 1 }}>
                              <FormControlLabel
                                 control={<Checkbox size="small" />}
                                 label="Representante legal"
                                 sx={{ mr: 3 }}
                              />
                              <FormControlLabel
                                 control={<Checkbox size="small" />}
                                 label="Beneficiario controlador"
                                 sx={{ mr: 3 }}
                              />
                              <FormControlLabel control={<Checkbox size="small" />} label="Control efectivo" />
                           </FormGroup>
                        </Box>
                     </>
                  ) : tipoAccionista === "personaMoral" ? (
                     <>
                        <Box sx={{ mb: 3, display: "flex", alignItems: "flex-start", gap: 2 }}>
                           {/* Razón social field for Persona Moral */}
                           <Box sx={{ width: "50%" }}>
                              <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                                 Razón social
                              </Typography>
                              <TextField
                                 id="razonSocial"
                                 name="razonSocial"
                                 size="small"
                                 fullWidth
                                 placeholder="Introduce la razón social"
                              />
                           </Box>

                           {/* Fundador radio group */}
                           <Box sx={{ width: "50%" }}>
                              <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                                 Fundador
                              </Typography>
                              <RadioGroup row name="fundador" defaultValue="">
                                 <FormControlLabel value="si" control={<Radio size="small" />} label="Sí" />
                                 <FormControlLabel value="no" control={<Radio size="small" />} label="No" />
                              </RadioGroup>
                           </Box>
                        </Box>

                        {/* RFC field for Persona Moral */}
                        <Box sx={{ mb: 3 }}>
                           <Box sx={{ width: "50%" }}>
                              <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                                 RFC
                              </Typography>
                              <TextField
                                 id="rfcPersonaMoral"
                                 name="rfcPersonaMoral"
                                 size="small"
                                 fullWidth
                                 placeholder="Introduce el RFC"
                              />
                           </Box>
                        </Box>

                        <Box sx={{ mb: 2 }}>
                           <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                              Apoderado legal
                           </Typography>
                        </Box>

                        {/* Nombre and Apellido fields for Apoderado legal */}
                        <Box sx={{ mb: 3, display: "flex", alignItems: "flex-start", gap: 2 }}>
                           {/* Nombre field */}
                           <Box sx={{ width: "50%" }}>
                              <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                                 Nombre
                              </Typography>
                              <TextField
                                 id="nombreApoderado"
                                 name="nombreApoderado"
                                 size="small"
                                 fullWidth
                                 placeholder="Introduce el nombre"
                              />
                           </Box>

                           {/* Apellido field */}
                           <Box sx={{ width: "50%" }}>
                              <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                                 Apellido
                              </Typography>
                              <TextField
                                 id="apellidoApoderado"
                                 name="apellidoApoderado"
                                 size="small"
                                 fullWidth
                                 placeholder="Introduce el apellido"
                              />
                           </Box>
                        </Box>

                        {/* Telefono and Correo electronico fields for Apoderado legal */}
                        <Box sx={{ mb: 3, display: "flex", alignItems: "flex-start", gap: 2 }}>
                           {/* Telefono field */}
                           <Box sx={{ width: "50%" }}>
                              <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                                 Teléfono
                              </Typography>
                              <TextField
                                 id="telefonoApoderado"
                                 name="telefonoApoderado"
                                 type="tel"
                                 size="small"
                                 fullWidth
                                 placeholder="Introduce el teléfono"
                              />
                           </Box>

                           {/* Correo electronico field */}
                           <Box sx={{ width: "50%" }}>
                              <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                                 Correo electrónico
                              </Typography>
                              <TextField
                                 id="correoApoderado"
                                 name="correoApoderado"
                                 type="email"
                                 size="small"
                                 fullWidth
                                 placeholder="Introduce el correo electrónico"
                              />
                           </Box>
                        </Box>

                        {/* Participación field for Persona Moral */}
                        <Box sx={{ mb: 3 }}>
                           <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                              Participación
                           </Typography>
                           <FormControl sx={{ width: "50%" }} size="small">
                              <Select id="participacionMoral" name="participacionMoral" displayEmpty defaultValue="">
                                 <MenuItem value="serieA">Serie A</MenuItem>
                                 <MenuItem value="serieB">Serie B</MenuItem>
                                 <MenuItem value="serieC">Serie C</MenuItem>
                              </Select>
                           </FormControl>
                        </Box>

                        {/* Table-like component for Persona Moral */}
                        <Box sx={{ mb: 3 }}>
                           <Box
                              sx={{
                                 display: "flex",
                                 alignItems: "center",
                                 height: "40px",
                                 borderRadius: "4px",
                                 overflow: "hidden",
                                 bgcolor: "#e3f2fd",
                              }}
                           >
                              {/* First column - Static "Serie A" text */}
                              <Box
                                 sx={{
                                    width: "33%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    p: 1,
                                 }}
                              >
                                 <Typography variant="body2" fontWeight="medium">
                                    Serie A
                                 </Typography>
                              </Box>

                              {/* Second column - Acciones with input */}
                              <Box
                                 sx={{
                                    width: "33%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    px: 2,
                                    borderLeft: "1px solid #bbdefb",
                                    borderRight: "1px solid #bbdefb",
                                 }}
                              >
                                 <Typography variant="body2" fontWeight="medium" sx={{ mr: 1 }}>
                                    Acciones
                                 </Typography>
                                 <TextField
                                    size="small"
                                    type="number"
                                    InputProps={{
                                       sx: {
                                          height: "30px",
                                          width: "80px",
                                          bgcolor: "white",
                                       },
                                    }}
                                 />
                              </Box>

                              {/* Third column - Votos with input */}
                              <Box
                                 sx={{
                                    width: "33%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    px: 2,
                                 }}
                              >
                                 <Typography variant="body2" fontWeight="medium" sx={{ mr: 1 }}>
                                    Votos
                                 </Typography>
                                 <TextField
                                    size="small"
                                    type="number"
                                    InputProps={{
                                       sx: {
                                          height: "30px",
                                          width: "80px",
                                          bgcolor: "white",
                                       },
                                    }}
                                 />
                              </Box>
                           </Box>
                        </Box>

                        {/* Asignar como section for Persona Moral */}
                        <Box sx={{ mb: 3 }}>
                           <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                              Asignar como:
                           </Typography>
                           <FormGroup row sx={{ ml: 1 }}>
                              <FormControlLabel
                                 control={<Checkbox size="small" />}
                                 label="Beneficiario controlador"
                                 sx={{ mr: 3 }}
                              />
                              <FormControlLabel control={<Checkbox size="small" />} label="Control efectivo" />
                           </FormGroup>
                        </Box>
                     </>
                  ) : null}
               </Box>

               {/* Footer buttons */}
               <Box
                  sx={{
                     bgcolor: "#F5F5F5",
                     borderTop: "1px solid #ccc",
                     padding: 2,
                     display: "flex",
                     height: "50px",
                     justifyContent: "flex-end",
                     gap: 1,
                     position: "sticky", // Add sticky positioning
                     bottom: 0, // Stick to bottom
                     zIndex: 10, // Ensure it stays on top of other content
                     width: "100%", // Take full width
                  }}
               >
                  <Button variant="text" onClick={handleCloseNewModal} sx={{ color: "#ABAFB1" }}>
                     Cancelar
                  </Button>
                  <Button
                     variant="text"
                     onClick={() => {
                        // Add save logic here
                        handleCloseNewModal();
                     }}
                     sx={{ color: "#464F60" }}
                  >
                     Guardar
                  </Button>
               </Box>
            </Box>
         </CustomModalComponent>
      </CustomModalComponent>
   );
};

export default RegisterOfRecordsModal;
