import {
   Box,
   Button,
   TableContainer,
   Table,
   TableHead,
   TableRow,
   TableCell,
   TableBody,
   CircularProgress,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useContext } from "react";
import { ComplaintStepperContext } from "../../context/complaintStepperContext";
import { getUrlForDocumentationFile } from "../../lib/usersBEClient";

interface ComplaintFileRecordProps {
   includeStepper: boolean;
   clousure: boolean;
}

export const ComplaintFileRecord = (props: ComplaintFileRecordProps) => {
   const { documentation, complaint } = useContext(ComplaintStepperContext);

   const getFileSignedUrl = async (docmunetKey: string) => {
      const noExtFileKey = docmunetKey.indexOf(".pdf");
      const fileUrlResponse = await getUrlForDocumentationFile(
         complaint.reportNumber,
         complaint.companyBranch.company._id,
         { folder: `cd/reports/${complaint.reportNumber}/docs` },
         docmunetKey.substring(0, noExtFileKey || docmunetKey.length)
      );
      window.open(fileUrlResponse, "_blank", "noopener,noreferrer");
   };
   return (
      <Box>
         {documentation.length !== 0 ? (
            <>
               <TableContainer sx={{ maxHeight: documentation.length > 5 ? 260 : "auto" }}>
                  <Table size="small">
                     <TableHead>
                        <TableRow>
                           <TableCell sx={{ fontWeight: 600 }}>Folio:</TableCell>
                           <TableCell align="right" sx={{ fontWeight: 600 }}>
                              Ver documento:
                           </TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {documentation.map((document, i) => (
                           <TableRow key={i}>
                              <TableCell>{document.name}</TableCell>
                              <TableCell align="center">
                                 <Button size="small" onClick={() => getFileSignedUrl(document.name)}>
                                    <PictureAsPdfIcon />
                                 </Button>
                              </TableCell>
                           </TableRow>
                        ))}
                     </TableBody>
                  </Table>
               </TableContainer>
            </>
         ) : (
            <Box display="flex" justifyContent="center" sx={{ p: 4, width: "100%" }}>
               <CircularProgress />
            </Box>
         )}
      </Box>
   );
};
