import { Document, Page, Text, View, Svg, Path, Font, Image } from "@react-pdf/renderer";
import { Table, TR, TD } from "@ag-media/react-pdf-table";
import { Companies } from "../../../types/BaseTypes";
import { IBeneficiary } from "../../../types/beneficiaryTypes";
import { getContrastYIQ } from "../../../const/globalConst";

interface beneficiaryIdentificationProps {
   logo: string;
   company: Companies;
   beneficiary: IBeneficiary;
   fideicomiso: { deedNum: string; inscriptrionNum: string };
   identifications: { type: string; value: string; chain?: string }[];
}

export const BenenficiaryFideicomisoIdentificationPDF = (props: beneficiaryIdentificationProps) => {
   const { logo, company, beneficiary, fideicomiso, identifications } = props;

   const fecha = new Date();
   const day = fecha.getDate();
   const month = fecha.toLocaleString("es-ES", { month: "long" });
   const year = fecha.getFullYear();

   return (
      <Document>
         <Page size="A4" style={{ padding: 40, fontFamily: "Open Sans", paddingTop: 80 }}>
            <View
               style={{
                  position: "absolute",
                  top: 27,
                  left: 40,
                  display: "flex",
                  flexDirection: "column",
               }}
               fixed
            >
               <Image
                  src={logo}
                  style={{
                     width: "auto",
                     height: 50,
                     marginBottom: 5,
                  }}
               />
            </View>

            <View
               style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
               }}
               fixed
            >
               <View
                  style={{
                     position: "absolute",
                     top: 0,
                     left: 0,
                     right: 0,
                     zIndex: 2,
                  }}
               >
                  <Svg width={414} height={13} viewBox="0 0 414 13">
                     <Path d="M0 0H414V13H0z" fill={company?.company_details?.primaryColor || "#162C44"} />
                  </Svg>
               </View>

               <View
                  style={{
                     position: "absolute",
                     top: 0,
                     right: -100,
                     zIndex: 1,
                  }}
               >
                  <Svg width={400} height={16} viewBox="0 0 400 16" style={{ position: "absolute", top: 0, right: 0 }}>
                     <Path d="M0 0H500V20H52.625L0 0Z" fill={company?.company_details?.secondaryColor || "#64748B"} />
                  </Svg>
               </View>
            </View>

            <View>
               <View>
                  <Text style={{ fontSize: 13, fontWeight: "bold", textAlign: "center" }}>
                     {"\n"}INFORME DE LA DETERMINACIÓN DEL BENEFICIARIO CONTROLADOR
                  </Text>
                  <Text style={{ fontSize: 12, fontWeight: "bold", textAlign: "center", marginBottom: 5 }}>
                     {company?.person_details?.businessName?.toUpperCase() ||
                        company?.person_details?.comercialName?.toUpperCase() ||
                        ""}
                  </Text>
                  <Text style={{ fontSize: 12, textAlign: "center", color: "gray" }}>
                     {company?.invoice_details?.rfc?.toUpperCase() || ""}
                  </Text>
                  <Text
                     style={{
                        fontSize: 10,
                        textAlign: "right",
                        color: "#152C44",
                        marginBottom: 10,
                     }}
                  >
                     {`${day} de ${month} del ${year}`}
                  </Text>
               </View>

               <View>
                  <Table style={{ width: "100%", padding: 5, gap: 5, borderColor: "transparent", marginBottom: 10 }}>
                     <TR>
                        <TD
                           style={{
                              backgroundColor: company?.company_details?.primaryColor || "#162c44",
                              color: getContrastYIQ(company?.company_details?.primaryColor),
                              padding: 5,
                              fontSize: 10,
                              textAlign: "left",
                              borderRadius: 5,
                              width: "25%",
                              maxWidth: "25%",
                              borderColor: company?.company_details?.primaryColor || "#162c44",
                              fontWeight: 600,
                           }}
                        >
                           NÚM. DE ESCRITURA
                        </TD>
                        <TD
                           style={{
                              backgroundColor: "#F5F5F5",
                              padding: 5,
                              fontSize: 10,
                              borderRadius: 5,
                              textAlign: "left",
                              width: "75%",
                              maxWidth: "75%",
                              borderColor: "#F5F5F5",
                              left: 5,
                           }}
                        >
                           <Text>{fideicomiso?.deedNum}</Text>
                        </TD>
                     </TR>
                     <TR>
                        <TD
                           style={{
                              backgroundColor: company?.company_details?.primaryColor || "#162c44",
                              color: getContrastYIQ(company?.company_details?.primaryColor),
                              padding: 5,
                              fontSize: 10,
                              borderRadius: 5,
                              textAlign: "left",
                              width: "25%",
                              maxWidth: "25%",
                              borderColor: company?.company_details?.primaryColor || "#162c44",
                              fontWeight: 600,
                           }}
                        >
                           NÚM. DE INSCRIPCION DEL FIDEICOMISO
                        </TD>
                        <TD
                           style={{
                              backgroundColor: "#F5F5F5",
                              padding: 5,
                              fontSize: 10,
                              borderRadius: 5,
                              textAlign: "left",
                              width: "75%",
                              maxWidth: "75%",
                              borderColor: "#F5F5F5",
                              left: 5,
                           }}
                        >
                           {fideicomiso?.inscriptrionNum}
                        </TD>
                     </TR>
                  </Table>
               </View>

               <Text style={{ fontSize: 10, textAlign: "justify" }}>
                  El presente informe ha sido elaborado en cumplimiento de los artículos 32-B Ter, 32-B Quáter y 32-B
                  Quinquies del Código Fiscal de la Federación, así como la regla 2.8.1.20 de la Resolución Miscelánea
                  Fiscal que le es aplicable, por medio del cual se realizó él descartó, que permitirá determinar a la{" "}
                  <Text style={{ fontWeight: "bold" }}>
                     persona física que funge como Beneficiario Controlador del fideicomiso con el número de
                     inscripción: {fideicomiso?.inscriptrionNum || ""}
                  </Text>
                  , de acuerdo con las características, funciones y lineamientos establecidos en el articulado ya
                  citado.{"\n"}
                  {"\n"} La metodología empleada para concluir el presente análisis se realizó aplicando de los
                  supuestos mencionados en el código fiscal en su artículo 32-B Quáter de manera sucesiva, a través del
                  cual se analizó la forma en la que cada uno de ellos la afectan o la pueden afectar de manera
                  particular y se tomaron las medidas de ampliación de información que fueron necesarias.{"\n"}
                  {"\n"} Tras evaluar las particularidades del fideicomiso y aplicar la metodología previamente
                  explicada, se concluye que el beneficiario controlador de la persona jurídica es:{" "}
                  <Text style={{ fontWeight: "bold" }}>
                     {beneficiary.businessName ||
                        (beneficiary.user
                           ? `${beneficiary.user.firstName} ${beneficiary.user.lastName}`
                           : beneficiary.name)}
                  </Text>
                  , sustentando esta determinación en los siguientes argumentos:{"\n"}
                  {"\n"}
               </Text>

               <View>
                  <View style={{ flexDirection: "row", justifyContent: "space-between", marginBottom: 2 }}>
                     <View
                        style={{
                           backgroundColor: company?.company_details?.primaryColor || "#162c44",
                           color: getContrastYIQ(company?.company_details?.primaryColor),
                           padding: 5,
                           fontSize: 10,
                           textAlign: "center",
                           borderRadius: 5,
                           width: "50%",
                           borderColor: company?.company_details?.primaryColor || "#162c44",
                           fontWeight: 600,
                        }}
                     >
                        <Text>SUPUESTO EN EL QUE SE IDENTIFICA</Text>
                     </View>

                     <View
                        style={{
                           backgroundColor: company?.company_details?.primaryColor || "#162c44",
                           color: getContrastYIQ(company?.company_details?.primaryColor),
                           padding: 5,
                           fontSize: 10,
                           textAlign: "center",
                           borderRadius: 5,
                           width: "50%",
                           borderColor: company?.company_details?.primaryColor || "#162c44",
                           marginLeft: 2,
                           fontWeight: 600,
                        }}
                     >
                        <Text>RAZONAMIENTO DE IDENTIFICACIÓN</Text>
                     </View>
                  </View>

                  {identifications.map((identification, index) => (
                     <View key={index} style={{ flexDirection: "row", justifyContent: "space-between", marginTop: 2 }}>
                        <View
                           style={{
                              backgroundColor: "#F5F5F5",
                              fontSize: 9,
                              borderRadius: 5,
                              width: "50%",
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                              padding: 10,
                           }}
                        >
                           <Text>{identification.type}</Text>
                        </View>

                        <View
                           style={{
                              backgroundColor: "#F5F5F5",
                              fontSize: 9,
                              borderRadius: 5,
                              width: "50%",
                              padding: 10,
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                              marginLeft: 2,
                           }}
                        >
                           <Text>{identification.value}</Text>
                        </View>
                     </View>
                  ))}
               </View>

               <View style={{}}>
                  <Text style={{ fontSize: 10, textAlign: "justify" }}>
                     {"\n"}
                     Por lo que está en plena disposición de colaborar para proporcionar la documentación e información
                     necesaria, clara, exacta, suficiente, fidedigna, completa y actualizada que permita su plena
                     identificación. De esta manera, se compromete a informar de manera pronto y rápida de cualquier
                     cambio o actualización que sufra la misma, conforme lo requieren los artículos 32-B Ter y 32-B
                     Quinquies del Código Fiscal de la Federación, así como las reglas 2.8.21 y 2.8.1.22 de la
                     Resolución Miscelánea Fiscal.{"\n"}
                     {"\n"}
                     De acuerdo con el artículo 84-M fracción I del CFF, el incumplimiento de esta obligación puede
                     resultar en sanciones económicas significativas, que oscilan entre $1,686,750.00 y $2,249,000.00
                     por cada beneficiario controlador.{"\n"}
                     {"\n"}
                  </Text>
               </View>
            </View>
         </Page>
      </Document>
   );
};

Font.register({
   family: "Open Sans",
   fonts: [
      { src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf" },
      { src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf", fontWeight: 600 },
      { src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf", fontWeight: 800 },
   ],
});
