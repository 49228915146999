import React, { useState } from "react";
import { Grid, Box, Typography, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const ExpandableTextBox = ({}) => {
   const [expanded, setExpanded] = useState(false);

   const handleExpandClick = () => {
      setExpanded(!expanded);
   };

   return (
      <Grid
         item
         sx={{
            width: "96%",
            height: expanded ? "auto" : "50px",
            margin: "auto",
            display: "flex",
            marginTop: "20px",
            justifyContent: "space-between",
         }}
      >
         <Box
            sx={{
               width: "20%",
               bgcolor: "#152C44",
               height: "50px",
               display: "flex",
               alignItems: "center",
               justifyContent: "center",
               textAlign: "center",
            }}
         >
            <Typography variant="h6" sx={{ color: "white", fontWeight: "bold" }}>
               Orden del día
            </Typography>
         </Box>
         <Box
            sx={{
               width: "80%",
               bgcolor: "#E4E4E7",
               height: expanded ? "auto" : "50px",
               textAlign: "left",
               padding: "5px 17px",
               overflow: "hidden",
               textOverflow: "ellipsis",
               whiteSpace: expanded ? "normal" : "nowrap",
               display: "flex",
               alignItems: "center",
               justifyContent: "space-between",
            }}
         >
            <Typography
               variant="h6"
               sx={{
                  color: "MidnightBlue",
                  fontWeight: "bold",
                  fontSize: "12px",
                  flexGrow: 1,
               }}
            >
               Es un ejemplo de texto,Es un ejemplo de texto,Es un ejemplo de texto,Es un ejemplo de texto,Es un ejemplo
               de texto,Es un ejemplo de texto para ver si funciona
            </Typography>
            <IconButton onClick={handleExpandClick} size="small">
               {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
         </Box>
      </Grid>
   );
};

export default ExpandableTextBox;
