import { Box, Grid, IconButton, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { BeneficiaryControllerContext } from "../../../../context/beneficiaryContext/beneficiaryContollerContext";
import { FormatListBulletedRounded, ListAlt } from "@mui/icons-material";
import PossibleBeneficiariesListDrawer from "./PossibleBeneficiariesListDrawer";

const PossibleBeneficiaryController = () => {
   const { possibleBeneficiaries: beneficiaries } = useContext(BeneficiaryControllerContext);
   const [openDrawer, setOpenDrawer] = useState(false);

   const commonStyles = {
      boxStyle: {
         bgcolor: "#f2f3f5",
         p: 1,
         borderRadius: 1,
         width: "100%",
         height: "40px",
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
      },
      gridStyle: {
         display: "flex",
         alignItems: "center",
         justifyContent: "flex-start",
         bgcolor: "white",
         py: 0.5,
      },
   };

   return (
      <Box
         sx={{
            width: "100%",
            pb: 1,
            border: "1px solid #ccc",
            borderRadius: "8px",
            overflow: "hidden",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
         }}
      >
         <Grid
            item
            sx={{
               bgcolor: "#162c44",
               p: 2,
               width: "100%",
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               height: 40,
               borderRadius: "5px 5px 0 0",
               mb: 1,
            }}
         >
            <Typography sx={{ color: "white", fontWeight: "bold", fontSize: 18, flex: 1, textAlign: "center" }}>
               Posibles beneficiarios controladores
            </Typography>
            <IconButton sx={{ color: "white" }} onClick={() => setOpenDrawer(true)}>
               <FormatListBulletedRounded
                  sx={{ fontSize: 20, color: "#152C44", bgcolor: "white", borderRadius: 1, p: 0.2 }}
               />
            </IconButton>
         </Grid>

         <Box
            sx={{
               minHeight: "250px",
               maxHeight: "250px",
               overflow: "auto",
               px: 1,
               "& .header": {
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  backgroundColor: "white",
               },
            }}
         >
            <Grid container sx={{ width: "100%", m: 0, flexWrap: "nowrap", gap: 0.3 }} className="header">
               <Grid item sx={{ ...commonStyles.gridStyle, width: 600 }}>
                  <Box sx={{ ...commonStyles.boxStyle, bgcolor: "#738090" }}>
                     <Typography sx={{ textAlign: "center", fontSize: 14, color: "white" }}>Nombre</Typography>
                  </Box>
               </Grid>
               <Grid item sx={{ ...commonStyles.gridStyle, width: 200 }}>
                  <Box sx={{ ...commonStyles.boxStyle, bgcolor: "#738090" }}>
                     <Typography sx={{ textAlign: "center", fontSize: 14, color: "white" }}>
                        Identificación %
                     </Typography>
                  </Box>
               </Grid>
            </Grid>

            {beneficiaries &&
               beneficiaries
                  .sort((a, b) => b.completedPercentage - a.completedPercentage)
                  .map((benef) => (
                     <Grid key={benef._id} container sx={{ width: "100%", m: 0, flexWrap: "nowrap", gap: 0.3 }}>
                        <Grid item sx={{ ...commonStyles.gridStyle, width: 600 }}>
                           <Box sx={commonStyles.boxStyle}>
                              <Typography sx={{ fontSize: "13px" }}>
                                 {benef.businessName ||
                                    (benef.user ? `${benef.user.firstName} ${benef.user.lastName}` : benef.name)}
                              </Typography>
                           </Box>
                        </Grid>
                        <Grid item sx={{ ...commonStyles.gridStyle, width: 200 }}>
                           <Box sx={commonStyles.boxStyle}>
                              <Typography sx={{ fontSize: "13px" }}>
                                 {benef?.completedPercentage?.toFixed(0) ?? 0}%
                              </Typography>
                           </Box>
                        </Grid>
                     </Grid>
                  ))}
         </Box>
         <PossibleBeneficiariesListDrawer state={openDrawer} setState={setOpenDrawer} />
      </Box>
   );
};

export default PossibleBeneficiaryController;
