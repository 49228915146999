import { useState, useContext, useEffect, useMemo } from "react";
import {
   Box,
   Button,
   FormControl,
   Grid,
   IconButton,
   InputAdornment,
   InputBase,
   ListItemButton,
   Popover,
   List,
   ListItem,
   ListItemText,
   Typography,
   Container,
   CircularProgress,
} from "@mui/material";
import { NotificationBar } from "../../components/notificationsBar";
import { sendMailLink } from "../../lib/lecosyBackendClient";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check";
import SearchIcon from "@mui/icons-material/Search";
import { lowerFirstLetter } from "../../const/globalConst";
import QRCode from "react-qr-code";
import html2canvas from "html2canvas";
import downloadjs from "downloadjs";
import { InputTextField } from "../../components/Inputs/InputTextField";
import { Form, Formik } from "formik";
import { disseminationsMethods } from "../../lib/validations/inputSchemas";
import PositionedPopper from "../../components/PositionedPopper";
import VisibilityIcon from "@mui/icons-material/Visibility";
import HideImageIcon from "@mui/icons-material/HideImage";
import { SnackBarContext } from "../../context/snackBarContext";
import { link, mail, qr } from "../../assets/icons/DrawerAppScreenIcons";
import { UserContext } from "../../context/userContext";
import { getPlatformInfo, getUrlS3 } from "../../lib/usersBEClient";
import VerifyUserSession from "../../hooks/verifyUserSession";

export const DisseminationMethodsNewScreen = () => {
   VerifyUserSession();
   const { showSnackBar } = useContext(SnackBarContext);
   const { user, isLoadingCompany } = useContext(UserContext);
   const [copyClipboard, setCopyClipboard] = useState(false);
   const [companyLink, setCompanyLink] = useState("");
   const [companySelected, setCompanySelected] = useState<any>();
   const [sendEmailIsLoading, setSendEmailIsLoading] = useState(false);
   const [companyNameFilter, setCompanyNameFilter] = useState("");
   const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
   const [anchorElPopper, setAnchorElPopper] = useState<null | HTMLElement>(null);
   const [flyersSrcIsLoading, setFlyersSrcIsLoading] = useState(false);
   const [flyerPoliciesImageBlob, setFlyerPoliciesImageBlob] = useState<File>();
   const [flyerMediaImageBlob, setFlyerMediaImageBlob] = useState<File>();
   const [flyerProcessImageBlob, setFlyerProcessImageBlob] = useState<File>();

   const herramientasMarketing =
      "https://drive.google.com/file/d/1bGeebN_m0yT03do-t27gXtOLpspk_01y/view?usp=share_link";
   const manualIdentidad = "https://drive.google.com/file/d/11VO9IMUw59079zuLUmhurzQv79S73Ufu/view?usp=sharing";

   const handleClickPopper = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElPopper(anchorElPopper ? null : event.currentTarget);
   };

   const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   const open = Boolean(anchorEl);
   const id = open ? "simple-popover" : undefined;

   const getPoliciesFlyer = async (clientId: string) => {
      try {
         const logoUrl: string = await getUrlS3(
            clientId,
            { folder: `diffusionCamps/${clientId}` },
            "policiesFlyer.png"
         );
         await fetch(logoUrl).then(async (data) => {
            const newBlob = await data.blob();
            const file = new File([newBlob], "policiesFlyer.png", { type: "image/png" });
            setFlyerPoliciesImageBlob(file);
         });
      } catch (error) {}
   };
   const getMediaFlyer = async (clientId: string) => {
      try {
         const logoUrl: string = await getUrlS3(clientId, { folder: `cd/diffusionCamps` }, "mediaFlyer.png");
         await fetch(logoUrl).then(async (data) => {
            const newBlob = await data.blob();
            const file = new File([newBlob], "mediaFlyer.png", { type: "image/png" });
            setFlyerMediaImageBlob(file);
         });
      } catch (error) {}
   };
   const getProcessFlyer = async (clientId: string) => {
      try {
         const logoUrl: string = await getUrlS3(clientId, { folder: `cd/diffusionCamps` }, "processFlyer.png");
         await fetch(logoUrl).then(async (data) => {
            const newBlob = await data.blob();
            const file = new File([newBlob], "processFlyer.png", { type: "image/png" });
            setFlyerProcessImageBlob(file);
         });
      } catch (error) {}
   };

   const fetchPlatformInfo = async (companySelected) => {
      const platformFetched = await getPlatformInfo(companySelected.company_details._id);
      if (!platformFetched) {
         setCompanySelected(null);
         setCompanyLink("");
         return showSnackBar("Esta empresa no cuenta con un canal de denuncias.", true);
      }
      setCompanySelected(platformFetched);
      setCompanyLink(`denuncias.lecosy.com.mx/${lowerFirstLetter(platformFetched.subName)}`);
      await getPoliciesFlyer(companySelected._id);
      await getMediaFlyer(companySelected._id);
      await getProcessFlyer(companySelected._id);
      setFlyersSrcIsLoading(false);
   };

   const handleSendMail = async (values, actions) => {
      try {
         setSendEmailIsLoading(true);
         const response = await sendMailLink(values.email, values.message, companyLink);
         if (response.data.message === "Email sent") {
            showSnackBar("Correo enviado con éxito!", false);
            setSendEmailIsLoading(false);
            handleClose();
            return;
         }
      } catch (error: any) {
         if (error.response.data.message !== "Email sent") {
            showSnackBar("Error al enviar correo electrónico", true);
            setSendEmailIsLoading(false);
         }
      }
   };

   const downloadQRCode = async () => {
      const element = document.querySelector<HTMLElement>(".QRCode");
      if (!element) return;
      const canvas = await html2canvas(element);
      const dataURL = canvas.toDataURL("image/png");
      downloadjs(dataURL, `QR-${companySelected.subName}.png`, "image/png");
   };

   const filteredRows = (rows: any) => {
      const filter =
         companyNameFilter === ""
            ? rows
            : rows.filter((row) => row.person_details.comercialName.startsWith(companyNameFilter));
      return filter;
   };

   const string =
      "Te envío este enlace para que accedas a nuestra plataforma a realizar alguna denuncia en caso de necesario.";

   const openMarketingFileInNewTab = (url: string) => {
      window.open(url, "_blank");
   };

   useEffect(() => {
      const setCompanyInfo = () => {
         if (user.companies?.length === 1) {
            setFlyersSrcIsLoading(true);
            fetchPlatformInfo(user.companies[0]);
         }
      };

      return setCompanyInfo;
      // eslint-disable-next-line
   }, [user.companies]);

   const filteredCompanies = useMemo(() => {
      if (isLoadingCompany) return [];
      return user.companies?.filter((company) =>
         company.company_details.servicesDetails.some((service) => service.details?.platformId)
      );
   }, [isLoadingCompany, user.companies]);

   return (
      <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
         <NotificationBar
            head="Campañas y medios de difusión"
            content={
               <Button variant="outlined" onClick={() => openMarketingFileInNewTab(herramientasMarketing)}>
                  Herramientas de Marketing
               </Button>
            }
         />
         <Container maxWidth="xl">
            <Grid container item xs={12} spacing={3}>
               <Grid item xs={12} md={12} lg={2.5}>
                  <Box display={"flex"} flexDirection={"column"} sx={{ mt: 6.5, minWidth: "100%", boxShadow: 2 }}>
                     <Box flex={1} sx={{ bgcolor: "#142c44" }}>
                        <Typography color={"white"} sx={{ p: 2 }} fontWeight={500} variant="h6" align="center">
                           Empresas
                        </Typography>
                     </Box>
                     <Box flex={1}>
                        <FormControl variant="outlined" fullWidth>
                           <InputBase
                              sx={{ p: 1, bgcolor: "#FAF9F9" }}
                              placeholder="Nombre de la empresa"
                              value={companyNameFilter}
                              onChange={(e) => setCompanyNameFilter(e.target.value)}
                              type={"text"}
                              endAdornment={
                                 <InputAdornment position="end">
                                    <IconButton aria-label="toggle password visibility" edge="end">
                                       <SearchIcon />
                                    </IconButton>
                                 </InputAdornment>
                              }
                           />
                        </FormControl>
                     </Box>
                     <Box flex={5} sx={{ bgcolor: "white", maxHeight: 500, overflowY: "scroll" }}>
                        {filteredCompanies?.length > 0 && (
                           <List dense={false}>
                              {filteredRows(filteredCompanies).map((company) => (
                                 <ListItem key={`company-${company._id}`}>
                                    <ListItemButton
                                       onClick={(e) =>
                                          filteredCompanies.findIndex((companyF) => {
                                             if (
                                                company.person_details.comercialName ===
                                                companyF.person_details.comercialName
                                             ) {
                                                setFlyersSrcIsLoading(true);
                                                fetchPlatformInfo(company);
                                             }
                                             return null;
                                          })
                                       }
                                    >
                                       <ListItemText disableTypography>
                                          <Typography align="center">{company.person_details.comercialName}</Typography>
                                       </ListItemText>
                                    </ListItemButton>
                                 </ListItem>
                              ))}
                           </List>
                        )}
                     </Box>
                  </Box>
                  <Box py={2}>
                     <Button fullWidth variant="outlined" onClick={() => openMarketingFileInNewTab(manualIdentidad)}>
                        Manual de Identidad Corporativa
                     </Button>
                  </Box>
               </Grid>
               <Grid item xs={12} md={12} lg={9.5}>
                  <Box display={"flex"} alignItems={"center"} sx={{ minWidth: "100%", p: 1 }}>
                     <ArrowRightIcon fontSize="large" />
                     <Typography color={"#142c44"}>Medios de difusión</Typography>
                  </Box>
                  <Box display={"flex"} flexDirection={"column"}>
                     <Grid container item xs={12} spacing={3}>
                        {/* Codigo QR */}
                        <Grid item xs={12} md={12} lg={4}>
                           <Box
                              display={"flex"}
                              flexDirection={"column"}
                              alignItems={"center"}
                              sx={{ bgcolor: "white", p: 1.5, minHeight: "100%", boxShadow: 2 }}
                           >
                              <Box sx={{ minHeight: 35 }}>
                                 <Typography fontWeight={600} fontSize={18}>
                                    Código QR
                                 </Typography>
                              </Box>
                              <div className="QRCode">
                                 <Box
                                    sx={{
                                       display: "flex",
                                       justifyContent: "center",
                                       alignItems: "center",
                                       my: 2,
                                    }}
                                 >
                                    {companyLink === "" ? (
                                       <Box component={"img"} sx={{ maxWidth: 160, height: 140 }} alt="QR" src={qr} />
                                    ) : (
                                       <QRCode
                                          value={companyLink}
                                          style={{ maxWidth: 160, height: 140 }}
                                          viewBox={`0 0 256 256`}
                                       />
                                    )}
                                 </Box>
                              </div>

                              <Button
                                 variant="contained"
                                 sx={{ bgcolor: "#142c44" }}
                                 onClick={companySelected?.subName ? downloadQRCode : handleClickPopper}
                              >
                                 <SystemUpdateAltIcon sx={{ mr: 1 }} />
                                 Descargar
                              </Button>
                           </Box>
                        </Grid>
                        {/* Enlace a plataforma */}
                        <Grid item xs={12} md={12} lg={4}>
                           <Box
                              display={"flex"}
                              flexDirection={"column"}
                              alignItems={"center"}
                              sx={{ bgcolor: "white", p: 1.5, minHeight: "100%", boxShadow: 2 }}
                           >
                              <Box sx={{ minHeight: 50 }}>
                                 <Typography fontWeight={600} fontSize={18}>
                                    Enlace a plataforma
                                 </Typography>
                              </Box>

                              <Box
                                 sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    mb: 2,
                                 }}
                              >
                                 <Box component={"img"} sx={{ maxWidth: 160, height: 140 }} alt="LINK" src={link} />
                              </Box>
                              <Button
                                 variant="contained"
                                 sx={{ bgcolor: "#142c44" }}
                                 onClick={handleClickPopper}
                                 className="link"
                                 id="link"
                              >
                                 <VisibilityIcon sx={{ mr: 1 }} />
                                 Ver enlace
                              </Button>
                              <PositionedPopper
                                 anchorEl={anchorElPopper}
                                 setAnchorEl={setAnchorElPopper}
                                 placement="bottom"
                              >
                                 <Box>
                                    <Box
                                       sx={{
                                          overflow: "visible",
                                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                          mt: 1.5,
                                          py: 1,
                                          boxShadow: 1,
                                          bgcolor: "white",
                                          display: "flex",
                                          flexDirection: "row",
                                          borderRadius: 1,
                                          "&:before": {
                                             content: '""',
                                             display: "block",
                                             position: "absolute",
                                             top: 0,
                                             right: "50%",
                                             width: 10,
                                             height: 10,
                                             bgcolor: "white",
                                             border: 1,
                                             opacity: 0.7,
                                             transform: "translateY(-50%) rotate(45deg)",
                                             zIndex: 0,
                                          },
                                       }}
                                    >
                                       <Typography
                                          sx={{
                                             display: "flex",
                                             alignItems: "center",
                                             textAlign: "right",
                                             px: 2,
                                          }}
                                       >
                                          {companySelected?.subName ? companyLink : "Selecciona una empresa"}
                                       </Typography>
                                       {companySelected?.subName ? (
                                          <Button
                                             onClick={() => {
                                                setCopyClipboard(true);
                                                navigator.clipboard.writeText(companyLink);
                                                setTimeout(() => {
                                                   setCopyClipboard(false);
                                                }, 2000);
                                             }}
                                          >
                                             {copyClipboard ? (
                                                <CheckIcon color="success" fontSize="small" />
                                             ) : (
                                                <ContentCopyIcon fontSize="small" />
                                             )}
                                          </Button>
                                       ) : (
                                          <></>
                                       )}
                                    </Box>
                                 </Box>
                              </PositionedPopper>
                           </Box>
                        </Grid>
                        {/* Envió por correo electrónico */}
                        <Grid item xs={12} md={12} lg={4}>
                           <Box
                              display={"flex"}
                              flexDirection={"column"}
                              alignItems={"center"}
                              sx={{ bgcolor: "white", p: 1.5, boxShadow: 2, minHeight: "100%" }}
                           >
                              <Box sx={{ minHeight: 50 }}>
                                 <Typography fontWeight={600} fontSize={18} align="center">
                                    Enviar por correo electrónico
                                 </Typography>
                              </Box>

                              <Box
                                 sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    mb: 2,
                                 }}
                              >
                                 <Box component={"img"} sx={{ maxWidth: 160, height: 140 }} alt="MAIL" src={mail} />
                              </Box>
                              <Button variant="contained" sx={{ bgcolor: "#142c44" }} onClick={handleClick}>
                                 Agregar información
                              </Button>
                              <Popover
                                 id={id}
                                 open={open}
                                 anchorEl={anchorEl}
                                 onClose={handleClose}
                                 anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                 }}
                                 transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                 }}
                              >
                                 <Box sx={{ display: "flex", flexDirection: "column", p: 2, minWidth: 320 }}>
                                    <Formik
                                       initialValues={{
                                          email: "",
                                          message: string,
                                       }}
                                       validationSchema={disseminationsMethods}
                                       onSubmit={handleSendMail}
                                    >
                                       <Form>
                                          <Typography fontWeight={600}>Correo electrónico</Typography>
                                          <InputTextField
                                             placeholder="ejemplo@lecosy.com.mx"
                                             size="small"
                                             type="email"
                                             name="email"
                                             id="email"
                                             fullWidth={true}
                                          />
                                          <Typography fontWeight={600}>Mensaje</Typography>
                                          <InputTextField
                                             placeholder={string}
                                             name="message"
                                             size="small"
                                             type="text"
                                             id="message"
                                             fullWidth={true}
                                             multiline={true}
                                             rows={3}
                                             sx={{ mt: 2 }}
                                          />
                                          {!companySelected?.subName ? (
                                             <Typography sx={{ py: 1, textAlign: "center" }}>
                                                Selecciona una empresa
                                             </Typography>
                                          ) : (
                                             <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                                <Button
                                                   sx={{
                                                      backgroundColor: "#162c44",
                                                      color: "#fff",
                                                      mx: 2,
                                                      mt: 2,
                                                      "&:hover": { backgroundColor: "#162c44" },
                                                      width: "80px",
                                                   }}
                                                   type="submit"
                                                >
                                                   {sendEmailIsLoading ? (
                                                      <CircularProgress size={"25px"} sx={{ color: "#fff" }} />
                                                   ) : (
                                                      "Enviar"
                                                   )}
                                                </Button>
                                             </Box>
                                          )}
                                       </Form>
                                    </Formik>
                                 </Box>
                              </Popover>
                           </Box>
                        </Grid>
                     </Grid>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} sx={{ minWidth: "100%", p: 1 }}>
                     <ArrowRightIcon fontSize="large" />
                     <Typography color={"#142c44"}>Campañas de difusión</Typography>
                  </Box>
                  <Box display={"flex"} sx={{}}>
                     <Grid container item xs={12} spacing={3}>
                        {/* Conoce las políticas canal de denuncia*/}
                        <Grid item xs={12} md={12} lg={4}>
                           <Box
                              display={"flex"}
                              flexDirection={"column"}
                              alignItems={"center"}
                              sx={{ bgcolor: "white", p: 1.5, minHeight: "100%", boxShadow: 2 }}
                           >
                              <Box sx={{ minHeight: 50 }}>
                                 <Typography fontWeight={600} fontSize={18} align="center">
                                    Conoce las políticas canal de denuncia
                                 </Typography>
                              </Box>
                              <Box
                                 sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    mb: 3,
                                 }}
                              >
                                 {!flyersSrcIsLoading ? (
                                    flyerPoliciesImageBlob ? (
                                       <Box
                                          component={"img"}
                                          sx={{ maxWidth: 160, height: 140 }}
                                          alt="politicas"
                                          src={URL.createObjectURL(flyerPoliciesImageBlob)}
                                       />
                                    ) : (
                                       <Box
                                          display={"flex"}
                                          justifyContent="center"
                                          alignItems={"center"}
                                          sx={{ height: 140, width: 140, bgcolor: "gainsboro" }}
                                       >
                                          <HideImageIcon color="action" />
                                       </Box>
                                    )
                                 ) : (
                                    <CircularProgress />
                                 )}
                              </Box>
                              <Button
                                 variant="contained"
                                 sx={{ bgcolor: "#142c44" }}
                                 onClick={() => {
                                    if (flyerPoliciesImageBlob)
                                       window.open(URL.createObjectURL(flyerPoliciesImageBlob), "_blank");
                                 }}
                              >
                                 Abrir
                              </Button>
                           </Box>
                        </Grid>
                        {/* Medios para interponer denuncia */}
                        <Grid item xs={12} md={12} lg={4}>
                           <Box
                              display={"flex"}
                              flexDirection={"column"}
                              alignItems={"center"}
                              sx={{ bgcolor: "white", p: 1.5, minHeight: "100%", boxShadow: 2 }}
                           >
                              <Box sx={{ minHeight: 50 }}>
                                 <Typography fontWeight={600} fontSize={18} align="center">
                                    Medios para interponer denuncia
                                 </Typography>
                              </Box>
                              <Box
                                 sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    mb: 3,
                                 }}
                              >
                                 {!flyersSrcIsLoading ? (
                                    flyerMediaImageBlob ? (
                                       <Box
                                          component={"img"}
                                          sx={{ maxWidth: 160, height: 140 }}
                                          alt="politicas"
                                          src={URL.createObjectURL(flyerMediaImageBlob)}
                                       />
                                    ) : (
                                       <Box
                                          display={"flex"}
                                          justifyContent="center"
                                          alignItems={"center"}
                                          sx={{ height: 140, width: 140, bgcolor: "gainsboro" }}
                                       >
                                          <HideImageIcon color="action" />
                                       </Box>
                                    )
                                 ) : (
                                    <CircularProgress />
                                 )}
                              </Box>
                              <Button
                                 variant="contained"
                                 sx={{ bgcolor: "#142c44" }}
                                 onClick={() => {
                                    if (flyerMediaImageBlob)
                                       window.open(URL.createObjectURL(flyerMediaImageBlob), "_blank");
                                 }}
                              >
                                 Abrir
                              </Button>
                           </Box>
                        </Grid>
                        {/* Proceso de gestión de denuncia */}
                        <Grid item xs={12} md={12} lg={4}>
                           <Box
                              display={"flex"}
                              flexDirection={"column"}
                              alignItems={"center"}
                              sx={{ bgcolor: "white", p: 1.5, minHeight: "100%", boxShadow: 2 }}
                           >
                              <Box sx={{ minHeight: 50 }}>
                                 <Typography fontWeight={600} fontSize={18} align="center">
                                    Proceso de gestión de denuncia
                                 </Typography>
                              </Box>
                              <Box
                                 sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    mb: 3,
                                 }}
                              >
                                 {!flyersSrcIsLoading ? (
                                    flyerProcessImageBlob ? (
                                       <Box
                                          component={"img"}
                                          sx={{ maxWidth: 160, height: 140 }}
                                          alt="politicas"
                                          src={URL.createObjectURL(flyerProcessImageBlob)}
                                       />
                                    ) : (
                                       <Box
                                          display={"flex"}
                                          justifyContent="center"
                                          alignItems={"center"}
                                          sx={{ height: 140, width: 140, bgcolor: "gainsboro" }}
                                       >
                                          <HideImageIcon color="action" />
                                       </Box>
                                    )
                                 ) : (
                                    <CircularProgress />
                                 )}
                              </Box>
                              <Button
                                 variant="contained"
                                 sx={{ bgcolor: "#142c44" }}
                                 onClick={() => {
                                    if (flyerProcessImageBlob)
                                       window.open(URL.createObjectURL(flyerProcessImageBlob), "_blank");
                                 }}
                              >
                                 Abrir
                              </Button>
                           </Box>
                        </Grid>
                     </Grid>
                  </Box>
               </Grid>
            </Grid>
         </Container>
      </Box>
   );
};
