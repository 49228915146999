import { Typography, Box } from "@mui/material";
import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

interface SearchbarSelectorProps {
   users: Array<{
      _id: string;
      firstName: string;
      lastName: string;
      name?: string;
      isManualEntry?: boolean;
   }>;
   label?: string;
   placeholder?: string;
   isLoading: boolean;
   setSelectedUser: Function;
   setReset?: Function;
   disabled?: boolean;
   initialSelectedUser?: {
      _id: string;
      firstName: string;
      lastName: string;
      name?: string;
      isManualEntry?: boolean;
   } | null;
}

export const SearchbarSelector = (props: SearchbarSelectorProps) => {
   const [value, setValue] = useState<any>(null);
   const [options, setOptions] = useState<
      Array<{
         _id: string;
         firstName: string;
         lastName: string;
         name?: string;
         isManualEntry?: boolean;
      }>
   >([]);

   useEffect(() => {
      let updatedOptions = [...(props.users || [])];

      if (props.initialSelectedUser?.isManualEntry) {
         if (!updatedOptions.some((u) => u.isManualEntry && u.firstName === props.initialSelectedUser?.firstName)) {
            updatedOptions = [...updatedOptions, props.initialSelectedUser];
         }
      }

      setOptions(updatedOptions);
   }, [props.users, props.initialSelectedUser]);

   useEffect(() => {
      if (props.initialSelectedUser) {
         setValue(props.initialSelectedUser);
      }
   }, [props.initialSelectedUser]);

   const filterOptions = (options, { inputValue }) => {
      if (!inputValue) return options;

      const filtered = options.filter((user) =>
         `${user?.firstName || ""} ${user?.lastName || ""}`.toLowerCase().includes(inputValue.toLowerCase())
      );

      if (inputValue && filtered.length === 0) {
         return [
            {
               firstName: inputValue,
               lastName: "",
               _id: "",
               isManualEntry: true,
               name: inputValue,
            },
         ];
      }

      return filtered;
   };

   const getFullName = (user) => {
      if (!user) return "";
      return `${user.firstName || ""} ${user.lastName || ""}`.trim();
   };

   return (
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
         <Autocomplete
            size="small"
            options={options}
            loading={props.isLoading}
            disabled={props.disabled}
            filterOptions={filterOptions}
            value={value}
            getOptionLabel={(user) => {
               if (!user) return "";
               return !user._id ? `Agregar usuario: "${getFullName(user)}"` : getFullName(user);
            }}
            isOptionEqualToValue={(option, value) => {
               if (option._id && value._id) {
                  return option._id === value._id;
               } else if (option.isManualEntry || value.isManualEntry) {
                  const optionName = option.name || option.firstName || "";
                  const valueName = value.name || value.firstName || "";
                  return optionName === valueName;
               }
               return false;
            }}
            onChange={(event, newValue) => {
               setValue(newValue);
               props.setSelectedUser(newValue);
            }}
            renderOption={(optionProps, user) => (
               <li {...optionProps}>
                  <Typography>{!user._id ? `Agregar usuario: "${getFullName(user)}"` : getFullName(user)}</Typography>
               </li>
            )}
            renderInput={(params) => (
               <TextField
                  {...params}
                  label={props.label}
                  placeholder={props.placeholder}
                  sx={{
                     bgcolor: "#F5F5F5",
                     "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#E0E0E0",
                     },
                  }}
               />
            )}
         />
      </Box>
   );
};
