import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { PowersGrantedDrawer } from "./PowersGrantedDrawer";
import { useParams } from "react-router-dom";
import { getPowerGrantedByCompany } from "../../../lib/usersBEClient";

const PowersGranted = () => {
   const [powers, setPowers] = useState([]);
   const { companyId } = useParams();

   useEffect(() => {
      const fetchPowers = async () => {
         try {
            const powersData = await getPowerGrantedByCompany(companyId);
            setPowers(powersData || []);
         } catch (error) {
            console.error("Error fetching powers:", error);
         }
      };
      fetchPowers();
   }, [companyId]);

   const getPowerCount = (powerType: string) => {
      return powers.filter((power) => power.powerGranted === powerType).length;
   };
   const commonBoxStyle = {
      bgcolor: "#f2f3f5",
      p: 1,
      borderRadius: 1,
      width: "100%",
      height: "40px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& .MuiTypography-root": {
         fontSize: "13px",
      },
   };

   const leftGridStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      bgcolor: "white",
      width: "88%",
      pl: 1,
      py: 0.5,
   };

   const rightGridStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      bgcolor: "white",
      width: "12%",
      pr: 1,
      py: 0.5,
   };

   const PODERES = [
      "Poder general para pleitos y cobranzas",
      "Poder general para actos de administración",
      "Poder en materia laboral",
      "Poder general para actos de dominio",
      "Poder para suscribir títulos y operaciones de crédito",
      "Poder para efectos bancarios y cambiarios",
      "Poder para otorgar y delegar poderes generales o especiales",
      "Facultades para ejercitar actos de administración aduanal",
      "Poder general para llevar a cabo actos de rescisión",
      "Poder para efectos fiscales",
   ];

   return (
      <Box
         sx={{
            width: "100%",
            pb: 1,
            border: "1px solid #ccc",
            borderRadius: "8px",
            overflow: "hidden",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
         }}
      >
         <Grid
            item
            sx={{
               bgcolor: "#162c44",
               p: 2,
               width: "100%",
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               height: 40,
               borderRadius: "5px 5px 0 0",
               mb: 1,
            }}
         >
            <Typography sx={{ color: "white", fontWeight: "bold", fontSize: 18, flex: 1, textAlign: "center" }}>
               Poderes otorgados
            </Typography>
            <PowersGrantedDrawer />
         </Grid>
         <Box
            sx={{
               minHeight: "200px",
               maxHeight: "200px",
               overflow: "auto",
            }}
         >
            {PODERES.map((poder, index) => {
               const powerCount = getPowerCount(poder);

               if (powerCount === 0) return null;

               return (
                  <Grid key={index} container sx={{ width: "100%", m: 0, flexWrap: "nowrap", gap: 0.3 }}>
                     <Grid item sx={leftGridStyle}>
                        <Box sx={commonBoxStyle}>
                           <Typography>{poder}</Typography>
                        </Box>
                     </Grid>
                     <Grid item sx={rightGridStyle}>
                        <Box sx={commonBoxStyle}>
                           <Typography>{powerCount}</Typography>
                        </Box>
                     </Grid>
                  </Grid>
               );
            })}
         </Box>
      </Box>
   );
};

export default PowersGranted;
