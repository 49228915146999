import IconButton from "@mui/material/IconButton";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Button from "@mui/material/Button";
import { Grid, Typography, Box, Popover, ListItem, Modal, Menu, MenuItem } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { AddCircle, Add, Remove, Close, FormatListBulletedRounded } from "@mui/icons-material";
import AddPowerModal from "./Subcomponents/AddPowerModal";
import { useParams } from "react-router-dom";
import { HeadCell, TableComponent } from "../../TableComponent";
import SearchbarPowers from "./Subcomponents/SearchbarPowers";
import { SnackBarContext } from "../../../context/snackBarContext";
import PowersModifyModal from "./Subcomponents/PowersModifyModal";
import PowersDeleteConfirmationModal from "./Subcomponents/PowersDeleteConfirmationModal";
import { getCompanyById, getPowerGrantedByCompany, getUrlS3, deletePowerGranted } from "../../../lib/usersBEClient";
import Sample from "../../Gobierno corporativo/PDFVisualizer/PDFViewer";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { IPowerGranted } from "../../../types/beneficiaryTypes";
import { CustomDrawerComponent } from "../../CustomDrawerComponent";

interface FileDocument {
   name: string;
   size: string;
}

interface User {
   firstName?: string;
   lastName?: string;
}

interface TablePowerGranted {
   _id: string;
   powerGranted: string;
   grantDate: string;
   expirationDate: string;
   company: string;
   representativeName: string;
   file: FileDocument;
   user: User | string;
   nombrePoder: string;
}

const TableHeaders: HeadCell[] = [
   { field: "nombrePoder", headerName: "Nombre del poder", type: "string", align: "center" },
   { field: "apoderado", headerName: "Apoderado", type: "string", align: "center" },
   { field: "otorgamiento", headerName: "Otorgamiento", type: "date", align: "center" },
   { field: "vencimiento", headerName: "Vencimiento", type: "date", align: "center" },
   { field: "documento", headerName: "Documento", type: "string", align: "center" },
   { field: "acciones", headerName: "Acciones", type: "string", align: "center" },
];

const PODERES = [
   "Poder general para pleitos y cobranzas",
   "Poder general para actos de administración",
   "Poder en materia laboral",
   "Poder general para actos de dominio",
   "Poder para suscribir títulos y operaciones de crédito",
   "Poder para efectos bancarios y cambiarios",
   "Poder para otorgar y delegar poderes generales o especiales",
   "Facultades para ejercitar actos de administración aduanal",
   "Poder general para llevar a cabo actos de rescisión",
   "Poder para efectos fiscales",
];

export const PowersGrantedDrawer = () => {
   const [open, setOpen] = useState(false);
   const [filterAnchorEl, setFilterAnchorEl] = useState(null);
   const [filteredRows, setFilteredRows] = useState([]);
   const [searchFilteredRows, setSearchFilteredRows] = useState([]);
   const [openModal, setOpenModal] = useState(false);
   const [power2, setPower2] = useState<TablePowerGranted[]>(null);
   const { companyId } = useParams();
   const [selected, setSelected] = useState<readonly string[]>([]);
   const [companyName, setCompanyName] = useState<string>("");
   const [actionAnchorEl, setActionAnchorEl] = useState<null | HTMLElement>(null);
   const [selectedPower, setSelectedPower] = useState<IPowerGranted | null>(null);
   const [powerIdToDelete, setPowerIdToDelete] = useState<string | null>(null);
   const [modifyModalOpen, setModifyModalOpen] = useState(false);
   const [showPdfPreview, setShowPdfPreview] = useState(false);
   const [selectedFile, setSelectedFile] = useState(null);
   const [deleteModalOpen, setDeleteModalOpen] = useState(false);
   const [selectedPowerName, setSelectedPowerName] = useState("");
   const [isDeleting, setIsDeleting] = useState(false);
   const { showSnackBar } = useContext(SnackBarContext);
   const [zoom, setZoom] = useState<number>(1);

   const PdfPreviewModal = () => {
      if (!selectedFile) return null;

      return (
         <Modal open={showPdfPreview} style={{ backgroundColor: "#00000050" }}>
            <Box sx={{ overflow: "auto", maxHeight: "100vh" }}>
               <Box
                  sx={{
                     position: "absolute",
                     bottom: 15,
                     left: "50%",
                     transform: "translateX(-50%)",
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                     bgcolor: "rgba(255, 255, 255, 0.8)",
                     borderRadius: 1,
                     p: 1,
                     boxShadow: 3,
                     zIndex: 1000,
                  }}
               >
                  <Button
                     variant="contained"
                     onClick={() => setZoom((prevZoom) => Math.max(prevZoom - 0.1, 0.5))}
                     sx={{ mx: 1 }}
                  >
                     <Remove />
                  </Button>
                  <Typography variant="body1" sx={{ mx: 1 }}>
                     Zoom: {(zoom * 100).toFixed(0)}%
                  </Typography>
                  <Button
                     variant="contained"
                     onClick={() => setZoom((prevZoom) => Math.min(prevZoom + 0.1, 2))}
                     sx={{ mx: 1 }}
                  >
                     <Add />
                  </Button>
               </Box>

               <Box sx={{ display: "flex", justifyContent: "flex-end", position: "absolute", right: 20, top: 0 }}>
                  <IconButton
                     onClick={() => {
                        setShowPdfPreview(false);
                        setSelectedFile(null);
                     }}
                  >
                     <Close sx={{ color: "white", fontSize: "37px" }} />
                  </IconButton>
               </Box>
               <Sample file={selectedFile.url} zoom={zoom} />
            </Box>
         </Modal>
      );
   };

   const handleFileClick = async (power) => {
      try {
         if (!power.file) {
            showSnackBar("No hay documento disponible para este poder", true);
            return;
         }

         let fileId, fileName;

         if (typeof power.file === "object") {
            fileId = power.file._id || power.file.id || "";
            fileName = power.file.name || power.file.originalName || "";
         } else if (typeof power.file === "string") {
            fileId = power.file;
            fileName = `${power._id}.pdf`;
         }

         try {
            const fileUrl = await getUrlS3(
               "files-lecosy",
               { folder: `gc/companies/${companyId}/poderes/${power.file.folder}` },
               `${fileName}`
            );

            if (fileUrl) {
               setSelectedFile({
                  name: fileName,
                  url: fileUrl,
                  size: typeof power.file === "object" ? power.file.size || "" : "",
               });
               setShowPdfPreview(true);
            } else {
               showSnackBar("No se pudo obtener la URL del archivo", true);
            }
         } catch (error) {
            showSnackBar("Error al cargar archivo", true);
         }
      } catch (error) {
         showSnackBar("Error al cargar archivo", true);
      }
   };

   const formatTableData = (powerData) => {
      return (
         powerData?.map((power2) => {
            const hasFile = Boolean(power2.file);

            return {
               ...power2,
               nombrePoder: power2.powerGranted,
               apoderado: power2.user
                  ? `${power2.user.firstName || ""} ${power2.user.lastName || ""}`
                  : power2.representativeName,
               otorgamiento: power2.grantDate,
               vencimiento: power2.expirationDate,
               documento: (
                  <IconButton
                     onClick={(e) => {
                        e.stopPropagation();
                        handleFileClick(power2);
                     }}
                     sx={{ color: hasFile ? "#3F5063" : "#cccccc" }}
                     disabled={!hasFile}
                  >
                     <InsertDriveFileIcon />
                  </IconButton>
               ),
               acciones: (
                  <IconButton onClick={(e) => handleActionClick(e, power2)} sx={{ color: "#808080" }}>
                     <MoreVertIcon />
                  </IconButton>
               ),
            };
         }) || []
      );
   };

   const fetchPower = async () => {
      try {
         const power = await getPowerGrantedByCompany(companyId);
         const formattedPower = formatTableData(power);
         const company = await getCompanyById(companyId);
         setPower2(formattedPower);
         setFilteredRows(formattedPower);
         setSearchFilteredRows(formattedPower);
         setCompanyName(company.data.person_details.businessName);
      } catch (error) {
         console.error("Error fetching power data:", error);
      }
   };

   useEffect(() => {
      fetchPower();
   }, [companyId]);

   const toggleDrawer = (isOpen) => () => setOpen(isOpen);
   const handleFilterClick = (event) => setFilterAnchorEl(event.currentTarget);
   const handleFilterClose = () => setFilterAnchorEl(null);
   const filterOpen = Boolean(filterAnchorEl);
   const filterId = filterOpen ? "filter-popover" : undefined;

   const handleFilterByPoder = (nombrePoder) => {
      if (!power2) return;

      if (nombrePoder === "Todos") {
         setFilteredRows(power2);
         setSearchFilteredRows(power2);
      } else {
         const filtered = power2.filter((row) => row.nombrePoder === nombrePoder);
         setFilteredRows(filtered);
         setSearchFilteredRows(filtered);
      }
      handleFilterClose();
   };

   const handleActionClick = (event, power) => {
      setActionAnchorEl(event.currentTarget);
      setSelectedPower(power);
   };

   const handleActionClose = () => {
      setActionAnchorEl(null);
      setSelectedPower(null);
   };

   const handleModify = () => {
      setModifyModalOpen(true);
   };

   const handleDelete = () => {
      if (!selectedPower) return;

      let powerName = "";
      if (typeof selectedPower.user === "object" && selectedPower.user !== null) {
         powerName = `${selectedPower.user.firstName || ""} ${selectedPower.user.lastName || ""}`.trim();
      } else {
         powerName = selectedPower.representativeName || "";
      }

      setPowerIdToDelete(selectedPower._id);
      setSelectedPowerName(powerName);
      setDeleteModalOpen(true);
      handleActionClose();
   };

   const handleConfirmDelete = async () => {
      try {
         if (!powerIdToDelete) {
            showSnackBar("No power selected", false);
            return;
         }

         setIsDeleting(true);
         await deletePowerGranted(powerIdToDelete);
         await fetchPower();
         showSnackBar("Poder eliminado correctamente", false);
      } catch (error) {
         showSnackBar("Error al eliminar el poder: " + (error.message || "Unknown error"), true);
      } finally {
         setIsDeleting(false);
         setDeleteModalOpen(false);
         setPowerIdToDelete(null);
      }
   };

   return (
      <div>
         <IconButton sx={{ color: "white" }} onClick={toggleDrawer(true)}>
            <FormatListBulletedRounded
               sx={{ fontSize: 20, color: "#152C44", bgcolor: "white", borderRadius: 1, p: 0.2 }}
            />
         </IconButton>
         <CustomDrawerComponent title="Lista de poderes otorgados" open={open} setOpen={setOpen} width={1100}>
            <div>
               <Grid
                  container
                  direction="row"
                  sx={{
                     width: "100%",
                     overflow: "hidden",
                     "& .MuiGrid-root": { margin: 0 },
                     marginTop: "35px",
                     display: "flex",
                     alignItems: "left",
                     justifyContent: "left",
                     marginBottom: "20px",
                  }}
               >
                  <Grid
                     item
                     sx={{
                        bgcolor: "#F0F0F0",
                        width: "34%",
                        height: 40,
                        margin: "auto",
                        border: "2px solid #F0F0F0",
                        borderTopRightRadius: "10px",
                        justifyContent: "flex-start",
                        textAlign: "left",
                        marginBottom: "40px",
                     }}
                  >
                     <Typography variant="h6" sx={{ color: "#3F5063", marginLeft: "20px", fontSize: 20 }}>
                        Poderante :<strong style={{ fontWeight: "bold", fontSize: 20 }}> {companyName} </strong>
                     </Typography>
                  </Grid>
               </Grid>
               <Grid container sx={{ width: "100%", px: "20px" }}>
                  <Box sx={{ display: "flex", gap: 2, width: "100%", alignItems: "center" }}>
                     <Button
                        variant="contained"
                        endIcon={<FilterAltIcon />}
                        onClick={handleFilterClick}
                        sx={{
                           textTransform: "none",
                           bgcolor: "#64748B",
                           height: 40,
                           minWidth: 150,
                           borderRadius: "5px",
                           fontSize: 18,
                        }}
                     >
                        Filtrar
                     </Button>

                     <SearchbarPowers
                        originalRows={filteredRows}
                        filteredRows={searchFilteredRows}
                        setFilteredRows={setSearchFilteredRows}
                        fullWidth
                     />

                     <Button
                        variant="contained"
                        onClick={() => setOpenModal(true)}
                        sx={{
                           textTransform: "none",
                           bgcolor: "#162c44",
                           height: 40,
                           minWidth: 150,
                           borderRadius: "5px",
                        }}
                     >
                        <AddCircle sx={{ marginRight: 1 }} />
                        Agregar registro
                     </Button>
                  </Box>

                  <Popover
                     id={filterId}
                     open={filterOpen}
                     anchorEl={filterAnchorEl}
                     onClose={handleFilterClose}
                     anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                     transformOrigin={{ vertical: "top", horizontal: "left" }}
                     PaperProps={{ sx: { marginTop: 0.5 } }}
                  >
                     <Box sx={{ p: 1, width: 600 }}>
                        <Box sx={{ borderBottom: "1px solid #ccc", pb: 0.25, mb: 0.5 }}>
                           <ListItem
                              button
                              onClick={() => handleFilterByPoder("Todos")}
                              sx={{
                                 display: "flex",
                                 justifyContent: "space-between",
                                 borderRadius: "5px",
                                 padding: 0.5,
                                 mb: 0.5,
                                 "&:hover": { bgcolor: "#E0E0E0" },
                              }}
                           >
                              <Box sx={{ flex: 1, bgcolor: "#F1F3F4", borderRadius: "5px", p: 0.75 }}>
                                 <Typography
                                    sx={{
                                       fontWeight: "bold",
                                       whiteSpace: "nowrap",
                                       overflow: "hidden",
                                       textOverflow: "ellipsis",
                                    }}
                                 >
                                    Todos los poderes
                                 </Typography>
                              </Box>
                              <Box
                                 sx={{
                                    bgcolor: "#F1F3F4",
                                    borderRadius: "5px",
                                    p: 0.75,
                                    ml: 1,
                                    width: 60,
                                    textAlign: "center",
                                 }}
                              >
                                 {power2?.length || 0}
                              </Box>
                           </ListItem>
                        </Box>

                        <Box sx={{ maxHeight: 350, overflow: "auto" }}>
                           {PODERES.map((poder) => {
                              const poderCount = power2?.filter((row) => row.nombrePoder === poder).length || 0;
                              if (poderCount === 0) return null;

                              return (
                                 <ListItem
                                    button
                                    key={poder}
                                    onClick={() => handleFilterByPoder(poder)}
                                    sx={{
                                       display: "flex",
                                       justifyContent: "space-between",
                                       borderRadius: "5px",
                                       padding: 0.5,
                                       mb: 0.5,
                                       "&:hover": { bgcolor: "#E0E0E0" },
                                    }}
                                 >
                                    <Box sx={{ flex: 1, bgcolor: "#F1F3F4", borderRadius: "5px", p: 0.75 }}>
                                       <Typography
                                          sx={{
                                             whiteSpace: "nowrap",
                                             overflow: "hidden",
                                             textOverflow: "ellipsis",
                                          }}
                                       >
                                          {poder}
                                       </Typography>
                                    </Box>
                                    <Box
                                       sx={{
                                          bgcolor: "#F1F3F4",
                                          borderRadius: "5px",
                                          p: 0.75,
                                          ml: 1,
                                          width: 60,
                                          textAlign: "center",
                                       }}
                                    >
                                       {poderCount}
                                    </Box>
                                 </ListItem>
                              );
                           })}
                        </Box>
                     </Box>
                  </Popover>
               </Grid>
               <Grid container direction="column" sx={{ width: "100%", mt: 3, px: "20px" }}>
                  <TableComponent
                     headerColor
                     checkboxSelection={true}
                     multiselect={true}
                     setSelected={setSelected}
                     selectedRows={selected}
                     headers={TableHeaders}
                     defaultColumnToOrder=""
                     defaultOrder="asc"
                     defaultRowsPerPage={10}
                     rowsPerPageOptions={[10, 15, 20]}
                     upRows={[]}
                     rows={searchFilteredRows}
                     loader={false}
                     emptyDataText="No se encontraron poderes"
                  />
               </Grid>

               <Menu
                  anchorEl={actionAnchorEl}
                  open={Boolean(actionAnchorEl)}
                  onClose={handleActionClose}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
               >
                  <MenuItem onClick={handleModify} sx={{ minWidth: 120 }}>
                     Modificar
                  </MenuItem>
                  <MenuItem onClick={handleDelete} sx={{ minWidth: 120, color: "error.main" }}>
                     Borrar
                  </MenuItem>
               </Menu>

               <AddPowerModal open={openModal} setOpen={setOpenModal} onSuccess={fetchPower} />
               <PowersModifyModal
                  open={modifyModalOpen}
                  setOpen={setModifyModalOpen}
                  power={selectedPower}
                  onUpdate={fetchPower}
               />
               <PdfPreviewModal />
               <PowersDeleteConfirmationModal
                  open={deleteModalOpen}
                  setOpen={setDeleteModalOpen}
                  onConfirm={handleConfirmDelete}
                  userName={selectedPowerName}
                  loading={isDeleting}
               />
            </div>
         </CustomDrawerComponent>
      </div>
   );
};
