import React from "react";
import { Grid, Typography, Box } from "@mui/material";

const InfoRow = ({ title, content }) => {
   return (
      <Grid
         item
         direction="column"
         xs={3}
         sx={{
            p: 2,
            display: "flex",
            alignItems: "center",
            bgcolor: "white",
            justifyContent: "space-between",
            borderRight: "2px solid white",
         }}
      >
         <Typography variant="subtitle2" sx={{ mr: 2, flex: 1, fontWeight: "bold" }}>
            {title}
         </Typography>
         <Box
            sx={{
               bgcolor: "#f2f3f5",
               p: 1,
               borderRadius: 1,
               width: "100%",
               height: "30px",
               display: "flex",
               alignItems: "center",
               justifyContent: "center",
            }}
         >
            <Typography>{content}</Typography>
         </Box>
      </Grid>
   );
};

export default InfoRow;
