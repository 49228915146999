import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const PowersGrantedaux = () => {
   const commonBoxStyle = {
      bgcolor: "#f2f3f5",
      p: 1,
      borderRadius: 1,
      width: "100%",
      height: "40px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& .MuiTypography-root": {
         fontSize: "13px",
      },
   };

   const leftGridStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      bgcolor: "white",
      width: "88%",
      pl: 1,
      py: 0.5,
   };

   const rightGridStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      bgcolor: "white",
      width: "12%",
      pr: 1,
      py: 0.5,
   };

   return (
      <Box
         sx={{
            width: "100%",
            pb: 1,
            border: "1px solid #ccc",
            borderRadius: "8px",
            overflow: "hidden",
         }}
      >
         <Grid
            item
            sx={{
               bgcolor: "#162c44",

               width: "100%",
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               height: 31,
               borderRadius: "5px 5px 0 0",
               m: 0,
               mb: 1,
            }}
         >
            <Typography variant="h6" sx={{ color: "white", fontWeight: "bold", fontSize: "15px" }}>
               Poderes Otorgados
            </Typography>
            <Box>{/* <ListDrawerBeneficiary /> */}</Box>
         </Grid>
         <Box
            sx={{
               minHeight: "100px",
               maxHeight: "145px",
               overflow: "auto",
            }}
         >
            <Grid container sx={{ width: "100%", m: 0, flexWrap: "nowrap", gap: 0.3 }}>
               <Grid item sx={leftGridStyle}>
                  <Box sx={commonBoxStyle}>
                     <Typography>Poder general para pleitos y cobranzas</Typography>
                  </Box>
               </Grid>
               <Grid item sx={rightGridStyle}>
                  <Box sx={commonBoxStyle}>
                     <Typography>--</Typography>
                  </Box>
               </Grid>
            </Grid>
            <Grid container sx={{ width: "100%", m: 0, flexWrap: "nowrap", gap: 0.3 }}>
               <Grid item sx={leftGridStyle}>
                  <Box sx={commonBoxStyle}>
                     <Typography>Poder para Actos de Administracion</Typography>
                  </Box>
               </Grid>
               <Grid item sx={rightGridStyle}>
                  <Box sx={commonBoxStyle}>
                     <Typography>--</Typography>
                  </Box>
               </Grid>
            </Grid>
            <Grid container sx={{ width: "100%", m: 0, flexWrap: "nowrap", gap: 0.3 }}>
               <Grid item sx={leftGridStyle}>
                  <Box sx={commonBoxStyle}>
                     <Typography>Poder en Materia Laboral</Typography>
                  </Box>
               </Grid>
               <Grid item sx={rightGridStyle}>
                  <Box sx={commonBoxStyle}>
                     <Typography>--</Typography>
                  </Box>
               </Grid>
            </Grid>
            <Grid container sx={{ width: "100%", m: 0, flexWrap: "nowrap", gap: 0.3 }}>
               <Grid item sx={leftGridStyle}>
                  <Box sx={commonBoxStyle}>
                     <Typography>Poder General para Actos de Dominio</Typography>
                  </Box>
               </Grid>
               <Grid item sx={rightGridStyle}>
                  <Box sx={commonBoxStyle}>
                     <Typography>--</Typography>
                  </Box>
               </Grid>
            </Grid>
            <Grid container sx={{ width: "100%", m: 0, flexWrap: "nowrap", gap: 0.3 }}>
               <Grid item sx={leftGridStyle}>
                  <Box sx={commonBoxStyle}>
                     <Typography>Poder General para Actos de Dominio</Typography>
                  </Box>
               </Grid>
               <Grid item sx={rightGridStyle}>
                  <Box sx={commonBoxStyle}>
                     <Typography>--</Typography>
                  </Box>
               </Grid>
            </Grid>
         </Box>
      </Box>
   );
};

export default PowersGrantedaux;
