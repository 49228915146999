import { useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Grid, Typography, CircularProgress, Divider, Container, IconButton } from "@mui/material";
import { NotificationDocumentsBar } from "../../../../components/notificationsDocumentsBar";
import { createDocumentPdf } from "../../../../lib/uploadDocuments";
import { lowerFirstLetter } from "../../../../const/globalConst";
import { Form, Formik, FormikProps } from "formik";
import { denouncedInterviewSchema } from "../../../../lib/validations/inputSchemas";
import { InputTextField } from "../../../../components/Inputs/InputTextField";
import { InputRadioButton } from "../../../../components/Inputs/InputRadioButton";
import ClearIcon from "@mui/icons-material/Clear";
import { SnackBarContext } from "../../../../context/snackBarContext";
import pdfMake from "../../../../const/pdfMakeFonts";
import { ComplaintStepperContext } from "../../../../context/complaintStepperContext";
import { denouncedInterview } from "../../../../lib/documentsTemplates/NewInterviewTemplates";

export const DenouncedInterview = () => {
   const { offender } = useParams();
   const { showSnackBar } = useContext(SnackBarContext);
   const { complaint } = useContext(ComplaintStepperContext);
   const [uploadedLogoBlob, setUploadedLogoBlob] = useState<File | null>();
   const [isLoading, setIsLoading] = useState<boolean>(false);
   const navigate = useNavigate();

   const handleSendForm = (values, actions) => {
      setIsLoading(true);
      let base64data: any = "";
      if (values.online === "Si" && !uploadedLogoBlob) {
         showSnackBar("Favor de subir la imagen de evidencia.", true);
      } else if (values.online === "Si" && uploadedLogoBlob) {
         const reader = new FileReader();
         reader.readAsDataURL(uploadedLogoBlob);
         reader.onloadend = function () {
            base64data = reader.result;
            const dd = denouncedInterview(
               false,
               values.denouncedName,
               values.denouncedActivity,
               values.denouncedTime,
               values.denouncedRecognition,
               values.denouncedFacts,
               values.denouncedFactsAnswer,
               values.denouncedResponsible,
               values.denouncedPersonName,
               values.denouncedPersonNameAnswer,
               values.denouncedDayActivities,
               values.denouncedWitness,
               values.denouncedWitnessAnswer,
               values.denouncedFundamentals,
               values.denouncedFundamentalsAnswer,
               values.denouncedCategoryPartake,
               values.denouncedNarration,
               values.denouncedTruth,
               complaint.clasification,
               base64data
            );
            const pdfDocGenerator = pdfMake.createPdf(dd);
            pdfDocGenerator.getBlob(async (blob) => {
               setIsLoading(true);
               if (
                  complaint.documentation.some(
                     (e) => e.name === `entrevista-denunciado-${complaint.reportNumber}-${offender}.pdf`
                  )
               ) {
                  showSnackBar("Este documento ya fue enviado.", true);
                  setIsLoading(false);
                  setTimeout(() => navigate(-1), 1000);
               } else {
                  await createDocumentPdf(
                     complaint.reportNumber,
                     complaint.companyBranch.company._id,
                     `entrevista-denunciado-${complaint.reportNumber}-${offender}`,
                     blob
                  );
                  showSnackBar("Documento creado y subido correctamente.", false);
                  setIsLoading(false);
                  setTimeout(() => navigate(-1), 1000);
                  actions.resetForm();
               }
               setIsLoading(false);
            });
         };
      } else {
         const dd = denouncedInterview(
            false,
            values.denouncedName,
            values.denouncedActivity,
            values.denouncedTime,
            values.denouncedRecognition,
            values.denouncedFacts,
            values.denouncedFactsAnswer,
            values.denouncedResponsible,
            values.denouncedPersonName,
            values.denouncedPersonNameAnswer,
            values.denouncedDayActivities,
            values.denouncedWitness,
            values.denouncedWitnessAnswer,
            values.denouncedFundamentals,
            values.denouncedFundamentalsAnswer,
            values.denouncedCategoryPartake,
            values.denouncedNarration,
            values.denouncedTruth,
            complaint.clasification,
            ""
         );
         pdfMake.createPdf(dd).open();
      }
      setIsLoading(false);
      setTimeout(() => navigate(-1), 1000);
   };

   const handleOpenEmptyInterview = () => {
      const dd = denouncedInterview(
         true,
         "",
         "",
         "",
         "",
         "",
         "",
         "",
         "",
         "",
         "",
         "",
         "",
         "",
         "",
         "",
         "",
         "",
         complaint.clasification,
         ""
      );
      pdfMake.createPdf(dd).open();
      setTimeout(() => navigate(-1), 1000);
   };

   return (
      <Box p={2}>
         <NotificationDocumentsBar head="Entrevista a denunciado" isScheduleAppointment={true} />
         <Container maxWidth="xl">
            <Formik
               initialValues={{
                  online: "",
                  denouncedName: "",
                  denouncedActivity: "",
                  denouncedTime: "",
                  denouncedRecognition: "",
                  denouncedFacts: "",
                  denouncedFactsAnswer: "",
                  denouncedResponsible: "",
                  denouncedPersonName: "",
                  denouncedPersonNameAnswer: "",
                  denouncedDayActivities: "",
                  denouncedWitness: "",
                  denouncedWitnessAnswer: "",
                  denouncedFundamentals: "",
                  denouncedFundamentalsAnswer: "",
                  denouncedCategoryPartake: "",
                  denouncedNarration: "",
                  denouncedTruth: "",
               }}
               validateOnChange={false}
               validationSchema={denouncedInterviewSchema}
               onSubmit={handleSendForm}
            >
               {(props: FormikProps<any>) => (
                  <Form>
                     <Box
                        display={"flex"}
                        sx={{
                           py: 2,
                           px: 4,
                           minWidth: 950,
                           flexDirection: "column",
                           alignItems: "center",
                           mx: 4,
                        }}
                     >
                        <Box display={"flex"} sx={{ justifyContent: "flex-end", width: "100%" }}>
                           <Button variant="contained" sx={{ mb: 2 }} onClick={() => handleOpenEmptyInterview()}>
                              Descargar formato
                           </Button>
                        </Box>
                        <Box
                           sx={{
                              width: "100%",
                              borderRadius: 2,
                              bgcolor: "#FFFFFF",
                              border: 1,
                              borderColor: "#8A95A0",
                              mb: 2,
                           }}
                        >
                           <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", p: 2 }}>
                              <Typography color={"#2d4357"} fontWeight={700} variant="h5" sx={{ mb: 1 }}>
                                 Evidencia
                              </Typography>
                           </Grid>
                           <Divider sx={{ mx: 2, mb: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={8} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    ¿La entrevista es por vía electrónica?
                                 </Typography>
                              </Grid>
                              <Grid
                                 item
                                 xs={4}
                                 display={"flex"}
                                 sx={{ alignItems: "center", justifyContent: "center", p: 2 }}
                              >
                                 <InputRadioButton name="online" id="online" row={true} setfile={setUploadedLogoBlob} />
                              </Grid>
                           </Grid>
                           {props.values.online === "Si" ? (
                              <Box py={2} px={6}>
                                 <Box display="flex" justifyContent="space-evenly" alignItems="center">
                                    <Box>
                                       <Typography>Evidencia</Typography>{" "}
                                       <Typography fontSize={11}>Solo archivos .png y .jpeg</Typography>
                                    </Box>

                                    <Button variant="outlined" component="span" sx={{ p: 1, ml: 1 }}>
                                       <input
                                          accept="image/png, image/jpeg, image/jpg"
                                          id="logo-button-file"
                                          // multiple
                                          // value={uploadedLogoBlob ? uploadedLogoBlob.name : ""}
                                          type="file"
                                          onChange={(e) => {
                                             if (e.target.files) setUploadedLogoBlob(e.target.files[0]);
                                          }}
                                       />
                                    </Button>
                                 </Box>
                                 {uploadedLogoBlob ? (
                                    <Box
                                       alignItems="center"
                                       justifyContent="space-between"
                                       display="flex"
                                       bgcolor="whitesmoke"
                                       m={1}
                                       px={2}
                                       borderRadius={1}
                                    >
                                       <Box
                                          component="img"
                                          src={URL.createObjectURL(uploadedLogoBlob)}
                                          maxHeight={90}
                                          maxWidth={180}
                                          sx={{ alignSelf: "center", borderRadius: 1, m: 1, p: 1 }}
                                       />
                                       <IconButton
                                          onClick={() => {
                                             setUploadedLogoBlob(null);
                                          }}
                                       >
                                          <ClearIcon sx={{ color: "#C60000" }} />
                                       </IconButton>
                                    </Box>
                                 ) : (
                                    <></>
                                 )}
                              </Box>
                           ) : null}
                        </Box>
                        <Box
                           sx={{
                              width: "100%",
                              backgroundColor: "#ffffff",
                              borderRadius: 2,
                              border: 1,
                              borderColor: "#8A95A0",
                           }}
                        >
                           <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", p: 2 }}>
                              <Typography color={"#2d4357"} fontWeight={700} variant="h5" sx={{ mb: 1 }}>
                                 Formato de entrevista a denunciado
                              </Typography>
                              <Typography color={"#2d4357"} fontWeight={500} variant="body1">
                                 La razón de esta entrevista es colaborar con la investigación de los hechos, conforme
                                 un acopio de información de cualquier denuncia existente en el canal de denuncias.
                              </Typography>
                           </Grid>
                           <Divider sx={{ mx: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={6} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    1. ¿Cuál es su nombre completo?
                                 </Typography>
                              </Grid>
                              <Grid item xs={6} display={"flex"} sx={dataFieldSX}>
                                 <InputTextField
                                    variant="standard"
                                    multiline={true}
                                    size="medium"
                                    name="denouncedName"
                                    id="denouncedName"
                                    type="text"
                                    fullWidth={true}
                                 />
                              </Grid>
                           </Grid>
                           <Divider sx={{ mx: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={6} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    2. ¿Cuál es la actividad que realiza en la organización?
                                 </Typography>
                              </Grid>
                              <Grid item xs={6} display={"flex"} sx={dataFieldSX}>
                                 <InputTextField
                                    variant="standard"
                                    multiline={true}
                                    size="medium"
                                    name="denouncedActivity"
                                    id="denouncedActivity"
                                    type="text"
                                    fullWidth={true}
                                 />
                              </Grid>
                           </Grid>
                           <Divider sx={{ mx: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={6} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    3. ¿Cuánto tiempo tiene trabajando en esta organización?
                                 </Typography>
                              </Grid>
                              <Grid item xs={6} display={"flex"} sx={dataFieldSX}>
                                 <InputTextField
                                    variant="standard"
                                    multiline={true}
                                    size="medium"
                                    name="denouncedTime"
                                    id="denouncedTime"
                                    type="text"
                                    fullWidth={true}
                                 />
                              </Grid>
                           </Grid>
                           <Divider sx={{ mx: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={8} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    4. ¿Personalmente reconoces alguna falta catalogada como{" "}
                                    {lowerFirstLetter(complaint.category)} que te indique como responsable de la misma?
                                 </Typography>
                              </Grid>
                              <Grid
                                 item
                                 xs={4}
                                 display={"flex"}
                                 sx={{ alignItems: "center", justifyContent: "center", p: 2 }}
                              >
                                 <InputRadioButton
                                    name="denouncedRecognition"
                                    id="denouncedRecognition"
                                    value={props.values.denouncedRecognition}
                                    onChange={props.handleChange}
                                    row={true}
                                 />
                              </Grid>
                           </Grid>
                           <Divider sx={{ mx: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={8} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    5. ¿Tiene algún hecho que manifestar referente a la denuncia que fue instaurada en
                                    su contra?
                                 </Typography>
                              </Grid>
                              <Grid
                                 item
                                 xs={4}
                                 display={"flex"}
                                 sx={{ alignItems: "center", justifyContent: "center", p: 2 }}
                              >
                                 <InputRadioButton
                                    name="denouncedFacts"
                                    id="denouncedFacts"
                                    row={true}
                                    value={props.values.denouncedFacts}
                                    onChange={props.handleChange}
                                 />
                              </Grid>
                           </Grid>
                           {props.values.denouncedFacts === "Si" ? (
                              <>
                                 <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                                    <Grid item xs={6} display={"flex"} sx={{ alignItems: "center", pl: 8 }}>
                                       <Typography fontWeight={500} variant="body1">
                                          a. ¿Puede mencionarlos?
                                       </Typography>
                                    </Grid>
                                    <Grid item xs={6} display={"flex"} sx={dataFieldSX}>
                                       <InputTextField
                                          variant="standard"
                                          multiline={true}
                                          size="medium"
                                          name="denouncedFactsAnswer"
                                          id="denouncedFactsAnswer"
                                          type="text"
                                          fullWidth={true}
                                       />
                                    </Grid>
                                 </Grid>
                              </>
                           ) : (
                              <></>
                           )}
                           <Divider sx={{ mx: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={8} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    6. ¿Niega que exista una denuncia de este tipo, que se le indique como responsable
                                    de alguna falta?
                                 </Typography>
                              </Grid>
                              <Grid
                                 item
                                 xs={4}
                                 display={"flex"}
                                 sx={{ alignItems: "center", justifyContent: "center", p: 2 }}
                              >
                                 <InputRadioButton name="denouncedResponsible" id="denouncedResponsible" row={true} />
                              </Grid>
                           </Grid>
                           {props.values.denouncedResponsible === "Si" ? (
                              <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                                 <Grid item xs={8} display={"flex"} sx={{ alignItems: "center", pl: 8 }}>
                                    <Typography fontWeight={500} variant="body1">
                                       a. ¿Tiene conocimiento del nombre de la persona que pudiera ser la que cometió
                                       dicha denuncia?
                                    </Typography>
                                 </Grid>
                                 <Grid
                                    item
                                    xs={4}
                                    display={"flex"}
                                    sx={{ alignItems: "center", justifyContent: "center", p: 2 }}
                                 >
                                    <InputRadioButton name="denouncedPersonName" id="denouncedPersonName" row={true} />
                                 </Grid>
                              </Grid>
                           ) : (
                              <></>
                           )}
                           {props.values.denouncedPersonName === "Si" ? (
                              <>
                                 <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                                    <Grid item xs={6} display={"flex"} sx={{ alignItems: "center", pl: 11 }}>
                                       <Typography fontWeight={500} variant="body1">
                                          i. ¿Puede mencionarlos?
                                       </Typography>
                                    </Grid>
                                    <Grid item xs={6} display={"flex"} sx={dataFieldSX}>
                                       <InputTextField
                                          variant="standard"
                                          multiline={true}
                                          size="medium"
                                          name="denouncedPersonNameAnswer"
                                          id="denouncedPersonNameAnswer"
                                          type="text"
                                          fullWidth={true}
                                       />
                                    </Grid>
                                 </Grid>
                              </>
                           ) : (
                              <></>
                           )}
                           <Divider sx={{ mx: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={6} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    7. ¿Qué actividades realizaba usted en la fecha que se estima la denuncia?
                                 </Typography>
                              </Grid>
                              <Grid item xs={6} display={"flex"} sx={dataFieldSX}>
                                 <InputTextField
                                    variant="standard"
                                    multiline={true}
                                    size="medium"
                                    name="denouncedDayActivities"
                                    id="denouncedDayActivities"
                                    type="text"
                                    fullWidth={true}
                                 />
                              </Grid>
                           </Grid>
                           <Divider sx={{ mx: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={8} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    8. ¿Tiene testigos de las actividades que realizaba ese día?
                                 </Typography>
                              </Grid>
                              <Grid
                                 item
                                 xs={4}
                                 display={"flex"}
                                 sx={{ alignItems: "center", justifyContent: "center", p: 2 }}
                              >
                                 <InputRadioButton name="denouncedWitness" id="denouncedWitness" row={true} />
                              </Grid>
                           </Grid>
                           {props.values.denouncedWitness === "Si" ? (
                              <>
                                 <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                                    <Grid item xs={6} display={"flex"} sx={{ alignItems: "center", pl: 8 }}>
                                       <Typography fontWeight={500} variant="body1">
                                          a. ¿Puede mencionarlos?
                                       </Typography>
                                    </Grid>
                                    <Grid item xs={6} display={"flex"} sx={dataFieldSX}>
                                       <InputTextField
                                          variant="standard"
                                          multiline={true}
                                          size="medium"
                                          name="denouncedWitnessAnswer"
                                          id="denouncedWitnessAnswer"
                                          type="text"
                                          fullWidth={true}
                                       />
                                    </Grid>
                                 </Grid>
                              </>
                           ) : (
                              <></>
                           )}
                           <Divider sx={{ mx: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={8} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    9. ¿Tiene alguna prueba que fundamente lo que menciona en esta entrevista?
                                 </Typography>
                              </Grid>
                              <Grid
                                 item
                                 xs={4}
                                 display={"flex"}
                                 sx={{ alignItems: "center", justifyContent: "center", p: 2 }}
                              >
                                 <InputRadioButton name="denouncedFundamentals" id="denouncedFundamentals" row={true} />
                              </Grid>
                           </Grid>
                           {props.values.denouncedFundamentals === "Si" ? (
                              <>
                                 <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                                    <Grid item xs={6} display={"flex"} sx={{ alignItems: "center", pl: 8 }}>
                                       <Typography fontWeight={500} variant="body1">
                                          a. ¿Puede mencionarlos?
                                       </Typography>
                                    </Grid>
                                    <Grid item xs={6} display={"flex"} sx={dataFieldSX}>
                                       <InputTextField
                                          variant="standard"
                                          multiline={true}
                                          size="medium"
                                          name="denouncedFundamentalsAnswer"
                                          id="denouncedFundamentalsAnswer"
                                          type="text"
                                          fullWidth={true}
                                       />
                                    </Grid>
                                 </Grid>
                              </>
                           ) : (
                              <></>
                           )}
                           <Divider sx={{ mx: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={8} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    10. ¿Conoce o fue protagonista de algún suceso de esta categoría recientemente?
                                 </Typography>
                              </Grid>
                              <Grid
                                 item
                                 xs={4}
                                 display={"flex"}
                                 sx={{ alignItems: "center", justifyContent: "center", p: 2 }}
                              >
                                 <InputRadioButton
                                    name="denouncedCategoryPartake"
                                    id="denouncedCategoryPartake"
                                    row={true}
                                 />
                              </Grid>
                           </Grid>
                           {props.values.denouncedCategoryPartake === "Si" ? (
                              <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                                 <Grid item xs={6} display={"flex"} sx={{ alignItems: "center", pl: 8 }}>
                                    <Typography fontWeight={500} variant="body1">
                                       a. ¿Podría narrar los hechos?
                                    </Typography>
                                 </Grid>
                                 <Grid item xs={6} display={"flex"} sx={dataFieldSX}>
                                    <InputTextField
                                       variant="standard"
                                       multiline={true}
                                       size="medium"
                                       name="denouncedNarration"
                                       id="denouncedNarration"
                                       type="text"
                                       fullWidth={true}
                                    />
                                 </Grid>
                              </Grid>
                           ) : (
                              <></>
                           )}
                           <Divider sx={{ mx: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={8} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    11. ¿Ha respondido todas las preguntas con la verdad?
                                 </Typography>
                              </Grid>
                              <Grid
                                 item
                                 xs={4}
                                 display={"flex"}
                                 sx={{ alignItems: "center", justifyContent: "center", p: 2 }}
                              >
                                 <InputRadioButton name="denouncedTruth" id="denouncedTruth" row={true} />
                              </Grid>
                           </Grid>
                        </Box>
                        <Box display={"flex"} sx={{ justifyContent: "flex-end", width: "100%" }}>
                           <Button variant="contained" sx={{ mt: 2, width: "50px" }} type="submit">
                              {isLoading ? <CircularProgress size={25} sx={{ color: "white" }} /> : "Enviar"}
                           </Button>
                        </Box>
                     </Box>
                  </Form>
               )}
            </Formik>
         </Container>
      </Box>
   );
};

const dataFieldSX = {
   py: 2,
   pr: 12,
   pl: 2,
};
