import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { Typography, Box, Stack, Tooltip } from "@mui/material";
import React, { useCallback, useContext, useEffect } from "react";
import { useState } from "react";
import { HeadCell, TableComponent } from "../../../TableComponent";
import { FilterComponent } from "../../../FilterComponent";
import { BeneficiaryControllerContext } from "../../../../context/beneficiaryContext/beneficiaryContollerContext";
import { ArrowBack, Article, Link } from "@mui/icons-material";
import { CustomDrawerComponent } from "../../../CustomDrawerComponent";
import { useNavigate } from "react-router-dom";
import CreatePossibleBeneficiaryModal from "./CreatePossibleBeneficiaryModal";
import {
   createBeneficiary,
   getBeneficiariesFromMoralAssociation,
   getBeneficiaryByRFC,
   getBeneficiaryByUser,
   getUserByEmail,
   sendBeneficiarySurvey,
   updateBeneficiaryCompanies,
} from "../../../../lib/usersBEClient";
import BeneficiaryQuestionaryModal from "./Questionary/BeneficiaryQuestionaryModal";
import { BeneficiaryQuestionaryContext } from "../../../../context/beneficiaryContext/beneficiaryQuestionaryContext";
import { ConfirmationModal } from "../../../ConfirmationModal";
import { SnackBarContext } from "../../../../context/snackBarContext";
import { UserContext } from "../../../../context/userContext";

const statusMap = {
   pending: {
      text: "Pendiente",
      bgcolor: "#FF000037",
      color: "#FF0000",
   },
   completed: {
      text: "Completado",
      bgcolor: "#6DBB7540",
      color: "#6DBB75",
   },
   "in progress": {
      text: "En Proceso",
      bgcolor: "#FDC74840",
      color: "#FDC748",
   },
};

const getStatusComponent = (status: string) => {
   const statusInfo = statusMap[status];
   return statusInfo ? (
      <Typography sx={{ borderRadius: 2, bgcolor: statusInfo.bgcolor, color: statusInfo.color, fontSize: 14 }}>
         {statusInfo.text}
      </Typography>
   ) : null;
};

const headers: HeadCell[] = [
   { field: "name", headerName: "Nombre", type: "string", align: "center" },
   { field: "children", headerName: "", type: "string", align: "center" },
   { field: "rfc", headerName: "RFC", type: "string", align: "center" },
   { field: "createdAt", headerName: "Fecha de registro", type: "date", align: "center" },
   { field: "completedPercentage", headerName: "Avance", type: "string", align: "center" },
   { field: "status", headerName: "Estatus", type: "string", align: "center" },
   { field: "questionary", headerName: "Cuestionario", type: "string", align: "center" },
];

interface PossibleBeneficiariesListDrawerProps {
   state: boolean;
   setState: (state: boolean) => void;
}

const PossibleBeneficiariesListDrawer = (props: PossibleBeneficiariesListDrawerProps) => {
   const { state, setState } = props;
   //HOOKS
   const navigate = useNavigate();
   //CONTEXT
   const { possibleBeneficiaries, beneficiaries, refetch } = useContext(BeneficiaryControllerContext);
   const { setSelectedPossibleBeneficiary, selectedPossibleBeneficiary } = useContext(BeneficiaryQuestionaryContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const { companySelected } = useContext(UserContext);
   //STATES
   const [rows, setRows] = useState([]);
   const [openModal, setOpenModal] = useState(false);
   const [openQuestionary, setOpenQuestionary] = useState(false);
   const [filteredRows, setFilteredRows] = useState([]);
   const [selectedMoral, setSelectedMoral] = useState(null);
   const [openSendMailModal, setOpenSendMailModal] = useState(false);
   const [isLoading, setIsLoading] = useState(false);

   const handleSendMail = async () => {
      if (!selectedPossibleBeneficiary) return;
      try {
         setIsLoading(true);
         let beneficiaryFound = await getBeneficiaryByUser(selectedPossibleBeneficiary?.user);
         if (!beneficiaryFound) {
            beneficiaryFound = await getBeneficiaryByRFC(selectedPossibleBeneficiary?.rfc);
         }
         if (beneficiaryFound) {
            await updateBeneficiaryCompanies(beneficiaryFound._id, companySelected._id);
            await sendBeneficiarySurvey(beneficiaryFound._id, companySelected._id);
            showSnackBar("Solicitud de identificación enviada con éxito", false);
         } else {
            const creationData = {
               name: selectedPossibleBeneficiary.name,
               email: selectedPossibleBeneficiary.email,
               rfc: selectedPossibleBeneficiary.rfc,
               phoneNumber: selectedPossibleBeneficiary.phoneNumber,
               shareHolderType: selectedPossibleBeneficiary.shareHolderType,
               companyId: [companySelected._id],
               user: (selectedPossibleBeneficiary as any).userId ?? undefined,
            };

            const response = await createBeneficiary(creationData);
            await sendBeneficiarySurvey(response.id, companySelected._id);
            showSnackBar("Solicitud de identificación enviada con éxito", false);
         }
         setOpenSendMailModal(false);
         setIsLoading(false);
         refetch();
      } catch (error) {
         console.error(error);
         setIsLoading(false);
      }
   };

   const fetchBeneficiaries = useCallback(async () => {
      if (!possibleBeneficiaries || !beneficiaries) return;

      let beneficiariesToUse = possibleBeneficiaries;
      if (selectedMoral) beneficiariesToUse = await getBeneficiariesFromMoralAssociation(selectedMoral._id);

      const formattedRows = beneficiariesToUse.reduce((acc, beneficiary) => {
         const formattedRow = {
            ...beneficiary,
            name:
               beneficiary.shareHolderType === "Persona moral"
                  ? beneficiary.businessName
                  : beneficiary.user
                  ? `${beneficiary.user.firstName} ${beneficiary.user.lastName}`
                  : beneficiary.name,
            children: beneficiary.shareHolderType === "Persona moral" && (
               <Typography sx={{ borderRadius: 2, bgcolor: "#162c44", color: "white" }}>
                  {(beneficiaries as any).reduce((count, benef) => {
                     return benef.moralAssociation?._id === beneficiary._id ? count + 1 : count;
                  }, 0)}
               </Typography>
            ),
            rfc: beneficiary.rfc ?? beneficiary.businessRFC,
            completedPercentage: `${beneficiary?.completedPercentage?.toFixed(0) || 0}%`,
            status: getStatusComponent(beneficiary.status || "pending"),
            questionary:
               (beneficiary as any).registered === false ? (
                  <Tooltip title="Enviar cuestionario">
                     <IconButton
                        onClick={(e) => {
                           e.stopPropagation();
                           setSelectedPossibleBeneficiary(beneficiary);
                           setOpenSendMailModal(true);
                        }}
                     >
                        <Link sx={{ color: "#64748b", fontSize: 27 }} />
                     </IconButton>
                  </Tooltip>
               ) : (
                  <Tooltip title="Responder cuestionario">
                     <IconButton
                        onClick={(e) => {
                           e.stopPropagation();
                           setSelectedPossibleBeneficiary(beneficiary);
                           setOpenQuestionary(true);
                        }}
                     >
                        <Article sx={{ color: "#64748b", fontSize: 27 }} />
                     </IconButton>
                  </Tooltip>
               ),
         };
         acc.push(formattedRow);
         return acc;
      }, []);

      setRows(formattedRows);
      setFilteredRows(formattedRows);
   }, [possibleBeneficiaries, beneficiaries, selectedMoral]);

   useEffect(() => {
      fetchBeneficiaries();
   }, [fetchBeneficiaries]);

   useEffect(() => {
      if (!openQuestionary) refetch();
   }, [openQuestionary]);

   return (
      <div>
         <CustomDrawerComponent
            title="Lista de posibles beneficiarios controladores"
            open={state}
            setOpen={setState}
            width={1100}
            onClose={() => setSelectedMoral(null)}
         >
            <Stack sx={{ px: 5, py: 2, pb: 5 }}>
               {selectedMoral && (
                  <Box sx={{ borderRadius: 2, mb: 1, border: 0.5, borderColor: "#D8D8D8" }}>
                     <Box
                        sx={{
                           bgcolor: "#868FA0",
                           p: 1,
                           borderTopLeftRadius: 5,
                           borderTopRightRadius: 5,
                           display: "flex",
                           alignItems: "center",
                           justifyContent: "center",
                        }}
                     >
                        <IconButton
                           size="small"
                           sx={{ position: "absolute", left: 50 }}
                           onClick={() => setSelectedMoral(null)}
                        >
                           <ArrowBack sx={{ color: "white", fontSize: 20 }} />
                        </IconButton>
                        <Typography sx={{ color: "white", textAlign: "center" }}>Información de la sociedad</Typography>
                     </Box>
                     <Box
                        sx={{
                           p: 1,
                           display: "flex",
                           borderBottomLeftRadius: 5,
                           borderBottomRightRadius: 5,
                           justifyContent: "space-around",
                        }}
                     >
                        <Box sx={{ display: "flex", columnGap: 2, alignItems: "center" }}>
                           <Typography sx={{ fontSize: 14 }}>Nombre / Denominación o razón social</Typography>
                           <Typography sx={{ fontSize: 14, bgcolor: "#F1F3F4", borderRadius: 1, px: 2, py: 0.5 }}>
                              {selectedMoral.businessName}
                           </Typography>
                        </Box>

                        <Box sx={{ display: "flex", columnGap: 2, alignItems: "center" }}>
                           <Typography sx={{ fontSize: 14 }}>RFC</Typography>
                           <Typography sx={{ fontSize: 14, bgcolor: "#F1F3F4", borderRadius: 1, px: 2, py: 0.5 }}>
                              {selectedMoral.businessRFC}
                           </Typography>
                        </Box>
                     </Box>
                  </Box>
               )}
               <FilterComponent
                  fullWidth
                  originaltableOneRows={rows}
                  tableOneFilteredRows={filteredRows}
                  setTableOneFilteredRows={setFilteredRows}
                  disableRefresh
                  filters={[]}
                  searchBar={
                     <Stack sx={{ flexDirection: "row", gap: 2 }}>
                        <Button
                           variant="contained"
                           sx={{
                              bgcolor: "#8A95A0",
                              "&:hover": {
                                 bgcolor: "#8A95A0",
                              },
                           }}
                           onClick={() =>
                              navigate(`/beneficiario-controlador/${companySelected._id}/beneficiario-controlador`, {
                                 replace: true,
                              })
                           }
                        >
                           Beneficiarios controladores
                        </Button>
                        <Button variant="contained" onClick={() => setOpenModal(true)}>
                           Agregar nuevo
                        </Button>
                     </Stack>
                  }
               />

               <Box
                  sx={{
                     display: "flex",
                     justifyContent: "space-around",
                     mb: -2, //https://www.youtube.com/watch?v=5gLoEBbZNis
                     mt: 2,
                  }}
               >
                  {["Información del beneficiario controlador", "Información de identificación"].map((text, index) => (
                     <Typography
                        key={index}
                        sx={{
                           color: "white",
                           bgcolor: "#868FA0",
                           borderRadius: 1,
                           p: 0.5,
                           pb: 2,
                           fontSize: 14,
                           flex: index === 0 ? 2 : 1,
                           textAlign: "center",
                           mx: 3,
                        }}
                     >
                        {text}
                     </Typography>
                  ))}
               </Box>

               <TableComponent
                  headerColor
                  headers={headers}
                  rows={filteredRows}
                  defaultColumnToOrder="name"
                  defaultOrder="desc"
                  defaultRowsPerPage={5}
                  emptyDataText={
                     rows.length > 0
                        ? "No se encontró el posible beneficiario"
                        : "No se han registrado posibles beneficiarios"
                  }
                  loader={!rows}
                  rowsPerPageOptions={[5, 10, 15, 20]}
                  onClick={(_, row) => {
                     if (row.shareHolderType === "Persona moral") setSelectedMoral(row);
                  }}
               />
            </Stack>
         </CustomDrawerComponent>
         <CreatePossibleBeneficiaryModal
            state={openModal}
            setState={setOpenModal}
            moralAssociation={selectedMoral?._id ?? null}
            setOpenQuestionary={setOpenQuestionary}
         />
         <BeneficiaryQuestionaryModal state={openQuestionary} setState={setOpenQuestionary} />
         <ConfirmationModal
            open={openSendMailModal}
            setOpen={setOpenSendMailModal}
            onConfirm={() => handleSendMail()}
            title={"Solicitud de identificación"}
            isLoading={isLoading}
            body={
               <Typography sx={{ textAlign: "center" }}>
                  {`¿Desea enviar un enlace con el cuestionario de identificación de beneficiario controlador a ${selectedPossibleBeneficiary?.name}?`}
               </Typography>
            }
         />
      </div>
   );
};

export default PossibleBeneficiariesListDrawer;
