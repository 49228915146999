import { useContext, useEffect, useMemo, useState } from "react";
import { Box, Card, Stack, Typography, Tab, Tabs } from "@mui/material";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import { CouncilGraphComponent } from "./SubComponents/CouncilGraphComponent";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LoadingButton } from "../../LoadingButton";
import { createChartPdfTest } from "../../../lib/gobCorpBEClient";
import { SnackBarContext } from "../../../context/snackBarContext";
import { UserContext } from "../../../context/userContext";
import { FiltersTabsCompanyComponent } from "../AgreementsAndCommitmentsScreen/SubComponents/FiltersTabsCompanyComponent";
import { getGroupColors, getCompanyColors, getUrlS3 } from "../../../lib/usersBEClient";
import { PerformanceEvaluationBar } from "./PerformanceEvaluationBar";
import { PanelReportGenerationSection } from "./ReportGenerationComponent";

export const CouncilGraphs = () => {
   const { user, setcompanySelectedUserP, companySelectedUserP } = useContext(UserContext);
   const { gobernanceBody = [], consultiveGroup, companySelected: governanceContext } = useContext(GovernanceContext);

   const today = dayjs();
   const [startDate, setStartDate] = useState<Dayjs>(today);
   const [finishDate, setFinishDate] = useState<Dayjs>(today);
   const [monthValue, setMonthValue] = useState<Dayjs | null>(null);
   const [chartRefs, setChartRefs] = useState([]);
   const [isLoadingReport, setIsLoadingReport] = useState(false);
   const { showSnackBar } = useContext(SnackBarContext);
   const [openReport, setOpenReport] = useState(false);

   const isConsultiveGroup = consultiveGroup !== null && consultiveGroup._id === governanceContext;

   const selectedGovernances = useMemo(() => {
      if (!companySelectedUserP) return [];

      return gobernanceBody.filter((g) => g.company === companySelectedUserP._id && g._id !== "0");
   }, [companySelectedUserP, gobernanceBody]);

   useEffect(() => {
      setcompanySelectedUserP(null);
   }, []);

   useEffect(() => {
      setChartRefs([]);
   }, [selectedGovernances]);

   const createPDF = async () => {
      setIsLoadingReport(true);
      try {
         const dateInfo = {
            startDate: startDate ? startDate.toDate() : null,
            finishDate: finishDate ? finishDate.toDate() : null,
            monthValue: monthValue ? monthValue.toDate() : null,
         };

         const response = await createChartPdfTest(dateInfo, user?.id, companySelectedUserP._id);

         if (response.message !== "OK") {
            throw new Error("Error al generar el PDF");
         }

         setOpenReport(true);
      } catch (error) {
         console.error("Error al generar el PDF:", error);
         showSnackBar("No es posible crear el PDF", true);
         setIsLoadingReport(false);
      }
   };

   useEffect(() => {
      const handleOpenReport = async () => {
         setTimeout(async () => {
            try {
               const reportUrl = await getUrlS3(
                  "files-lecosy",
                  { folder: `gc/${companySelectedUserP._id}/panel/${user.id}/reports/temp` },
                  `temp-report.pdf`
               );
               window.open(reportUrl, "_blank", "noopener,noreferrer");
               setOpenReport(false);
            } catch (error) {
               console.error("Error al obtener la URL del reporte:", error);
               showSnackBar("Error al obtener la URL del reporte", true);
            } finally {
               setIsLoadingReport(false);
            }
         }, 2000);
      };

      if (openReport) handleOpenReport();
   }, [openReport]);

   return (
      <Stack direction="column" spacing={2}>
         <Stack direction="row" spacing={2} alignItems="stretch">
            <Box sx={{ flex: 0.2 }}>
               <FiltersTabsCompanyComponent setFilterValue={setcompanySelectedUserP} />
            </Box>

            <Box sx={{ flex: isConsultiveGroup ? 0.8 : 1 }}>
               <PerformanceEvaluationBar />
            </Box>
         </Stack>
         <Box sx={{ flex: 1 }}>
            {companySelectedUserP && selectedGovernances.length > 0 ? (
               <>
                  {selectedGovernances.map((governance) => (
                     <CouncilGraphComponent key={governance._id} governance={governance} chartRefs={chartRefs} />
                  ))}
                  <Stack mt={4} direction={"row"} spacing={1}>
                     <PanelReportGenerationSection />
                     <Card sx={{ flex: 1 }}>
                        <Tabs value="1" textColor="primary">
                           <Tab label="Generar reportes" value="1" />
                        </Tabs>

                        <Stack direction="column" spacing={2}>
                           <Typography px={4} pt={2}>
                              <strong>Personalizado:</strong>
                           </Typography>
                           <Stack spacing={2} px={3} py={2}>
                              {/* Selector de Período */}
                              <Stack direction="row" spacing={2} alignItems="center">
                                 <Box flex={1}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-mx">
                                       <Stack direction="row" spacing={1} alignItems="center">
                                          <Typography>Desde</Typography>
                                          <DatePicker
                                             maxDate={finishDate}
                                             label="Fecha de inicio"
                                             format="DD/MM/YYYY"
                                             value={startDate}
                                             onChange={(date) => date && setStartDate(date)}
                                             slotProps={{
                                                textField: { variant: "outlined", size: "small" },
                                             }}
                                          />
                                       </Stack>
                                    </LocalizationProvider>
                                 </Box>

                                 <Box flex={1}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-mx">
                                       <Stack direction="row" spacing={1} alignItems="center">
                                          <Typography>Hasta</Typography>
                                          <DatePicker
                                             minDate={startDate}
                                             label="Fecha de fin"
                                             format="DD/MM/YYYY"
                                             value={finishDate}
                                             onChange={(date) => date && setFinishDate(date)}
                                             slotProps={{
                                                textField: { variant: "outlined", size: "small" },
                                             }}
                                          />
                                       </Stack>
                                    </LocalizationProvider>
                                 </Box>
                              </Stack>
                              <Stack direction="row" spacing={2} alignItems="center">
                                 <Box flex={1}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-mx">
                                       <Stack direction="row" spacing={1} alignItems="center">
                                          <Typography>Mensual</Typography>
                                          <DatePicker
                                             views={["year", "month"]}
                                             label="Mes y año"
                                             value={monthValue}
                                             onChange={(date) => setMonthValue(date)}
                                             slotProps={{
                                                textField: { variant: "outlined", size: "small" },
                                             }}
                                          />
                                       </Stack>
                                    </LocalizationProvider>
                                 </Box>
                              </Stack>

                              <Box display="flex" justifyContent="flex-end" alignItems="center">
                                 <LoadingButton
                                    onClick={createPDF}
                                    isLoading={isLoadingReport}
                                    label="Generar reporte en PDF"
                                 />
                              </Box>
                           </Stack>
                        </Stack>
                     </Card>
                  </Stack>
               </>
            ) : (
               <Box sx={{ p: 3 }}>
                  <Typography variant="h6" color="textSecondary" align="center">
                     No hay ninguna empresa seleccionada
                  </Typography>
               </Box>
            )}
         </Box>
      </Stack>
   );
};
