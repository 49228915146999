import { Box, Button, Drawer, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import React from "react";
import DrawerList from "./DeedInformationDrawer";
import { useState } from "react";

interface DeedRow {
   deed: string;
   type: string;
   protocolization: string;
   celebration: string;
   registration: string;
   notaryName: string;
   notaryOffice: string;
   nominalValue: string;
   socialCapital: string;
   shareholderChanges: boolean;
   powers: boolean;
}

const DeedInformation = () => {
   const [open, setOpen] = useState(false);
   const [selectedRow, setSelectedRow] = useState({});

   const handleRowClick = (row) => {
      setSelectedRow(row);
      setOpen(true);
   };
   const headerCells = [
      "Escritura",
      "Tipo",
      "Protocolizacion",
      "Celebracion",
      "Inscripcion",
      "Nombre del notario",
      "Notaria",
      "Valor Nominal",
      "Capital social",
      "Cambios al cuadro accionario",
      "Poderes",
   ];

   const deedData: DeedRow[] = [
      {
         deed: "Lorem ipsum dolor sit amet",
         type: "Lorem ipsum dolor sit amet",
         protocolization: "Lorem ipsum dolor sit amet",
         celebration: "Lorem ipsum dolor sit amet",
         registration: "Lorem ipsum dolor sit amet",
         notaryName: "Lorem ipsum dolor sit amet",
         notaryOffice: "Lorem ipsum dolor sit amet",
         nominalValue: "Lorem ipsum dolor sit amet",
         socialCapital: "Lorem ipsum dolor sit amet",
         shareholderChanges: true,
         powers: true,
      },
      ...Array(5)
         .fill(0)
         .map((_, i) => ({
            deed: `Test Deed ${i + 2}`,
            type: "Sample Type",
            protocolization: "2023-01-01",
            celebration: "2023-01-02",
            registration: "2023-01-03",
            notaryName: "John Doe",
            notaryOffice: `Office ${i + 2}`,
            nominalValue: "$1000",
            socialCapital: "$10000",
            shareholderChanges: i % 2 === 0,
            powers: i % 2 !== 0,
         })),
   ];

   const commonCellStyle = {
      textAlign: "center" as const,
      fontSize: 14,
      fontWeight: "bold",
      padding: "0px 5px",
      height: "30px",
   };

   const getCellStyle = (index: number, total: number) => ({
      bgcolor: "white",
      border: "1px solid #d3d3d3",
      textAlign: "center" as const,
      fontSize: 12,
      borderLeft: index === 0 ? "none" : undefined,
      borderRight: index === total - 1 ? "none" : undefined,
   });

   return (
      <div>
         <Grid
            sx={{
               border: "1px solid #ccc",
               borderRadius: "8px",
               overflow: "hidden",
               boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            }}
         >
            <Grid
               item
               sx={{
                  bgcolor: "#162c44",
                  p: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 40,
                  borderRadius: "5px 5px 0 0",
               }}
            >
               <Typography variant="h6" sx={{ color: "white", fontWeight: "bold" }}>
                  Informacion de la escritura
               </Typography>
            </Grid>
            <Box
               sx={{
                  maxHeight: "400px",
                  overflow: "auto",
                  position: "relative",
                  "& .MuiTableHead-root": {
                     position: "sticky",
                     top: 0,
                     zIndex: 2,
                     backgroundColor: "#eeeeee",
                  },
                  "& .MuiTable-root": {
                     tableLayout: "fixed",
                  },
               }}
            >
               <Table>
                  <TableHead>
                     <TableRow sx={{ bgcolor: "#eeeeee", height: "30px" }}>
                        {headerCells.map((header, index) => (
                           <TableCell
                              key={header}
                              sx={{
                                 ...commonCellStyle,
                                 borderLeft: index === 0 ? "none" : "2px solid white",
                                 borderRight: index === headerCells.length - 1 ? "none" : "2px solid white",
                              }}
                           >
                              {header}
                           </TableCell>
                        ))}
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {deedData.map((row, rowIndex) => (
                        <TableRow key={rowIndex} onClick={() => handleRowClick(row)} sx={{ cursor: "pointer" }}>
                           <TableCell sx={getCellStyle(0, headerCells.length)}>{row.deed}</TableCell>
                           <TableCell sx={getCellStyle(1, headerCells.length)}>{row.type}</TableCell>
                           <TableCell sx={getCellStyle(2, headerCells.length)}>{row.protocolization}</TableCell>
                           <TableCell sx={getCellStyle(3, headerCells.length)}>{row.celebration}</TableCell>
                           <TableCell sx={getCellStyle(4, headerCells.length)}>{row.registration}</TableCell>
                           <TableCell sx={getCellStyle(5, headerCells.length)}>{row.notaryName}</TableCell>
                           <TableCell sx={getCellStyle(6, headerCells.length)}>{row.notaryOffice}</TableCell>
                           <TableCell sx={getCellStyle(7, headerCells.length)}>{row.nominalValue}</TableCell>
                           <TableCell sx={getCellStyle(8, headerCells.length)}>{row.socialCapital}</TableCell>
                           <TableCell sx={getCellStyle(9, headerCells.length)}>
                              {row.shareholderChanges && <CheckBoxIcon sx={{ color: "#63748a" }} />}
                           </TableCell>
                           <TableCell sx={getCellStyle(10, headerCells.length)}>
                              {row.powers && <CheckBoxIcon sx={{ color: "#63748a" }} />}
                           </TableCell>
                        </TableRow>
                     ))}
                  </TableBody>
               </Table>
            </Box>
         </Grid>
         <DrawerList open={open} setOpen={setOpen} row={selectedRow} />
      </div>
   );
};

export default DeedInformation;
