import { Route } from "react-router-dom";
import { BeneficiarioControladorScreen } from "../screens/Beneficiary controller/BeneficiarioControladorScreen";
import { ExpedientScreen } from "../screens/Beneficiary controller/ExpedientScreen";
import { FilesProvider } from "../context/governanceContext/filesContext";
import { PrivateRoute } from "../components/RouteComponents/PrivateRoute";
import { CorporateDataProvider } from "../context/governanceContext/corporateDataContext";
import { MainScreenBeneficiary } from "../screens/Beneficiary controller/MainScreen";
import { ShareHolderScreen } from "../screens/Beneficiary controller/ShareHolderScreen";
import { EffectiveControlScreen } from "../screens/Beneficiary controller/EffectiveControlScreen";
import BeneficiaryConfiguration from "../components/Beneficiary Controller/PeriodConfiguration/BeneficiaryConfiguration";
import { CreateProfileProvider } from "../context/beneficiaryContext/createProfileContext";
import BeneficiaryTabs from "../screens/Beneficiary controller/BeneficiaryTabs";
import BeneficiaryDashboard from "../screens/Beneficiary controller/BeneficiaryDashboard";
import { BeneficiaryControllerProvider } from "../context/beneficiaryContext/beneficiaryContollerContext";
import { BreadcrumbProvider } from "../context/breadCrumbContext";
import CorporateDataDashboard from "../screens/Beneficiary controller/CorporateDataDashboard";
import { BeneficiaryQuestionaryProvider } from "../context/beneficiaryContext/beneficiaryQuestionaryContext";

export const useBeneficiaryControllerRoutes = () => {
   return (
      <Route element={<PrivateRoute section="Beneficiario controlador" module={true} />}>
         <Route
            path="/beneficiario-controlador"
            element={
               <CorporateDataProvider>
                  <BeneficiaryControllerProvider>
                     <CreateProfileProvider>
                        <BreadcrumbProvider>
                           <MainScreenBeneficiary />
                        </BreadcrumbProvider>
                     </CreateProfileProvider>
                  </BeneficiaryControllerProvider>
               </CorporateDataProvider>
            }
         >
            <Route path=":companyId/inicio" element={<BeneficiaryDashboard />} />
            <Route path=":companyId/configuracion" index element={<BeneficiaryConfiguration />} />
            <Route
               path=":companyId/expediente/:folderId"
               element={
                  <FilesProvider>
                     <ExpedientScreen />
                  </FilesProvider>
               }
            />
            <Route path=":companyId" element={<BeneficiaryTabs />}>
               <Route
                  path="dashboard-societario"
                  element={
                     <BeneficiaryQuestionaryProvider>
                        <CorporateDataDashboard />
                     </BeneficiaryQuestionaryProvider>
                  }
               />
               <Route path="accionistas" element={<ShareHolderScreen />} />
               <Route path="socios" element={<ShareHolderScreen />} />
               <Route path="control-efectivo" element={<EffectiveControlScreen />} />
               <Route path="beneficiario-controlador" element={<BeneficiarioControladorScreen />} />
            </Route>
         </Route>
      </Route>
   );
};
