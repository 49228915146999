import React, { useState, useEffect } from "react";
import {
   IconButton,
   Grid,
   Typography,
   Box,
   Drawer,
   Table,
   TableBody,
   TableCell,
   TableHead,
   TableRow,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import InfoRowFide from "../../../Subcomponents/InfoRowFide";
import BeneficiaryOrganizacionComite from "../../../../../screens/Beneficiary controller/BeneficiaryOrganizacionComite";
import BeneficiaryBlockBottom from "../../../../../screens/Beneficiary controller/BeneficiaryBlockBottom";
import PowersGrantedaux from "../../PowersGrantedaux";
import { getAllFideicomiso, getByIdFideicomiso } from "../../../../../lib/usersBEClient";
import { CustomDrawerComponent } from "../../../../CustomDrawerComponent";

const commonCellStyle = {
   color: "white",
   fontWeight: "bold",
   textAlign: "center",
   fontSize: "13px",
};

// Estilos para las celdas de la segunda fila
const secondRowCellStyle = {
   color: "black",
   borderBottom: "1px solid #ccc",
   height: "30px",
   fontSize: "14px",
};

const ThirdRowCellStyle = {
   color: "black",
   borderBottom: "1px solid #ccc",
   height: "30px",
   fontSize: "12px",
};

const formatDuration = (duracion) => {
   const regex = /(?:(\d+)a)?\s*(?:(\d+)m)?\s*(?:(\d+)d)?/;
   const match = duracion.match(regex);

   if (match) {
      const años = match[1] || 0;
      const meses = match[2] || 0;
      const dias = match[3] || 0;

      let result = "";
      if (años > 0) result += `${años} Años `;
      if (meses > 0) result += `${meses} Meses `;
      if (dias > 0) result += `${dias} Días`;

      return result.trim();
   }

   return duracion;
};

const useFideicomisoData = () => {
   const [fideicomiso, setFideicomiso] = useState([]);
   const [selectedComite, setSelectedComite] = useState([]);
   const [selectedFideicomitente, setSelectedFideicomitente] = useState([]);
   const [selectedFideicomisario, setSelectedFideicomisario] = useState([]);
   const [selectedPresidente, setSelectedPresidente] = useState([]);
   const [selectedFiduciaria, setSelectedFiduciaria] = useState([]);
   const [selectedRowId, setSelectedRowId] = useState(null);

   useEffect(() => {
      const fetchFideicomiso = async () => {
         const data = await getAllFideicomiso();
         setFideicomiso(data);
      };
      fetchFideicomiso();
   }, []);

   const handleRowClick = async (id) => {
      try {
         const fideicomisoSeleccionado = await getByIdFideicomiso(id);
         const comiteTecnico = fideicomisoSeleccionado.miembrosComite || [];
         const presidente = fideicomisoSeleccionado.presidente || {};

         setSelectedPresidente(presidente);
         setSelectedComite(comiteTecnico);
         setSelectedRowId(id);

         setSelectedFideicomitente([
            {
               razon: fideicomisoSeleccionado.nomfideicomitente,
               derechos: fideicomisoSeleccionado.derechosfideicomitente,
               obligaciones: fideicomisoSeleccionado.Obligacionesfideicomitente,
            },
         ]);

         setSelectedFideicomisario([
            {
               nombre: "Lecosy",
               razon: fideicomisoSeleccionado.nomfideicomisario,
               derechos: fideicomisoSeleccionado.derechosfideicomisario,
               obligaciones: fideicomisoSeleccionado.Obligacionesfideicomisario,
            },
         ]);

         setSelectedFiduciaria([
            {
               nombre: "Lecosy",
               delegados: fideicomisoSeleccionado.delegadosfiduciario,
               derechos: fideicomisoSeleccionado.derechosfideicomisario,
               obligaciones: fideicomisoSeleccionado.Obligacionesfiduciario,
            },
         ]);
      } catch (error) {
         console.error("Error al obtener el comité técnico:", error);
      }
   };

   return {
      fideicomiso,
      selectedComite,
      selectedFideicomitente,
      selectedFideicomisario,
      selectedPresidente,
      selectedFiduciaria,
      selectedRowId,
      handleRowClick,
   };
};

const FideicomisoTable = ({ rows, selectedRowId, onRowClick }) => {
   const rowHeight = 36;

   return (
      <Table sx={{ width: "100%", margin: "auto" }}>
         <TableHead>
            <TableRow sx={{ bgcolor: "#152C44", height: "10px", "& .MuiTableCell-root": { padding: "5px" } }}>
               <TableCell sx={commonCellStyle}>Fecha de creación</TableCell>
               <TableCell sx={commonCellStyle}>Duración</TableCell>
               <TableCell sx={commonCellStyle}>Número de Inscripción</TableCell>
               <TableCell sx={commonCellStyle}>Número de escritura</TableCell>
               <TableCell sx={commonCellStyle}>Nombre del notario</TableCell>
               <TableCell sx={commonCellStyle}>Nombre de la notaria</TableCell>
               <TableCell sx={{ ...commonCellStyle, borderRight: "none" }}>Fecha de realización</TableCell>
            </TableRow>
         </TableHead>
         <TableBody sx={{ border: "1px solid #ccc" }}>
            {rows.map((row, index) => (
               <TableRow
                  key={index}
                  onClick={() => onRowClick(row.id)}
                  sx={{
                     bgcolor: selectedRowId === row.id ? "#e0e0e0" : "white",
                     height: `${rowHeight}px`,
                     "& .MuiTableCell-root": { padding: "5px" },
                     cursor: "pointer",
                  }}
               >
                  <TableCell sx={{ ...secondRowCellStyle, textAlign: "center" }}>{row.datecreation}</TableCell>
                  <TableCell sx={{ ...ThirdRowCellStyle, textAlign: "center" }}>{row.duration}</TableCell>
                  <TableCell sx={{ ...secondRowCellStyle, textAlign: "center" }}>{row.numofinscription}</TableCell>
                  <TableCell sx={{ ...secondRowCellStyle, textAlign: "center" }}>{row.numofwriting}</TableCell>
                  <TableCell sx={{ ...secondRowCellStyle, textAlign: "center" }}>{row.notaryname}</TableCell>
                  <TableCell sx={{ ...secondRowCellStyle, textAlign: "center" }}>{row.notarynum}</TableCell>
                  <TableCell sx={{ ...secondRowCellStyle, textAlign: "center" }}>{row.daterealization}</TableCell>
               </TableRow>
            ))}
         </TableBody>
      </Table>
   );
};

const FideiComisoDrawer = ({ open, setOpen }) => {
   const {
      fideicomiso,
      selectedComite,
      selectedFideicomitente,
      selectedFideicomisario,
      selectedPresidente,
      selectedFiduciaria,
      selectedRowId,
      handleRowClick,
   } = useFideicomisoData();

   const toggleDrawer = (isOpen) => () => {
      setOpen(isOpen);
      if (isOpen && fideicomiso.length > 0) {
         handleRowClick(fideicomiso[0]._id);
      }
   };

   const FideicomisoRowsInfo = fideicomiso.map((fideicomiso) => ({
      id: fideicomiso._id,
      datecreation: fideicomiso.fechainicio,
      duration: formatDuration(fideicomiso.duracion),
      numofwriting: fideicomiso.numescritura,
      numofinscription: fideicomiso.numinscripcion,
      notarynum: fideicomiso.numnotariocorredoria,
      notaryname: fideicomiso.numnotariocorredor,
      daterealization: fideicomiso.dateToday,
   }));

   return (
      <CustomDrawerComponent title="Fideicomiso" open={open} setOpen={setOpen} width={1100}>
         <Box sx={{ width: 1300, marginTop: "61px", "& .MuiDrawer-paper": { marginTop: "61px" } }} role="presentation">
            <Grid
               container
               sx={{ width: "98%", height: "100%", margin: "auto", textAlign: "center", borderRadius: "8px" }}
            >
               <Grid
                  item
                  sx={{ width: "100%", height: "14%", margin: "auto", textAlign: "center", borderRadius: "14px" }}
               >
                  <InfoRowFide />
               </Grid>
               <Grid
                  container
                  sx={{
                     width: "100%",
                     height: "25%", // Ajusta esto según necesites
                     margin: "left",
                     textAlign: "left",
                     borderRadius: "8px",
                     marginTop: "55px",
                     justifyContent: "space-between",
                  }}
               >
                  <Grid item sx={{ width: "70%", height: "100%" }}>
                     <Grid
                        container
                        direction="column"
                        sx={{
                           width: "100%",
                           height: "100%",
                           borderRadius: "8px",
                           overflow: "hidden",
                           "& .MuiGrid-root": { margin: 0 },
                        }}
                     >
                        <Grid
                           item
                           sx={{
                              bgcolor: "#738090",
                              p: 2,
                              width: "100%",
                              height: "35px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderTopLeftRadius: "8px",
                              borderTopRightRadius: "8px",
                           }}
                        >
                           <Typography sx={{ color: "white", fontWeight: "bold" }}>
                              Informacion del fideicomiso
                           </Typography>
                        </Grid>
                        <Grid
                           item
                           sx={{
                              width: "100%",
                              borderBottomLeftRadius: "8px",
                              borderBottomRightRadius: "8px",
                              border: "1px solid #CCCCCC",
                              height: "calc(75% )",
                              overflowY: "auto",
                           }}
                        >
                           <FideicomisoTable
                              rows={FideicomisoRowsInfo}
                              selectedRowId={selectedRowId}
                              onRowClick={handleRowClick}
                           />
                        </Grid>
                     </Grid>
                  </Grid>

                  <Grid item sx={{ width: "28%", height: "100%" }}>
                     <BeneficiaryOrganizacionComite
                        rows={selectedComite}
                        title="Comite Técnico"
                        presidente={selectedPresidente}
                     />
                  </Grid>
               </Grid>
               <Grid
                  container
                  sx={{
                     width: "100%",
                     height: "30%",
                     margin: "left",
                     textAlign: "left",
                     borderRadius: "8px",
                     justifyContent: "space-between",
                  }}
               >
                  <Grid item sx={{ width: "22%", height: "100%" }}>
                     <BeneficiaryBlockBottom
                        title="Fideicomitente"
                        rows={selectedFideicomitente}
                        tipo="razon"
                        nombre="Fideicomitente"
                     />
                  </Grid>
                  <Grid item sx={{ width: "22%", height: "100%" }}>
                     <BeneficiaryBlockBottom
                        title="Fideicomisario"
                        rows={selectedFideicomisario}
                        tipo="razon"
                        nombre="Lecosy"
                     />
                  </Grid>
                  <Grid item sx={{ width: "22%", height: "100%" }}>
                     <BeneficiaryBlockBottom
                        title="Fiduciaria"
                        rows={selectedFiduciaria}
                        tipo="delegados"
                        nombre="Lecosy"
                     />
                  </Grid>
                  <Grid item sx={{ width: "22%", height: "80%" }}>
                     <PowersGrantedaux />
                  </Grid>
               </Grid>
            </Grid>
         </Box>
      </CustomDrawerComponent>
   );
};

export default FideiComisoDrawer;
