import { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { IBeneficiary, IBeneficiaryQuestionary } from "../../types/beneficiaryTypes";
import { useParams } from "react-router-dom";
import {
   changeSignState,
   completeBeneficiaryQuestionary,
   createFileAsync,
   getBeneficiaryById,
   getBeneficiaryQuestionary,
   getCompanyByIdToken,
   getUrlS3Token,
   sendBeneficiaryToSign,
   updateBeneficiaryQuestionary,
} from "../../lib/usersBEClient";
import { SnackBarContext } from "../snackBarContext";
import { b64toBlob, blobToBase64 } from "../../const/globalConst";
import { pdf } from "@react-pdf/renderer";
import { BenenficiaryIdentificationPDF } from "../../lib/React-PDF/BeneficiaryIdentification/BeneficiaryIdentificationPDF";
import SignService from "../../lib/signBEClient";
import { legalario } from "../../lib/Legalario/legalario";
import { BenenficiaryFideicomisoIdentificationPDF } from "../../lib/React-PDF/BeneficiaryIdentification/BeneficiaryFideicomisoIdentificationPDF";
import { getGoberningBodiesByCompanyBC } from "../../lib/gobCorpBEClient";
import { uploadFileToS3 } from "../../lib/s3Client";
import { BenenficiaryQuestionaryPDF } from "../../lib/React-PDF/BeneficiaryIdentification/BeneficiaryQuestionaryPDF";
import { IFolder } from "../../types/BaseTypes";

const environment =
   window.location.hostname === "test.web.lecosy.com.mx" || window.location.hostname === "www.test.web.lecosy.com.mx"
      ? "development"
      : process.env.NODE_ENV === "production"
      ? "production"
      : "development";

interface BeneficiaryQuestionaryContextType {
   questionary: IBeneficiaryQuestionary;
   selectedPossibleBeneficiary: IBeneficiary;
   externalToken: string;
   questionaryProgress: number;
   setQuestionary: Function;
   setSelectedPossibleBeneficiary: Function;
   handleSave: Function;
   handlePagination: Function;
   handleEndQuestionary: Function;
   refetch: Function;
   isLoading: boolean;
   currentPage: number;
   totalPages: number;
   saving: boolean;
   setSaving: Function;
   saved: boolean;
   setSaved: Function;
   error: boolean;
   setError: Function;
}

interface BeneficiaryQuestionaryState {
   questionary: IBeneficiaryQuestionary;
   selectedPossibleBeneficiary: IBeneficiary;
   externalToken: string;
}

const initialState: BeneficiaryQuestionaryState = {
   questionary: null,
   selectedPossibleBeneficiary: null,
   externalToken: null,
};

function userReducer(state: BeneficiaryQuestionaryState, action) {
   switch (action.type) {
      case "SET_QUESTIONARY":
         return { ...state, questionary: action.payload };
      case "SET_SELECTED_BENEFICIARY":
         return { ...state, selectedPossibleBeneficiary: action.payload };
      case "SET_TOKEN":
         return { ...state, externalToken: action.payload };
      default:
         return state;
   }
}

export const BeneficiaryQuestionaryContext = createContext<BeneficiaryQuestionaryContextType | undefined>(undefined);

export const BeneficiaryQuestionaryProvider = ({ children }) => {
   const [state, dispatch] = useReducer(userReducer, initialState);

   //#region Dispatch
   const setQuestionary = useCallback((state: BeneficiaryQuestionaryState) => {
      dispatch({ type: "SET_QUESTIONARY", payload: state });
   }, []);

   const setSelectedPossibleBeneficiary = useCallback((state: BeneficiaryQuestionaryState) => {
      dispatch({ type: "SET_SELECTED_BENEFICIARY", payload: state });
   }, []);

   const setExternalToken = useCallback((state: BeneficiaryQuestionaryState) => {
      dispatch({ type: "SET_TOKEN", payload: state });
   }, []);
   //#end region

   const { companyId, beneficiaryId } = useParams();
   //CONTEXT
   const { showSnackBar } = useContext(SnackBarContext);
   //STATES
   const [fetchKey, setFetchKey] = useState(0);
   const [isLoading, setIsLoading] = useState(false);
   const [currentPage, setCurrentPage] = useState(0);
   const [saving, setSaving] = useState(false);
   const [saved, setSaved] = useState(false);
   const [error, setError] = useState(false);
   const [initialPageSet, setInitialPageSet] = useState(false);
   //MEMO
   const totalPages = useMemo(() => {
      if (!state.questionary?.questions?.length) return 1;
      return Math.ceil(state.questionary.questions.length / 3);
   }, [state.questionary?.questions?.length]);

   const findFirstUnansweredQuestion = useCallback((questions, pageSize = 3, pageIndex = 0) => {
      for (let i = 0; i < questions.length; i++) {
         const question = questions[i];
         if (!question.answer?.length) return { index: pageIndex + i, found: true };
         for (const answer of question.answer ?? []) {
            if (answer.children?.length) {
               const result = findFirstUnansweredQuestion(answer.children, pageSize, pageIndex + i);
               if (result.found) return result;
            }
         }
      }
      return { index: -1, found: false };
   }, []);

   useEffect(() => {
      if (!state.questionary || initialPageSet) return;
      const { index } = findFirstUnansweredQuestion(state.questionary.questions);
      const currentPageTemp = index !== -1 ? Math.floor(index / 3) : totalPages - 1;
      setCurrentPage(currentPageTemp);
      setInitialPageSet(true);
   }, [state.questionary, initialPageSet, findFirstUnansweredQuestion]);

   const countQuestions = useCallback((questions = [], withAnswers = false) => {
      return questions.reduce((acc, question) => {
         if (!withAnswers || (question.answer?.length ?? 0) > 0) acc++;
         for (const answer of question.answer ?? []) {
            if (answer.children?.length) acc += countQuestions(answer.children, withAnswers);
         }
         return acc;
      }, 0);
   }, []);

   const questionaryProgress = useMemo(() => {
      if (!state.questionary?.questions?.length) return 0;
      const totalQuestionsWithAnswers = countQuestions(state.questionary.questions, true);
      const totalQuestions = countQuestions(state.questionary.questions);

      return totalQuestions ? (totalQuestionsWithAnswers * 100) / totalQuestions : 0;
   }, [saved, countQuestions, state.questionary]);

   const refetch = () => setFetchKey((prevKey) => prevKey + 1);

   const fetchQuestionary = useCallback(async () => {
      if (!companyId) return;
      if (!state.selectedPossibleBeneficiary?._id && !beneficiaryId) return;
      try {
         const response = await getBeneficiaryQuestionary(
            companyId,
            beneficiaryId ?? state.selectedPossibleBeneficiary._id
         );
         if (response) {
            if (state.questionary?._id !== response.questionary) {
               setQuestionary(response.questionary);
            }
            setExternalToken(response.token);
         }
      } catch (error) {
         console.error("Error fetching beneficiary questionary:", error);
      }
   }, [companyId, beneficiaryId, state.selectedPossibleBeneficiary, fetchKey]);

   useEffect(() => {
      fetchQuestionary();
      const intervalId = setInterval(fetchQuestionary, 300000); //INTERVAL TO REFRESH TOKEN 5MIN
      return () => clearInterval(intervalId);
   }, [fetchQuestionary]);

   const handleSave = async (questions) => {
      try {
         setSaving(true);
         setSaved(false);
         setError(false);
         const response = await updateBeneficiaryQuestionary(
            companyId,
            beneficiaryId ?? state.selectedPossibleBeneficiary._id,
            state.externalToken,
            questions
         );
         if (response) {
            if (response !== state.questionary) setQuestionary(response);
            setSaved(true);
            setSaving(false);
            setError(false);
         } else {
            setSaving(false);
            setError(true);
         }
         setTimeout(() => setSaved(false), 1000);
         return response;
      } catch (error) {
         setSaving(false);
         setError(true);
         console.error("Error saving beneficiary questionary:", error);
      }
   };

   const handlePagination = (direction: "next" | "prev") => {
      setCurrentPage((prevPage) => {
         if (direction === "next" && prevPage < totalPages - 1) return prevPage + 1;
         if (direction === "prev" && prevPage > 0) return prevPage - 1;
         return prevPage;
      });
   };

   const handleDocumentCreationAndSign = async (
      identifications: { type: string; value: string; chain?: string }[],
      assemblyFolder: IFolder,
      beneficiaryFolder: IFolder,
      beneficiary: IBeneficiary,
      type?: "Fideicomiso" | "Figura juridica"
   ) => {
      const beneficiaryName =
         beneficiary.businessName ||
         (beneficiary.user ? `${beneficiary.user.firstName} ${beneficiary.user.lastName}` : beneficiary.name);

      const fileName = `Identificación ${type || ""} - ${beneficiaryName}.pdf`;
      const userToSign = [
         {
            fullname: beneficiaryName,
            email: beneficiary?.user?.email || beneficiary.email,
            phone: beneficiary?.user?.phoneNumber || beneficiary.phoneNumber,
            type: "FIRMA",
         },
      ];

      const [company, logo] = await Promise.all([
         getCompanyByIdToken(companyId, state.externalToken),
         getUrlS3Token("images-lecosy", { folder: companyId, token: state.externalToken }, "logo.png"),
      ]);

      const pdfTemplate =
         type === "Fideicomiso"
            ? BenenficiaryFideicomisoIdentificationPDF({
                 logo,
                 company,
                 beneficiary,
                 fideicomiso: { deedNum: "TEMP", inscriptrionNum: "TEMP" },
                 identifications: identifications.filter((id) => id?.value?.toLowerCase().includes("fideicomiso")),
              })
            : BenenficiaryIdentificationPDF({
                 type: beneficiary.businessName ? "Moral" : "Fisica",
                 logo,
                 company,
                 beneficiary,
                 identifications: identifications.filter((id) => !id?.value?.toLowerCase().includes("fideicomiso")),
              });

      let blob = await pdf(pdfTemplate).toBlob();
      try {
         const base64 = await blobToBase64(blob);

         if (environment === "production") {
            const documentCreated = await SignService.createDocument({
               file: base64 as string,
               name: fileName,
               signers: userToSign,
            });

            const legalarioSDK = legalario();
            legalarioSDK.signature({
               signerId: documentCreated.document.signers[0].id,
               callbacks: {
                  onFinish: async () => {
                     const documentId = documentCreated.document.signers[0].user_document_id;
                     blob = b64toBlob((await SignService.downloadDocument(documentId)).document, "application/pdf");

                     const fileData = {
                        name: fileName,
                        owner: beneficiary.user?._id || undefined,
                        size: blob.size,
                        type: blob.type,
                        fileDirection: `beneficiaries/${companyId}/identification/${beneficiary._id}`,
                     };

                     const [responseFile] = await Promise.all([
                        createFileAsync({ ...fileData, folder: assemblyFolder._id }, state.externalToken),
                        createFileAsync({ ...fileData, folder: beneficiaryFolder._id }, state.externalToken),
                     ]);

                     await uploadFileToS3(responseFile.urlToUpload, blob);
                     await SignService.deleteDocument(documentId);

                     await changeSignState(
                        companyId,
                        beneficiary._id,
                        state.externalToken,
                        type === "Fideicomiso" ? "fideicomiso" : "general"
                     );
                     fetchQuestionary();
                  },
                  onCancel: async () => {
                     await SignService.deleteDocument(documentCreated.document.signers[0].user_document_id);
                  },
                  onError: async () => {},
               },
            });
         } else {
            const fileData = {
               name: fileName,
               owner: beneficiary.user?._id || undefined,
               size: blob.size,
               type: blob.type,
               fileDirection: `beneficiaries/${companyId}/identification/${beneficiary._id}`,
            };

            const [responseFile] = await Promise.all([
               createFileAsync({ ...fileData, folder: assemblyFolder._id }, state.externalToken),
               createFileAsync({ ...fileData, folder: beneficiaryFolder._id }, state.externalToken),
            ]);

            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);

            await uploadFileToS3(responseFile.urlToUpload, blob);
            await changeSignState(
               companyId,
               beneficiary._id,
               state.externalToken,
               type === "Fideicomiso" ? "fideicomiso" : "general"
            );
            fetchQuestionary();
         }
      } catch (error) {
         if (
            error?.response?.data?.message?.includes(
               '"success":false,"message":"El campo [document_id] es obligatorio"'
            )
         ) {
            const fileData = {
               name: fileName,
               owner: beneficiary.user?._id || undefined,
               size: blob.size,
               type: blob.type,
               fileDirection: `beneficiaries/${companyId}/identification/${beneficiary._id}`,
            };

            const [responseFile] = await Promise.all([
               createFileAsync({ ...fileData, folder: assemblyFolder._id }, state.externalToken),
               createFileAsync({ ...fileData, folder: beneficiaryFolder._id }, state.externalToken),
            ]);

            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);

            await uploadFileToS3(responseFile.urlToUpload, blob);
            await changeSignState(
               companyId,
               beneficiary._id,
               state.externalToken,
               type === "Fideicomiso" ? "fideicomiso" : "general"
            );
            fetchQuestionary();
         }
         setIsLoading(false);
         showSnackBar("Error al crear documento de identificación", true);
         console.error(error);
      }
   };

   const handleQuestionaryDoc = async (
      assemblyFolder: IFolder,
      beneficiaryFolder: IFolder,
      beneficiary: IBeneficiary
   ) => {
      const [company, logo] = await Promise.all([
         getCompanyByIdToken(companyId, state.externalToken),
         getUrlS3Token("images-lecosy", { folder: companyId, token: state.externalToken }, "logo.png"),
      ]);

      const fileName = `Cuestionario - ${
         beneficiary.businessName ||
         (beneficiary.user ? `${beneficiary.user.firstName} ${beneficiary.user.lastName}` : beneficiary.name)
      }.pdf`;

      const blob = await pdf(
         BenenficiaryQuestionaryPDF({
            questions: state.questionary.questions,
            company,
            logo,
            beneficiary,
            type: beneficiary.businessName ? "Moral" : "Fisica",
         })
      ).toBlob();

      const fileData = {
         name: fileName,
         owner: beneficiary.user?._id || undefined,
         size: blob.size,
         type: blob.type,
         fileDirection: `beneficiaries/${companyId}/identification/${beneficiary._id}`,
      };

      const [responseFile] = await Promise.all([
         createFileAsync({ ...fileData, folder: beneficiaryFolder._id }, state.externalToken),
         createFileAsync({ ...fileData, folder: assemblyFolder._id }, state.externalToken),
      ]);

      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      await uploadFileToS3(responseFile.urlToUpload, blob);
      await changeSignState(companyId, beneficiary._id, state.externalToken, "questionary");
      fetchQuestionary();
   };

   const handleEndQuestionary = async () => {
      try {
         setIsLoading(true);
         const governanceFound = await getGoberningBodiesByCompanyBC(companyId, state.externalToken);
         let beneficiary;
         if (beneficiaryId) beneficiary = (await getBeneficiaryById(beneficiaryId))?.beneficiary;
         else beneficiary = state.selectedPossibleBeneficiary;

         const { questionaryFound, beneficiaryAssemblyFolder, beneficiaryFolder, results } =
            await completeBeneficiaryQuestionary(
               companyId,
               beneficiary._id,
               state.externalToken,
               governanceFound[0]?._id
            );
         setQuestionary(questionaryFound);

         if (environment === "production" && !beneficiaryId) {
            await sendBeneficiaryToSign(beneficiary._id, companyId, state.externalToken);
            showSnackBar(
               "Cuestionario finalizado, favor de realizar firma en el enlace enviado al beneficiario",
               false
            );
            return setIsLoading(false);
         }

         if (state.questionary.signs.questionary === false) {
            await handleQuestionaryDoc(beneficiaryAssemblyFolder, beneficiaryFolder, beneficiary);
         }

         if (state.questionary.signs.general === false) {
            await handleDocumentCreationAndSign(
               results.references,
               beneficiaryAssemblyFolder,
               beneficiaryFolder,
               beneficiary
            );
         }

         if (state.questionary.signs.fideicomiso === false) {
            await handleDocumentCreationAndSign(
               results.references,
               beneficiaryAssemblyFolder,
               beneficiaryFolder,
               beneficiary,
               "Fideicomiso"
            );
         }

         //send initial request HERE

         setIsLoading(false);
      } catch (error) {
         console.error(error);
         setIsLoading(false);
      }
   };

   return (
      <BeneficiaryQuestionaryContext.Provider
         value={{
            ...state,
            isLoading,
            setQuestionary,
            setSelectedPossibleBeneficiary,
            questionaryProgress,
            handleSave,
            handlePagination,
            handleEndQuestionary,
            refetch,
            currentPage,
            totalPages,
            saving,
            setSaving,
            saved,
            setSaved,
            error,
            setError,
         }}
      >
         {children}
      </BeneficiaryQuestionaryContext.Provider>
   );
};
