import { Box, CircularProgress, Stack } from "@mui/material";
import { DragDropContext } from "react-beautiful-dnd";
import { ColumnTaskStatus } from "./SubComponents/ColumnTaskStatus";
import { useContext, useEffect, useState } from "react";
import { updateCommitmentStatus, updateTaskStatus, useGetResourceSelected } from "../../../lib/gobCorpBEClient";
import { SnackBarContext } from "../../../context/snackBarContext";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import { useParams } from "react-router-dom";

const columns = {
   PENDING: { title: "PENDIENTE", color: "#64748b" },
   PROCESS: { title: "EN PROCESO", color: "#3498DB" },
   REVIEW: { title: "EN REVISIÓN", color: "#F1B44C" },
   COMPLETE: { title: "FINALIZADO", color: "#009929" },
   EXPIRED: { title: "VENCIDOS", color: "red" },
};

const processStatus = (status: string) => {
   switch (status) {
      case "PENDIENTE":
         return "PENDING";
      case "FINALIZADO":
         return "COMPLETE";
      case "VENCIDOS":
         return "EXPIRED";
      case "EN REVISIÓN":
         return "REVIEW";
      case "EN PROCESO":
         return "PROCESS";
      default:
         break;
   }
};

export const KanbanBoardComponent = (props: { tasks: any[]; allUsers; isLoadingUsers }) => {
   const [tabs, setTabs] = useState(structuredClone(props.tasks));
   const resourceId = useGetResourceSelected();
   const { showSnackBar } = useContext(SnackBarContext);
   const { setRefetchTasks, companySelected, selectedGovernance, consultiveGroup } = useContext(GovernanceContext);
   const { id: governanceId, companyId } = useParams();
   const isConsultiveGroup =
      governanceId &&
      consultiveGroup?._id === companyId &&
      selectedGovernance?.title !== "Asamblea de accionistas" &&
      selectedGovernance?.company === consultiveGroup?._id;

   useEffect(() => {
      setTabs(structuredClone(props.tasks));
   }, [props.tasks]);

   const handleDnd = async ({ destination, draggableId, source }) => {
      if (!destination || (source.droppableId === destination.droppableId && source.index === destination.index))
         return;

      const tClone = [...tabs];
      const originalClone = [...tabs];
      const movedTaskIndex = tClone.findIndex((task) => task._id === draggableId);
      const movedTask = tClone[movedTaskIndex];
      movedTask.status = processStatus(destination.droppableId);
      tClone.splice(movedTaskIndex, 1);
      tClone.splice(destination.index, 0, movedTask);

      // if (source.droppableId === "VENCIDOS")
      //    return showSnackBar("No es posible realizar esa acción: Tarea vencida", true);
      if (destination.droppableId === "VENCIDOS") return showSnackBar("No es posible realizar esa acción", true);

      try {
         const status = movedTask.status;
         const updateFunction = movedTask.dueDate ? updateCommitmentStatus : updateTaskStatus;
         await updateFunction(movedTask._id, status, companySelected, resourceId);
         setTabs(tClone);
         setRefetchTasks((r) => !r);
      } catch (error) {
         console.error(error);
         setTabs(originalClone);
         showSnackBar("Error al actualizar la tarea", true);
      }
   };

   if (!props.tasks || props.isLoadingUsers)
      return (
         <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
            <CircularProgress />
         </Box>
      );
   return (
      <Box
         sx={{
            overflowX: "auto",
            width: selectedGovernance.title === "PANEL DE USUARIO" || isConsultiveGroup ? 1120 : 1380,
            minHeight: 400,
         }}
      >
         <DragDropContext onDragEnd={handleDnd}>
            <Stack
               direction={"row"}
               spacing={selectedGovernance.title === "PANEL DE USUARIO" || isConsultiveGroup ? 4 : 12}
               p={1}
               ml={2}
               sx={{ pb: 2 }}
            >
               {Object.keys(columns).map((col) => (
                  <ColumnTaskStatus
                     key={col}
                     title={columns[col].title}
                     color={columns[col].color}
                     tasks={props.tasks.filter((task) => task.status === col)}
                     allUsers={props.allUsers}
                  />
               ))}
            </Stack>
         </DragDropContext>
      </Box>
   );
};
