import React from "react";
import { Button, CircularProgress, Typography, Box } from "@mui/material";
import { CustomModalComponent } from "../../../CustomModalComponent";

interface PowersDeleteConfirmationModalProps {
   open: boolean;
   setOpen: (value: boolean) => void;
   onConfirm: () => void;
   userName: string;
   loading?: boolean;
}

const PowersDeleteConfirmationModal = ({
   open,
   setOpen,
   onConfirm,
   userName,
   loading = false,
}: PowersDeleteConfirmationModalProps) => {
   return (
      <CustomModalComponent open={open} setOpen={setOpen} title="Eliminar apoderado" timeStamp>
         <Box
            sx={{
               display: "flex",
               flexDirection: "column",
               p: 4,
               bgcolor: "white",
               minWidth: 400,
            }}
         >
            <Typography sx={{ textAlign: "center" }}>
               ¿Está seguro que desea eliminar a {userName} de la lista de apoderados?
            </Typography>
            <Typography
               sx={{
                  textAlign: "center",
                  fontStyle: "italic",
                  fontSize: 12,
                  color: "red",
                  mt: 1,
               }}
            >
               Una vez confirmado, el usuario quedará inhabilitado.
            </Typography>
         </Box>
         <Box
            sx={{
               display: "flex",
               justifyContent: "flex-end",
               bgcolor: "#F3F3F3",
               width: "100%",
               p: 1,
               height: 50,
               borderBottomRightRadius: 5,
               borderBottomLeftRadius: 5,
            }}
         >
            <Button
               onClick={() => setOpen(false)}
               disabled={loading}
               sx={{
                  color: "black",
                  height: 35,
                  ":hover": { bgcolor: "#E5E6EB" },
                  mr: 2,
               }}
            >
               Cancelar
            </Button>
            <Button
               onClick={onConfirm}
               disabled={loading}
               sx={{
                  height: 35,
                  ":hover": { bgcolor: "success" },
               }}
            >
               {!loading ? "Confirmar" : <CircularProgress size={24} />}
            </Button>
         </Box>
      </CustomModalComponent>
   );
};

export default PowersDeleteConfirmationModal;
