import { Box, Checkbox, FormControlLabel, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import { IQuestionaryAnswer } from "../../../../../types/beneficiaryTypes";
import { BeneficiaryQuestionaryContext } from "../../../../../context/beneficiaryContext/beneficiaryQuestionaryContext";

interface ChildQuestionComponentProps {
   child: IQuestionaryAnswer;
   mainQuestionIndex: number;
}

const ChildQuestionComponent = (props: ChildQuestionComponentProps) => {
   const { child, mainQuestionIndex } = props;
   const { handleSave, questionary, setSaving, setSaved, setError } = useContext(BeneficiaryQuestionaryContext);

   const handleUpdate = async (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
         const { questions } = questionary;
         const childQuestionId = child?.question?._id;
         let foundQuestion = null;
         let answerIndex = -1;
         let childrenIndex = -1;

         const findAnswerIndexRecursively = (childQuestion: any, childQuestionId: string): boolean => {
            if (!childQuestion?.answer) return false;

            for (let j = 0; j < childQuestion.answer.length; j++) {
               const answer = childQuestion.answer[j];
               if (!answer?.children) continue;
               const foundChild = answer.children.find((child) => child?.question?._id === childQuestionId);
               if (foundChild) {
                  foundQuestion = foundChild.question;
                  answerIndex = j;
                  childrenIndex = answer.children.findIndex((child) => child?.question?._id === childQuestionId);
                  return true;
               }

               for (const child of answer.children) {
                  const foundInChildren = findAnswerIndexRecursively(child, childQuestionId);
                  if (foundInChildren) {
                     answerIndex = j;
                     return true;
                  }
               }
            }

            return false;
         };

         const foundAnswer = findAnswerIndexRecursively(questions[mainQuestionIndex], childQuestionId);
         if (!foundAnswer || answerIndex === -1 || childrenIndex === -1) return;

         const { answers } = foundQuestion;
         const answerFound = answers.find((a) => a._id === event.target.value);

         if (!answerFound) return;

         const newSelectedAnswers = child.question.multiple
            ? event.target.checked
               ? [...child.answer, { ...answerFound }]
               : child.answer.filter((ans) => ans._id !== answerFound._id)
            : [{ ...answerFound }];

         const tempQuest: any = { ...questionary };

         const updateAnswerRecursively = (answerList: any[], childQuestionId: string, newAnswer: any) => {
            for (const answer of answerList) {
               if (answer.children?.length) {
                  for (let i = 0; i < answer.children.length; i++) {
                     if (answer.children[i]?.question?._id === childQuestionId) {
                        answer.children[i].answer = newAnswer;
                        return;
                     }
                  }
                  updateAnswerRecursively(
                     answer.children.flatMap((child) => child.answer),
                     childQuestionId,
                     newAnswer
                  );
               }
            }
         };
         updateAnswerRecursively(tempQuest.questions[mainQuestionIndex].answer, childQuestionId, newSelectedAnswers);
         await handleSave(tempQuest.questions);
      } catch (error) {
         console.error("Error:", error);
         setError(true);
         setSaving(false);
      }
   };

   return (
      <Box key={child?.question?._id} sx={{ pl: 3 }}>
         <Typography sx={{ fontSize: 14 }}>{child?.question?.question}</Typography>
         {child?.question?.multiple ? (
            <Stack sx={{ pl: 3 }}>
               {child?.question.answers.map((answer, index) => (
                  <FormControlLabel
                     key={answer._id || `answer-${index}`}
                     control={
                        <Checkbox
                           size="small"
                           checked={child?.answer.some((a) => a._id === answer._id)}
                           onChange={handleUpdate}
                           value={answer._id}
                        />
                     }
                     label={<Typography sx={{ fontSize: 14 }}>{answer.answer}</Typography>}
                  />
               ))}
            </Stack>
         ) : (
            <RadioGroup
               key={child?.question?.answers?.length}
               value={child?.answer?.[0]?._id}
               onChange={handleUpdate}
               sx={{ pl: 3 }}
            >
               {child?.question?.answers?.map((answer, aIndex) => (
                  <React.Fragment key={answer._id || `answer-${aIndex}`}>
                     <FormControlLabel
                        value={answer._id}
                        control={<Radio size="small" />}
                        label={<Typography sx={{ fontSize: 14 }}>{answer.answer}</Typography>}
                     />
                     {answer?.children?.length > 0 &&
                        child?.answer?.[0]?._id === answer._id &&
                        child?.answer?.[0]?.children?.map((child, index) => (
                           <ChildQuestionComponent
                              key={child?.question?._id || `child-${index}`}
                              child={child}
                              mainQuestionIndex={mainQuestionIndex}
                           />
                        ))}
                  </React.Fragment>
               ))}
            </RadioGroup>
         )}
      </Box>
   );
};

export default ChildQuestionComponent;
