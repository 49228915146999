import * as React from "react";
import { Typography, Grid, Divider } from "@mui/material";
import InfoRow from "../Subcomponents/InfoRow";
import ExpandableTextBox from "../../../screens/Beneficiary controller/ExpandableTextBox";
import { CustomDrawerComponent } from "../../CustomDrawerComponent";

const DeedInformationDrawer = ({ open, setOpen, row }) => {
   return (
      <CustomDrawerComponent title="Información de la escritura" open={open} setOpen={setOpen} width={1100}>
         <div>
            <Grid
               container
               direction="row"
               sx={{
                  width: "100%",
                  overflow: "hidden",
                  "& .MuiGrid-root": {
                     margin: 0,
                  },
                  marginTop: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
               }}
            >
               <Grid
                  item
                  sx={{
                     bgcolor: "#738090",
                     width: "99%",
                     height: 30,
                     margin: "auto",
                     border: "1px solid #ccc",
                     borderTopLeftRadius: "5px",
                     borderTopRightRadius: "5px",
                     textAlign: "center",
                     marginBottom: "20px",
                  }}
               >
                  <Typography variant="h6" sx={{ color: "white", fontWeight: "bold" }}>
                     Informacion de la sociedad
                  </Typography>
               </Grid>

               <Grid
                  container
                  sx={{
                     width: "100%",
                     display: "flex",
                     flexWrap: "nowrap",
                     marginTop: "20px",
                     fontWeight: "bold",
                  }}
               >
                  <InfoRow title="Escritura" content={row.deed} />
                  <InfoRow title="Tipo de acta" content={row.type} />
                  <InfoRow title="Fecha de protocolización" content={row.protocolization} />
                  <InfoRow title="Fecha de celebración" content={row.celebration} />
               </Grid>
            </Grid>
            <Divider sx={{ marginTop: "20px", marginBottom: "20px" }} />
            <Grid
               container
               sx={{
                  width: "100%",
                  display: "flex",
                  flexWrap: "nowrap",
                  marginTop: "20px",
                  fontWeight: "bold",
               }}
            >
               <InfoRow title="Fecha de Inscripción" content={row.registration} />
               <InfoRow title="Nombre del Notario" content={row.notaryName} />
               <InfoRow title="Número del notario" content={row.notaryOffice} />
               <InfoRow title="FEM" content={row.nominalValue} />
            </Grid>
            <Divider sx={{ marginTop: "20px", marginBottom: "20px" }} />
            <Grid
               container
               sx={{
                  width: "99%",
                  display: "flex",
                  height: "%100",
                  border: "1px solid #ccc",
                  borderTopLeftRadius: "7px",
                  borderTopRightRadius: "7px",
                  margin: "auto",
               }}
            >
               <Grid
                  item
                  sx={{
                     bgcolor: "#738090",
                     width: "100%",
                     height: 30,
                     margin: "auto",
                     border: "1px solid #ccc",
                     borderTopLeftRadius: "5px",
                     borderTopRightRadius: "5px",
                     textAlign: "center",
                     marginBottom: "20px",
                  }}
               >
                  <Typography variant="h6" sx={{ color: "white", fontWeight: "bold" }}>
                     Informacion de asamblea ordinaria
                  </Typography>
               </Grid>
               <Grid
                  container
                  direction="column"
                  sx={{
                     width: "100%",
                     display: "flex",
                     flexWrap: "nowrap",
                     fontWeight: "bold",
                     marginBottom: "20px",
                  }}
               >
                  <ExpandableTextBox />
                  <ExpandableTextBox />
               </Grid>
            </Grid>
         </div>
      </CustomDrawerComponent>
   );
};

export default DeedInformationDrawer;
