import React from "react";
import { Grid, Typography, Box, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { CustomModalComponent } from "../../components/CustomModalComponent";
import { useState } from "react";

// Estilos comunes para las celdas
const commonCellStyle = {
   width: "33%",
   fontSize: "12px",
   borderRadius: "8px",
   bgcolor: "#F0F2F3",
   textAlign: "center",
};

const BeneficiaryBlockBottom = ({ title, rows, tipo, nombre }) => {
   const [openD, setOpenD] = useState(false);
   const [openB, setOpenB] = useState(false);

   if (!rows || rows.length == !0) {
      return (
         <Grid
            container
            direction="column"
            sx={{
               width: "100%",
               borderRadius: "12px",
               overflow: "hidden",
               "& .MuiGrid-root": {
                  margin: 0,
               },
            }}
         >
            <Grid
               item
               sx={{
                  bgcolor: "#152C44",
                  p: 2,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  height: 20,
                  alignItems: "center",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
               }}
            >
               <Typography sx={{ color: "white", fontWeight: "bold" }}>{title}</Typography>
            </Grid>

            <Grid
               container
               sx={{
                  width: "100%",
                  marginTop: "10px",
                  border: "1px solid #CCCCCC",
                  borderBottomLeftRadius: "12px",
                  borderBottomRightRadius: "12px",
               }}
            >
               <Box
                  sx={{
                     display: "flex",
                     flexDirection: "column",
                     width: "90%",
                     margin: "auto",
                     marginTop: "10px",
                     gap: "8px",
                     marginBottom: "10px",
                  }}
               >
                  <Grid
                     container
                     sx={{
                        display: "flex",
                        height: "30px",
                        justifyContent: "space-between",
                        alignItems: "center",
                     }}
                  >
                     <Grid sx={{ ...commonCellStyle, width: "35%", color: "#768391", height: "28px" }}>Nombre</Grid>
                     <Grid
                        sx={{
                           ...commonCellStyle,
                           width: "64%",
                           fontWeight: "bold",
                           color: "black",
                           display: "flex",
                           justifyContent: "space-between",
                           alignItems: "center",
                           height: "28px",
                           whiteSpace: "pre-wrap",
                        }}
                     >
                        <Typography sx={{ marginLeft: "10px", fontSize: "12px" }}>{nombre}</Typography>
                     </Grid>
                  </Grid>

                  <Grid
                     container
                     sx={{
                        display: "flex",
                        height: "30px",
                        justifyContent: "space-between",
                        alignItems: "center",
                     }}
                  >
                     <Grid sx={{ ...commonCellStyle, width: "35%", color: "#768391", height: "28px" }}>
                        {tipo === "razon" ? "Razón" : "Delegados"}
                     </Grid>
                     <Grid
                        sx={{
                           ...commonCellStyle,
                           width: "64%",
                           fontWeight: "bold",
                           color: "black",
                           display: "flex",
                           justifyContent: "space-between",
                           alignItems: "center",
                           height: "28px",
                           whiteSpace: "pre-wrap",
                        }}
                     >
                        <Typography sx={{ marginLeft: "10px", fontSize: "12px" }}>
                           {rows && rows.length > 0 ? (tipo === "razon" ? rows[0].razon : rows[0].delegados) : "N/A"}
                        </Typography>
                     </Grid>
                  </Grid>

                  <Grid
                     container
                     sx={{
                        display: "flex",
                        height: "30px",
                        justifyContent: "space-between",
                        alignItems: "center",
                     }}
                  >
                     <Grid sx={{ ...commonCellStyle, width: "35%", color: "#768391", height: "28px" }}>Derechos</Grid>
                     <Grid
                        sx={{
                           ...commonCellStyle,
                           width: "64%",
                           fontWeight: "bold",
                           color: "black",
                           display: "flex",
                           justifyContent: "center",
                           alignItems: "center",
                           height: "28px",
                           whiteSpace: "pre-wrap",
                        }}
                     >
                        <IconButton sx={{ color: "#152C44" }} onClick={() => setOpenD(true)}>
                           <VisibilityIcon />
                        </IconButton>
                     </Grid>
                  </Grid>

                  <Grid
                     container
                     sx={{
                        display: "flex",
                        height: "30px",
                        justifyContent: "space-between",
                        alignItems: "center",
                     }}
                  >
                     <Grid sx={{ ...commonCellStyle, width: "35%", color: "#768391", height: "28px" }}>
                        Obligaciones
                     </Grid>
                     <Grid
                        sx={{
                           ...commonCellStyle,
                           width: "64%",
                           fontWeight: "bold",
                           color: "black",
                           display: "flex",
                           justifyContent: "center",
                           alignItems: "center",
                           height: "28px",
                           whiteSpace: "pre-wrap",
                        }}
                     >
                        <IconButton sx={{ color: "#152C44" }} onClick={() => setOpenB(true)}>
                           <VisibilityIcon />
                        </IconButton>
                     </Grid>
                  </Grid>
               </Box>
            </Grid>
            <CustomModalComponent open={openD} setOpen={setOpenD} onClose={() => setOpenD(false)} title="Derechos">
               <Box
                  sx={{
                     width: "90vw", // 90% del ancho de la ventana
                     maxWidth: "600px", // Tamaño máximo fijo
                     minHeight: "400px",
                     height: "auto",
                     display: "flex",
                     flexDirection: "column",
                     alignItems: "flex-start",
                     bgcolor: "#F0F2F3",
                     borderRadius: "8px",
                     padding: "16px",
                     overflowY: "auto",
                  }}
               >
                  {rows && rows.length > 0
                     ? rows[0].derechos.split("\n").map((line, index) => (
                          <Box
                             key={index}
                             sx={{
                                width: "100%",
                                padding: "8px",
                                bgcolor: "white",
                                borderRadius: "8px",
                                marginBottom: "8px",
                                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                             }}
                          >
                             {line.trim()}
                          </Box>
                       ))
                     : "N/A"}
               </Box>
            </CustomModalComponent>
            <CustomModalComponent open={openB} setOpen={setOpenB} onClose={() => setOpenB(false)} title="Obligaciones">
               <Box
                  sx={{
                     width: "90vw", // 90% del ancho de la ventana
                     maxWidth: "600px", // Tamaño máximo fijo
                     minHeight: "400px",
                     height: "auto",
                     display: "flex",
                     flexDirection: "column",
                     alignItems: "flex-start",
                     bgcolor: "#F0F2F3",
                     borderRadius: "8px",
                     padding: "16px",
                     overflowY: "auto",
                  }}
               >
                  {rows && rows.length > 0
                     ? rows[0].obligaciones.split("\n").map((line, index) => (
                          <Box
                             key={index}
                             sx={{
                                width: "100%",
                                padding: "8px",
                                bgcolor: "white",
                                borderRadius: "8px",
                                marginBottom: "8px",
                                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                             }}
                          >
                             {line.trim()}
                          </Box>
                       ))
                     : "N/A"}
               </Box>
            </CustomModalComponent>
         </Grid>
      );
   }

   return null; //Si no hay nada no aparecen xd
};

export default BeneficiaryBlockBottom;
