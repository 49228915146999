import React from "react";
import { Outlet, Route } from "react-router-dom";
import { RootBoundary } from "../screens/ErrorScreen";
import { ExternalGovernanceSessionProvider } from "../context/governanceContext/externalSessionContext";
import { SnackBarComponent } from "../components/SnackBarComponent";
import ExternalSession from "../screens/Gobierno corporativo/ExternalSession";
import { ExternalLegalarioContextProvider } from "../context/governanceContext/ExternalLegalarioContext";
import { BeneficiaryControllerExternal } from "../screens/Beneficiary controller/BeneficiaryControllerExternal";
import LegalarioMobile from "../screens/Gobierno corporativo/Legalario/LegalarioMobile";
import ExternalQuestionaryScreen from "../screens/Beneficiary controller/Questionary/ExternalQuestionaryScreen";
import { BeneficiaryQuestionaryProvider } from "../context/beneficiaryContext/beneficiaryQuestionaryContext";

export const nonProtectedRoutes = () => {
   return (
      <Route element={<Outlet />} errorElement={<RootBoundary />}>
         {/* GOVERNANCE */}
         <Route path="/sesion/:sessionId/:userId" element={<Outlet />}>
            <Route
               path=""
               element={
                  <ExternalGovernanceSessionProvider>
                     <SnackBarComponent />
                     <ExternalSession />
                  </ExternalGovernanceSessionProvider>
               }
            />
            <Route
               path=":action"
               element={
                  <ExternalLegalarioContextProvider>
                     <SnackBarComponent />
                     <LegalarioMobile />
                  </ExternalLegalarioContextProvider>
               }
            />
         </Route>
         {/* BENEFICIARY */}
         <Route path="/beneficiario-controlador/externo" element={<Outlet />}>
            <Route
               path="identificacion/:companyId/:beneficiaryId"
               element={
                  <BeneficiaryQuestionaryProvider>
                     <SnackBarComponent />
                     <ExternalQuestionaryScreen />
                  </BeneficiaryQuestionaryProvider>
               }
            />
            <Route path=":beneficiaryId/:requestChangesId" element={<Outlet />}>
               <Route path="" element={<BeneficiaryControllerExternal />} />
               <Route path=":action" element={<BeneficiaryControllerExternal />} />
            </Route>
         </Route>
      </Route>
   );
};
