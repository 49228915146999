import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import { useContext } from "react";
import { CorporateDataContext } from "../../../context/governanceContext/corporateDataContext";
import { UserContext } from "../../../context/userContext";

const InfoRowFide = () => {
   const { companySelected } = useContext(UserContext);
   const { corporateData } = useContext(CorporateDataContext);

   const data = [
      { title: "Denominacion o razon social", content: companySelected.person_details.businessName },
      { title: "RFC", content: companySelected.invoice_details.rfc },
      { title: "Nombre comercial", content: companySelected.person_details.comercialName },
      { title: "Figura juridica", content: corporateData.legalConcept },
      {
         title: "Dirección",
         content: companySelected.invoice_details.suburb + " " + companySelected.invoice_details.address,
      },
   ];

   return (
      <Grid
         container
         direction="column"
         sx={{
            width: "100%",
            border: "1px solid #ccc",
            borderRadius: "12px",
            overflow: "hidden",
            "& .MuiGrid-root": {
               margin: 0,
            },
         }}
      >
         <Grid
            item
            sx={{
               bgcolor: "#738090",
               p: 2,
               width: "100%",
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               height: 20,
               borderTopLeftRadius: "12px",
               borderTopRightRadius: "12px",
            }}
         >
            <Typography variant="h6" sx={{ color: "white", fontWeight: "bold" }}>
               Informacion mas actualizada de la empresa
            </Typography>
         </Grid>

         <Grid container sx={{ width: "100%" }}>
            {data.map((item, index) => (
               <Grid
                  item
                  xs={6}
                  key={index}
                  sx={{
                     p: 2,
                     display: "flex",
                     alignItems: "center",
                     bgcolor: "white",
                     height: "25px",
                     justifyContent: "space-between",
                     textAlign: "left",
                  }}
               >
                  <Typography variant="subtitle2" sx={{ mr: 2, flex: 1 }}>
                     {item.title}
                  </Typography>
                  <Box
                     sx={{
                        bgcolor: "#f2f3f5",
                        p: 1,
                        width: "375px",
                        height: "25px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "left",
                        borderRadius: "5px",
                     }}
                  >
                     <Typography>{item.content}</Typography>
                  </Box>
               </Grid>
            ))}
            {/* Si el número de elementos es impar, agregar un Grid vacío */}
            {data.length % 2 !== 0 && (
               <Grid
                  item
                  xs={6}
                  sx={{
                     p: 2,
                     display: "flex",
                     alignItems: "center",
                     bgcolor: "white",
                     justifyContent: "space-between",
                     borderLeft: "2px solid white",
                     borderRight: "2px solid white",
                  }}
               ></Grid>
            )}
         </Grid>
      </Grid>
   );
};

export default InfoRowFide;
