import { CustomModalComponent } from "../../../../CustomModalComponent";
import { Form, Formik, FormikProps } from "formik";
import { useTheme, Grid, Button, useMediaQuery, Typography, Box } from "@mui/material";
import { InputTextField } from "../../../../Inputs/InputTextField";
import { CoOwnerSchemaModal } from "../../../../../lib/validations/inputSchemas";
import { useEffect, useState } from "react";
import { getAllCoOwners, createCoOwner } from "../../../../../lib/usersBEClient";

const BeneficiaryCoOwnerModal = ({ open, setOpen, onClose }) => {
   const theme = useTheme();
   const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const [coOnwers, setCoOwners] = useState([]);

   useEffect(() => {
      const getOwners = async () => {
         const owners = await getAllCoOwners();
         setCoOwners(owners);
      };
      getOwners();
   }, []);

   const handleSubmit = (values) => {
      onClose();
      createCoOwner(values);
   };

   return (
      <CustomModalComponent open={open} setOpen={setOpen} title="Copropiedad" onClose={onClose}>
         <Formik
            initialValues={{
               object: "",
               contributionsCoOwner: "",
               rights: "",
               obligations: "",
               commonRepresentative: "",
               businessSupervisor: "",
               validity: "",
               termination: "",
            }}
            onSubmit={handleSubmit}
            validationSchema={CoOwnerSchemaModal}
         >
            {(formProps: FormikProps<any>) => (
               <Form onSubmit={formProps.handleSubmit}>
                  <Box
                     sx={{
                        width: "100%",
                        maxWidth: "800px",
                        minWidth: "100%",
                        height: "auto",
                        maxHeight: "600px",
                        minHeight: "400px",
                        margin: "auto",
                        overflowY: "auto",
                        overflowX: "hidden",
                        padding: isSmallScreen ? 1 : 2,
                     }}
                  >
                     <Grid container spacing={2}>
                        <Grid item xs={12}>
                           <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                              Objeto
                           </Typography>
                           <InputTextField
                              id="object"
                              type="text"
                              name="object"
                              fullWidth
                              size="small"
                              sx={{ marginBottom: "8px" }}
                           />
                        </Grid>

                        <Grid item xs={12}>
                           <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                              Aportaciones de los copropietarios
                           </Typography>
                           <InputTextField
                              id="contributionsCoOwner"
                              type="text"
                              name="contributionsCoOwner"
                              fullWidth
                              size="small"
                              sx={{ marginBottom: "8px" }}
                           />
                        </Grid>

                        <Grid item xs={12}>
                           <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                              Derechos
                           </Typography>
                           <InputTextField
                              id="rights"
                              type="text"
                              name="rights"
                              fullWidth
                              multiline
                              rows={3}
                              sx={{ marginBottom: "8px" }}
                           />
                        </Grid>

                        <Grid item xs={12}>
                           <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                              Obligaciones
                           </Typography>
                           <InputTextField
                              id="obligations"
                              type="text"
                              name="obligations"
                              fullWidth
                              multiline
                              rows={3}
                              sx={{ marginBottom: "8px" }}
                           />
                        </Grid>

                        <Grid item xs={10}>
                           <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                              Representante común
                           </Typography>
                           <InputTextField
                              id="commonRepresentative"
                              type="text"
                              name="commonRepresentative"
                              fullWidth
                              size="small"
                              sx={{ marginBottom: "8px" }}
                           />
                        </Grid>

                        <Grid item xs={10}>
                           <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                              Supervisión y desarrollo del negocio
                           </Typography>
                           <InputTextField
                              id="businessSupervisor"
                              type="text"
                              name="businessSupervisor"
                              fullWidth
                              size="small"
                              sx={{ marginBottom: "8px" }}
                           />
                        </Grid>

                        <Grid item xs={12} md={5}>
                           <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                              Vigencia
                           </Typography>
                           <InputTextField
                              id="validity"
                              type="text"
                              name="validity"
                              fullWidth
                              size="small"
                              label="Duración"
                              sx={{ marginBottom: "8px" }}
                           />
                        </Grid>

                        <Grid item xs={10} md={5}>
                           <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                              Terminación
                           </Typography>
                           <InputTextField id="termination" type="date" name="termination" fullWidth size="small" />
                        </Grid>

                        <Grid item xs={12}>
                           <Typography variant="body2" sx={{ color: "#152746", marginBottom: "5px" }}>
                              Usar el formato 2a 10m 17d
                           </Typography>
                           <Typography
                              variant="body2"
                              sx={{ color: "#152746", marginBottom: "5px", fontSize: "13px", marginLeft: "0px" }}
                           >
                              a = Años | m = Meses | d = Días
                           </Typography>
                        </Grid>
                     </Grid>
                  </Box>
                  <Box
                     sx={{
                        bgcolor: "#F5F5F5",
                        borderTop: "1px solid #ccc",
                        padding: 1,
                        display: "flex",
                        height: "auto",
                        justifyContent: "flex-end",
                        gap: 1,
                        width: "100%",
                     }}
                  >
                     <Button variant="text" type="button" sx={{ color: "#ABAFB1" }} onClick={onClose}>
                        Cerrar
                     </Button>
                     <Button variant="text" type="submit" sx={{ color: "#464F60" }}>
                        Guardar
                     </Button>
                  </Box>
               </Form>
            )}
         </Formik>
      </CustomModalComponent>
   );
};

export default BeneficiaryCoOwnerModal;
