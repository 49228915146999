import {
   Box,
   Button,
   CircularProgress,
   Divider,
   IconButton,
   List,
   ListItem,
   ListItemButton,
   ListItemText,
   Stack,
   Typography,
} from "@mui/material";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
   MoveFileQuery,
   MoveFolderQuery,
   beneficiaryMoveFile,
   getFoldersAndFilesById,
   useGetResourceSelected,
} from "../../../../lib/usersBEClient";
import { getIconByType } from "../../../../screens/Gobierno corporativo/FolderScreen";
import { formatFileSize, getDateAndTimeFromISODate } from "../../../../const/globalConst";
import { ArrowBack, Folder } from "@mui/icons-material";
import { SnackBarContext } from "../../../../context/snackBarContext";
import { UserContext } from "../../../../context/userContext";
import { ConfirmationModal } from "../../../ConfirmationModal";
import { useParams } from "react-router-dom";
import { FilesContext } from "../../../../context/governanceContext/filesContext";
import { CorporateDataContext } from "../../../../context/governanceContext/corporateDataContext";

const useGetFiles = () => {
   const { companyId } = useParams();
   const { beneficiary } = useContext(FilesContext);
   const { corporateData } = useContext(CorporateDataContext);
   const [isLoading, setIsLoading] = useState(true);
   const [selectedFoldersId, setSelectedFoldersId] = useState<any[]>([]);
   const [files, setFiles] = useState([]);

   const fetchBeneficiaryFolder = useCallback(async () => {
      setIsLoading(true);
      try {
         const folderId =
            !selectedFoldersId || selectedFoldersId.length === 0
               ? beneficiary.folderId
               : selectedFoldersId[selectedFoldersId.length - 1]._id;
         const response = await getFoldersAndFilesById([folderId], companyId);
         const filesFromResponse = [...(response?.folder?.children || []), ...(response?.files || [])];
         const filteredFiles = filesFromResponse
            .filter((file) => !(corporateData?.societyKind === "Sociedad civil" && file.name === "Titulo accionario"))
            .sort((a, b) => a.name.localeCompare(b.name));
         setFiles(filteredFiles);
      } finally {
         setIsLoading(false);
      }
   }, [beneficiary, selectedFoldersId, corporateData, companyId]);

   useEffect(() => {
      fetchBeneficiaryFolder();
   }, [fetchBeneficiaryFolder]);

   const processedData = useMemo(
      () =>
         files.map(({ size, name, updatedAt, owner, ...rest }) => {
            const common = {
               ...rest,
               updatedAt: getDateAndTimeFromISODate(updatedAt),
            };
            if (size) {
               const originalName = name.split(".").slice(0, -1).join(".");
               return {
                  ...common,
                  name: (
                     <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                        {getIconByType(rest.type)}
                        <Typography variant="subtitle2">{name}</Typography>
                     </Box>
                  ),
                  originalName,
                  owner: owner ? `${owner.firstName} ${owner.lastName}` : "---",
                  size: formatFileSize(size),
               };
            }

            return {
               ...common,
               type: "carpeta",
               name: (
                  <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
                     <Folder sx={{ color: "#F1B44C", fontSize: 25 }} />
                     <Typography variant="subtitle2">{name}</Typography>
                  </Box>
               ),
               size: "---",
               originalName: name,
            };
         }),
      [files]
   );

   return { isLoading, processedData, selectedFoldersId, setSelectedFoldersId };
};

export const MoveFileBeneficiary = ({ selectedFile, setOpenModal, reload, setReload, handleClose }) => {
   const { processedData, isLoading, selectedFoldersId, setSelectedFoldersId } = useGetFiles();
   const [openModal2, setOpenModal2] = useState(false);
   const [isLoadingFile, setIsLoadingFile] = useState(false);
   const lastSelected = selectedFoldersId[selectedFoldersId.length - 1];
   const { showSnackBar } = useContext(SnackBarContext);

   const handleMoveFile = async () => {
      try {
         setIsLoadingFile(true);
         await beneficiaryMoveFile(selectedFile._id, lastSelected._id);
         showSnackBar("El archivo fue movido correctamente.", false);
         setIsLoadingFile(false);
         setOpenModal(false);
         setReload(!reload);
         handleClose();
      } catch (error) {
         setIsLoadingFile(false);
      }
   };

   return (
      <Stack>
         <Box sx={{ width: 600, height: 400, overflow: "auto" }}>
            {selectedFoldersId.length > 0 && (
               <Box sx={{ p: 1, display: "flex", alignItems: "center" }}>
                  <IconButton onClick={() => setSelectedFoldersId((prev) => prev.slice(0, -1))}>
                     <ArrowBack />
                  </IconButton>
                  <Typography>{lastSelected.originalName}</Typography>
               </Box>
            )}
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
               {isLoading ? (
                  <Box sx={{ display: "flex", py: 5, justifyContent: "center", alignItems: "center" }}>
                     <CircularProgress />
                  </Box>
               ) : (
                  <List sx={{ flex: 1 }} dense>
                     {processedData.map((item, index) => (
                        <div key={item.name}>
                           <ListItemButton
                              onClick={() => {
                                 if (item.type === "carpeta") {
                                    setSelectedFoldersId((prev) => {
                                       const newValue = [...prev, item];
                                       return newValue;
                                    });
                                 }
                              }}
                              disabled={item.type !== "carpeta"}
                           >
                              <ListItem>
                                 <ListItemText primary={item.name} />
                              </ListItem>
                           </ListItemButton>
                           {index < processedData.length - 1 && <Divider sx={{ backgroundColor: "#f9f9f9" }} />}
                        </div>
                     ))}
                  </List>
               )}
            </Box>
         </Box>
         <Box sx={{ display: "flex", columnGap: 1, p: 2 }}>
            <Button variant="outlined" fullWidth onClick={() => setOpenModal(false)} disabled={isLoading}>
               Cancelar
            </Button>
            <Button
               variant="contained"
               fullWidth
               disabled={isLoading || selectedFoldersId.length === 0}
               onClick={() => setOpenModal2(true)}
            >
               {isLoadingFile ? <CircularProgress size={24} sx={{ color: "white" }} /> : "Mover"}
            </Button>
         </Box>
         <ConfirmationModal
            open={openModal2}
            setOpen={setOpenModal2}
            onConfirm={handleMoveFile}
            isLoading={isLoadingFile}
            body={
               <Box sx={{ pt: 2 }}>
                  <Typography align="center">
                     ¿Esta seguro que desea mover el archivo: {selectedFile.originalName} a la carpeta:{" "}
                     {selectedFoldersId.length > 0 ? lastSelected.originalName : null}?
                  </Typography>
               </Box>
            }
            title={"Confirmar cambio"}
         />
      </Stack>
   );
};
