import React, { useContext, useMemo } from "react";
import { CustomModalComponent } from "../../../../CustomModalComponent";
import { Box, Button, CircularProgress, Fade, Stack, Typography } from "@mui/material";
import { BeneficiaryQuestionaryContext } from "../../../../../context/beneficiaryContext/beneficiaryQuestionaryContext";
import QuestionaryStatusbar from "./QuestionaryStatusbar";
import BeneficiaryQuestionComponent from "./BeneficiaryQuestionComponent";

interface BeneficiaryQuestionaryModalProps {
   state: boolean;
   setState?: Function;
}

const BeneficiaryQuestionaryModal = (props: BeneficiaryQuestionaryModalProps) => {
   const { state, setState } = props;
   const {
      setSelectedPossibleBeneficiary,
      setQuestionary,
      isLoading,
      handleSave,
      handleEndQuestionary,
      currentPage,
      questionary,
      saving,
      saved,
      error,
      setSaving,
      setSaved,
      setError,
      questionaryProgress,
   } = useContext(BeneficiaryQuestionaryContext);

   const handleClose = () => {
      setSelectedPossibleBeneficiary(null);
      setQuestionary(null);
      setSaving(false);
      setSaved(false);
      setError(false);
   };

   const completed = useMemo(
      () => questionary?.signs && Object.values(questionary?.signs).every(Boolean),
      [questionary?.signs]
   );

   return (
      <CustomModalComponent
         title="Identificación de beneficiario controlador"
         open={state}
         setOpen={setState ?? null}
         timeStamp
         onClose={handleClose}
         maxWidth={"95%"}
      >
         <Box
            sx={{
               opacity: completed ? 0.3 : 1,
               pointerEvents: completed ? "none" : "auto",
               overflow: completed ? "hidden" : "visible",
            }}
         >
            {questionary?.questions?.length > 0 ? (
               <div>
                  <QuestionaryStatusbar />
                  <Stack
                     spacing={2}
                     key={currentPage}
                     sx={{
                        px: { xs: 2, lg: 4 },
                        pb: 2,
                        height: { xs: "60vh", lg: 450 },
                        maxWidth: { xs: "100%", lg: 800 },
                        overflowY: "auto",
                     }}
                  >
                     {questionary?.questions.slice(currentPage * 3, (currentPage + 1) * 3).map((question, index) => (
                        <BeneficiaryQuestionComponent
                           key={question.question._id}
                           question={question.question}
                           index={currentPage * 3 + index}
                           multiple={question.question.multiple}
                        />
                     ))}
                  </Stack>
               </div>
            ) : (
               <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", py: 4 }}>
                  <CircularProgress />
               </Box>
            )}

            <Box
               sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  bgcolor: "#F3F3F3",
                  width: "100%",
                  p: 1,
                  height: 50,
                  borderBottomRightRadius: 5,
                  borderBottomLeftRadius: 5,
               }}
            >
               <Fade in={saving || saved || error} timeout={{ enter: 500, exit: 500 }}>
                  <Typography sx={{ fontSize: 12, color: error ? "red" : "gray", flex: 1, px: 1 }}>
                     {saving ? "Guardando..." : error ? "Error al guardar respuesta" : "Guardado con éxito"}
                  </Typography>
               </Fade>
               <Box sx={{ display: "flex", alignItems: "center" }}></Box>
               <Button
                  onClick={() => {
                     handleClose();
                     setState(false);
                  }}
                  sx={{
                     color: "#c4c4c4",
                     height: 35,
                     ":hover": { bgcolor: "#E5E6EB" },
                     mr: 2,
                     display: setState ? "inherit" : "none",
                  }}
               >
                  Cancelar
               </Button>
               {questionaryProgress === 100 ? (
                  <Button
                     type="submit"
                     disabled={isLoading}
                     sx={{ height: 35, ":hover": { bgcolor: "success" } }}
                     onClick={async () => {
                        await handleEndQuestionary();
                        setState && setState(false);
                     }}
                  >
                     {isLoading ? <CircularProgress size={25} /> : "Continuar"}
                  </Button>
               ) : (
                  <Button
                     disabled={isLoading}
                     sx={{ height: 35, ":hover": { bgcolor: "success" } }}
                     onClick={() => handleSave(questionary.questions)}
                  >
                     {isLoading ? <CircularProgress size={25} /> : "Guardar"}
                  </Button>
               )}
            </Box>
         </Box>
         {completed && (
            <Typography
               sx={{
                  textAlign: "center",
                  color: "#162c44",
                  fontSize: 20,
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "100%",
               }}
            >
               El beneficiario controlador ha sido identificado. Gracias.
            </Typography>
         )}
      </CustomModalComponent>
   );
};

export default BeneficiaryQuestionaryModal;
