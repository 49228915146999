import React from "react";
import { Table, TableHead, TableRow, TableCell, TableBody, Grid, Typography } from "@mui/material";
import { d } from "msw/lib/SetupApi-f4099ef3";

const commonCellStyle = {
   color: "white",
   fontWeight: "bold",
   textAlign: "center",
   fontSize: "14px",
   border: "1px solid #CCCCCC",
   borderRadius: "8px",
};

const secondRowCellStyle = {
   color: "black",
   height: "30px",
   fontSize: "12px",
   bgcolor: "#F0F2F3",
   justifyContent: "center",
   textAlign: "center",
   borderRadius: "8px",
};

const BeneficiaryOrganizacionComite = ({ rows, title, presidente }) => {
   const rowHeight = 36;
   const headerHeight = 40;
   const maxRows = 5;
   const maxHeight = maxRows * rowHeight + headerHeight;

   const sortedRows = presidente ? [{ nombre: presidente, cargo: "Presidente" }, ...rows] : rows;

   return (
      <Grid
         container
         direction="column"
         sx={{
            width: "100%",
            height: "100%",
            maxHeight: `${maxHeight}px`,
            borderRadius: "12px",
            overflow: "hidden",
            "& .MuiGrid-root": {
               margin: 0,
            },
         }}
      >
         <Grid
            item
            sx={{
               bgcolor: "#152C44",
               p: 2,
               width: "100%",
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               height: 20,
               borderTopLeftRadius: "8px",
               borderTopRightRadius: "8px",
            }}
         >
            <Typography sx={{ color: "white", fontWeight: "bold" }}>{title}</Typography>
         </Grid>

         <Grid
            item
            sx={{
               width: "100%",
               height: `calc(100% - ${headerHeight}px)`,
               marginTop: "10px",
               overflowY: sortedRows.length >= maxRows - 2 ? "auto" : "hidden",
               borderBottomLeftRadius: "8px",
               borderBottomRightRadius: "8px",
               border: "1px solid #CCCCCC",
            }}
         >
            {rows.length !== 0 && (
               <>
                  <Table
                     sx={{
                        width: "86%",
                        margin: "auto",
                        height: "96%",
                        borderCollapse: "separate",
                        borderSpacing: "4px 4px",
                     }}
                  >
                     <TableHead>
                        <TableRow
                           sx={{
                              bgcolor: "#738090",
                              height: "10px",
                              "& .MuiTableCell-root": {
                                 padding: "5px",
                                 border: "1px solid #CCCCCC",
                                 borderRadius: "8px",
                              },
                           }}
                        >
                           <TableCell
                              sx={{
                                 ...commonCellStyle,
                                 borderTopLeftRadius: "8px",
                                 borderBottomLeftRadius: "8px",
                              }}
                           >
                              Nombre
                           </TableCell>
                           <TableCell
                              sx={{
                                 ...commonCellStyle,
                                 borderTopRightRadius: "8px",
                                 borderBottomRightRadius: "8px",
                              }}
                           >
                              Cargo
                           </TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {sortedRows.map((row, index) => (
                           <TableRow
                              key={index}
                              sx={{
                                 bgcolor: "white",
                                 height: 10,
                                 "& .MuiTableCell-root": {
                                    padding: "5px",

                                    borderRadius: "8px",
                                 },
                              }}
                           >
                              <TableCell
                                 sx={{
                                    ...secondRowCellStyle,
                                    borderTopLeftRadius: "8px",
                                    borderBottomLeftRadius: "8px",
                                    height: 10,
                                 }}
                              >
                                 {row.nombre}
                              </TableCell>
                              <TableCell
                                 sx={{
                                    ...secondRowCellStyle,
                                    borderTopRightRadius: "8px",
                                    borderBottomRightRadius: "8px",
                                    fontWeight: "Bold",
                                    height: 10,
                                 }}
                              >
                                 {row.cargo}
                              </TableCell>
                           </TableRow>
                        ))}
                     </TableBody>
                  </Table>
               </>
            )}
            {rows.length === 0 && (
               <Grid
                  item
                  sx={{
                     width: "100%",
                     height: `calc(100% - ${headerHeight}px)`,
                     marginTop: "10px",
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                     borderBottomLeftRadius: "8px",
                     borderBottomRightRadius: "8px",
                  }}
               >
                  <Typography sx={{ color: "black", fontWeight: "bold", textAlign: "center" }}>
                     No cuenta con comite técnico
                  </Typography>
               </Grid>
            )}
         </Grid>
      </Grid>
   );
};

export default BeneficiaryOrganizacionComite;
