import { Box, CircularProgress, Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useState, useEffect, useCallback, useContext } from "react";
import { getAllFideicomiso, getAllCoOwners, getAllCoInvestment } from "../../../../lib/usersBEClient";
import { UserContext } from "../../../../context/userContext";
import { AddCircle, FormatListBulletedRounded } from "@mui/icons-material";
import BeneficiaryFideicomisoForm from "./Modals/BeneficiaryFideicomisoForm";
import BeneficiaryCoOwnerModal from "./Modals/BeneficiaryCo-OwnerModal";
import BeneficiaryCoInvestmentModal from "./Modals/BeneficiaryCoInvestmentModal";
import FideiComisoDrawer from "./Drawers/FideiComisoDrawer";

const LegalFigures = () => {
   //CONTEXT
   const { companySelected } = useContext(UserContext);
   //STATES
   const [isLoading, setIsLoading] = useState(null);
   const [fideicomisosData, setFideicomisosData] = useState([]);
   const [copropiedadData, setCopropiedadData] = useState([]);
   const [coinversionData, setCoinversionData] = useState([]);
   ////MODALS
   const [openFideicomisoModal, setOpenFideicomisoModal] = useState(false);
   const [openCopropiedadModal, setOpenCopropiedadModal] = useState(false);
   const [openCoinversionModal, setOpenCoinversionModal] = useState(false);
   ////DRAWERS
   const [openFideicomisoDrawer, setOpenFideicomisoDrawer] = useState(false);

   const fetchData = useCallback(async () => {
      try {
         setIsLoading(true);
         const [fideicomisos, copropietarios, coinversiones] = await Promise.all([
            getAllFideicomiso(),
            getAllCoOwners(),
            getAllCoInvestment(),
         ]);
         setFideicomisosData(fideicomisos);
         setCopropiedadData(copropietarios);
         setCoinversionData(coinversiones);
         setIsLoading(false);
      } catch (error) {
         console.error("Error fetching data:", error);
      }
   }, [companySelected]);

   useEffect(() => {
      fetchData();
   }, [fetchData]);

   const handleModalClose = () => {
      setOpenFideicomisoModal(false);
      setOpenCopropiedadModal(false);
      setOpenCoinversionModal(false);
   };

   const openCorrectModal = (legalFigure: string, type: string) => {
      if (type === "modal") {
         switch (legalFigure) {
            case "Fideicomiso":
               return setOpenFideicomisoModal(!openFideicomisoModal);
            case "Copropiedad":
               return setOpenCopropiedadModal(!openCopropiedadModal);
            case "Coinversion":
               return setOpenCoinversionModal(!openCoinversionModal);
            default:
               break;
         }
      }
      switch (legalFigure) {
         case "Fideicomiso":
            return setOpenFideicomisoDrawer(!openFideicomisoDrawer);
         default:
            break;
      }
   };

   const LegalFigure = ({ title, length, disabled }: { title: string; length: number; disabled?: boolean }) => (
      <Stack spacing={0.5} sx={{ width: 300, px: 1, opacity: disabled ? 0.5 : 1 }}>
         <Box sx={{ bgcolor: "#73808F", display: "flex", alignItems: "center", borderRadius: 1 }}>
            <Typography sx={{ textAlign: "center", fontSize: 15, color: "white", flex: 1 }}>{title}</Typography>
            <IconButton disabled={disabled} onClick={() => openCorrectModal(title, "modal")}>
               <AddCircle sx={{ color: "#E0E0E0" }} />
            </IconButton>
         </Box>

         <Box sx={{ display: "flex", alignItems: "center", borderRadius: 1, columnGap: 0.5 }}>
            <Typography
               sx={{
                  bgcolor: "#73808F10",
                  borderRadius: 1,
                  display: "flex",
                  flex: 2,
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: 15,
               }}
            >{`Total ${length}`}</Typography>
            <Box sx={{ bgcolor: "#73808F10", borderRadius: 1, flex: 1, display: "flex", justifyContent: "center" }}>
               <IconButton disabled={disabled} onClick={() => openCorrectModal(title, "drawer")}>
                  <FormatListBulletedRounded
                     sx={{ fontSize: 20, color: "white", bgcolor: "#152C44", borderRadius: 1, p: 0.2 }}
                  />
               </IconButton>
            </Box>
         </Box>
      </Stack>
   );

   return (
      <div>
         <Box
            sx={{
               width: "100%",
               pb: 1,
               position: "relative",
               border: "1px solid #ccc",
               borderRadius: "8px",
               overflow: "hidden",
               boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            }}
         >
            <Grid
               item
               sx={{
                  bgcolor: "#162c44",
                  p: 2,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 40,
                  borderRadius: "5px 5px 0 0",
                  m: 0,
                  mb: 1,
               }}
            >
               <Typography sx={{ color: "white", fontWeight: "bold", fontSize: 18 }}>Figuras jurídicas</Typography>
            </Grid>

            <Box
               sx={{
                  width: "100%",
                  height: "115px",
                  overflow: "auto",
                  position: "relative",
                  flexGrow: 0,
                  flexShrink: 0,
                  top: 10,
               }}
            >
               {isLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", my: 3 }}>
                     <CircularProgress />
                  </Box>
               ) : (
                  <Box
                     sx={{
                        display: "flex",
                        gap: 4,
                        position: "absolute",
                        left: 5,
                        top: 0,
                        height: "100%",
                        paddingX: 0,
                     }}
                  >
                     <LegalFigure title="Fideicomiso" length={fideicomisosData.length} />
                     <LegalFigure title="Copropiedad" length={copropiedadData.length} />
                     <LegalFigure title="Coinversion" length={coinversionData.length} />
                     <LegalFigure title="Asociacion en participación" length={0} disabled />
                     <LegalFigure title="Aparceria rural" length={0} disabled />
                     <LegalFigure title="Usufructo" length={0} disabled />
                  </Box>
               )}
            </Box>
         </Box>
         {/* MODALS */}
         <BeneficiaryFideicomisoForm
            open={openFideicomisoModal}
            setOpen={setOpenFideicomisoModal}
            onClose={handleModalClose}
         />
         <BeneficiaryCoOwnerModal
            open={openCopropiedadModal}
            setOpen={setOpenCopropiedadModal}
            onClose={handleModalClose}
         />
         <BeneficiaryCoInvestmentModal
            open={openCoinversionModal}
            setOpen={setOpenCoinversionModal}
            onClose={handleModalClose}
         />
         {/* DRAWERS */}
         <FideiComisoDrawer open={openFideicomisoDrawer} setOpen={setOpenFideicomisoDrawer} />
      </div>
   );
};

export default LegalFigures;
