import React, { useState } from "react";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";

interface SearchbarPowersProps {
   originalRows: any[];
   filteredRows: any[];
   setFilteredRows: (rows: any[]) => void;
   disableRefresh?: boolean;
   fullWidth?: boolean;
}

const SearchbarPowers = (props: SearchbarPowersProps) => {
   const [searchValue, setSearchValue] = useState("");

   const filterPowers = (value: string) => {
      if (!value) {
         props.setFilteredRows(props.originalRows);
         return;
      }

      const filtered = props.originalRows.filter((row) => {
         const nombrePoderMatch = row.nombrePoder?.toString().toLowerCase().includes(value.toLowerCase());
         const apoderadoMatch = row.apoderado?.toString().toLowerCase().includes(value.toLowerCase());
         return nombrePoderMatch || apoderadoMatch;
      });

      props.setFilteredRows(filtered);
   };

   const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setSearchValue(value);
      filterPowers(value);
   };

   const handleReset = () => {
      setSearchValue("");
      props.setFilteredRows(props.originalRows);
   };

   return (
      <Box sx={{ display: "flex", flex: 1 }}>
         <Box
            sx={{
               my: 1,
               display: "flex",
               flexDirection: "row",
               justifyContent: "center",
               alignItems: "center",
               columnGap: 2,
               flex: props.fullWidth ? 1 : "",
            }}
         >
            <Box
               sx={{
                  flex: 2,
                  display: "flex",
                  flexDirection: "row",
                  columnGap: 2,
                  maxHeight: 40,
               }}
            >
               <Box sx={{ flex: 3 }}>
                  <TextField
                     type="text"
                     onChange={handleSearch}
                     value={searchValue}
                     size="small"
                     fullWidth
                     sx={{ bgcolor: "white" }}
                     label={
                        <Box sx={{ display: "flex", flexDirection: "row", columnGap: 1 }}>
                           <SearchIcon />
                           <Typography>Buscar por poder o apoderado</Typography>
                        </Box>
                     }
                  />
               </Box>
            </Box>
         </Box>
      </Box>
   );
};

export default SearchbarPowers;
