import React, { useContext } from "react";
import { BeneficiaryQuestionaryContext } from "../../../../../context/beneficiaryContext/beneficiaryQuestionaryContext";
import { Box, Button, LinearProgress } from "@mui/material";
import { ArrowBackIosNewRounded, ArrowForwardIosRounded } from "@mui/icons-material";

const QuestionaryStatusbar = () => {
   const { handlePagination, currentPage, totalPages, questionary, questionaryProgress } =
      useContext(BeneficiaryQuestionaryContext);

   return (
      questionary?.questions?.length > 0 && (
         <Box sx={{ width: { xs: "95vw", lg: 800 }, bgcolor: "white" }}>
            <Box sx={{ p: 2, display: "flex", justifyContent: "space-between", alignItems: "center", gap: 2 }}>
               <Button sx={{ px: 3 }} onClick={() => handlePagination("prev")} disabled={currentPage === 0}>
                  <ArrowBackIosNewRounded sx={{ fontSize: 15, mr: 1 }} /> Anterior
               </Button>
               <Box sx={{ width: "100%" }}>
                  <LinearProgress
                     variant="determinate"
                     value={questionaryProgress}
                     sx={{
                        height: 7,
                        borderRadius: 5,
                        backgroundColor: "#EBEBEB",
                        transition: "width 0.5s ease-in-out",
                     }}
                  />
               </Box>
               <Button sx={{ px: 3 }} onClick={() => handlePagination("next")} disabled={currentPage >= totalPages - 1}>
                  Siguiente <ArrowForwardIosRounded sx={{ fontSize: 15, ml: 1 }} />
               </Button>
            </Box>
         </Box>
      )
   );
};

export default QuestionaryStatusbar;
