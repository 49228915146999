import { KeyboardDoubleArrowRight, Settings } from "@mui/icons-material";
import { Box, ButtonBase, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import React, { useContext, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getContrastYIQ } from "../../const/globalConst";
import { InsightsBeneficiary } from "../../components/Beneficiary Controller/Subcomponents/InsightsBeneficiary";
import { BeneficiaryControllerContext } from "../../context/beneficiaryContext/beneficiaryContollerContext";
import { useGetMembersInfo } from "../../hooks/gob-corp/corporateData/useGetMembersInfo";
import { GuidesBeneficiary } from "../../components/Beneficiary Controller/Subcomponents/GuidesBeneficiary";
import { CorporateDataContext } from "../../context/governanceContext/corporateDataContext";
import { societaryDashBoardIcon } from "../../assets/icons/DrawerAppScreenIcons";

const BeneficiaryDashboard = () => {
   const { dashboardData } = useContext(BeneficiaryControllerContext);
   const { corporateData } = useContext(CorporateDataContext);
   const { companyId } = useParams();
   const navigate = useNavigate();
   const handleNavigate = (link) => navigate(link);
   const fileStatus = useMemo(() => dashboardData?.fileStatus, [dashboardData]);
   const { formatedMembers } = useGetMembersInfo(false);
   const isCivil = useMemo(() => corporateData?.societyKind === "Sociedad civil", [corporateData]);

   const cardData = useMemo(
      () => [
         {
            text: "Dashboard societario",
            center: (
               <Box
                  component={"img"}
                  src={societaryDashBoardIcon}
                  width={50}
                  loading="lazy"
                  sx={{ objectFit: "cover", pt: 2 }}
               />
            ),
            color: "#E0E0E0",
            link: `/beneficiario-controlador/${companyId}/dashboard-societario`,
         },
         {
            text: isCivil ? "Socios" : "Accionistas",
            center: formatedMembers?.length ?? dashboardData?.beneficiariesCount?.shareholder ?? <CircularProgress />,
            link: `/beneficiario-controlador/${companyId}/${isCivil ? "socios" : "accionistas"}`,
            color: "#E0E0E0",
         },
         {
            text: "Control efectivo",
            center: dashboardData?.beneficiariesCount?.efective ?? <CircularProgress />,
            color: "#E0E0E0",
            link: `/beneficiario-controlador/${companyId}/control-efectivo`,
         },
         {
            text: "Beneficiario controlador",
            center: dashboardData?.beneficiariesCount?.beneficiary ?? <CircularProgress />,
            color: "#E0E0E0",
            link: `/beneficiario-controlador/${companyId}/beneficiario-controlador`,
         },
      ],
      [corporateData, formatedMembers, dashboardData]
   );

   return (
      <Stack spacing={2} sx={{ py: 2, maxWidth: 1380 }}>
         <Box sx={{ boxShadow: 1, borderRadius: 3 }}>
            <Typography
               sx={{
                  py: 2,
                  px: 5,
                  bgcolor: "#162c44",
                  color: "white",
                  borderTopRightRadius: 10,
                  borderTopLeftRadius: 10,
               }}
            >
               Registros de beneficiario controlador
            </Typography>
            <Box sx={{ display: "flex", p: 5, justifyContent: "space-around", bgcolor: "#FFFFFF50" }}>
               {cardData.map((card, index) => (
                  <Card key={index} {...card} onNavigate={handleNavigate} />
               ))}
            </Box>
         </Box>
         <Box sx={{ display: "flex", columnGap: 2 }}>
            <Box sx={{ boxShadow: 1, borderRadius: 3, flex: 2, bgcolor: "white" }}>
               <Box
                  sx={{
                     py: 2,
                     bgcolor: "#162c44",
                     color: "white",
                     borderTopRightRadius: 10,
                     borderTopLeftRadius: 10,
                     display: "flex",
                     justifyContent: "space-between",
                     px: 5,
                  }}
               >
                  <Typography>Gestión documental</Typography>
                  <Typography>
                     Estatus:{" "}
                     {fileStatus && fileStatus.some((f) => f.completedPercentage !== "100%")
                        ? "Pendiente"
                        : "Completado"}
                  </Typography>
               </Box>
               {dashboardData ? (
                  <Stack
                     spacing={2}
                     sx={{
                        display: "flex",
                        bgcolor: "white",
                        overflowY: "auto",
                        maxHeight: 520,
                        borderBottomRightRadius: 10,
                        borderBottomLeftRadius: 10,
                     }}
                  >
                     {fileStatus &&
                        fileStatus?.map((file) => (
                           <Grid
                              container
                              sx={{
                                 textAlign: "center",
                                 borderBottom: "1px solid #D8D8D8",
                                 p: 2,
                              }}
                           >
                              <Grid item xs={4}>
                                 {file.fileName}
                              </Grid>
                              <Grid item xs={3}>
                                 {file.completedPercentage !== "100%" ? "Solicitud enviada" : "Completada"}
                              </Grid>
                              <Grid item xs={3} sx={{ maxWidth: 100 }}>
                                 {file.usersFoundInRequest.length === dashboardData?.beneficiariesCount?.beneficiary ? (
                                    <Typography>Todos</Typography>
                                 ) : (
                                    <Typography>{file.usersFoundInRequest.join(", ")}</Typography>
                                 )}
                              </Grid>
                              <Grid item xs={2}>
                                 <Typography
                                    sx={{
                                       border: "2px solid #BFC1C5",
                                       fontWeight: 600,
                                       borderRadius: 2,
                                       bgcolor: "#F5F5F5",
                                       width: 100,
                                       mx: "auto",
                                    }}
                                 >
                                    {file.completedPercentage}
                                 </Typography>
                              </Grid>
                           </Grid>
                        ))}
                  </Stack>
               ) : (
                  <Box sx={{ display: "flex", justifyContent: "center", py: 5 }}>
                     <CircularProgress />
                  </Box>
               )}
            </Box>
            <Stack spacing={2} sx={{ maxWidth: 450 }}>
               <InsightsBeneficiary />
               <GuidesBeneficiary />
            </Stack>
         </Box>
      </Stack>
   );
};

const Card = ({ text, center, color = "#F5F5F5", link, onNavigate }) => (
   <Stack
      sx={{
         borderRadius: 2,
         border: 1,
         borderColor: "#D8D8D8",
         width: 250,
         textAlign: "center",
         justifyContent: "center",
         bgcolor: "#FFFFFF",
      }}
   >
      <Stack sx={{ px: 3, py: 1, height: "100%", justifyContent: "center" }}>
         <Typography sx={{ fontSize: 18, color: "#3F5063", fontWeight: 600 }}>{text}</Typography>
         <Typography sx={{ fontSize: 48, color: "#3F5063", fontWeight: 700 }}>{center}</Typography>
      </Stack>
      <ButtonBase
         sx={{
            fontSize: 18,
            color: getContrastYIQ(color) === "black" ? "#3F5063" : "#F5F5F5",
            bgcolor: color,
            px: 2,
            py: 1,
            fontWeight: 600,
            borderBottomRightRadius: 5,
            borderBottomLeftRadius: 5,
            fontFamily: "open sans",
            borderTop: "1px solid #D8D8D8",
            columnGap: 1,
            transition: "all 0.1s ease",
            "&:hover": {
               bgcolor: "#162c44",
               color: "#fff",
            },
         }}
         onClick={() => onNavigate(link)}
      >
         Ver detalles
         <KeyboardDoubleArrowRight />
      </ButtonBase>
   </Stack>
);

export default BeneficiaryDashboard;
