import { AttachFile, Description } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, MenuItem, Select, Stack, Typography, CircularProgress } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { CustomModalComponent } from "../../../CustomModalComponent";
import { formatFileSize } from "../../../../const/globalConst";
import { SearchbarSelector } from "./SearchbarSelector";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SnackBarContext } from "../../../../context/snackBarContext";
import { UseGetPresignedURLUpload, uploadFileToS3 } from "../../../../lib/s3Client";
import { UserContext } from "../../../../context/userContext";
import { AddFileModal } from "../../../MiLecosy/AddFileModal";
import { Form, Formik, FormikProps } from "formik";
import { getUrlS3, updatePowerGranted } from "../../../../lib/usersBEClient";
import { useGetMembersInfo } from "../../../../hooks/gob-corp/corporateData/useGetMembersInfo";
import { CorporateDataContext } from "../../../../context/governanceContext/corporateDataContext";
import dayjs from "dayjs";
import { IPowerGranted } from "../../../../types/beneficiaryTypes";
import { IFile } from "../../../../types/BaseTypes";

const PODERES = [
   "Poder general para pleitos y cobranzas",
   "Poder general para actos de administración",
   "Poder en materia laboral",
   "Poder general para actos de dominio",
   "Poder para suscribir títulos y operaciones de crédito",
   "Poder para efectos bancarios y cambiarios",
   "Poder para otorgar y delegar poderes generales o especiales",
   "Facultades para ejercitar actos de administración aduanal",
   "Poder general para llevar a cabo actos de rescisión",
   "Poder para efectos fiscales",
];

interface PowersModifyModalProps {
   open: boolean;
   setOpen: (open: boolean) => void;
   power: IPowerGranted;
   onUpdate?: () => void;
}

interface PowerUpdateData {
   powerGranted: string;
   user: string | null;
   grantDate: Date | null;
   expirationDate: Date | null;
   company: string;
   representativeName: string;
   governanceBodyId: string;
   file?: {
      name: string;
      size: number;
      type: string;
   };
   fileMetadata?: {
      name: string;
      size: number;
      type: string;
   };
}

const PowerFormContent = ({ formProps, memberName, isLoading, setFileModalOpen, file, formatFileSize }) => {
   const formSelectedUser = useMemo(() => {
      if (formProps.values.user && memberName?.find((m) => m._id === formProps.values.user)) {
         return memberName.find((m) => m._id === formProps.values.user);
      } else if (formProps.values.representativeName) {
         return {
            _id: "",
            firstName: formProps.values.representativeName,
            lastName: "",
            name: formProps.values.representativeName,
            isManualEntry: true,
         };
      }
      return null;
   }, [formProps.values.user, formProps.values.representativeName, memberName]);

   return (
      <Form>
         <Box
            sx={{
               display: "flex",
               flexDirection: "column",
               maxHeight: 500,
               width: 700,
               px: 4,
               py: 2,
               rowGap: 2,
               bgcolor: "white",
               overflow: "auto",
            }}
         >
            <Box sx={{ display: "flex", columnGap: 8, justifyContent: "space-between" }}>
               <Stack sx={{ width: "100%", rowGap: 1 }}>
                  <>
                     <Typography>Poder otorgado:</Typography>
                     <Select
                        value={formProps.values.powerGranted}
                        onChange={(event) => {
                           formProps.setFieldValue("powerGranted", event.target.value);
                        }}
                        size="small"
                        fullWidth
                        sx={{
                           bgcolor: "#F5F5F5",
                           "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#E0E0E0",
                           },
                        }}
                     >
                        {PODERES.map((poder) => (
                           <MenuItem key={poder} value={poder}>
                              {poder}
                           </MenuItem>
                        ))}
                     </Select>

                     <Box sx={{ display: "flex", flexDirection: "column", rowGap: 1, paddingTop: 1 }}>
                        <Typography>Apoderado:</Typography>
                        <SearchbarSelector
                           users={memberName}
                           isLoading={isLoading}
                           placeholder="Selecciona o agrega apoderado"
                           initialSelectedUser={formSelectedUser}
                           setSelectedUser={(user) => {
                              try {
                                 if (!user) {
                                    formProps.setFieldValue("user", "");
                                    formProps.setFieldValue("representativeName", "");
                                    return;
                                 }

                                 if (user._id) {
                                    formProps.setFieldValue("user", user._id);
                                    formProps.setFieldValue(
                                       "representativeName",
                                       `${user.firstName || ""} ${user.lastName || ""}`.trim()
                                    );
                                    return;
                                 }

                                 formProps.setFieldValue("user", "");

                                 if (typeof user === "string") {
                                    formProps.setFieldValue("representativeName", user);
                                 } else if (user.isManualEntry) {
                                    formProps.setFieldValue("representativeName", user.firstName || user.name || "");
                                 } else if (user.name) {
                                    formProps.setFieldValue("representativeName", user.name);
                                 } else if (user.firstName) {
                                    formProps.setFieldValue("representativeName", user.firstName);
                                 }
                              } catch (error) {
                                 formProps.setFieldValue("user", "");
                                 if (user) {
                                    if (typeof user === "string") {
                                       formProps.setFieldValue("representativeName", user);
                                    } else {
                                       formProps.setFieldValue("representativeName", user.name || user.firstName || "");
                                    }
                                 }
                              }
                           }}
                        />
                     </Box>

                     <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={6}>
                           <Typography>Fecha de otorgamiento:</Typography>
                           <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                 value={formProps.values.grantDate}
                                 onChange={(date) => {
                                    formProps.setFieldValue("grantDate", date);
                                 }}
                                 slotProps={{
                                    textField: {
                                       size: "small",
                                       fullWidth: true,
                                       sx: {
                                          bgcolor: "#F5F5F5",
                                          "& .MuiOutlinedInput-notchedOutline": {
                                             borderColor: "#E0E0E0",
                                          },
                                       },
                                    },
                                 }}
                              />
                           </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6}>
                           <Typography>Fecha de vencimiento:</Typography>
                           <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                 value={formProps.values.expirationDate}
                                 onChange={(date) => {
                                    formProps.setFieldValue("expirationDate", date);
                                 }}
                                 slotProps={{
                                    textField: {
                                       size: "small",
                                       fullWidth: true,
                                       sx: {
                                          bgcolor: "#F5F5F5",
                                          "& .MuiOutlinedInput-notchedOutline": {
                                             borderColor: "#E0E0E0",
                                          },
                                       },
                                    },
                                 }}
                              />
                           </LocalizationProvider>
                        </Grid>
                     </Grid>

                     <Box sx={{ mt: 2, mb: 1 }}>
                        <Typography>Documento:</Typography>
                        {file || formProps.values.fileName ? (
                           <Box
                              sx={{
                                 display: "flex",
                                 alignItems: "center",
                                 bgcolor: "#F5F5F5",
                                 border: "1px solid #E0E0E0",
                                 borderRadius: 1,
                                 p: 1,
                                 mt: 1,
                              }}
                           >
                              <Description sx={{ mr: 1, color: "primary.main" }} />
                              <Box sx={{ flexGrow: 1 }}>
                                 <Typography variant="body2">{file ? file.name : formProps.values.fileName}</Typography>
                                 <Typography variant="caption" color="textSecondary">
                                    {file ? formatFileSize(file.size) : formProps.values.fileSize}
                                 </Typography>
                              </Box>
                              <IconButton
                                 onClick={() => {
                                    setFileModalOpen(true);
                                 }}
                                 size="small"
                              >
                                 <AttachFile fontSize="small" />
                              </IconButton>
                           </Box>
                        ) : (
                           <Button
                              variant="outlined"
                              startIcon={<AttachFile />}
                              onClick={() => setFileModalOpen(true)}
                              fullWidth
                              sx={{
                                 mt: 1,
                                 textTransform: "none",
                                 borderColor: "#E0E0E0",
                                 color: "text.primary",
                              }}
                           >
                              Adjuntar documento
                           </Button>
                        )}
                     </Box>
                  </>
               </Stack>
            </Box>
         </Box>

         <Box
            sx={{
               display: "flex",
               justifyContent: "flex-end",
               bgcolor: "#F3F3F3",
               width: "100%",
               p: 1,
               height: 50,
               borderBottomRightRadius: 5,
               borderBottomLeftRadius: 5,
            }}
         >
            <Button
               variant="outlined"
               onClick={() => formProps.resetForm()}
               sx={{ mr: 1, textTransform: "none" }}
               disabled={isLoading}
            >
               Cancelar
            </Button>
            <Button variant="contained" type="submit" sx={{ textTransform: "none" }} disabled={isLoading}>
               {isLoading ? <CircularProgress size={24} color="inherit" /> : "Guardar cambios"}
            </Button>
         </Box>
      </Form>
   );
};

const PowersModifyModal = ({ open, setOpen, power, onUpdate }: PowersModifyModalProps) => {
   const { companySelected } = useContext(UserContext);
   const [fileModalOpen, setFileModalOpen] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [file, setFile] = useState<null | File>(null);
   const { showSnackBar } = useContext(SnackBarContext);
   const { formatedMembers, members } = useGetMembersInfo(false);
   const { governanceBody } = useContext(CorporateDataContext);
   const [initialValues, setInitialValues] = useState({
      powerGranted: "",
      user: "",
      grantDate: null,
      expirationDate: null,
      representativeName: "",
      fileName: "",
      fileSize: "",
   });

   const memberName = useMemo(() => {
      return members?.map((member) => ({
         _id: member.user,
         firstName: member.firstName,
         lastName: member.lastName,
      }));
   }, [formatedMembers, members]);

   const fetchFile = async () => {
      try {
         const fileUrl = await getUrlS3("files-lecosy", { folder: power.file.fileDirection }, power.file.name);
         const response = await fetch(fileUrl);
         const blob = await response.blob();
         const file = new File([blob], power.file.name, { type: "application/pdf" });
         setFile(file);
      } catch (error) {
         console.error("Error fetching file:", error);
      }
   };

   useEffect(() => {
      const fetchPowerData = async () => {
         if (power) {
            try {
               setIsLoading(true);
               fetchFile();
               const grantDate = power.grantDate ? dayjs(power.grantDate) : null;
               const expirationDate = power.expirationDate ? dayjs(power.expirationDate) : null;

               let fileName = "";
               let fileSize = "";

               if (power.file && typeof power.file === "object") {
                  fileName = power.file.name || "";

                  if (power.file.size !== undefined) {
                     const size = typeof power.file.size === "string" ? parseInt(power.file.size) : power.file.size;
                     fileSize = formatFileSize(size);
                  }
               }

               setInitialValues({
                  powerGranted: power.powerGranted || "",
                  user: power.user?._id || "",
                  grantDate: grantDate,
                  expirationDate: expirationDate,
                  representativeName: power.representativeName || "",
                  fileName: fileName,
                  fileSize: fileSize,
               });
            } catch (error) {
               showSnackBar("Error al cargar los datos del poder", true);
               console.error(error);
            } finally {
               setIsLoading(false);
            }
         }
      };
      fetchPowerData();
   }, [power, companySelected?._id]);

   const handleUploadFile = async (powerFile: IFile, file: File): Promise<boolean> => {
      try {
         if (!file) return false;

         const urlReceipt = await UseGetPresignedURLUpload({
            bucket: "files-lecosy",
            folder: powerFile.fileDirection,
            name: powerFile.name,
         });

         if (!urlReceipt?.data) return false;

         await uploadFileToS3(urlReceipt.data, file);
         return true;
      } catch (error) {
         const errorMsg = error.response?.data?.message || error.message || "Unknown error";
         showSnackBar(`Error al subir el archivo: ${errorMsg}`, true);
         return false;
      }
   };

   const handleUpdate = async (values) => {
      try {
         setIsLoading(true);

         if (!values.powerGranted) {
            showSnackBar("Seleccione un poder", true);
            return;
         }

         if (!values.user && !values.representativeName) {
            showSnackBar("Seleccione un apoderado", true);
            return;
         }

         if (!values.grantDate) {
            showSnackBar("Seleccione una fecha de otorgamiento", true);
            return;
         }

         if (!companySelected?._id) {
            showSnackBar("Error: No hay empresa seleccionada", true);
            return;
         }

         if (!governanceBody?._id) {
            showSnackBar("Error: No hay órgano de gobierno seleccionado", true);
            return;
         }

         let grantDate = null;
         if (values.grantDate) {
            grantDate = values.grantDate.$d || values.grantDate;
            grantDate = grantDate instanceof Date ? grantDate : new Date(grantDate);
         }

         let expirationDate = null;
         if (values.expirationDate) {
            expirationDate = values.expirationDate.$d || values.expirationDate;
            expirationDate = expirationDate instanceof Date ? expirationDate : new Date(expirationDate);
         }

         let cleanUser = values.user;
         let cleanRepresentativeName = values.representativeName || "";

         if (!cleanUser && !cleanRepresentativeName) {
            showSnackBar("Seleccione un apoderado", true);
            setIsLoading(false);
            return;
         }

         const updateData: PowerUpdateData = {
            powerGranted: values.powerGranted,
            user: cleanUser || null,
            grantDate: grantDate,
            expirationDate: expirationDate,
            company: companySelected._id,
            representativeName: cleanRepresentativeName,
            governanceBodyId: governanceBody._id,
         };

         if (file && file instanceof File) {
            let fileType = "";
            try {
               fileType = file.type && file.type.includes("/") ? file.type.split("/")[1] : "";
            } catch (error) {
               fileType = "";
            }

            const uploadSuccess = await handleUploadFile(power.file, file);
            if (!uploadSuccess) {
               showSnackBar("Error al subir el archivo", true);
               setIsLoading(false);
               return;
            }

            updateData.fileMetadata = {
               name: power.file.name,
               size: file.size,
               type: fileType,
            };
         }

         await updatePowerGranted(power._id, updateData);

         showSnackBar("Poder modificado correctamente", false);
         if (onUpdate) onUpdate();
         setOpen(false);
      } catch (error) {
         if (error.response) {
            const errorMessage = error.response.data.message || "Error al actualizar el poder";
            showSnackBar(errorMessage, true);
         } else {
            showSnackBar(`Error: ${error.message || "Error desconocido"}`, true);
         }
      } finally {
         setIsLoading(false);
      }
   };

   return (
      <div>
         <CustomModalComponent title="Modificar poder" setOpen={setOpen} open={open}>
            <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={handleUpdate}>
               {(formProps: FormikProps<any>) => (
                  <PowerFormContent
                     formProps={formProps}
                     memberName={memberName}
                     isLoading={isLoading}
                     setFileModalOpen={setFileModalOpen}
                     file={file}
                     formatFileSize={formatFileSize}
                  />
               )}
            </Formik>
         </CustomModalComponent>

         <AddFileModal state={fileModalOpen} setState={setFileModalOpen} setFile={setFile} file={file} />
      </div>
   );
};

export default PowersModifyModal;
