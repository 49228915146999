import {
   Box,
   Button,
   CircularProgress,
   Stack,
   Typography,
   Checkbox,
   MenuItem,
   ListItemText,
   TextField,
   Select,
   Divider,
   Grid,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { SnackBarContext } from "../../../context/snackBarContext";
import { CustomModalComponent } from "../../CustomModalComponent";
import { UserContext } from "../../../context/userContext";
import {
   addGovernBodyRole,
   addUserRoleOffice,
   getAllResources,
   getRoleByCharge,
   getUserByEmail,
   getUsersByIds,
   getUsersDetails,
   updateCorporateData,
   useCreateUser,
} from "../../../lib/usersBEClient";
import { BodyUserFormat } from "../GoverningBodiesComponent";
import { UserSearchSelector } from "../../Inputs/UserSearchSelector";
import { addUserGovernBody, getAllCharges } from "../../../lib/gobCorpBEClient";
import { CorporateDataContext } from "../../../context/governanceContext/corporateDataContext";
import EconomyModal from "./EconomyModal";
import ControllingBeneficiaryModal from "./ControllingBeneficiaryModal";
import { BeneficiaryControllerContext } from "../../../context/beneficiaryContext/beneficiaryContollerContext";
import _ from "lodash";

interface AddShareholderModalProps {
   state: boolean;
   setState: Function;
}

interface UserData {
   firstName: string;
   lastName: string;
   phoneNumber: string;
   email: string;
}

const shareHolderTypes = ["Persona física", "Persona moral"];

const UsefetchCompanyUsers = () => {
   const { members } = useContext(CorporateDataContext);
   const { companySelected } = useContext(UserContext);
   const [isLoading, setIsLoading] = useState(false);
   const [users, setUsers] = useState([]);
   useEffect(() => {
      const getUsers = async () => {
         setIsLoading(true);
         let users: any[] = [];
         if (companySelected.group) {
            const usersIds: any[] = (companySelected.group as any).users;
            const usersResponse = await getUsersByIds(usersIds);
            users = [...usersResponse];
         }
         users = companySelected.company_details.admin
            ? [...users, ...companySelected.company_details.users, companySelected.company_details.admin]
            : [...users, ...companySelected.company_details.users];

         const uniqueUsers = _.uniqBy(users, "_id");
         const usersIds = uniqueUsers.map((u: any) => u._id);
         const usersDetails = await getUsersDetails(usersIds);
         let usersFormat = [];
         for (let t = 0; t < users.length; t++) {
            usersFormat.push({
               ...usersDetails.find((e) => e.user === users[t]._id),
               ...users[t],
            });
         }
         const bodyUsersIds = members.map((e) => e.user);
         const filterUsers: BodyUserFormat[] = usersFormat.filter(
            (user) => user.disabled === false && !bodyUsersIds.includes(user._id)
         );
         setUsers(filterUsers);
         setIsLoading(false);
      };
      if (members && companySelected?.company_details) getUsers();
   }, [members, companySelected]);

   return { users, isLoading };
};

export const AddShareholderModal = (props: AddShareholderModalProps) => {
   const [submitLoading, setSubmitLoading] = useState(false);
   const { series, governanceBody, updateMembers, corporateData } = useContext(CorporateDataContext);
   const { companySelected } = useContext(UserContext);
   const { users, isLoading } = UsefetchCompanyUsers();
   const { showSnackBar } = useContext(SnackBarContext);
   const [founder, setFounder] = useState(false);
   const [seed, setSeed] = useState(0);
   const [legalR, setLegalR] = useState(false);
   const [controllerB, setControllerB] = useState(true);
   const [effectiveC, setEffectiveC] = useState(false);
   const [selectedUser, setSelectedUser] = useState<any>(null);
   const [rfc, setRfc] = useState("");
   const { mutate } = useCreateUser();
   const [shareHolderType, setShareHolderType] = useState("");
   const [razonSocial, setRazonSocial] = useState("");
   const [newUserData, setNewUserData] = useState<UserData>(null);
   const [selectedSeries, setSelectedSeries] = useState<any[]>([]);
   const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
   const [isEconomyModalOpen, setEconomyModalOpen] = useState(false);
   const [isControllingBeneficiaryOpen, setControllingBeneficiaryOpen] = useState(false);
   const [isActive, setIsActive] = useState(false);
   const { refetch } = useContext(BeneficiaryControllerContext);
   const [foundByEmail, setFoundByEmail] = useState(null);

   const handleNewUserData = (value, field) =>
      setNewUserData((s) => {
         return { ...s, [field]: value };
      });

   const handleChange = () => {
      setFounder(!founder);
   };

   const handleChangeLegal = () => {
      setLegalR(!legalR);
   };

   const handleChangeController = () => {
      setControllerB(!controllerB);
   };

   const handleChangeEffective = () => {
      setEffectiveC(!effectiveC);
   };

   const handleClose = () => {
      setSelectedSeries([]);
      setLegalR(false);
      setControllerB(false);
      setEffectiveC(false);
      setRfc(undefined);
      setFounder(false);
      setSubmitLoading(false);
      setSelectedUser(undefined);
      setRazonSocial(null);
   };

   const handleEconomyModalConfirm = (open) => {
      if (open) {
         window.open("https://psm.economia.gob.mx/PSM/avisoInscripcion.jsf", "gob");
         window.open("https://sya.sat.gob.mx/login", "sat");
      }
      setEconomyModalOpen(false);
      props.setState(false);
      setControllingBeneficiaryOpen(false);
      handleClose();
   };

   const handleControllingBeneficiaryConfirm = () => {
      refetch();
      setControllingBeneficiaryOpen(false);
      handleClose();
      props.setState(false);
   };

   const handleSubmit = async () => {
      if (selectedSeries.length === 0) return showSnackBar("Seleccionar participación", true);
      if (!shareHolderType) return showSnackBar("Seleccionar tipo de accionista/socio", true);
      if (!rfc) return showSnackBar("Favor de introducir RFC", true);
      for (const serie of selectedSeries) {
         if (serie.sharesAmount < 0 || serie.sharesAmount >= 99999999 || !serie.sharesAmount)
            return showSnackBar(`Ingresar cantidad de acciones valida en ${serie.title}`, true);
         if (serie.votes <= 0 || serie.votes >= 99999999 || !serie.votes)
            return showSnackBar(`Ingresar cantidad de votos valida en ${serie.title}`, true);
      }

      try {
         setSubmitLoading(true);
         const response = await getAllCharges();
         const shareholderChargeId = response.find((charge) => charge.chargeName === "Accionista")._id;
         const resourceResponse = await getAllResources();
         const resourceId = resourceResponse.find((r) => r.name === "Mi Lecosy")._id;
         if (!resourceId) return showSnackBar("Error al agregar accionista", true);
         const roleId = await getRoleByCharge(shareholderChargeId);

         if (shareHolderType === "Persona moral" || isActive) {
            if (newUserData === null || Object.entries(newUserData).length < 4)
               return showSnackBar("Datos insuficientes para nuevo usuario", true);
            if (foundByEmail) {
               const formatedRoles = {
                  company: companySelected?._id,
                  roles: [roleId._id],
               };

               const roleAdded = await addUserRoleOffice(formatedRoles, foundByEmail._id);
               if (!roleAdded) return showSnackBar("Error al agregar rol a usuario.", true);

               const userData = {
                  user: foundByEmail._id,
                  charge: shareholderChargeId,
                  founder,
                  controllerB,
                  effectiveC,
                  actions: selectedSeries,
                  businessName: razonSocial,
                  rfc: rfc,
                  addedDate: new Date(),
               };
               await addUserGovernBody(governanceBody._id, userData, companySelected?._id, resourceId);
               updateMembers();
               setSelectedUserId(userData.user);
               showSnackBar("Accionista agregado correctamente", false);
               // Modal de Beneficiario
               if (legalR && corporateData) {
                  const datac = {
                     companyId: companySelected?._id,
                     legalRepresentative: `${foundByEmail.firstName} ${foundByEmail.lastName}`,
                  };
                  await updateCorporateData(corporateData._id, datac);
               }
               if (controllerB || effectiveC) setControllingBeneficiaryOpen(true);
               else setEconomyModalOpen(true);
            } else {
               const formatedRoles = [
                  {
                     company: companySelected?._id,
                     roles: [roleId._id],
                  },
               ];
               mutate(
                  {
                     userData: {
                        firstName: newUserData.firstName,
                        lastName: newUserData.lastName,
                        email: newUserData.email,
                        phoneNumber: newUserData.phoneNumber,
                        role: formatedRoles,
                     },
                     additionalData: {
                        admin: false,
                        company: companySelected?._id,
                        roles: formatedRoles,
                     },
                  },
                  {
                     onError: (error: any) => {
                        error.response.data.message === "email already in use"
                           ? showSnackBar("Ya existe un usuario con ese correo", true)
                           : showSnackBar("Error al agregar usuario.", true);
                     },
                     onSuccess: async (data) => {
                        const userData = {
                           user: data.user._id,
                           charge: shareholderChargeId,
                           founder,
                           controllerB,
                           effectiveC,
                           actions: selectedSeries,
                           businessName: razonSocial,
                           rfc: rfc,
                           addedDate: new Date(),
                        };
                        await addUserGovernBody(governanceBody._id, userData, companySelected?._id, resourceId);
                        updateMembers();
                        setSelectedUserId(userData.user);
                        showSnackBar("Accionista agregado correctamente", false);
                        // Modal de Beneficiario
                        if (legalR && corporateData) {
                           const datac = {
                              companyId: companySelected?._id,
                              legalRepresentative: `${data.user.firstName} ${data.user.lastName}`,
                           };
                           await updateCorporateData(corporateData._id, datac);
                        }
                        if (controllerB || effectiveC) setControllingBeneficiaryOpen(true);
                        else setEconomyModalOpen(true);
                     },
                  }
               );
            }
         } else {
            if (!selectedUser) return showSnackBar("Seleccionar usuario existente", true);
            const userData = {
               user: selectedUser?._id,
               charge: shareholderChargeId,
               founder,
               controllerB,
               effectiveC,
               actions: selectedSeries,
               rfc: rfc,
               addedDate: new Date(),
            };
            await addUserGovernBody(governanceBody._id, userData, companySelected?._id, resourceId);
            const roleData = {
               role: roleId._id,
               company: companySelected?._id,
               user: selectedUser?._id,
            };
            await addGovernBodyRole(roleData, governanceBody._id);
            updateMembers();
            if (legalR && corporateData) {
               const data = {
                  companyId: companySelected?._id,
                  legalRepresentative: `${selectedUser.firstName} ${selectedUser.lastName}`,
               };
               await updateCorporateData(corporateData._id, data);
            }
            showSnackBar("Accionista agregado correctamente", false);

            if (controllerB || effectiveC) setControllingBeneficiaryOpen(true);
            else setEconomyModalOpen(true);
         }
      } catch (error) {
         console.log(error);
         showSnackBar("Error al agregar accionista", true);
      } finally {
         setSubmitLoading(false);
      }
   };

   const handleAddSerie = (serie) => {
      setSelectedSeries(
         [...selectedSeries, serie].sort((a, b) => {
            let titleA = a.title.toLowerCase();
            let titleB = b.title.toLowerCase();
            if (titleA < titleB) {
               return -1;
            }
            if (titleA > titleB) {
               return 1;
            }
            return 0;
         })
      );
   };

   const handleRemoveSerie = (serie) => {
      const tempSeries = selectedSeries;
      const deleteIndex = tempSeries.map((s) => s.title).indexOf(serie.title);
      const newArr = tempSeries.slice(0, deleteIndex).concat(tempSeries.slice(deleteIndex + 1));
      setSelectedSeries(newArr);
   };

   const handleUpdateSerie = (serie) => {
      const tempSeries = selectedSeries;
      const updateIndex = tempSeries.map((s) => s.title).indexOf(serie.title);
      tempSeries[updateIndex] = serie;
      setSelectedSeries(tempSeries);
   };

   const handleButtonClickAddUser = () => {
      setIsActive((prevState) => !prevState);
   };

   const searchUserByEmail = async (email: string) => {
      try {
         const userFound = await getUserByEmail(email);
         if (!userFound) return setFoundByEmail(null);
         setFoundByEmail(userFound);

         handleNewUserData(userFound.firstName, "firstName");
         handleNewUserData(userFound.lastName, "lastName");
         handleNewUserData(userFound.email, "email");
         handleNewUserData(userFound.phoneNumber, "phoneNumber");
      } catch (error) {
         return setFoundByEmail(null);
      }
   };

   //#endregion

   return (
      <CustomModalComponent
         open={props.state}
         setOpen={props.setState}
         onClose={handleClose}
         title="Registro de accionista/socio"
         timeStamp
      >
         <Box
            sx={{
               display: "flex",
               flexDirection: "column",
               maxHeight: 500,
               width: 700,
               px: 4,
               py: 2,
               rowGap: 2,
               bgcolor: "white",
               overflow: "auto",
            }}
         >
            <Stack sx={{ width: "60%", rowGap: 1 }}>
               <Typography>Tipo de accionista/socio:</Typography>
               <Select size="small" variant="outlined">
                  {shareHolderTypes.map((e) => (
                     <MenuItem
                        key={e}
                        value={e}
                        onClick={() => {
                           setShareHolderType(e);
                        }}
                     >
                        <ListItemText primary={e} sx={{ my: -0.1 }} />
                     </MenuItem>
                  ))}
               </Select>
            </Stack>
            <Box sx={{ display: "flex", columnGap: 8, justifyContent: "space-between" }}>
               <Stack sx={{ width: "100%", rowGap: 1 }}>
                  {shareHolderType !== "Persona moral" ? (
                     <>
                        <Typography>Nombre accionista/socio:</Typography>
                        <UserSearchSelector
                           users={users}
                           isLoading={isLoading}
                           placeholder="Agregar usuario existente"
                           setSelectedUser={(user) => {
                              setSelectedUser(user);
                              setSelectedUserId(user?._id);
                           }}
                           disabled={isActive}
                        />
                        <Box
                           sx={{
                              display: "flex",
                              flexDirection: "column",
                              rowGap: 1,
                              paddingTop: 1,
                           }}
                        >
                           <Typography>RFC:</Typography>
                           <TextField
                              type="text"
                              size="small"
                              placeholder="XXXXXXXXXXXXX"
                              onBlur={(value) => {
                                 setRfc(value.target.value);
                              }}
                           />
                        </Box>
                     </>
                  ) : (
                     <>
                        <Typography>Razón social:</Typography>
                        <TextField
                           type="text"
                           size={"small"}
                           placeholder="Nombre"
                           onBlur={(value) => {
                              setRazonSocial(value.target.value);
                           }}
                        />
                        <Box
                           sx={{
                              display: "flex",
                              flexDirection: "column",
                              rowGap: 1,
                              paddingTop: 1,
                           }}
                        >
                           <Typography>RFC:</Typography>
                           <TextField
                              type="text"
                              size="small"
                              placeholder="XXXXXXXXXXXXX"
                              onBlur={(value) => {
                                 setRfc(value.target.value);
                              }}
                           />
                        </Box>
                     </>
                  )}
               </Stack>
               {shareHolderType !== "Persona moral" ? (
                  <Stack sx={{ width: "40%", paddingTop: "32px" }}>
                     <Button variant="contained" onClick={handleButtonClickAddUser}>
                        <Typography color={"#fff"}>Agregar Usuario</Typography>
                     </Button>

                     <Box
                        sx={{
                           display: "flex",
                           flexDirection: "column",
                           rowGap: 1,
                           paddingTop: 3,
                        }}
                     >
                        <Typography>Fundador:</Typography>
                        <Stack sx={{ flexDirection: "row", justifyContent: "space-evenly", alignItems: "center" }}>
                           <Typography>Sí</Typography>
                           <Checkbox checked={founder} onChange={handleChange} />
                           <Typography>No</Typography>
                           <Checkbox checked={!founder} onChange={handleChange} />
                        </Stack>
                     </Box>
                  </Stack>
               ) : (
                  <Box
                     sx={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: 1,
                        width: 200,
                     }}
                  >
                     <Typography>Fundador:</Typography>
                     <Stack sx={{ flexDirection: "row", justifyContent: "space-evenly", alignItems: "center" }}>
                        <Typography>Sí</Typography>
                        <Checkbox checked={founder} onChange={handleChange} />
                        <Typography>No</Typography>
                        <Checkbox checked={!founder} onChange={handleChange} />
                     </Stack>
                  </Box>
               )}
            </Box>
            {(shareHolderType === "Persona moral" || isActive) && (
               <Box>
                  {shareHolderType === "Persona moral" && <Typography>Apoderado legal</Typography>}
                  <Grid container sx={{ gap: 2 }}>
                     <Grid item xs={5.5}>
                        <Typography>Nombre(s)</Typography>
                        <TextField
                           size="small"
                           type="text"
                           variant="outlined"
                           placeholder="Nombre(s)"
                           fullWidth
                           value={foundByEmail ? newUserData.firstName : undefined}
                           onBlur={(input) => handleNewUserData(input.target.value, "firstName")}
                           disabled={!!foundByEmail}
                        />
                     </Grid>
                     <Grid item xs={5.5}>
                        <Typography>Apellidos</Typography>
                        <TextField
                           size="small"
                           type="text"
                           variant="outlined"
                           value={foundByEmail ? newUserData.lastName : undefined}
                           placeholder="Apellidos"
                           fullWidth
                           onBlur={(input) => handleNewUserData(input.target.value, "lastName")}
                           disabled={!!foundByEmail}
                        />
                     </Grid>
                     <Grid item xs={5.5}>
                        <Typography>Teléfono</Typography>
                        <TextField
                           size="small"
                           type="tel"
                           variant="outlined"
                           placeholder="Teléfono"
                           fullWidth
                           value={newUserData?.phoneNumber || ""}
                           onBlur={(e) => handleNewUserData(e.target.value, "phoneNumber")}
                           disabled={!!foundByEmail}
                           inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                           onChange={(e) => {
                              const value = e.target.value.replace(/\D/g, "");
                              handleNewUserData(value, "phoneNumber");
                           }}
                        />
                     </Grid>
                     <Grid item xs={5.5}>
                        <Typography>Correo electrónico</Typography>
                        <TextField
                           size="small"
                           type="email"
                           variant="outlined"
                           placeholder="Correo electrónico"
                           fullWidth
                           onBlur={(input) => {
                              searchUserByEmail(input.target.value);
                              handleNewUserData(input.target.value, "email");
                           }}
                        />
                     </Grid>
                  </Grid>
               </Box>
            )}
            <Stack spacing={1} sx={{ width: "40%" }}>
               <Typography>Seleccionar serie:</Typography>

               <Select
                  variant="outlined"
                  size={"small"}
                  fullWidth
                  value={selectedSeries ? selectedSeries.map((s) => s?.title) : []}
                  multiple
                  renderValue={() => <Typography sx={{ color: "#969696" }}>Seleccionar serie</Typography>}
               >
                  {series &&
                     series.map((serie, i) => (
                        <MenuItem
                           key={i}
                           value={serie.title}
                           onClick={() => {
                              if (!selectedSeries.some((s) => s.title === serie.title)) handleAddSerie(serie);
                              else if (selectedSeries.length > 1) handleRemoveSerie(serie);
                           }}
                        >
                           <Checkbox
                              checked={selectedSeries.some((s) => s.title === serie.title)}
                              sx={{ py: 0, my: 0 }}
                           />
                           <ListItemText primary={serie.title} />
                        </MenuItem>
                     ))}
               </Select>
            </Stack>
            <Stack spacing={0.5} key={selectedSeries.length}>
               {selectedSeries.map((serie, i) => (
                  <Box
                     key={i}
                     sx={{
                        display: "flex",
                        columnGap: 2,
                        p: 1,
                        px: 2,
                        borderRadius: 2,
                        bgcolor: "whitesmoke",
                        alignItems: "center",
                        justifyContent: "space-between",
                     }}
                  >
                     <Typography fontWeight={600}>{serie?.title}</Typography>
                     <Divider orientation="vertical" sx={{ height: 30 }} />
                     <Stack
                        sx={{
                           columnGap: 2,
                           flexDirection: "row",
                           alignItems: "center",
                        }}
                     >
                        <Typography fontWeight={600}>Acciones</Typography>

                        <TextField
                           type="number"
                           size={"small"}
                           placeholder="#"
                           defaultValue={selectedSeries[i]?.sharesAmount}
                           onBlur={(value) => {
                              handleUpdateSerie({
                                 title: serie.title,
                                 sharesAmount: parseInt(value.target.value),
                                 votes: serie.votes,
                              });
                              setSeed(seed + 1);
                           }}
                           sx={{ width: 90 }}
                        />
                     </Stack>
                     <Divider orientation="vertical" sx={{ height: 30 }} />

                     <Stack
                        sx={{
                           columnGap: 2,
                           flexDirection: "row",
                           alignItems: "center",
                        }}
                     >
                        <Typography fontWeight={600}>Votos</Typography>
                        <TextField
                           type="number"
                           size={"small"}
                           placeholder="#"
                           defaultValue={selectedSeries[i]?.votes}
                           onBlur={(value) => {
                              handleUpdateSerie({
                                 title: serie.title,
                                 sharesAmount: serie.sharesAmount,
                                 votes: parseInt(value.target.value),
                              });
                              setSeed(seed + 1);
                           }}
                           sx={{ width: 90 }}
                        />
                     </Stack>
                  </Box>
               ))}
            </Stack>
            <Box>
               <Typography>Asignar como:</Typography>
               <Box sx={{ display: "flex", columnGap: 3, alignItems: "center" }}>
                  {shareHolderType !== "Persona moral" && (
                     <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                           checked={legalR}
                           onChange={handleChangeLegal}
                           id="legalRepresentative"
                           name="legalRepresentative"
                        />
                        <Typography
                           sx={{
                              color: "#969696",
                           }}
                        >
                           Apoderado legal
                        </Typography>
                     </Box>
                  )}
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                     <Checkbox
                        checked={controllerB}
                        onChange={handleChangeController}
                        id="beneficiaryController"
                        name="beneficiaryController"
                     />
                     <Typography
                        sx={{
                           color: "#969696",
                        }}
                     >
                        Beneficiario controlador
                     </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                     <Checkbox
                        checked={effectiveC}
                        onChange={handleChangeEffective}
                        id="effectiveControl"
                        name="effectiveControl"
                     />
                     <Typography
                        sx={{
                           color: "#969696",
                        }}
                     >
                        Control efectivo
                     </Typography>
                  </Box>
               </Box>
            </Box>
         </Box>
         <Box
            sx={{
               display: "flex",
               justifyContent: "flex-end",
               bgcolor: "#F3F3F3",
               width: "100%",
               p: 1,
               height: 50,
               borderBottomRightRadius: 5,
               borderBottomLeftRadius: 5,
            }}
         >
            <Button
               onClick={() => {
                  props.setState(false);
                  handleClose();
               }}
               sx={{ color: "black", height: 35, ":hover": { bgcolor: "#E5E6EB" }, mr: 2 }}
            >
               Cancelar
            </Button>
            <Button
               onClick={handleSubmit}
               disabled={submitLoading}
               sx={{ height: 35, ":hover": { bgcolor: "success" } }}
            >
               {!submitLoading ? "Guardar" : <CircularProgress size={24} />}
            </Button>
            {isControllingBeneficiaryOpen && (
               <ControllingBeneficiaryModal
                  state={isControllingBeneficiaryOpen}
                  setState={setControllingBeneficiaryOpen}
                  companyId={companySelected._id}
                  userId={selectedUserId}
                  shareHolderType={shareHolderType}
                  rfc={rfc}
                  businessName={razonSocial}
                  onConfirm={handleControllingBeneficiaryConfirm}
                  timeStamp={true}
                  beneficiaries={[]}
                  control={effectiveC}
               />
            )}
            {isEconomyModalOpen && (
               <EconomyModal
                  state={isEconomyModalOpen}
                  setState={setEconomyModalOpen}
                  onConfirm={handleEconomyModalConfirm}
               />
            )}
         </Box>
      </CustomModalComponent>
   );
};
