import { useContext, useState } from "react";
import {
   Box,
   Grid,
   Typography,
   Divider,
   FormControlLabel,
   Checkbox,
   AccordionDetails,
   AccordionSummary,
   Accordion,
} from "@mui/material";
import { getDateAndTimeFromISODate } from "../../const/globalConst";
import { ComplaintStepperContext } from "../../context/complaintStepperContext";
import { capitalizeFirstLetter } from "../../const/globalConst";
import { ComplaintFileRecord } from "../ComplaintInfo/ComplaintFileRecord";
import { InputTextField } from "../Inputs/InputTextField";
import EvidenceTable from "../Evidences/EvidenceTable";
import UseGetDocumentationList from "../../hooks/complaints/getDocumentList";
import PersonIcon from "@mui/icons-material/Person";

export const CommonDetails = () => {
   const { complaint } = useContext(ComplaintStepperContext);
   const [expanded, setExpanded] = useState<string | false>("panel0");

   const noDeleteHeaders = [
      { field: "file", headerName: "Evidencia" },
      { field: "iconDelete", headerName: "Borrar" },
      { field: "icon", headerName: "Ver" },
   ];

   const handleChangeAccordion = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
   };

   const accordionData = (data: any, i: number, type: string) => {
      const documentList = UseGetDocumentationList({
         selected: data,
         subject: type,
         reportNumber: complaint.reportNumber,
      });

      return (
         <Accordion
            key={`${data.name} Involved ${i}`}
            expanded={expanded === `panel${data._id ? data._id : "1"}`}
            onChange={handleChangeAccordion(`panel${data._id ? data._id : "1"}`)}
            sx={{ borderRadius: 0 }}
         >
            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
               <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ display: "flex", flex: 1 }}>
                     <PersonIcon sx={{ mr: 1 }} />
                     <Typography fontWeight={600} fontSize={17}>
                        {data.name !== ""
                           ? `${data.name}  ${data.lastName}`
                           : complaint.complaintType === "anonima"
                           ? `${(complaint.type || "denuncia") !== "denuncia" ? "Interpositor" : "Denunciante"} anónimo`
                           : ` ${type + (i + 1)}`}
                     </Typography>
                  </Box>
               </Box>
            </AccordionSummary>
            <AccordionDetails>
               <Box
                  sx={{
                     display: "flex",
                     flexDirection: "column",
                  }}
               >
                  <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
                     <Box
                        sx={{
                           flex: 1,
                           justifyContent: "center",
                           display: "flex",
                           alignItems: "center",
                        }}
                     >
                        {documentList.length > 0 ? (
                           <EvidenceTable
                              rows={documentList}
                              headers={noDeleteHeaders}
                              reportNumber={complaint.reportNumber}
                              subject={type}
                              selectedUser={data._id}
                           />
                        ) : (
                           <Typography>Esta persona no tiene evidencias</Typography>
                        )}
                     </Box>
                  </Box>
               </Box>
            </AccordionDetails>
         </Accordion>
      );
   };
   return (
      <>
         <Grid container>
            <Grid item xs={4}>
               <Box display={"flex"} sx={dataFieldSX}>
                  <Typography fontWeight={600} variant="body1">
                     {`Reporte de ${complaint.type || "denuncia"}`}
                  </Typography>
                  <Typography variant="subtitle1">{complaint.reportNumber}</Typography>
               </Box>
            </Grid>
            <Grid item xs={4}>
               <Box display={"flex"} sx={dataFieldSX}>
                  <Typography fontWeight={600} variant="body1">
                     {`${capitalizeFirstLetter(complaint.type || "denuncia")} anónima`}
                  </Typography>
                  <Typography variant="subtitle1">{complaint.complaintType === "anonima" ? "Si" : "No"}</Typography>
               </Box>
            </Grid>
            <Grid item xs={4}>
               <Box display={"flex"} sx={dataFieldSX}>
                  <Typography fontWeight={600} variant="body1">
                     Clasificación
                  </Typography>
                  <Typography textAlign={"center"} variant="subtitle1">
                     {complaint.clasification || "No aplica"}
                  </Typography>
               </Box>
            </Grid>
            {/* <Grid item xs={3} sx={{ display: "flex", alignItems: "center", px: 1 }}>
               <InputTextField
                  variant="standard"
                  fullWidth
                  name="dateTime"
                  id="datetime-local"
                  label="Fecha y hora"
                  type="datetime-local"
                  InputLabelProps={{
                     shrink: true,
                  }}
               />
            </Grid> */}
         </Grid>
         <Divider sx={{ mx: 2 }} />
         <Grid container>
            <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
               <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                  {`Datos ${(complaint.type || "denuncia") !== "denuncia" ? "del interpositor" : "del denunciante"}`}
               </Typography>
            </Grid>
            <Grid item xs={4}>
               <Box display={"flex"} sx={dataFieldSX}>
                  <Typography fontWeight={600} variant="body1" sx={{ textAlign: "center" }}>
                     {`Tipo de ${(complaint.type || "denuncia") !== "denuncia" ? "interpositor" : "denunciante"}`}
                  </Typography>
                  <Typography>{complaint.complainerType}</Typography>
               </Box>
            </Grid>
            <Grid item xs={4}>
               <Box display={"flex"} sx={dataFieldSX}>
                  <Typography fontWeight={600} variant="body1" sx={{ textAlign: "center" }}>
                     Se le afectó directamente
                  </Typography>
                  <Typography>{complaint.directlyAffected ? "Sí" : "No"}</Typography>
               </Box>
            </Grid>
            <Grid item xs={4}>
               <Box display={"flex"} sx={dataFieldSX}>
                  <Typography fontWeight={600} variant="body1" sx={{ textAlign: "center" }}>
                     Número telefónico
                  </Typography>
                  <Typography variant="subtitle1">
                     {complaint.complainerDetails ? complaint.complainerDetails.phoneNumber : "6325794510"}
                  </Typography>
               </Box>
            </Grid>
            <Grid item xs={4}>
               <Box display={"flex"} sx={dataFieldSX}>
                  <Typography fontWeight={600} variant="body1" sx={{ textAlign: "center" }}>
                     Nombre
                  </Typography>
                  <Typography variant="subtitle1">
                     {complaint.complainerDetails
                        ? complaint.complainerDetails.name + " " + complaint.complainerDetails.lastName
                        : "No aplica"}
                  </Typography>
               </Box>
            </Grid>
            <Grid item xs={4}>
               <Box display={"flex"} sx={dataFieldSX}>
                  <Typography fontWeight={600} variant="body1" sx={{ textAlign: "center" }}>
                     Correo electrónico
                  </Typography>
                  <Typography variant="subtitle1">
                     {complaint.complainerDetails ? complaint.complainerDetails.email : "No aplica"}
                  </Typography>
               </Box>
            </Grid>
            <Grid item xs={4}>
               <Box display={"flex"} sx={dataFieldSX}>
                  <Typography fontWeight={600} variant="body1" sx={{ textAlign: "center" }}>
                     Domicilio
                  </Typography>
                  <Typography variant="subtitle1">
                     {complaint.complainerDetails ? complaint.complainerDetails.address : "No aplica"}
                  </Typography>
               </Box>
            </Grid>
         </Grid>
         <Divider sx={{ mx: 2 }} />
         {complaint.offenderDetails?.length > 0 && (
            <div>
               <Grid container>
                  <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                     <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                        {complaint.offenderDetails.length > 1 ? "Datos de los denunciados" : "Datos del denunciado"}
                     </Typography>
                  </Grid>
                  {complaint.offenderDetails.map((e) => {
                     return (
                        <>
                           <Grid item xs={4}>
                              <Box display={"flex"} sx={dataFieldSX}>
                                 <Typography fontWeight={600} variant="body1" sx={{ textAlign: "center" }}>
                                    Nombre
                                 </Typography>
                                 <Typography variant="subtitle1">{e.name + " " + e.lastName}</Typography>
                              </Box>
                           </Grid>
                           <Grid item xs={4}>
                              <Box display={"flex"} sx={dataFieldSX}>
                                 <Typography fontWeight={600} variant="body1" sx={{ textAlign: "center" }}>
                                    Puesto
                                 </Typography>
                                 <Typography variant="subtitle1">{e.position}</Typography>
                              </Box>
                           </Grid>
                           <Grid item xs={4}>
                              <Box display={"flex"} sx={dataFieldSX}>
                                 <Typography fontWeight={600} variant="body1">
                                    Género
                                 </Typography>
                                 <Typography>{e.gender}</Typography>
                              </Box>
                           </Grid>
                           <Grid item xs={12}>
                              <Box
                                 display={"flex"}
                                 sx={{
                                    alignItems: "center",
                                    flexDirection: "column",
                                    py: 2,
                                    px: 12,
                                 }}
                              >
                                 <Typography fontWeight={600} variant="body1" sx={{ textAlign: "right" }}>
                                    Observaciones
                                 </Typography>
                                 <Typography variant="subtitle1">{e.observations}</Typography>
                              </Box>
                           </Grid>
                        </>
                     );
                  })}
               </Grid>
               <Divider sx={{ mx: 2 }} />
            </div>
         )}
         {complaint.witnesses?.length > 0 && (
            <div>
               <Grid container>
                  <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                     <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                        {complaint.witnesses.length > 1 ? "Datos de los testigos" : "Datos del testigo"}
                     </Typography>
                  </Grid>
                  {complaint.witnesses.map((e) => {
                     return (
                        <>
                           <Grid item xs={4}>
                              <Box display={"flex"} sx={dataFieldSX}>
                                 <Typography fontWeight={600} variant="body1" sx={{ textAlign: "center" }}>
                                    Nombre
                                 </Typography>
                                 <Typography variant="subtitle1">{e.name + " " + e.lastName}</Typography>
                              </Box>
                           </Grid>
                           <Grid item xs={4}>
                              <Box display={"flex"} sx={dataFieldSX}>
                                 <Typography fontWeight={600} variant="body1" sx={{ textAlign: "center" }}>
                                    Puesto
                                 </Typography>
                                 <Typography variant="subtitle1">{e.position}</Typography>
                              </Box>
                           </Grid>
                           <Grid item xs={4}>
                              <Box display={"flex"} sx={dataFieldSX}>
                                 <Typography fontWeight={600} variant="body1">
                                    Género
                                 </Typography>
                                 <Typography>{e.gender}</Typography>
                              </Box>
                           </Grid>
                           <Grid item xs={12}>
                              <Box
                                 display={"flex"}
                                 sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    py: 2,
                                    px: 12,
                                 }}
                              >
                                 <Typography fontWeight={600} variant="body1" sx={{ textAlign: "right" }}>
                                    Observaciones
                                 </Typography>
                                 <Typography variant="subtitle1" textAlign={"justify"}>
                                    {e.observations}
                                 </Typography>
                              </Box>
                           </Grid>
                        </>
                     );
                  })}
               </Grid>
               <Divider sx={{ mx: 2 }} />
            </div>
         )}

         <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
               <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                  {`Datos de la ${complaint.type || "denuncia"}`}
               </Typography>
            </Grid>
            <Grid item xs={6}>
               <Box display={"flex"} sx={dataFieldSX}>
                  <Typography fontWeight={600} variant="body1" sx={{ textAlign: "center" }}>
                     Fecha del acto
                  </Typography>
                  <Typography variant="subtitle1">{getDateAndTimeFromISODate(complaint.createdAt)}</Typography>
               </Box>
            </Grid>
            <Grid item xs={6}>
               <Box display={"flex"} sx={dataFieldSX}>
                  <Typography fontWeight={600} variant="body1" sx={{ textAlign: "center" }}>
                     Donde sucedieron los hechos
                  </Typography>
                  <Typography variant="subtitle1">{complaint.specificPlace || "No aplica"}</Typography>
               </Box>
            </Grid>
            <Grid item xs={12} paddingTop={2}>
               <Box display={"flex"} sx={{ display: "flex", flexDirection: "column", px: 12, pb: 1 }}>
                  <Typography fontWeight={600} variant="body1" sx={{ pb: 1 }} textAlign={"center"}>
                     {`Descripción de la ${complaint.type || "denuncia"}`}
                  </Typography>
                  <Typography variant="subtitle1" textAlign={"justify"}>
                     {complaint.body}
                  </Typography>
               </Box>
            </Grid>
         </Grid>
         <Divider sx={{ mx: 2 }} />
         <Grid container>
            <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
               <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                  Reporte Lecosy
               </Typography>
            </Grid>
            <Grid item xs={4}>
               <Box display={"flex"} sx={dataFieldSX}>
                  <Typography fontWeight={600} variant="body1" sx={{ textAlign: "center" }}>
                     {`Estatus de la ${complaint.type || "denuncia"}`}
                  </Typography>
                  <Typography variant="subtitle1">{complaint.status}</Typography>
               </Box>
            </Grid>
            <Grid item xs={4}>
               <Box display={"flex"} sx={dataFieldSX}>
                  <Typography fontWeight={600} variant="body1" sx={{ textAlign: "center" }}>
                     {`Fecha de interposición de la ${complaint.type || "denuncia"}`}
                  </Typography>
                  <Typography variant="subtitle1">{getDateAndTimeFromISODate(complaint.createdAt)}</Typography>
               </Box>
            </Grid>
            <Grid item xs={4}>
               <Box display={"flex"} sx={dataFieldSX}>
                  <Typography fontWeight={600} variant="body1" sx={{ textAlign: "center" }}>
                     Clasificación del hecho
                  </Typography>
                  <Typography textAlign={"center"} variant="subtitle1">
                     {complaint.clasification || "No aplica"}
                  </Typography>
               </Box>
            </Grid>
            <Grid item xs={12}>
               <Box display={"flex"} sx={{ display: "flex", flexDirection: "column", px: 12, pb: 1 }}>
                  <Typography fontWeight={600} variant="body1" sx={{ pb: 1 }} textAlign={"center"}>
                     Observaciones
                  </Typography>
                  <Typography variant="subtitle1" textAlign={"center"}>
                     {complaint.observations ? `${complaint.observations}` : "No aplica"}
                  </Typography>
               </Box>
            </Grid>
         </Grid>
         <Grid>
            <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
               <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                  Documentación
               </Typography>
            </Grid>
            <Grid item xs={12}>
               <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column", p: 4 }}>
                  <ComplaintFileRecord includeStepper={false} clousure={true} />
               </Box>
            </Grid>
            <Grid container sx={{ pb: 5 }}>
               <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                  <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                     Evidencias
                  </Typography>
               </Grid>
               <Grid item xs={12}>
                  <Box sx={{ display: "flex", justifyContent: "center", px: 6 }}>
                     <Box sx={{ display: "flex", flexDirection: "column", flex: 1, gap: 2 }}>
                        <>
                           <Typography variant="h6" align="center">
                              {`${(complaint.type || "denuncia") !== "denuncia" ? "Interpositor" : "Denunciante"}`}
                           </Typography>
                           {complaint.complaintType !== "no anonima"
                              ? accordionData({ name: "", evidence: complaint.anonimousEvidence }, 0, "Denunciante")
                              : accordionData(complaint.complainerDetails, 0, "Denunciante")}
                        </>
                        {complaint.type === "denuncia" && (
                           <>
                              <Typography variant="h6" align="center">
                                 Denunciados
                              </Typography>
                              {complaint.offenderDetails.length > 0 ? (
                                 complaint.offenderDetails.map((offender, i) =>
                                    accordionData(offender, i, "Denunciado")
                                 )
                              ) : (
                                 <Typography variant="body1" sx={{ p: 1, border: 1, borderColor: "#DCDCDC" }}>
                                    No existen denunciados
                                 </Typography>
                              )}
                           </>
                        )}
                        {complaint.type === "denuncia" && (
                           <>
                              <Typography variant="h6" align="center">
                                 Testigos
                              </Typography>
                              {complaint.witnesses.length > 0 ? (
                                 complaint.witnesses.map((witness, i) => accordionData(witness, i, "Testigo"))
                              ) : (
                                 <Typography variant="body1" sx={{ p: 1, border: 1, borderColor: "#DCDCDC" }}>
                                    No existen testigos
                                 </Typography>
                              )}
                           </>
                        )}
                     </Box>
                     {/* )} */}
                  </Box>
               </Grid>
            </Grid>
         </Grid>
      </>
   );
};

const dataFieldSX = {
   alignItems: "center",
   justifyContent: "center",
   flexDirection: "column",
   py: 2,
   px: 2,
};
