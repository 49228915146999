import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
   getAnswersByUser,
   getAnswersOfUser,
   getEvaluationQuestions,
   getLastEvaluation,
   getUserEvaluationProgress,
} from "../../lib/gobCorpBEClient";
import { UserContext } from "../userContext";
import { GovernanceContext } from "./governanceContext";

interface IEvaluationContext {
   questions: any[];
   fetchQuestions: Function;
   personQuestions: any[];
   councilQuestions: any[];
   isLoadingQuestions: boolean;
   activeCouncil: any;
   setActiveCouncil: Function;
   responsesByCouncil: any[];
   addNewResponse: Function;
   activePersonEvaluation: any;
   setActivePersonEvaluation: Function;
   dotMembersByCouncil: any[];
   total: (step: any[]) => number;
   verifyStepsToContinue: Function;
   progressMembers: any;
   fetchLastEvaluation: Function;
   evaluation: any;
   isLoadingEvaluation: boolean;
   finished: boolean;
   isLoadingPersonalEvaluation: boolean;
   responsesByMember: any[];
   refreshInfo: Function;
   setUserData: Function;
   setFinished: Function;
   userData: any[];
   setResponsesByCouncil: Function;
}

export const EvaluationContext = createContext<IEvaluationContext>({
   questions: [],
   fetchQuestions: () => {},
   personQuestions: [],
   councilQuestions: [],
   isLoadingQuestions: false,
   activeCouncil: {},
   setActiveCouncil: () => {},
   responsesByCouncil: [],
   addNewResponse: () => {},
   activePersonEvaluation: {},
   setActivePersonEvaluation: () => {},
   dotMembersByCouncil: [],
   total: () => 0,
   verifyStepsToContinue: () => {},
   progressMembers: {},
   fetchLastEvaluation: () => {},
   evaluation: {},
   isLoadingEvaluation: false,
   finished: false,
   isLoadingPersonalEvaluation: false,
   responsesByMember: [],
   refreshInfo: () => {},
   setUserData: () => {},
   setFinished: () => {},
   userData: [],
   setResponsesByCouncil: () => {},
});

export const EvaluationProvider = ({ children }) => {
   const [questions, setQuestions] = useState([]);
   const [isLoadingQuestions, setIsLoadingQuestions] = useState(false);
   const [activeCouncil, setActiveCouncil] = useState(null);
   const [responsesByCouncil, setResponsesByCouncil] = useState([]);
   const [activePersonEvaluation, setActivePersonEvaluation] = useState(null);
   const [evaluation, setEvaluation] = useState(null);
   const [isLoadingEvaluation, setIsLoadingEvaluation] = useState(true);
   const [isLoadingPersonalEvaluation, setIsLoadingPersonalEvaluation] = useState(true);
   const [responsesByMember, setResponsesByMember] = useState([]);
   const [finished, setFinished] = useState(false);
   const [userData, setUserData] = useState([]);
   const { user, companySelectedUserP } = useContext(UserContext);
   const { gobernanceBody, selectedGovernance, companySelected: governanceContext } = useContext(GovernanceContext);
   const [userProgress, setUserProgress] = useState(null);

   const refreshInfo = (setOpenModalEv: Function) => {
      // setResponsesByCouncil([]);
      // setActivePersonEvaluation(null);
      // setActiveCouncil(null);
      setOpenModalEv(false);
   };

   const addNewResponse = (response: any) => {
      setResponsesByCouncil((prevState) => {
         if (response.toPerson) {
            const found = prevState.findIndex(
               (question) =>
                  question.question === response.question &&
                  question.memberEvaluation === response.memberEvaluation &&
                  question.governance === response.governance
            );
            if (found !== -1) {
               prevState.splice(found, 1);
            }
            const newState = [...prevState, response];
            return newState;
         } else {
            const found = prevState.findIndex(
               (question) =>
                  question.question === response.question &&
                  !question.toPerson &&
                  question.governance === response.governance
            );
            if (found !== -1) {
               prevState.splice(found, 1);
            }
            const newState = [...prevState, response];
            return newState;
         }
      });
   };

   const fetchQuestions = async () => {
      try {
         setIsLoadingQuestions(true);
         const questionsResponse = await getEvaluationQuestions();
         setQuestions(questionsResponse);
         setIsLoadingQuestions(false);
      } catch (error) {}
   };

   const fetchLastEvaluation = async () => {
      try {
         setIsLoadingEvaluation(true);

         const companyToUse = await companySelectedUserP._id;

         if (!companyToUse) {
            setIsLoadingEvaluation(false);
            setIsLoadingPersonalEvaluation(false);
            return;
         }

         const evaluationResponse = await getLastEvaluation(companyToUse);

         if (evaluationResponse.length > 0) {
            const lastEvaluation = evaluationResponse[0];
            setEvaluation(lastEvaluation);
            setFinished(lastEvaluation.closed || lastEvaluation.finishedByUser);
            setIsLoadingPersonalEvaluation(!lastEvaluation.finishedByUser);
         } else {
            setEvaluation(null);
            setFinished(false);
            setIsLoadingPersonalEvaluation(false);
         }
      } catch (error) {
         console.error("Error fetching last evaluation:", error);
      } finally {
         setIsLoadingEvaluation(false);
      }
   };

   useEffect(() => {
      fetchLastEvaluation();
   }, [selectedGovernance, companySelectedUserP]);

   useEffect(() => {
      if (evaluation) {
         fetchAnswers();
      }
   }, [evaluation, finished]);

   const fetchUserProgress = useCallback(async () => {
      if (gobernanceBody.length === 0) return;
      const response = await getUserEvaluationProgress(evaluation._id);
      if (response) {
         setActiveCouncil(gobernanceBody.find((gov) => gov._id === response.council));
         setUserProgress(response);
      } else {
         setActiveCouncil(
            gobernanceBody.filter(
               (governance) =>
                  governance.title !== "PANEL DE USUARIO" &&
                  governance.title.toLocaleLowerCase() !== "asamblea de accionistas"
            )[0]
         );
      }
   }, [evaluation, finished, gobernanceBody]);

   useEffect(() => {
      if (evaluation && companySelectedUserP) {
         fetchUserProgress();
      }
   }, [fetchUserProgress]);

   const fetchAnswers = async () => {
      try {
         setIsLoadingPersonalEvaluation(true);
         const evaluationResponse = await getAnswersOfUser(evaluation._id);
         if (evaluationResponse.length > 0) {
            setResponsesByCouncil(evaluationResponse);
         } else {
            setResponsesByCouncil([]);
         }
         setIsLoadingPersonalEvaluation(false);
      } catch (error) {}
   };

   const fetchAnswersCompleted = async () => {
      try {
         const evaluationResponse = await getAnswersByUser(evaluation._id);
         if (evaluationResponse.length > 0) {
            setResponsesByMember(evaluationResponse);
         } else {
            setResponsesByMember([]);
         }
      } catch (error) {}
   };

   useEffect(() => {
      if (evaluation) {
         fetchAnswersCompleted();
      }
   }, [evaluation, finished]);

   const personQuestions = useMemo(() => {
      return questions.filter((question) => question.toPerson);
   }, [questions]);

   const councilQuestions = useMemo(() => {
      return questions.filter((question) => !question.toPerson);
   }, [questions]);

   const dotMembersByCouncil = useMemo(() => {
      if (activeCouncil) {
         const steps = [];
         for (const userC of activeCouncil.users) {
            if (userC.user !== user.id) {
               steps.push({
                  ...userC,
                  completed: false,
               });
            }
         }
         return [{ ...activeCouncil, completed: false }, ...steps];
      }
      return [];
   }, [activeCouncil, user.id]);

   const setCheckpoint = useCallback(() => {
      if (dotMembersByCouncil.length > 0 && userProgress) {
         const dotFound = dotMembersByCouncil.find((dot) => dot._id === userProgress.lastSave);
         if (dotFound && dotFound.user) {
            setActivePersonEvaluation(dotFound);
         }
      }
   }, [dotMembersByCouncil, userProgress]);

   useEffect(() => {
      setCheckpoint();
   }, [setCheckpoint]);

   const total = useCallback(
      (steps) => {
         let counter = 0;
         for (const step of steps) {
            const userFound = step.users.find((usr) => usr.user === user.id);
            if (userFound) {
               counter += step.users.length - 1;
            } else {
               counter += step.users.length;
            }
         }
         return counter * personQuestions.length + steps.length * councilQuestions.length;
      },
      [councilQuestions, personQuestions]
   );

   const progressMembers = useMemo(() => {
      if (!activeCouncil) return;
      const responses = responsesByCouncil.filter((response) => response.governance === activeCouncil._id);
      const progress = {
         0: 0,
      };
      if (dotMembersByCouncil) {
         for (let i = 0; i < dotMembersByCouncil.length; i++) {
            if (i === 0) {
               progress[i] =
                  responses.filter(
                     (response) => response.governance === dotMembersByCouncil[i]._id && !response.memberEvaluation
                  ).length / councilQuestions.length;
            } else {
               progress[i] =
                  responses.filter((response) => response.memberEvaluation === dotMembersByCouncil[i].user).length /
                  personQuestions.length;
            }
         }
      }
      return progress;
   }, [
      responsesByCouncil,
      dotMembersByCouncil,
      councilQuestions,
      personQuestions,
      activeCouncil,
      activePersonEvaluation,
   ]);

   const verifyStepsToContinue = () => {
      if (activePersonEvaluation) {
         const questions = personQuestions.length;
         const responses = responsesByCouncil.filter(
            (question) =>
               question.memberEvaluation === activePersonEvaluation.user && question.governance === activeCouncil._id
         );
         if (questions === responses.length) {
            return true;
         }
         return false;
      } else if (councilQuestions) {
         const questions = councilQuestions.length;
         const responses = responsesByCouncil.filter(
            (question) => question.governance === activeCouncil._id && !question.toPerson
         );
         if (questions === responses.length) {
            return true;
         }
         return false;
      }
      return false;
   };

   return (
      <EvaluationContext.Provider
         value={{
            questions,
            fetchQuestions,
            personQuestions,
            councilQuestions,
            isLoadingQuestions,
            activeCouncil,
            setActiveCouncil,
            responsesByCouncil,
            addNewResponse,
            activePersonEvaluation,
            setActivePersonEvaluation,
            dotMembersByCouncil,
            total,
            verifyStepsToContinue,
            progressMembers,
            fetchLastEvaluation,
            evaluation,
            isLoadingEvaluation,
            finished,
            isLoadingPersonalEvaluation,
            responsesByMember,
            refreshInfo,
            setUserData,
            setFinished,
            userData,
            setResponsesByCouncil,
         }}
      >
         {children}
      </EvaluationContext.Provider>
   );
};
