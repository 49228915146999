import { Box, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, TableFooter } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import React, { useContext, useMemo } from "react";
import { useGetMembersInfo } from "../../../hooks/gob-corp/corporateData/useGetMembersInfo";
import { CorporateDataContext } from "../../../context/governanceContext/corporateDataContext";
import { UserContext } from "../../../context/userContext";

interface TableMemberRow {
   name: string;
   type: string;
   participation: number;
   shares: string;
   hasVotingRights: boolean;
   votingShares: string;
}

const AssemblyMembers = () => {
   const { formatedMembers, members } = useGetMembersInfo(false);
   const { affairs, series, governanceBody } = useContext(CorporateDataContext);

   const tableData: TableMemberRow[] = useMemo(() => {
      if (formatedMembers?.length === 0 || !formatedMembers) return [];
      if (series?.length === 0 || !series) return [];

      return formatedMembers.map((member, index) => {
         const seriesData = series.reduce(
            (acc, serie) => ({
               ...acc,
               [serie.title]: member[serie.title],
            }),
            {}
         );

         const memberSeriesNames = Object.keys(seriesData);
         const hasVote = affairs.some((affair) =>
            affair.series.some((serie) => memberSeriesNames.includes(serie.title) && serie.faculties > 1)
         );

         const originalMember = members[index];
         const memberType =
            originalMember?.businessName && originalMember.businessName !== "" ? "Persona moral" : "Persona física";

         return {
            name: member.name,
            type: memberType,
            participation: member.propertyPercentage,
            shares: member.totalActions,
            ...seriesData,
            hasVotingRights: hasVote,
            votingShares: member.votes,
         };
      });
   }, [formatedMembers, series, members, affairs]);
   const baseHeaderCells = ["Nombre, denominación o razón social", "Tipo", "Participación", "Acciones"];

   const headerCells = useMemo(() => {
      if (series?.length === 0) return [];
      return [
         ...baseHeaderCells,
         ...series.map((serie) => serie.title),
         "Derecho a voto",
         "Acciones con derecho a voto",
      ];
   }, [series]);

   const commonCellStyle = {
      textAlign: "center" as const,
      fontSize: 14,
      fontWeight: "bold",
      padding: "8px",
      minWidth: "150px",
      maxWidth: "150px",
      whiteSpace: "normal",
      wordBreak: "break-word",
      verticalAlign: "middle",
   };

   const getCellStyle = (index: number, totalColumns: number) => ({
      bgcolor: "white",
      border: "1px solid #d3d3d3",
      textAlign: "center" as const,
      fontSize: 13,
      minWidth: "150px",
      maxWidth: "150px",
      padding: "8px",
      whiteSpace: "normal",
      wordBreak: "break-word",
      verticalAlign: "middle",
      "&:first-of-type": {
         position: "sticky",
         left: 0,
         backgroundColor: "white",
         zIndex: 1,
         "&::after": {
            content: '""',
            position: "absolute",
            right: 0,
            top: 0,
            bottom: 0,
            width: "1px",
            backgroundColor: "#d3d3d3",
            zIndex: 3,
         },
      },
      "&:last-of-type": {
         borderColor: "#d3d3d3",
      },
   });

   const calculateTotals = useMemo(() => {
      if (!tableData.length)
         return {
            participation: 0,
            shares: 0,
            seriesTotal: {},
            votingShares: 0,
         };

      const totals = {
         participation: Math.min(
            100,
            tableData.reduce((sum, row) => sum + (Number(row.participation) || 0), 0)
         ),
         shares: tableData.reduce((sum, row) => sum + (Number(row.shares) || 0), 0),
         seriesTotal: series.reduce(
            (acc, serie) => ({
               ...acc,
               [serie.title]: tableData.reduce((sum, row) => sum + (Number(row[serie.title]) || 0), 0),
            }),
            {}
         ),
         votingShares: tableData.reduce((sum, row) => sum + (Number(row.votingShares) || 0), 0),
      };

      return totals;
   }, [tableData, series]);

   const footerCellStyle = {
      ...commonCellStyle,
      backgroundColor: "#eeeeee",
      fontWeight: "bold",
      borderLeft: "1px solid #eeeeee",
      borderRight: "1px solid ##eeeeee",
      position: "sticky",
      bottom: 0,
      "&:first-of-type": {
         position: "sticky",
         left: 0,
         zIndex: 4,
         color: "#000000",
      },
      "&:last-of-type": {
         borderColor: "#eeeeee",
         backgroundColor: "#eeeeee",
         color: "#000000",
         fontWeight: 900,
      },
   };

   return (
      <Grid
         sx={{
            border: "1px solid #ccc",
            borderRadius: "8px",
            overflow: "hidden",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
         }}
      >
         <Grid
            item
            sx={{
               bgcolor: "#162c44",
               p: 2,
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               height: 40,
               borderRadius: "5px 5px 0 0",
            }}
         >
            <Typography sx={{ color: "white", fontWeight: "bold", fontSize: 18 }}>Cuadro accionario</Typography>
         </Grid>
         <Box
            sx={{
               minHeight: "350px",
               maxHeight: "350px",
               overflow: "auto",
               position: "relative",
               "& .MuiTableHead-root": {
                  position: "sticky",
                  top: 0,
                  zIndex: 2,
                  backgroundColor: "#eeeeee",
               },
               "& .MuiTableFooter-root": {
                  position: "sticky",
                  bottom: -1,
                  zIndex: 2,
                  backgroundColor: "#eeeeee",
                  marginBottom: "-1px", // Add this to remove the gap
               },
               "& .MuiTable-root": {
                  tableLayout: "fixed",
                  minWidth: `${headerCells.length * 150}px`, // Calculate minimum width based on columns
               },
               "& .MuiTableCell-root": {
                  height: "auto",
                  minHeight: "30px",
                  verticalAlign: "middle",
               },
            }}
         >
            <Table>
               <TableHead>
                  <TableRow sx={{ bgcolor: "#eeeeee", height: "30px" }}>
                     {headerCells.map((header, index) => (
                        <TableCell
                           key={header}
                           sx={{
                              ...commonCellStyle,
                              position: index === 0 ? "sticky" : "static",
                              left: index === 0 ? 0 : "auto",
                              zIndex: index === 0 ? 3 : 2,
                              backgroundColor: "#eeeeee",
                              borderLeft: "1px solid #d3d3d3",
                              borderRight: "1px solid #d3d3d3",
                           }}
                        >
                           {header}
                        </TableCell>
                     ))}
                  </TableRow>
               </TableHead>
               <TableBody>
                  {tableData.map((row, rowIndex) => (
                     <TableRow key={rowIndex}>
                        {/* Base cells */}
                        <TableCell sx={getCellStyle(0, headerCells.length)}>{row.name}</TableCell>
                        <TableCell sx={getCellStyle(1, headerCells.length)}>{row.type}</TableCell>
                        <TableCell sx={getCellStyle(2, headerCells.length)}>
                           <Box sx={{ bgcolor: "#eeeeee", borderRadius: 5 }}>{row.participation}%</Box>
                        </TableCell>
                        <TableCell sx={getCellStyle(3, headerCells.length)}>{row.shares}</TableCell>

                        {/* Dynamic series cells */}
                        {series.map((serie, index) => (
                           <TableCell key={serie.title} sx={getCellStyle(4 + index, headerCells.length)}>
                              {row[serie.title]}
                           </TableCell>
                        ))}

                        {/* Voting rights cells */}
                        <TableCell sx={getCellStyle(4 + series.length, headerCells.length)}>
                           {row.hasVotingRights && <CheckBoxIcon sx={{ color: "#162c44" }} />}
                        </TableCell>
                        <TableCell sx={getCellStyle(5 + series.length, headerCells.length)}>
                           {row.votingShares}
                        </TableCell>
                     </TableRow>
                  ))}
               </TableBody>
               <TableFooter>
                  <TableRow>
                     <TableCell sx={footerCellStyle}>TOTAL:</TableCell>
                     <TableCell sx={footerCellStyle}></TableCell>
                     <TableCell sx={footerCellStyle}>
                        <Box sx={{ bgcolor: "#eeeeee", borderRadius: 5, fontWeight: 900, color: "#000000" }}>
                           {Math.floor(calculateTotals?.participation || 0)}%
                        </Box>
                     </TableCell>
                     <TableCell sx={{ ...footerCellStyle, fontWeight: 900, color: "#000000" }}>
                        {calculateTotals?.shares}
                     </TableCell>

                     {/* Dynamic series totals */}
                     {series.map((serie) => (
                        <TableCell
                           key={`total-${serie.title}`}
                           sx={{ ...footerCellStyle, fontWeight: 900, color: "#000000" }}
                        >
                           {calculateTotals?.seriesTotal[serie.title]}
                        </TableCell>
                     ))}

                     <TableCell sx={footerCellStyle}></TableCell>
                     <TableCell sx={footerCellStyle}>{calculateTotals?.votingShares}</TableCell>
                  </TableRow>
               </TableFooter>
            </Table>
         </Box>
      </Grid>
   );
};

export default AssemblyMembers;
